<template>
  <div class="qr-code">
    <div class="code">
      <div class="c-mask" v-if="mask[status]">
        <span>{{ mask[status] }}</span>
      </div>
      <div ref="qrcodeRef"></div>
    </div>
    <div class="num">{{ code || '完成下方任務讓票券生效' }}</div>
  </div>
</template>

<script>
import QRCode from '../../../tools/qrcode.js'

export default {
  name: 'QRCode',
  props: {
    status: {
      type: Number,
      default: 0
    },
    code: {
      type: String,
      default: '完成下方任務讓票券生效'
    }
  },
  data() {
    return {
      qrcode: null,
      mask: {
        0: '待完成', // 待完成
        1: '', // 未使用
        2: '已使用', // 已使用
        3: ' ' // 已过期
      }
    }
  },
  watch: {
    code(value) {
      console.log(value)
      if (value) {
        // 文本改变重新生成二维码
        this.qrcode.makeCode(value)
      }
    }
  },
  mounted() {
    // 生成二维码
    this.qrcode = new QRCode(this.$refs.qrcodeRef, {
      text: this.code || '完成下方任務讓票券生效',
      width: 136,
      height: 136
    })
  },
  destroyed() {
    // 清除二维码
    this.qrcode.clear()
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  .code {
    width: 136px;
    height: 136px;
    margin: 0 auto;
    position: relative;
    .c-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 15px;
        font-weight: 500;
        color: #7f7f7f;
        line-height: 22px;
      }
    }
  }
  .num {
    text-align: center;
    font-size: 13px;
    color: #333333;
    line-height: 22px;
    margin-top: 14px;
  }
}
</style>
