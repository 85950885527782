<template>
  <section v-if="!isLoading" id="an-announce">
    <div class="announce-header">
      <!-- <router-link :to="{ name: announcesList, params: { activePage: 2 } }"> -->
      <router-link to="/my/announces"> <span> &lt; </span> 返回會員公告 </router-link>
      <h1>{{ announce.title }}</h1>
      <p>
        最後更新日 <span>{{ announce.updated_at }}</span>
      </p>
    </div>
    <div class="announce">
      <div class="article-content" v-html="announce.content_html"></div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState(['announce', 'isMobile']),
    announcesList() {
      return this.isMobile ? 'my_announces' : 'my'
    }
  },
  methods: {},
  mounted() {
    this.$store.dispatch('loadAnnounce').then(() => {
      this.isLoading = false
    })
  }
}
</script>
<style lang="scss">
#an-announce {
  .announce-header {
    margin: 30px 5%;
    a {
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      span {
        font-weight: 700;
      }
    }
    h1 {
      font-size: 26px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: justified;
      margin: 20px 15px 15px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0px;
      span {
        font-family: Ubuntu Condensed;
        font-size: 16px;
        font-weight: 400;
        line-height: 10px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
