<template>
  <section id="hot-video" :class="isMobile ? '' : 'has-background-lookin-bg'">
    <div id="hot-video-inner">
      <div class="home-block-title has-text-centered has-text-black" ref="titleRef">
        <h2><span>H</span>ot Video<span class="has-text-lookin-darker">熱門影音</span></h2>
      </div>
      <div class="video-top hot-videos">
        <div class="content" ref="videoRef">
          <iframe
            :src="videoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <router-link :to="{ name: 'video', params: { id: hot_videos[0].id } }">
          <h3>{{ hot_videos[0].title }}</h3>
        </router-link>
      </div>
      <div class="small-videos-container">
        <div class="small-videos hot-videos" v-for="(video, index) in videos" :key="video.id" ref="videoListRef">
          <div class="video">
            <div class="content">
              <router-link :to="{ name: 'video', params: { id: video.id } }" @click.native="jumpViode(video.id, index)">
                <!-- <img :src="video.thumbnail" class="content-img" /> -->
                <LazyImage :src="video.thumbnail" class="content-img" />
              </router-link>
            </div>
          </div>
          <div class="v-title">
            <router-link :to="{ name: 'video', params: { id: video.id } }" @click.native="jumpViode(video.id, index)">
              <h3>{{ video.title }}</h3>
            </router-link>
          </div>
        </div>
      </div>
      <Advertisement v-if="!isMobile" :id="24" :width="970" :height="250" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Advertisement from 'src/components/common/Advertisement'
import LazyImage from '../../components/common/LazyImage.vue'
import useObserver from '../../../tools/useObserver'

export default {
  props: ['isLoading', 'hot_videos'],
  data() {
    return {
      videoUrl: ''
    }
  },
  components: { Advertisement, LazyImage },
  computed: {
    ...mapState(['isMobile']),
    videos: function () {
      let n = this.isMobile ? 3 : 5
      return this.hot_videos.slice(1, n)
    }
  },
  mounted() {
    // 视频懒加载
    this.lazyVideo()

    // 版位标题曝光
    useObserver(this.$refs.titleRef, () => {
      try {
        new TrackEvent().setEvent('exposure_home_video', 'read', this.videos.length, null, null)
      } catch (e) {}
    })
    // 文章曝光
    this.exposureArticle()
  },
  methods: {
    lazyVideo() {
      useObserver(this.$refs.videoRef, () => {
        this.videoUrl = this.hot_videos[0].embed_link
      })
    },
    jumpViode(id, index) {
      try {
        new TrackEvent().setEvent('click_home_video_article', 'click', id, index + 1, null)
      } catch (e) {}
    },
    exposureArticle() {
      if (!this.$refs.videoListRef) return
      this.$refs.videoListRef.forEach((el, i) => {
        useObserver(el, () => {
          try {
            new TrackEvent().setEvent('read_home_video_article', 'read', this.videos[i].id, i + 1, null)
          } catch (e) {}
        })
      })
    }
  }
}
</script>

<style lang="scss">
#hot-video {
  margin-bottom: 50px;
  #hot-video-inner {
    max-width: 950px;
    margin: 0 auto;
    padding-bottom: 40px;
    h3 {
      color: black;
    }
    .hot-videos {
      margin: 15px;
    }
    .video-top {
      .content {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        border: 1px solid #f08f7a;
        margin-bottom: 10px;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .small-videos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 104px;
      .video {
        // display: inline-block;
        // vertical-align: top;
        width: 40%;
        height: 100%;
        border: 1px solid #f08f7a;
        padding: 2px;
        .content {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }
      }
      .v-title {
        // display: inline-block;
        // vertical-align: top;
        width: 58%;
        margin: 5px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5; /* 这里是超出几行省略 */
        overflow: hidden;
        h3 {
          font-size: 14px;
          line-height: 140%;
          text-align: justify;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .video-top {
        h3 {
          font-size: 24px;
          font-weight: 400;
          line-height: 38px;
          letter-spacing: 0px;
          text-align: justified;
        }
      }
      .small-videos-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // width: 100%;
        margin-bottom: 60px;
        .small-videos {
          max-width: 424px;
          // margin: 10px;
          height: 148px;
          .video {
            width: 48%;
          }
          .v-title {
            width: 50%;
            h3 {
              font-size: 18px;
              font-weight: 400;
              line-height: 29px;
              letter-spacing: 0px;
              text-align: justified;
            }
          }
        }
      }
    }
  }
}
</style>
