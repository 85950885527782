<template>
  <div class="point-modal">
    <div class="point-modal__box">
      <div class="close" @click="closeModal">
        <img src="../../../images/icon-close.svg" alt="close" />
      </div>
      <!-- 普通阅读 -->
      <template v-if="type === 'read' || type === 'everyday'">
        <div class="m-read">
          <div class="picture">
            <img src="../../../images/point/points.png" alt="picture" />
          </div>
          <div class="text">
            <p class="tip1">{{ type === 'read' ? '閱讀完成' : '每日上線獎勵' }}</p>
            <p class="tip2">熱點+{{ info.point }}</p>
            <div class="btn" :class="type">
              <button @click="gotoExchange">立即領取</button>
            </div>
            <p class="tip3" v-if="type === 'read'">*每日閱讀最多可得10點</p>
          </div>
          <div class="prize-info" @click="handleJump">
            <img :src="info.imgUrl" alt="" />
            <div class="info-title">{{ info.title }}</div>
            <div class="info-tip">{{ tipText[info.resourceType] }}</div>
          </div>
        </div>
      </template>
      <!-- 特殊阅读 -->
      <template v-else>
        <div class="m-special">
          <div class="picture">
            <img src="../../../images/point/special-position-new.png" alt="picture" />
          </div>
          <div class="text">
            <div class="text__img">
              <img :src="specialImgUrl" alt="" />
            </div>
            <p class="text__tip1">來LOOKin蒐集熱點換禮物！</p>
            <p class="text__tip2">
              閱讀文章拿熱點，
              <i>每天最多10點！</i>
            </p>
          </div>
          <div class="btn">
            <button @click="gotoExchange">立即兌換</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'point-modal',
  /**
   * type
   * read 阅读得点
   * special 特殊得点
   * everyday 每日上线得点
   *
   * point 点数
   *
   * specialImgUrl 展示图片链接
   *
   */
  props: {
    type: {
      type: String,
      required: true,
      default: 'read'
    },
    point: {
      type: Number,
      default: 1
    },
    specialImgUrl: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tipText: {
        1: '熱點兌換',
        2: '最新活動'
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    gotoExchange() {
      if (this.type === 'read' || this.type === 'everyday') {
        // 这种关闭弹框 不设置close_point_modal cookie
        this.$emit('closeModal', 'auto')
      } else {
        this.$emit('gotoExchange')
      }
    },
    handleJump() {
      window.location.href = this.info.url
    }
  }
}
</script>

<style lang="scss" scoped>
.point-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  &__box {
    width: 460px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 61px 0 25px;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 16.67px;
        height: 16.19px;
        opacity: 0.5;
      }
    }
    .m-read {
      padding: 0 30px;
      .picture {
        position: absolute;
        left: 135px;
        top: -42px;
        img {
          width: 182px;
          height: 88px;
        }
      }
      .text {
        text-align: center;
        padding: 0 10px;
        .tip1 {
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
        .tip2 {
          font-size: 18px;
          font-weight: 600;
          color: #eb6b55;
          line-height: 25px;
          margin: 20px 0 30px;
        }
        .tip3 {
          font-size: 12px;
          color: #999999;
          margin: 10px 0 20px;
        }
      }
      .prize-info {
        height: 140px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          background-color: #d9d9d9;
          object-fit: cover;
        }
        .info-title {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 28px;
          background: rgba(0, 0, 0, 0.4);
          font-size: 14px;
          color: #ffffff;
          line-height: 28px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .info-tip {
          position: absolute;
          top: 1px;
          left: 0;
          width: 65px;
          height: 18px;
          background: #f78c7d;
          border-radius: 4px 0px 4px 0px;
          font-size: 11px;
          font-weight: 600;
          color: #ffffff;
          line-height: 16px;
          text-align: center;
        }
      }
    }
    .m-special {
      .picture {
        position: absolute;
        left: 128px;
        top: -85px;
        img {
          width: 206px;
          height: 125px;
        }
      }
      .text {
        text-align: center;
        padding: 0 10px;
        &__img {
          height: 51px;
          height: 158px;
          img {
            width: 145px;
            height: 145px;
            border-radius: 6px;
            background-color: #d9d9d9;
          }
        }
        &__tip1 {
          font-size: 20px;
          color: #0a0a0a;
          line-height: 30px;
          margin-bottom: 5px;
        }
        &__tip2 {
          font-size: 16px;
          color: #404040;
          line-height: 26px;
          i {
            color: #f08f7a;
            font-style: normal;
          }
        }
      }
    }
    .btn {
      text-align: center;
      // margin-top: 30px;
      margin-top: 13px;
      button {
        width: 229px;
        height: 42px;
        background: #f08f7a;
        border-radius: 21px;
        outline: none;
        border: none;
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
        cursor: pointer;
      }
    }
    .btn.everyday {
      margin-bottom: 27px;
    }
  }
}
// 在 600 像素或更小的屏幕上
@media screen and (max-width: 600px) {
  .point-modal {
    &__box {
      width: 310px;
      .m-read {
        padding: 0 15px;
        .picture {
          left: 55px;
        }
      }
      .m-special {
        .picture {
          left: 45px;
        }
      }
    }
  }
}
</style>
