import http from './request.js'

// 兑换记录列表
export function getExchangeRecord() {
  return http.get('/shop/prizes_exchange/exchangeRecord')
}

// 开奖结果列表
export function getLotteryResult() {
  return http.get('/shop/prizes_exchange/drawRecord')
}

// 兌換详情
export function getExchangeDetail(id) {
  return http.get(`/shop/prizes_exchange/drawInfo/${id}`)
}

// 获奖名单 prizesId 赠品id
export function listOfWinners(prizesId) {
  return http.get(`/shop/prizes_exchange/prizeMan/${prizesId}`)
}

// 领奖
export function receive(data) {
  return http.post('/shop/prizes_exchange/accept', data)
}

// 文章赠品信息接口
export function getArticlePrizeInfo(articleId) {
  return http.get('/shop/prizes_exchange/articleInfo', { params: { articleId } })
}

// 任务提交接口
export function taskSubmission(data) {
  return http.post('/shop/prizes_exchange/doTask', data)
}

// 赠品兑换（兑换中心)
export function giftExchange(prizesId) {
  return http.post(`/shop/prizes_exchange/exchange/${prizesId}`)
}
