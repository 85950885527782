<template>
  <section id="my-info">
    <div class="user-info">
      <h1 class="h1">會員資料</h1>
      <div class="tip">兌換商品寄送處、參加投票、報名活動，只要完整填寫會員資料，全站一次更新好方便！</div>
      <div class="my-data">
        <UserForm :edit="true" />
      </div>
    </div>
    <!-- <div v-if="user && user.has_password" class="my-password"> -->
    <div class="my-password">
      <h2 class="h1 password-title">修改密碼</h2>
      <form id="user-password" @submit.prevent="updatePassword()">
        <div class="submit-block">
          <b-field label="原密碼*">
            <b-input
              rounded
              type="password"
              v-model="pwdData.current_password"
              placeholder="請輸入原密碼"
              password-reveal
              required
            >
            </b-input>
          </b-field>
          <b-field label="新密碼*">
            <b-input
              rounded
              type="password"
              v-model="pwdData.password"
              placeholder="長度 6 字元以上，需區分大小寫"
              password-reveal
              required
            >
            </b-input>
          </b-field>
          <b-field label="確認新密碼*">
            <b-input
              rounded
              type="password"
              v-model="pwdData.password_confirmation"
              placeholder="請再次輸入新密碼"
              password-reveal
              required
            >
            </b-input>
          </b-field>
        </div>
        <div class="submit-block">
          <button class="button btn-update">修改密碼</button>
        </div>
      </form>
      <Modal
        :open="modal.open"
        :title="modal.title"
        :content="modal.message"
        :btnWord="'確認'"
        @close="modal.open = false"
      />
      <!-- 先隐藏绑定 -->
      <binding-account />
      <!-- <div style="margin-top: 30px">
        <b-button class="btn" type="is-primary" rounded expanded @click="goto"> 測試跳轉 </b-button>
      </div> -->
    </div>
    <new-modal
      v-show="isBindingShow"
      title="Facebook綁定成功"
      content="歡迎直接使用Facebook登入"
      confirmText="好的"
      @onConfirm="onModalConfirm"
      @onCancel="onModalCancel"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import Modal from '../../components/dialog/Modal'
import UserForm from '../../components/common/UserForm'
import BindingAccount from 'src/components/bindingAccount/index.vue'
import NewModal from 'src/components/cancellation/NewModal.vue'

export default {
  name: 'my_info',
  data() {
    return {
      formKey: 0,
      pwdData: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      modal: {
        open: false,
        message: null,
        title: null
      },
      isBindingShow: false
    }
  },
  components: {
    UserForm,
    Modal,
    BindingAccount,
    NewModal
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    updatePassword: function () {
      this.$store
        .dispatch('updatePassword', this.pwdData)
        .then(() => {
          this.modal.title = 'Success!'
          this.modal.message = '成功更改密碼！'
          this.modal.open = true
        })
        .catch(error => {
          console.log(error.data)
          this.modal.title = 'Oh No!'
          this.modal.message = error.data['error']
          this.modal.open = true
        })
    },
    onModalConfirm() {
      this.isBindingShow = false
    },
    onModalCancel() {
      this.isBindingShow = false
    }
    // goto() {
    //   const host = window.location.host || ''
    //   let url = ''
    //   if (host.includes('staging')) {
    //     url = `https://quiztest.cmh.com.tw/lookin/?avatar=${this.user.avatar_url}&member_uniqid=${this.user.id}&member_displayname=${this.user.nickname}`
    //   } else {
    //     url = `https://quiz.cmh.com.tw/lookin/?avatar=${this.user.avatar_url}&member_uniqid=${this.user.id}&member_displayname=${this.user.nickname}`
    //   }
    //   window.location.href = url
    // }
  }
}
</script>
<style lang="scss">
#my-info {
  display: flex;
  flex-direction: column;
  .h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    // line-height: 15px;
    padding: 20px 15px 10px;
  }
  .tip {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 19px;
    padding: 0 15px 20px;
    width: 400px;
  }
  .my-data {
    background-color: #fff0ee;
  }
  .my-password {
    margin-top: -100px;
    background-color: #fff0ee;
    .h1.password-title {
      padding: 30px 15px;
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 15px;
      letter-spacing: 2px;
      background-color: #fff;
    }
    .field {
      max-width: 400px;
      margin-top: 18px;
      line-height: 24px;
      width: 75%;
      label {
        color: #404040;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 0;
        margin-left: 11px;
      }
      input {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        border: none;
      }
      input:focus + span.has-text-primary {
        color: #f08f7a !important;
      }
    }

    .submit-block {
      flex-direction: column;
      display: flex;
      align-items: center;
      button.btn-update {
        background-color: #000;
        color: #ffffff;
        margin-bottom: 40px;
      }
      button.button {
        width: 135px;
        height: 41px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        border-radius: 50px;
        margin-top: 40px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #my-info {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1000px;
    width: 900px;
    .h1,
    .my-password .h1.password-title {
      display: inline-block;
      margin: 30px 15px;
      padding: 24px 0 0;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      width: unset;
      border-bottom: 4px solid #ffc2ae;
    }
    .my-data {
      background-color: unset;
    }
    .my-password {
      width: 400px;
      margin-top: unset;
      background-color: unset;
      .field {
        width: 100%;
      }
    }
    .field input {
      border: 1px solid #ffc2ae !important;
    }
  }
}
</style>
