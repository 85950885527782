<template>
  <div id="bind-modal">
    <transition name="fade">
      <div class="mask" v-if="visible"></div>
    </transition>
    <transition name="fade">
      <div class="box" :style="modalStyle" v-if="visible">
        <img class="close" @click="onClose" src="../../../images/icon-close.svg" alt="close" />
        <h2 class="box-title">{{ title }}</h2>
        <div class="box-content">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'yellow-modal',
  /**
   * mbTitle 移动端标题
   * title pc端标题
   * tip 提示内容
   * visible 显示隐藏弹出框 默认 false 隐藏   true 显示
   */
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    modalStyle: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    return {}
  },
  watch: {},
  methods: {
    onClose() {
      // this.$emit('update:visible', false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#bind-modal {
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 8;
    display: block;
  }
  .box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    padding: 16px 40px 20px;
    z-index: 9;
    .close {
      width: 13px;
      height: 13px;
      position: absolute;
      top: 20px;
      right: 30px;
      opacity: 0.5;
      cursor: pointer;
    }
    .box-title {
      font-size: 18px;
      font-weight: 600;
      color: #0a0a0a;
      margin-bottom: 25px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 820px) {
  #bind-modal {
    .box {
      width: 340px;
      padding: 25px 25px 16px;
    }
  }
}
</style>
