<template>
  <div class="bread-crumb-item">
    <RouterLink v-if="to" :to="to"><slot /></RouterLink>
    <span v-else><slot /></span>
    <i class="iconfont">/</i>
  </div>
</template>
<script>
export default {
  name: 'breadCrumbItem',
  props: {
    to: {
      type: [String, Object] //to的值即可以是字符串，也可以是对象
    }
  }
}
</script>
