<template>
  <section class="right-column has-background-lookin-bg">
    <Advertisement :id="31" :width="300" :height="250" />
    <div class="home-block-title has-text-centered has-text-black">
      <h2><span>R</span>ecommend<span class="has-text-lookin-darker">推薦文章</span></h2>
    </div>
    <div class="recommends">
      <template v-for="(article, i) in recommends">
        <div
          v-if="i < 5"
          class="issue"
          :key="article.id"
          @click="handleArticle(article.id, i)"
          ref="recommendArticleRef"
          :data-id="article.id"
        >
          <div class="recmd-img-container">
            <router-link
              :to="{
                path: `/${article.parent_path}/${article.child_path}/${article.id}`,
                params: {
                  title: article.title
                }
              }"
            >
              <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" />
            </router-link>
          </div>
          <router-link
            :to="{
              path: `/${article.parent_path}/${article.child_path}/${article.id}`,
              params: {
                title: article.title
              }
            }"
          >
            <h3 class="has-text-black">
              <span class="category" :class="cateClass(article.category.id)">{{ article.category.title }}</span>
              {{ article.title }}
            </h3>
          </router-link>
        </div>
      </template>
    </div>
    <Advertisement :id="32" :width="300" :height="600" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { categoryClass } from 'src/share'
import Advertisement from 'src/components/common/Advertisement'
import useObserver from '../../../tools/useObserver'
// import { recommendArticle } from '../../../api/article.js'
// import { GET_RECOMMENDS } from '../../../store/mutation-types'

export default {
  props: ['articleId'],
  data() {
    return {
      // recommendList: []
    }
  },
  components: { Advertisement },
  methods: {
    cateClass: id => categoryClass(id),
    handleArticle(id, index) {
      try {
        const params = { ex1: index + 1 }
        new TrackEvent().setEvent('article_recommend', 'click', this.articleId, id, params)
      } catch (error) {
        console.log(error)
      }
    },
    articleExposed() {
      // 推荐文章曝光事件
      this.$nextTick(() => {
        const recommendRefs = this.$refs.recommendArticleRef
        if (Array.isArray(recommendRefs)) {
          recommendRefs.map((el, index) => {
            useObserver(el, () => {
              try {
                const id = el.dataset.id
                const params = { ex1: index + 1 }
                new TrackEvent().setEvent('impression_article', 'recommend', this.articleId, id, params)
              } catch (error) {
                console.log(error)
              }
            })
          })
        }
      })
    }
    // async recommendFn(id) {
    //   try {
    //     const { code, result } = await recommendArticle(id)
    //     if (code === 200) {
    //       if (result) {
    //         // this.$store.commit(GET_RECOMMENDS, { recommends: result })
    //         this.recommendList = result
    //         this.articleExposed()
    //       } else {
    //         if (!this.recommends) {
    //           this.$store.dispatch('loadRecommends').then(() => {
    //             this.recommendList = this.recommends
    //             this.articleExposed()
    //           })
    //         } else {
    //           this.recommendList = this.recommends
    //           this.articleExposed()
    //         }
    //       }
    //     } else {
    //       if (!this.recommends) {
    //         this.$store.dispatch('loadRecommends').then(() => {
    //           this.recommendList = this.recommends
    //           this.articleExposed()
    //         })
    //       } else {
    //         this.recommendList = this.recommends
    //         this.articleExposed()
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  },
  computed: {
    ...mapState(['recommends'])
  },
  mounted() {
    if (this.recommends == null) {
      this.$store.dispatch('loadRecommends').then(() => {
        this.articleExposed()
      })
    } else {
      this.articleExposed()
    }
  }
}
</script>

<style lang="scss">
.right-column {
  position: absolute;
  right: -378px;
  top: 0;
  max-width: 300px;
  margin-left: 66px;
  padding: 0;
  h2 {
    span:nth-child(1) {
      width: 36px;
    }
    span.has-text-lookin-darker {
      left: 96px;
    }
  }
  .recommends {
    max-width: 232px;
    margin: 0 auto;
    .issue {
      height: 250px;
      margin: 20px 0;
      .recmd-img-container {
        border: 1px solid #f08f7a;
        padding: 6px;
        height: 170px;
        img {
          height: 156px;
          width: 100%;
          margin: 0 auto;
          object-fit: cover;
        }
      }
      h3 {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: justified;
        span.category {
          font-size: 13px;
          line-height: 185%;
          letter-spacing: 1px;
          padding: 1px 6px;
        }
      }
    }
  }
}
</style>
