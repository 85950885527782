import Vue from 'vue'
import i18n from '@/../i18n'
import store from '@/../store'
import router from '@/../router'
import Layout from '@/../src/Layout'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faEnvelope,
  faEye,
  faAngleLeft,
  faAngleRight,
  faExclamationCircle,
  faCamera,
  faSpinner,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Buefy from 'buefy'
// use local modified vue-slick package
import Slick from '../src/plugins/vue-slick'
// import Slick from 'vue-slick'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import VueScrollTo from 'vue-scrollto'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../stylesheets/client.scss'
// import './monitorBarMain'
import { getAppVersion, isLookinApp } from '../tools/bridge.js'
import Toast from '../src/components/common/Toast.vue'

// 需要用到的图标才添加 https://buefy.org/documentation/icon
library.add(faUser, faEnvelope, faEye, faAngleLeft, faAngleRight, faExclamationCircle, faCamera, faSpinner, faEyeSlash)
Vue.component('vue-fontawesome', FontAwesomeIcon)
Vue.component('slick', Slick)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})
Vue.use(VueCookies)
Vue.use(VueMeta)
Vue.use(VueScrollTo)
Vue.$cookies.config('90d')

Vue.use(
  VueGtag,
  {
    // user Gtag for Google Analystics
    // NOTE: another GTM move to Rails views/layouts/client.html.erb
    // NOTE: excluded videos-category because beforeEnter() will
    // cause 2 pageviews, check router to see
    pageTrackerExcludedRotues: ['videos-category'],
    config: { id: process.env.GA_TRACKING_ID },
    pageTrackerTemplate(to) {
      const postfix = ' － 【LOOKin】美人時髦話題網'
      let title = document.title
      if (to.params.title) {
        switch (to.name) {
          case 'search':
            title = `最新${to.params.title}推薦文章！女性、生活話題分享${postfix}`
            break
          case 'tag':
            title = `${to.params.title}推薦分享(持續更新)${postfix}`
            break
          default:
            title = `${to.params.title}${postfix}`
            break
        }
      } else if (to.meta.title) {
        title = `${to.meta.title}${postfix}`
      }
      return {
        page_title: title,
        page_path: to.path,
        page_location: window.location.href
      }
    }
  },
  router
)

// require images
require.context('@/../images', true, /\.(?:png|jpg|gif|ico|svg)$/)

// 直接调用，获取app版本号
getAppVersion()

// 定义插件对象
const ToastObj = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ToastConstructor = Vue.extend(Toast)
    // 创建一个该子类的实例，并挂载到一个元素上
    const instance = new ToastConstructor()
    // 将这个实例挂载到动态创建的元素上，并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue原型链上注册方法，控制组件
    Vue.prototype.$toast = (msg, duration = 1500) => {
      instance.message = msg
      instance.visible = true
      setTimeout(() => {
        instance.visible = false
      }, duration)
    }
  }
}

Vue.use(ToastObj)

// window.app = new Vue({
new Vue({
  router,
  store,
  i18n,
  render: h => h(Layout),
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线
  }
}).$mount('#seo-content')

// document.body.appendChild(app.$el)
// const seoContentDom = document.getElementById('seo-content')
// if (seoContentDom) seoContentDom.remove()

function addRss() {
  let tag = document.createElement('link')
  tag.rel = 'alternate'
  tag.type = 'application/rss+xml'
  tag.href = 'https://www.look-in.com.tw/rss'
  document.head.appendChild(tag)
}

// 尖端LOOKin探索及新聞所需添加的設定
addRss()

// 如果是app，改用本页跳转
if (isLookinApp) {
  // 延迟，防止获取不到动态插入的
  setTimeout(() => {
    setHref()
  }, 2000)
}

function setHref() {
  const links = document.querySelectorAll("a[target='_blank']")
  if (links) {
    links.forEach(el => {
      el.target = '_self'
    })
  }
}
