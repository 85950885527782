<template>
  <section class="inter-video">
    <div class="video-wrapper" ref="videoRef">
      <iframe
        loading="lazy"
        :src="videoUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <a :href="interVideoLink" target="_blank">
      <h3 class="title has-text-black">{{ this.video.text }}</h3>
    </a>
  </section>
</template>
<script>
import useObserver from '../../../tools/useObserver'

export default {
  name: 'inter-video',
  props: ['video'],
  data() {
    return {
      videoUrl: ''
    }
  },
  mounted() {
    // 视频懒加载
    if (this.video) this.lazyVideo()
  },
  computed: {
    interVideoLink() {
      if (this.video) {
        return `https://youtu.be/${this.video.link}`
      }
      return ''
    }
    // interVideoEmbedLink() {
    //   if (this.video) {
    //     return `https://www.youtube.com/embed/${this.video.link}`
    //   }
    // }
  },
  methods: {
    lazyVideo() {
      useObserver(this.$refs.videoRef, () => {
        this.videoUrl = `https://www.youtube.com/embed/${this.video.link}`
      })
    }
  }
}
</script>
<style lang="scss">
.inter-video {
  .video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border: 1px solid #f08f7a;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  a {
    h3 {
      font-size: 16px;
      line-height: 140%;
      text-align: justify;
      margin: 10px 14px 40px;
    }
  }
}
</style>
