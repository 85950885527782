<template>
  <div id="new_module" v-show="visible">
    <div class="new_box">
      <img class="close" @click="onClose" src="../../../images/icon-close.svg" alt="close" />
      <div class="new_box_content">
        <div class="c-title" v-if="title">{{ title }}</div>
        <div class="text">
          <div v-if="content">{{ content }}</div>
          <slot name="content"></slot>
        </div>
        <div class="btn">
          <b-button rounded class="cancel" :class="{ active: cancelActive }" v-if="cancelText" @click="onCancel">
            {{ cancelText }}
          </b-button>
          <b-button rounded class="confirm" v-if="confirmText" @click="onConfirm">{{ confirmText }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'my-dialog',
  /**
   * visible 显示隐藏组件
   * title 标题
   * content 内容
   *  如果像定制内容格式 可以选择使用插槽v-slot:content
   * cancelText 取消按钮文本 有 则显示，反之隐藏此按钮
   * confirmText 确认按钮文本 有 则显示，反之隐藏此按钮
   * onCancel 取消事件
   * onConfirm 确认事件
   * cancelActive 调整取消按钮样式
   *
   */
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: String,
    content: String,
    cancelText: String,
    confirmText: String,
    cancelActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    onCancel() {
      this.$emit('onCancel')
    },
    onConfirm() {
      this.$emit('onConfirm')
    },
    onClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
#new_module {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  // padding: 0 35px;
  z-index: 9;
  .new_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 48px 100px 31px;
    background-color: #ffffff;
    border-radius: 12px;
    .close {
      width: 25.46px;
      height: 25.46px;
      position: absolute;
      top: 7px;
      right: 9px;
      padding: 5px;
      opacity: 0.5;
      cursor: pointer;
    }
    .new_box_content {
      min-width: 260px;
      .c-title {
        font-size: 20px;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 25px;
        margin-bottom: 43px;
      }
      .text {
        font-size: 16px;
        color: #404040;
        line-height: 26px;
      }
      .btn {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        .cancel {
          margin-top: 56px;
          height: 42px;
          width: 120px;
          background-color: #f2f2f2;
          border-color: #f2f2f2;
          border: none;
          &.active {
            height: 44px;
            width: 229px;
            color: #ffffff;
            background-color: #f08f7a;
            margin: 0 auto;
          }
          &.button:focus:not(:active) {
            box-shadow: none;
          }
        }
        .confirm {
          margin-top: 56px;
          height: 42px;
          width: 120px;
          background-color: #f08f7a;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #new_module {
    .new_box {
      padding: 21px 22px 24px;
      border-radius: 8px;
      .new_box_content {
        .c-title {
          font-size: 18px;
          margin-bottom: 22px;
        }
        .btn {
          .cancel {
            margin-top: 25px;
          }
          .confirm {
            margin-top: 25px;
          }
        }
      }
    }
  }
}
</style>
