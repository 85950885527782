import axios from 'axios'

let baseUrl = ''
let auth = ''
const host = window.location.host || ''
if (host.includes('staging')) {
  baseUrl = 'https://staging-point.cmh.com.tw/api'
  auth = 'Bearer T2wnmT33ysM8ymw5Cf2P74pPzsrbns5wky6BeTfSdZdM'
} else if (host.includes('localhost')) {
  baseUrl = 'https://staging-point.cmh.com.tw/api'
  auth = 'Bearer T2wnmT33ysM8ymw5Cf2P74pPzsrbns5wky6BeTfSdZdM'
} else {
  baseUrl = 'https://point.cmh.com.tw/api'
  auth = 'Bearer N0Y2MjlFN0U5QjREQURCQUYxNDAzQzlGRjI5M0ExQzY='
}

// 獲取黃條點數
export function getPointApi(data) {
  return axios({
    method: 'post',
    url: `${baseUrl}/point/getWebCode`,
    headers: {
      Authorization: auth,
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 黄条点数归户
export function pointsToHousehold(data) {
  return axios({
    method: 'post',
    url: `${baseUrl}/point/offlineDataBing`,
    headers: {
      Authorization: auth,
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
