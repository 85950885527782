<template>
  <div id="not-found-container">
    <h2>OOPS!</h2>
    <h2>時髦女子對不起！</h2>
    <p>本頁面為無效網頁，請輸入或搜尋正確網址喔！</p>
    <h3>不過或許妳會想看看...</h3>
    <div v-if="!!articles" class="recommends">
      <div v-for="(article, i) in articles.slice(0, 3)">
        <h4 class="has-text-lookin-darker">
          <router-link :to="{ name: 'article', params: { channel: article.parent_path, sub_channel: article.child_path, id: article.id, title: article.title } }">
            {{ article.title }}
          </router-link>
        </h4>
      </div>
    </div>
    <div class="maybe">
      <a class="go-home" :href="homeURL">返回首頁</a>
      <a class="back" href="javascript:history.back()">回上一頁</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      homeURL: process.env.BASE_URL
    }
  },
  computed: {
    ...mapState(['articles']),
  },
  mounted() {
    this.$store.dispatch('loadArticles').then(() => {
      this.isLoading = false
    }).catch((error) => {
      console.log(error)
    })
  }
}
</script>

<style lang="scss">
#not-found-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans TC;
  font-size: 16px;
  h2 {
    margin: 0;
    position: relative;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 1px;
    z-index: 1;
    &:after {
      content: '';
      height: 8px;
      background: #FFC2AE;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 8px;
      z-index: -1;
    }
  }
  p {
    color: #404040;
    display: flex;
    line-height: 27px;
    margin: 20px;
  }
  h3 {
    position: relative;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
    z-index: 1;
    &:after {
      content: '';
      height: 5px;
      background: #FFC2AE;
      position: absolute;
      left: -2px;
      right: -2px;
      bottom: 2px;
      z-index: -1;
    }
  }
  .recommends {
    width: 85vw;
    max-width: 600px;
    h4 {
      margin: 20px;
    }
  }
  .maybe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px auto 30px;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 15px;
      padding: 8px;
      border-radius: 50px;
      width: 25vw;
      max-width: 200px;
      min-width: 110px;
      line-height: 24px;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
    }
    a, a:focus {
      text-decoration: none;
      outline: none;
    }
    .go-home {
      border: 2px solid #000000;
      color: #000000;
    }
    .back {
      border-color: #000000;
      background-color: #000000;
      color: #FFFFFF;
    }
  }
}
</style>
