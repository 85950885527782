import http from './request.js'

// 推荐文章
export function recommendArticle(articleId) {
  return http.get('/shop/article/recommends', { params: { article_id: articleId } })
}

// 瀑布流推荐文章
export function waterfallsRecommendArticle(articleId) {
  return http.get('/shop/article/recommends_cascades', { params: { article_id: articleId } })
}

/**
 * @description 首页个性化推荐
 * @param {String} params.cookieToc 设备id
 * @param {Number} params.type 类型 1：app；0: 其他
 */
export function personalizedRecommendation(params) {
  return http.get('/shop/article/recommendArticle', { params })
}
