import * as types from './mutation-types'

function flatten(result, node) {
  if (node == null) return result
  if (Array.isArray(node)) return node.reduce(flatten, result)
  result.push(node)
  return flatten(result, node.comments)
}

const mutations = {
  [types.SET_Mobile](state, payload) {
    state.isMobile = payload
  },
  [types.SET_LOGIN_DRAWER](state, payload) {
    state.loginDrawer = payload
  },
  [types.SET_SIGNUP_DRAWER](state, payload) {
    state.signUpDrawer = payload
  },
  [types.SET_FORGET_PASSWORD_DRAWER](state, payload) {
    state.forgetPasswordDrawer = payload
  },
  [types.GET_HOMEPAGE](state, payload) {
    state.home.slides = payload.home_slides
    state.home.ig_photos = payload.ig_photos
  },
  [types.GET_HOMEOTHERS](state, payload) {
    state.home.hot_videos = payload.hot_videos
    state.home.new_articles = payload.new_articles
    state.home.magazines = payload.magazines
    state.home.hot_issues = payload.hot_issues
    state.home.recommends = payload.recommends
    state.home.pick_up = payload.pick_up
    state.home.member_only = payload.member_only
  },
  [types.GET_ESSENTIALS](state, payload) {
    state.logos = payload.logos
    state.menu = payload.menu_list
    state.channels = payload.channels
    state.videoCategories = payload.video_categories
    state.hot_tags = payload.hot_tags
    state.search_tags = payload.search_tags
    state.fixed_widget = payload.fixed_widget
  },
  [types.GET_A_CHANNEL](state, payload) {
    state.current_channel.title = payload.title
    state.current_channel.slides = payload.slides
    if (state.articles == null) {
      state.articles = []
    }
    state.articles = state.articles.concat(payload.articles)
    state.current_page = payload.current_page
  },
  [types.SET_CURRENT_CHANNEL](state, payload) {
    state.current_channel = payload
  },
  [types.RESET_CURRENT_CHANNEL](state) {
    state.current_channel = { title: null, slides: [] }
  },
  [types.RESET_ARTICLES](state) {
    state.articles = null
    state.current_page = 1
  },
  [types.GET_ARTICLES](state, payload) {
    if (state.articles == null) {
      state.articles = []
    }
    const robots = document.querySelector("meta[name='robots']")
    if (payload.articles && payload.articles.length === 0) {
      if (robots) {
        robots.setAttribute('content', 'noindex, nofollow')
      }
    } else {
      if (location.href.includes('staging') && robots) {
        robots.setAttribute('content', 'noindex')
      } else {
        robots.setAttribute('content', 'index, follow, max-image-preview:large')
      }
    }
    state.articles = state.articles.concat(payload.articles)
    state.current_page = payload.pagination_info.current_page
    state.next_page = payload.pagination_info.next_page
  },
  [types.GET_AN_ARTICLE](state, payload) {
    state.article = payload
    state.inter_video = state.article.inter_video
    state.articles = null
  },
  [types.GET_RELATED_ARTICLES](state, payload) {
    state.articles = payload
  },
  [types.SET_ARTICLE_EVENT_PARTICIPATION](state, article_id) {
    const article = state.article.id == article_id ? state.article : state.articles.find(a => a.id == article_id)
    article.event.participated = true
  },
  [types.GET_RECOMMENDS](state, payload) {
    state.recommends = payload.recommends
  },
  [types.GET_ARTICLE_COMMENTS](state, { id, data }) {
    const article = state.article.id == id ? state.article : state.articles.find(a => a.id == id)
    // flatten comments in state
    let comments = data.comments
    article.comments = comments.map(function (comment, index, array) {
      comment.comments = flatten([], comment.comments)
      return comment
    })
  },
  [types.GET_TRIAL_COMMENTS](state, payload) {
    // flatten comments in state
    let comments = payload.comments
    current_trial.comments = comments.map(function (comment, index, array) {
      comment.comments = flatten([], comment.comments)
      return comment
    })
  },
  [types.GET_LECTURE_COMMENTS](state, payload) {
    // flatten comments in state
    let comments = payload.comments
    current_lecture.comments = comments.map(function (comment, index, array) {
      comment.comments = flatten([], comment.comments)
      return comment
    })
  },
  [types.GET_VOTE_COMMENTS](state, payload) {
    // flatten comments in state
    let comments = payload.comments
    current_vote.comments = comments.map(function (comment, index, array) {
      comment.comments = flatten([], comment.comments)
      return comment
    })
  },
  [types.SET_ARTICLE_COMMENT](state, { response, article_id, comment_id }) {
    const article = state.article.id == article_id ? state.article : state.articles.find(a => a.id == article_id)
    state.new_comment = response
    if (comment_id) {
      let parent_comment = article.comments.find(c => c.id == comment_id)
      parent_comment.comments.push(response)
    } else {
      article.comments.push(response)
    }
  },
  [types.SET_TRIAL_ITEM_COMMENT](state, { response, comment_id, trial_item_id }) {
    state.new_comment = response
    let trial_item = state.current_trial.trial_items.find(item => item.id == trial_item_id)
    if (comment_id) {
      let parent_comment = trial_item.comments.find(c => c.id == comment_id)
      parent_comment.comments.push(response)
    } else {
      trial_item.comments.push(response)
    }
  },
  [types.SET_LECTURE_COMMENT](state, { response, comment_id }) {
    state.new_comment = response
    if (comment_id) {
      let parent_comment = state.current_lecture.comments.find(c => c.id == comment_id)
      parent_comment.comments.push(response)
    } else {
      state.current_lecture.comments.push(response)
    }
  },
  [types.SET_VOTE_COMMENT](state, { response, comment_id }) {
    state.new_comment = response
    if (comment_id) {
      let parent_comment = state.current_vote.comments.find(c => c.id == comment_id)
      parent_comment.comments.push(response)
    } else {
      state.current_vote.comments.push(response)
    }
  },
  [types.DELETE_COMMENT](state, { article_id, id }) {
    const article = state.article.id == article_id ? state.article : state.articles.find(a => a.id == article_id)
    let comment = article.comments.find(c => c.id == id)
    if (comment) {
      article.comments.splice(
        article.comments.findIndex(c => c.id == id),
        1
      )
    } else {
      for (var i = 0; i < article.comments.length; i++) {
        comment = article.comments[i].comments.find(c => c.id == id)
        if (comment) {
          article.comments[i].comments.splice(
            article.comments[i].comments.findIndex(c => c.id == id),
            1
          )
          break
        }
      }
    }
  },
  [types.GET_VIDEOS](state, payload) {
    state.videos = payload.videos
    state.topVideo = payload.top
  },
  [types.GET_A_VIDEO](state, payload) {
    state.video = payload
  },
  [types.GET_ANNOUNCES](state, payload) {
    state.announces = payload.announces
  },
  [types.GET_AN_ANNOUNCE](state, payload) {
    state.announce = payload
  },
  [types.GET_PRIZES](state, payload) {
    state.prizes = payload
  },
  [types.GET_POINT_RECORDS](state, payload) {
    state.point_records = payload
  },
  [types.SET_POINTS](state, payload) {
    state.user.points = payload.points
  },
  [types.GET_EVENT_RECORDS](state, payload) {
    state.event_records = payload.events
  },
  [types.GET_SPECIALS](state, payload) {
    state.specials = payload
  },
  [types.GET_TRIALS](state, payload) {
    state.trials = payload.trials
  },
  [types.GET_A_TRIAL](state, payload) {
    state.current_trial = payload
  },
  [types.SET_TRIAL_PARTICIPATION](state) {
    state.current_trial.participated = true
  },
  [types.GET_LECTURES](state, payload) {
    state.lectures = payload.lectures
  },
  [types.GET_A_LECTURE](state, payload) {
    state.current_lecture = payload
  },
  [types.SET_LECTURE_PARTICIPATION](state) {
    state.current_lecture.participated = true
  },
  [types.GET_VOTES](state, payload) {
    state.votes = payload.votes
  },
  [types.GET_A_VOTE](state, payload) {
    state.current_vote = payload
  },
  [types.VOTE_OPTION](state, payload) {
    const i = state.current_vote.vote_options.findIndex(vote_option => vote_option.id == payload.vote_option.id)
    state.current_vote.vote_options[i].voted = true
    state.current_vote.vote_options[i].users_count += 1
  },
  [types.LOGIN_SUCCESS](state, payload) {
    state.user = payload
    state.token = payload.auth_token
    // 设置用户信息，给nuxt项目用
    const nuxtUser = JSON.stringify({ info: payload, token: payload.auth_token })
    // eslint-disable-next-line
    $cookies.set('user', nuxtUser)
  },
  [types.LOGIN_FAIL](state) {
    state.user = null
    state.token = null
  },
  [types.LOGOUT](state) {
    state.user = null
    state.token = null
    state.authFail = false
    localStorage.removeItem('auth-token')
    $cookies.remove('user_id')
    $cookies.remove('toc_user_id')
    $cookies.remove('user')
  },
  [types.RESET_USER_ID](state, payload) {
    if ($cookies.get('user_id').length > 20) {
      $cookies.remove('user_id')
    }
    $cookies.set('user_id', payload.id)
  },
  [types.AUTH_SUCCESS](state, payload) {
    localStorage.setItem('auth-token', payload.token)
    $cookies.set('user_id', payload.userId)
    state.token = payload.token
  },
  [types.AUTH_FAIL](state) {
    if (!state.loginDrawer) {
      state.authFail = true
    }
  },
  [types.GET_PROFILE](state, payload) {
    state.user = payload
    // 设置用户信息，给nuxt项目用
    const nuxtUser = JSON.stringify({ info: payload, token: state.token })
    // eslint-disable-next-line
    $cookies.set('user', nuxtUser)
    // == Add Begin ============================================================
    // if ($cookies.get("user_id").length > 20) {
    //   $cookies.set("toc_user_id", payload.id)
    // }
    //第三方登录归户
    // try {
    //   console.log('第三方login success')
    //   console.log('打印getIntegral方法', window.getIntegral)
    //   window.getIntegral()
    //   console.log('getIntegral')
    // } catch (e) {
    //   console.log(e)
    // }
    // == Add End   ============================================================
  },
  [types.GET_COLLECTIONS](state, payload) {
    state.collections = payload
  },
  [types.ADD_COLLECTION](state, article_id) {
    const article = state.article.id == article_id ? state.article : state.articles.find(a => a.id == article_id)
    article.bookmarked = true
  },
  [types.REMOVE_COLLECTION](state, article_id) {
    if (state.article !== null) {
      const article = state.article.id == article_id ? state.article : state.articles.find(a => a.id == article_id)
      if (article) {
        article.bookmarked = false
      }
    }
    if (state.collections !== null) {
      state.collections.splice(
        state.collections.findIndex(c => c.id == article_id),
        1
      )
    }
  },
  [types.GET_SUBSCRIPTIONS](state, payload) {
    state.subscriptions = payload
  },
  [types.ADD_SUBSCRIPTION](state, payload) {
    if (payload.article_id) {
      const article =
        state.article.id == payload.article_id ? state.article : state.articles.find(a => a.id == payload.article_id)
      if (article) {
        article.followed = true
      }
    } else {
      if (state.subscriptions != null) {
        const author = state.subscriptions.find(author => author.id == payload.author_id)
        author.unfollow = false
      }
      if (state.authors != null) {
        const author = state.authors.find(author => author.id == payload.author_id)
        author.followed = true
      }
      if (state.author != null && state.author.id == payload.author_id) {
        state.author.followed = true
      }
    }
  },
  [types.REMOVE_SUBSCRIPTION](state, payload) {
    if (!!payload.article_id && state.article !== null) {
      const article =
        state.article.id == payload.article_id ? state.article : state.articles.find(a => a.id == payload.article_id)
      if (article) {
        article.followed = false
      }
    } else {
      if (state.subscriptions != null) {
        const author = state.subscriptions.find(author => author.id == payload.author_id)
        author.unfollow = true
      }
      if (state.authors != null) {
        const author = state.authors.find(author => author.id == payload.author_id)
        author.followed = false
      }
      if (state.author != null && state.author.id == payload.author_id) {
        state.author.followed = false
      }
    }
  },
  [types.GET_AUTHORS](state, payload) {
    state.authors = payload
  },
  [types.GET_AN_AUTHOR](state, payload) {
    state.author = payload
  },
  [types.GET_NOTICES](state, payload) {
    const { list, total } = payload
    if (!state.notices) {
      state.notices = list
      state.noticesTotal = total
    } else {
      state.notices = [...state.notices, ...list]
    }
  },
  [types.READ_NOTICE](state, payload) {
    if (state.notices) {
      const aimData = state.notices.find(v => v.id === payload)
      // 让read_at不为空
      aimData.read_at = new Date()
    }
  },
  [types.SET_404_NOT_FOUND](state, payload) {
    state.notFound404 = payload
  },
  [types.SET_500_ERROR](state, payload) {
    state.serverError500 = payload
  },
  [types.SET_VIA](state, payload) {
    state.via = payload
  },
  [types.BIND_VISIBLE](state, payload) {
    state.bindVisible = payload
  },
  [types.APP_VERSION](state, payload) {
    state.appVersion = payload
  },
  [types.UPDATE_SHOW_YELLOW_TIP](state, payload) {
    state.showYellowTip = payload
  },
  [types.UPDATE_YELLOW_TIP](state, payload) {
    state.yellowTip = payload
  },
  [types.UPDATE_NOTICES_COUNT](state, count) {
    state.user.notices_count = count
  },
  [types.CLEAR_USER_INFO](state) {
    state.user = null
    state.token = ''
  },
  [types.SET_PRIZE_INFO](state, prizeInfo) {
    state.prizeInfoModal = prizeInfo
  },
  [types.APP_UPDATE_TIP](state, payload) {
    state.isShowUpdateTip = payload
  },
  [types.GET_EXPIRE_POINTS](state, payload) {
    state.expire_points = payload
  }
}

export default mutations
