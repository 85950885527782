<template>
  <div id="sitemap">
    <h1><span class="underline">網站總覽</span></h1>
    <div v-if="isMobile" class="columns is-desktop">
      <SectionBlock :title="about.title" :links="about.links" class="column" />
    </div>
    <div class="columns is-desktop">
      <template v-for="(channel, i) in channels">
        <SectionBlock :title="channel.title" :path="channel.path" :links="channel.channels" class="column" />
      </template>
    </div>
    <div class="columns is-desktop">
      <SectionBlock v-if="!isMobile" :title="about.title" :links="about.links" class="column" />
      <SectionBlock :title="videos.title" :path="videos.path" :links="videoCategories" class="column" />
      <SectionBlock :title="events.title" :links="events.links" class="column" />
    </div>
    <div class="columns is-desktop">
      <SectionBlock :title="authors.title" :path="authors.path" class="column" />
      <SectionBlock :title="onlineMag.title" :path="onlineMag.path" :open="true" class="column" />
      <SectionBlock :title="youtube.title" :path="youtube.path" :open="true" class="column" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SectionBlock from './SectionBlock'

export default {
  data() {
    return {
      about: {
        title: '關於',
        links: [
          {
            title: '關於我們',
            path: '/aboutus'
          },
          {
            title: '聯絡我們',
            path: 'mailto:ilovelookin520@gmail.com'
          },
          {
            title: '會員專區',
            path: '/me'
          },
          {
            title: '隱私權政策',
            path: '/privacy'
          },
          {
            title: '廣告刊登',
            path: 'mailto:smallfaye_lan@mail2.spp.com.tw'
          },
        ],
      },
      events: {
        title: '活動專區',
        links: [
          {
            title: '獨家企劃',
            path: '/specials'
          },
          {
            title: '體驗試用',
            path: '/trials'
          },
          {
            title: '活動募集',
            path: '/lectures'
          },
          {
            title: '網友來做主',
            path: '/votes'
          },
        ],
      },
      videos: {
        title: 'LOOK V 影音',
        path: '/videos'
      },
      authors: {
        title: '駐站名人',
        path: '/authors'
      },
      onlineMag: {
        title: '線上雜誌',
        path: 'https://choc.look-in.com.tw/'
      },
      youtube: {
        title: 'LOOKin YouTube',
        path: 'https://www.youtube.com/channel/UC9FymR5DhAubxbjWM9JmS_Q'
      },
    };
  },
  components: { SectionBlock },
  computed: {
    ...mapState(['isMobile', 'channels', 'videoCategories']),
  },
}
</script>

<style lang="scss">
#sitemap {
  max-width: 1200px;
  margin: 0 auto;
  h1 {
    margin: 15px 0;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    & .underline {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        height: 6px;
        background: #FFC2AE;
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: -5px;
        z-index: -1;
      }
    }
  }
  @media only screen and (min-width: 700px) {
    h1 {
      font-size: 42px;
      line-height: 42px;
      letter-spacing: 3px;
      text-align: center;
    }
  }
  .column {
    padding: 0;
  }
}
</style>
