<template>
  <section id="lectures">
    <h1><span class="underline">活動募集</span></h1>
    <carousel-3d v-if="lectures" class="top"
      :key="slider3d"
      :autoplay="true"
      :display="display"
      :perspective="0"
      :width="width"
      :height="height"
      :space="space"
      :border="0">
      <slide v-for="(lecture, i) in top3" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <router-link :to="{ name: 'lecture', params: { id: lecture.id }}">
            <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="lecture.image">
            <h3><span class="has-text-black">{{ lecture.title }}</span></h3>
            <p v-if="isOngoing(lecture.ended_time)" class="ended-at has-text-black">{{ lecture.ended_at }} 募集截止</p>
            <p v-else class="ended-at has-text-black">募集已截止</p>
          </router-link>
        </template>
      </slide>
    </carousel-3d>
    <div class="other-lectures">
      <div class="lecture" v-for="(lecture, i) in otherLectures">
        <router-link :to="{ name: 'lecture', params: { id: lecture.id }}">
          <img :src="lecture.image">
          <p v-if="isOngoing(lecture.ended_time)" class="ended-at">{{ lecture.ended_at }} 募集截止</p>
          <div v-else class="end-cover">
            <h3>已截止</h3>
            <p>觀看活動花絮</p>
          </div>
        </router-link>
      </div>
    </div>
    <button v-if="more" class="load-more has-text-white has-background-black has-text-centered" @click="loadMore()">
      LOAD MORE
    </button>
    <transition name="fade">
      <Loading v-show="isLoading" />
    </transition>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { isOngoing } from 'src/share'
import Loading from 'src/components/common/Loading'
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  data() {
    return {
      isLoading: true,
      slider3d: 0,
      display: 3,
      width: 320,
      height: 420,
      space: 180,
      itemCount: 9,
      perPage: 8
    }
  },
  components: { Carousel3d, Slide, Loading },
  watch: {
    isMobile: function () {
      if (this.isMobile) {
        this.height = 390
        this.width = 320
        this.space = 180
      } else {
        this.height = 550
        this.width = 440
        this.space = 480
      }
      this.slider3d += 1
    }
  },
  methods: {
    isOngoing,
    loadMore: function() {
      if (!this.isLoading && this.lectures) {
        this.itemCount += this.perPage
      }
    }
  },
  computed: {
    ...mapState(['lectures', 'isMobile', 'next_page']),
    top3: function () {
      return this.lectures ? this.lectures.slice(0, 3) : null
    },
    otherLectures: function () {
      return this.lectures ? this.lectures.slice(3, this.itemCount) : null
    },
    more: function () {
      return this.lectures ? (this.lectures.length - this.itemCount) > 0 : null
    }
  },
  created() {
    if (window.innerWidth >= 1200) {
      this.height = 550
      this.width = 440
      this.space = 480
      this.itemCount = 11
      this.slider3d += 1
    }
    this.$store.dispatch('loadLectures').then(() => {
      this.isLoading = false
    })
  }
}
</script>

<style lang="scss">
#lectures {
  h1 {
    margin: 30px 0;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    & .underline {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        height: 6px;
        background: #FFC2AE;
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: -5px;
        z-index: -1;
      }
    }
  }
  .top {
    margin: 45px 0;
  }
  .carousel-3d-slide {
    background-color: #FFFFFF;
    h3 {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 1px;
      text-align: center;
      span {
        background-color: #FFDBCF;
        padding: 3px;
      }
    }
    .onLeft + h3, .onRight + h3, .onLeft ~ .ended-at, .onRight ~ .ended-at {
      display: none;
    }
    .ended-at {
      margin-top: 12px;
      font-family: Ubuntu Condensed;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
    }
  }
  .other-lectures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
    padding: 70px 0;
    max-width: 1200px;
    margin: auto;
    .lecture {
      position: relative;
      margin: 10px;
      height: 43vw;
      width: 43vw;
      max-height: 250px;
      max-width: 250px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .ended-at {
        margin-top: 3px;
        font-family: Ubuntu Condensed;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
      }
      .end-cover {
        position: absolute;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(240, 143, 122, 0.7);
        top: 0;
        height: 43vw;
        width: 43vw;
        max-height: 250px;
        max-width: 250px;
        color: #FFFFFF;
        h3 {
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  button.load-more {
    display: block;
    cursor: pointer;
    margin: 40px auto;
    width: 144px;
    height: 34px;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 1px;
    border: none;
    border-radius: 50px;
    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-transition: all .5s;
      -o-transition: all .5s;
      transition: all .5s;
    }
  }
}
</style>
