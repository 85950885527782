<template>
  <div class="articleYellow">
    <div class="text">
      <!-- <p class="title" @click="gotoPlan">您現在有{{ point }}點可以前往兌換禮物！ <span>立即領取＞</span></p> -->
      <p class="title" @click="gotoPlan">從淨白透亮到穩定肌膚狀態超有感！領取熱點立即兌換！</p>
      <img class="img" src="../../../images/icon-close.svg" alt="" @click="onClose" />
    </div>
  </div>
</template>

<script>
import { isLookinApp, isMobile } from '../../../tools/bridge'
export default {
  name: 'YellowTip',
  props: {
    point: {
      type: Number,
      default: 0
    }
  },
  emits: ['close', 'gotoPlan'],
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      try {
        document.querySelector('#root-section header').style.top = '40px'
        if (isMobile || isLookinApp) {
          document.querySelector('#root-section header nav').style.top = '40px'
        }
      } catch (error) {
        console.warn(error)
      }
    })
  },
  destroyed() {
    try {
      document.querySelector('#root-section header').style.top = '0'
      document.querySelector('#root-section header nav#channel-list.fixed-header').style.top = '0'
    } catch (error) {
      console.warn(error)
    }
    try {
      document.querySelector('#root-section header nav').style.top = '0'
      document.querySelector('#root-section header #menu-list').style.top = '60px'
    } catch (error) {
      console.warn(error)
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
      try {
        new TrackEvent().setEvent('close_articleYellow', '', '', null, null)
      } catch (error) {
        console.warn(error)
      }
    },
    gotoPlan() {
      this.$emit('gotoPlan')
      if (isLookinApp) {
        try {
          new TrackEvent().setEvent('click_articleYellow', 'app', '', null, null)
        } catch (error) {
          console.warn(error)
        }
      } else if (isMobile) {
        try {
          new TrackEvent().setEvent('click_articleYellow', 'mobile', '', null, null)
        } catch (error) {
          console.warn(error)
        }
      } else {
        try {
          new TrackEvent().setEvent('click_articleYellow', 'pc', '', null, null)
        } catch (error) {
          console.warn(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.articleYellow {
  width: 100%;
  height: 40px;
  background: #f9e03a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 38;
  cursor: pointer;
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 10px; */
    .title {
      flex: 1;
      margin: 0;
      font-size: 14px;
      color: #000000;
      line-height: 40px;
      text-align: center;
      font-weight: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      padding-left: 5px;
      span {
        margin-right: 5px;
      }
    }
    .img {
      width: 40px;
      height: 40px;
      padding: 15px;
      box-sizing: border-box;
    }
  }
}
</style>
