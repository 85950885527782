<template>
  <section id="hot-issue" :class="isMobile ? '' : 'has-background-lookin-bg'">
    <div class="home-block-title has-text-centered has-text-black" ref="titleRef">
      <h2><span>H</span>ot Issue<span class="has-text-lookin-darker">熱門話題</span></h2>
    </div>
    <div class="issues">
      <div v-if="i < itemsToShow" class="issue" v-for="(article, i) in hot_issues" ref="articleRef">
        <div class="content">
          <router-link
            :to="{
              path: `/${article.parent_path}/${article.child_path}/${article.id}`,
              params: {
                title: article.title
              }
            }"
            @click.native="jumpArticle(article.id, i)"
          >
            <!-- <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" /> -->
            <LazyImage class="content-img" :src="article.image_url" />
          </router-link>
          <span class="image-tag eng-font has-text-centered has-text-black has-background-lookin">
            Hot! No.{{ i + 1 }}
          </span>
        </div>
        <div class="slide-text">
          <p class="has-text-centered">
            <router-link
              v-for="tag in article.tags.slice(0, 3)"
              :key="tag"
              class="tag"
              :to="{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }"
              >#{{ tag }}</router-link
            >
          </p>
          <p class="has-text-lookin-darker has-text-centered">
            <router-link :to="{ name: 'new', query: { category: article.category.path } }">
              <span class="category has-text-black" :class="cateClass(article.category.id)">{{
                article.category.title
              }}</span>
            </router-link>
            <span class="date-author"> {{ article.published_at }} ‧ {{ article.author_name }}</span>
          </p>
          <router-link
            :to="{
              path: `/${article.parent_path}/${article.child_path}/${article.id}`,
              params: {
                title: article.title
              }
            }"
            @click.native="jumpArticle(article.id, i)"
          >
            <h3 class="title">{{ article.title }}</h3>
          </router-link>
        </div>
      </div>
    </div>
    <button
      v-if="showMore"
      class="load-more has-text-white has-background-black has-text-centered"
      @click="showMore = false"
    >
      LOAD MORE
    </button>
    <Advertisement v-if="!isMobile" :id="25" :width="970" :height="250" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { categoryClass } from 'src/share'
import Advertisement from 'src/components/common/Advertisement'
import LazyImage from '../../components/common/LazyImage.vue'
import useObserver from '../../../tools/useObserver'

export default {
  props: ['isLoading', 'hot_issues'],
  data() {
    return {
      showMore: true
    }
  },
  components: { Advertisement, LazyImage },
  methods: {
    cateClass: id => categoryClass(id),
    jumpArticle(id, index) {
      try {
        new TrackEvent().setEvent('click_home_issue_article', 'click', id, index + 1, null)
      } catch (e) {}
    }
  },
  computed: {
    ...mapState(['isMobile']),
    itemsToShow: function () {
      if (this.showMore) {
        return this.isMobile ? 5 : 6
      } else {
        return this.isMobile ? 10 : 12
      }
    }
  },
  mounted() {
    useObserver(this.$refs.titleRef, () => {
      try {
        new TrackEvent().setEvent('exposure_home_issue', 'read', this.hot_issues.length, null, null)
      } catch (error) {}
    })

    const articleRef = this.$refs.articleRef
    if (articleRef) {
      articleRef.forEach((el, i) => {
        useObserver(el, () => {
          try {
            new TrackEvent().setEvent('read_home_issue_article', 'read', this.hot_issues[i].id, i + 1, null)
          } catch (e) {}
        })
      })
    }
  }
}
</script>

<style lang="scss">
#hot-issue {
  padding-bottom: 40px;
  .issues {
    width: 92%;
    margin: 0 4%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .issue {
      height: 435px;
      max-width: 375px;
      margin: 20px;
      position: relative;
      img {
        height: 216px;
        width: 100%;
        border: 1px solid #ffc2ae;
        margin: 0 auto;
        object-fit: cover;
      }
      span.image-tag {
        font-weight: normal;
        font-size: 14px;
        line-height: 71%;
        letter-spacing: 1px;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px;
      }
      .slide-text {
        p {
          padding: 5px;
          margin: 0;
          span.category {
            font-size: 13px;
            line-height: 185%;
            letter-spacing: 1px;
            padding: 1px 6px;
          }
          span.date-author {
            font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 71%;
            letter-spacing: 1px;
          }
        }
        h3.title {
          font-size: 16px;
          line-height: 140%;
          letter-spacing: 1px;
          text-align: justify;
          padding: 5px 15px;
          margin: 0;
        }
        .tag {
          font-size: 13px;
          line-height: 185%;
          text-align: center;
          letter-spacing: 1px;
          color: #f08f7a;
          background-color: transparent;
          margin: 6px 6px 0;
          border: 1px solid #f08f7a;
          border-radius: 50px;
        }
      }
    }
  }
}

button.load-more {
  display: block;
  cursor: pointer;
  margin: 40px auto;
  width: 144px;
  height: 34px;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  border: none;
  border-radius: 50px;
  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}
</style>
