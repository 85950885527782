<template>
  <div class="my-coupon-detail" v-if="couponDetail">
    <h3 class="d-title"><span @click="gotoCoupon">我的票券</span> &gt; <span class="t-detail">票券詳情</span></h3>
    <div class="d-content">
      <CouponItme :info="couponDetail" />
      <div class="task">
        <QRCode :status="couponDetail.status" :code="couponDetail.convertCode" />
        <template v-if="couponDetail.activateInfo">
          <CouponLine />
          <CouponTask
            :title="taskTitle[couponDetail.activateRules]"
            :isShow="isShowTask"
            @toggle="isShowTask = !isShowTask"
          >
            <template #content>
              <component
                :is="activationType[couponDetail.activateRules]"
                :info="couponDetail.activateInfo"
                :couponId="couponDetail.id"
                :helpTimes="couponDetail.helpTimes"
                :status="couponDetail.status"
                @reloadDetail="onReloadDetail"
              />
              <template v-if="couponDetail.activateRules === 2">
                <CouponLine text="瀏覽文章，可獲取答案哦" />
                <CouponRead :couponId="couponDetail.id" :info="couponDetail.activateInfo.couponArticleVO" :type="1" />
              </template>
            </template>
          </CouponTask>
        </template>
      </div>
      <div class="clarification">
        <h4 class="c-title">使用說明</h4>
        <div v-html="couponDetail.useRules"></div>
        <!-- <div class="c-text">
          <p>使用時間</p>
          <ul>
            <li>限09.01一日店長活動當天使用。</li>
          </ul>
        </div>
        <div class="c-text">
          <p>使用场景</p>
          <ul>
            <li>憑畫面或截圖到實體櫃位即可享10%優惠。</li>
            <li>每筆結帳限使用一張。</li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="d-send">
      <b-button
        class="btn"
        type="is-primary"
        :style="exchangeColor"
        :disabled="couponDetail.status !== 1"
        rounded
        expanded
        @click="handleExchange"
      >
        {{ exchangeText[couponDetail.status] }}
      </b-button>
    </div>
    <Modal :visible.sync="modalShow" :title="modalTitle[modalType]">
      <template #content v-if="modalType === 'code'">
        <p style="font-size: 14px; color: #999999">輸入商家碼核銷兌換獎品</p>
        <b-field
          style="text-align: left; margin: 25px 0 23px"
          :type="{ 'is-danger': hasInputError }"
          :message="inputMessage"
        >
          <b-input placeholder="請輸入商家碼" v-model="storeCode" @input="onInput" @focus="onFocus" />
        </b-field>
        <b-button type="is-primary" style="background: #f08f7a; color: #ffffff" rounded expanded @click="onConfirm">
          確認兌換
        </b-button>
      </template>
      <template #content v-else>
        <CouponIcon />
        <p style="font-size: 16px; color: #404040; margin: 5px 0 27px">優惠券已使用</p>
        <b-button
          type="is-primary"
          style="background: #f08f7a; color: #ffffff; width: 202px"
          rounded
          @click="modalShow = false"
        >
          我知道了
        </b-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CouponItme from '../../components/coupon/Item.vue'
import QRCode from '../../components/coupon/QRCode.vue'
import CouponTask from '../../components/coupon/Task.vue'
import InviteFriends from '../../components/coupon/InviteFriends.vue'
import CouponTitle from '../../components/coupon/Title.vue'
import CouponRead from '../../components/coupon/Read.vue'
import CouponLine from '../../components/coupon/Line.vue'
import CouponIcon from '../../components/coupon/Icon.vue'
import Modal from '../../components/dialog'
import { couponInfo, couponUserUse } from '../../../api/storeManager.js'

export default {
  name: 'MyCouponDetail',
  components: { CouponItme, QRCode, CouponTask, InviteFriends, CouponTitle, CouponRead, CouponLine, Modal, CouponIcon },
  props: {
    couponId: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      // 任务标题
      taskTitle: {
        1: '邀請好友任務',
        2: '做任務激活票券',
        3: '閱讀文章激活票券'
      },
      // 组件类型
      activationType: {
        1: InviteFriends, // 邀请好友激活
        2: CouponTitle, // 做题激活
        3: CouponRead // 阅读
      },
      // 兑换文本
      exchangeText: {
        0: '待完成',
        1: '使用優惠券',
        2: '已使用'
      },
      modalShow: false,
      isShowTask: false,
      /**
       * 优惠券信息
       * status 优惠券状态，0：未激活；1：已激活；2：已核销；3：已过期；
       * activateRules 激活规则  0：默认激活；1：分享助力激活；2：答题激活；3：读文章激活；
       */
      couponDetail: null,
      storeCode: '', // 商家码
      // 弹框类型 code：商家碼核銷； succ：核銷成功；
      modalType: 'code',
      modalTitle: {
        code: '商家碼核銷',
        succ: '核銷成功'
      },
      hasInputError: false,
      inputMessage: ''
    }
  },
  created() {
    // 获取票券详情
    this.getCouponInfo()
    // 已激活票券每隔5秒更新状态
    this.timeId = setInterval(() => {
      if (this.couponDetail.status === 1) {
        this.getCouponInfo()
      }
    }, 5000)
  },
  destroyed() {
    clearInterval(this.timeId)
  },
  computed: {
    ...mapState(['isMobile']),
    exchangeColor() {
      return this.couponDetail.status === 1
        ? { backgroundColor: '#F08F7A' }
        : { backgroundColor: '#DBDBDB', borderColor: '#DBDBDB' }
    }
  },
  methods: {
    // 点击兑换
    handleExchange() {
      // 已激活的票券才显示弹框
      if (this.couponDetail.status === 1) this.modalShow = true
    },
    gotoCoupon() {
      this.$router.push('/my/coupon')
    },
    // 获取票券信息
    async getCouponInfo() {
      try {
        const couponId = this.$route.params.id || this.couponId
        const { code, message, result } = await couponInfo(couponId)
        if (code === 200 && result) {
          // 优惠券未激活，折叠任务
          if (result.status === 0) {
            this.isShowTask = true
          }
          this.couponDetail = result
        } else {
          // console.log(message)
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
          clearInterval(this.timeId)
        }
      } catch (error) {
        // 报错 取消定时器
        clearInterval(this.timeId)
        console.error(error)
      }
    },
    onReloadDetail() {
      // 刷新详情接口
      this.getCouponInfo()
    },
    // 用户核销
    async onConfirm() {
      // 去掉全部空格
      const storeCode = this.storeCode.replace(/\s*/g, '')
      if (storeCode === '') {
        this.inputMessage = '商家碼不能為空'
        this.hasInputError = true
        return
      }

      const { code, message } = await couponUserUse({
        convertCode: this.couponDetail.convertCode,
        storeCode
      })
      if (code === 200) {
        this.modalType = 'succ'
        // 核销成功 清除定时器
        clearInterval(this.timeId)
        this.onReloadDetail()
      } else if (code === 1509) {
        this.inputMessage = '核銷失敗，請確認您輸入的核銷碼是否正確'
        this.hasInputError = true
      } else {
        this.$buefy.toast.open({
          type: 'is-danger',
          message
        })
      }
    },
    onFocus() {
      this.inputMessage = ''
      this.hasInputError = false
    },
    onInput(val) {
      if (!val) return
      // 每4位隔开
      this.storeCode = val.replace(/\s/g, '').replace(/....(?!$)/g, '$& ')
    },
  }
}
</script>

<style lang="scss" scoped>
.my-coupon-detail {
  min-height: 100vh;
  background-color: #f5f5f5;
  .d-title {
    font-size: 13px;
    line-height: 15px;
    color: #666666;
    padding: 16px 0 16px 19px;
    background-color: #ffffff;
    span {
      cursor: pointer;
    }
    .t-detail {
      color: #f08f7a;
    }
  }
  .d-content {
    padding: 10px 15px;
    .task {
      margin-top: 10px;
      padding: 30px 15px 20px;
      background-color: #ffffff;
      border-radius: 6px;
      // .line {
      //   width: 100%;
      //   border-bottom: 1px dashed #cccccc;
      //   margin-top: 27px;
      // }
    }
    .clarification {
      padding: 18px 7px 18px 13px;
      margin-top: 10px;
      background-color: #ffffff;
      border-radius: 6px;
      .c-title {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        line-height: 26px;
      }
      .c-text {
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 14px;
        ul {
          list-style: inherit;
          padding-left: 18px;
          li {
            list-style: inherit;
          }
        }
      }
    }
  }
  .d-send {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 83px;
    background-color: #ffffff;
    padding: 15px;
    .btn {
      font-size: 15px;
      color: #ffffff;
      background-color: #f08f7a;
    }
  }
}

// web端样式
@media screen and (min-width: 768px) {
  .my-coupon-detail {
    width: 640px;
    background-color: #ffffff;
    margin: 0 auto;
    .d-content {
      ::v-deep .coupon-item {
        // width: 452px;
        // margin: 20px 15px;
        cursor: pointer;
        &:not(.active) {
          .coupon {
            border: 1px solid #e9e9ea;
            &::before {
              background-color: #ffffff;
              left: -1px;
              border: 1px solid #e9e9ea;
              border-left: 1px solid #ffffff;
            }
            &::after {
              background-color: #ffffff;
              right: -1px;
              border: 1px solid #e9e9ea;
              border-right: 1px solid #ffffff;
            }
          }
        }
      }
      .task {
        border: 1px solid #e9e9ea;
      }
      .clarification {
        display: none;
      }
    }
    .d-send {
      display: none;
    }
  }
}
</style>
