<template>
  <b-modal
    v-model="open"
    custom-class='lookin-modal'
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    :can-cancel="false"
    aria-role="dialog"
    aria-modal>
    <div class="modal-card">
      <section class="modal-card-body has-text-black">
        <img :src="require('@/../images/icon-close.svg')" alt="Close" class="tail-icon close" @click="$emit('close')">
        <h3 class="modal-card-body-title">{{ this.title }}</h3>
        <p>{{ this.content }}</p>
      </section>
      <footer class="modal-card-foot">
        <b-button @click="$emit('close')" :class="confirm ? 'cancel' : ''">{{ this.btnWord }}</b-button>
        <b-button v-if="confirm" @click="$emit('submit')">{{ this.confirm }}</b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['open', 'title', 'content', 'btnWord', 'confirm'],
  data() {
    return {
    };
  },
}
</script>

<style lang="scss">
.modal.is-active.lookin-modal {
  z-index: 42;
  .modal-card {
    box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
    border-radius: 20px !important;
    width: 310px;
    margin: auto;
    .modal-card-head {
      border-bottom: 1px solid #FFDBCF;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .modal-card-body {
      background: #FFDBCF;
      text-align: center;
      .close {
        right: 12px;
        top: 13px;
      }
      .modal-card-body-title {
        font-size: 26px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 3px;
        margin: 30px auto 20px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis; 
      }
    }
    .modal-card-foot {
      border-top: 1px solid #FFDBCF;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background: #FFDBCF;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      button {
        width: 94px;
        height: 36px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
        border-radius: 50px;
        border: none;
      }
      button.cancel {
        background: #FFDBCF;
      }
    }
  }
  .modal-background {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
