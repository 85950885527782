<template>
  <div id="update_tip_pop">
    <div class="box">
      <img
        class="close"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAATRJREFUSEut1u11wjAMheE3G3QEbUBHaCcoI7STtEzSjgATwAh0g4zABu0RJ8kxwbYkY/7y8aDYutIgIl/AJ7AHPsZxvNDxJSJPwDewBXaDiPwlv38GXnuhE3YEnmdDQa3srTeaw4CDglryCdj0QgvYL/AyKNITrWF6VFewF2ph6izgo6gHuwNbUS+WBaNoBCuCXjSKVUELne7aTVMD16tfC46bS5NLtErL6MeXBPFgZoVGy6T/z6xsiTZvThcq1a+7MXeFyXmuz0zfCgW+eYYGNj8gN2qClauvWDjwq2Ctz6bSwlOmCHqaumXKZEEP1jpl7sAI1oLmxlM4rqwYTKNuPYCbsEil6YrxEOZF5yWqC+ZBc2tiKBtLWVxomf16Ee6C1SpVcF71D8B7r617hf5My/buH1flBakpKfBxAAAAAElFTkSuQmCC"
        alt=""
        @click="handleClose"
      />
      <h5 class="title">發現新版本</h5>
      <div class="tip">
        感謝大家的對LOOKin的喜愛，為提供更優化的閱讀方式與更優質的使用體驗，LOOKin已完成升級維護囉！現在就立即至
        {{ Android ? '應用商店' : 'APPSTORE' }}
        更新，一起體驗！
      </div>
      <div class="btn">
        <div class="comfig" @click="handleComfig">我知道了！</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Android } from '../../../tools/bridge'
export default {
  name: 'UpdateTip',
  data() {
    return {
      Android
    }
  },
  methods: {
    handleComfig() {
      this.$emit('comfig')
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
#update_tip_pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  /* display: none; */
  .box {
    width: 74.6667vw;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 3.4667vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 6.667vw;
    .title {
      font-size: 4.267vw;
      color: #0a0a0a;
      text-align: center;
      margin: 4vw 0;
    }
    .tip {
      color: #404040;
      font-size: 16px;
      .tip_title {
        margin-bottom: 1.333vw;
      }
      .tip_content {
        margin-top: 1.333vw;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      margin: 8vw 0 4.8vw;
      .comfig {
        width: 29vw;
        height: 10.667vw;
        background: #ffc2ae;
        border-radius: 6.6667vw;
        color: #676767;
        font-size: 3.733vw;
        line-height: 10.667vw;
        text-align: center;
      }
    }
    .close {
      width: 3vw;
      height: 3vw;
      position: absolute;
      top: 3vw;
      right: 4vw;
    }
  }
}
</style>
