<template>
  <div class="binding-account" v-if="isShow">
    <div class="binding-account-title">
      <span class="binding-title">社群帳號綁定</span>
      <p class="title-tip">統一帳號綁定最便利！快速登入一鍵完成！</p>
    </div>
    <div class="binding-account-list">
      <div class="binding-item facebook">
        <div class="item-left">
          <div class="left-title">
            <img
              class="img"
              src="../../../images/bindingAccount/icon_fb.svg"
              alt="fb"
            />
            <span class="text">Facebook</span>
          </div>
          <p class="unbound" :class="{ active: fbTip != '未綁定' }">{{ fbTip }}</p>
        </div>
        <div class="item-right">
          <button class="item-btn" v-if="isShowFbBtn" @click="onBindFb">
            <img
              class="icon"
              src="../../../images/bindingAccount/icon_succ.svg"
              alt=""
            />
            <span>已綁定</span>
          </button>
          <button class="item-btn" v-else @click="onGotoFb">
            <img
              class="icon"
              src="../../../images/bindingAccount/icon_add.svg"
              alt=""
            />
            <span>綁定</span>
          </button>
        </div>
      </div>
      <div class="binding-item google">
        <div class="item-left">
          <div class="left-title">
            <img
              class="img"
              src="../../../images/bindingAccount/icon_google.svg"
              alt="fb"
            />
            <span class="text">Google</span>
          </div>
          <p class="unbound" :class="{ active: glTip != '未綁定' }">{{ glTip }}</p>
        </div>
        <div class="item-right">
          <button class="item-btn" v-if="isShowGlBtn" @click="onBindGoogle">
            <img
              class="icon"
              src="../../../images/bindingAccount/icon_succ.svg"
              alt=""
            />
            <span>已綁定</span>
          </button>
          <button class="item-btn" v-else @click="onGotoGoogle">
            <img
              class="icon"
              src="../../../images/bindingAccount/icon_add.svg"
              alt=""
            />
            <span>綁定</span>
          </button>
        </div>
      </div>
      <!-- <div class="binding-item line">
        <div class="item-left">
          <div class="left-title">
            <img
              class="img"
              src="../../../images/bindingAccount/icon_line.svg"
              alt="fb"
            />
            <span class="text">LINE</span>
          </div>
          <p class="unbound">未綁定</p>
        </div>
        <div class="item-right">
          <button class="item-btn">
            <img
              class="icon"
              src="../../../images/bindingAccount/icon_add.svg"
              alt=""
            />
            <span>綁定</span>
          </button>
        </div>
      </div>
      <div class="binding-item apple">
        <div class="item-left">
          <div class="left-title">
            <img
              class="img"
              src="../../../images/bindingAccount/icon_apple.svg"
              alt="fb"
            />
            <span class="text">Apple</span>
          </div>
          <p class="unbound">未綁定</p>
        </div>
        <div class="item-right">
          <button class="item-btn">
            <img
              class="icon"
              src="../../../images/bindingAccount/icon_add.svg"
              alt=""
            />
            <span>綁定</span>
          </button>
        </div>
      </div> -->
    </div>
    <new-modal
      v-show="isModal"
      :title="titleModal"
      :content="contentModal"
      :confirmText="confirmTextModal"
      :cancelText="cancelTextModal"
      @onConfirm="onConfirmModal"
      @onCancel="onCancelModal"
    />
  </div>
</template>

<script>
import NewModal from "../cancellation/NewModal.vue";
import { getBindStatus, unbind } from '../../../api/bind.js'
export default {
  name: "BindingAccount",
  components: {
    NewModal,
  },
  data() {
    return {
      token: "",
      baseUrl: "",
      titleModal: "",
      contentModal: "",
      confirmTextModal: "",
      cancelTextModal: "",
      isModal: false,
      fbTip: "未綁定",
      glTip: "未綁定",
      isShowFbBtn: false, // 顯示或隱藏已綁定按鈕，默認隱藏
      isShowGlBtn: false,
      unbundlType: 0, // 解綁類型： 默認 0 不解綁， 1 Google， 2 Facebook
      isShow: false
    };
  },
  async created() {
    /**
     * 判断是测试服还是正式服
     * 测试服基地址：https://ynstaging.look-in.com.tw
     * 正式服基地址：
     */
    const host = window.location.host || "";
    if (host.includes("staging")) {
      this.baseUrl = "https://ynstaging.look-in.com.tw/apis";
    } else if (host.includes("localhost")) {
      this.baseUrl = "https://ynstaging.look-in.com.tw/apis";
    } else {
      this.baseUrl = "https://apis.look-in.com.tw";
    }
    // 获取token
    this.token = window.localStorage.getItem("auth-token") || "";
    /**
     * 获取参数 判断是否绑定
     * bind true 绑定成功， false 绑定失败
     * type google_oauth2 facebook
     */
    const { bind, type, msg } = this.$route.query;
    // 獲取綁定狀況
    try {
      const { code, result } = await getBindStatus();
      if (code === 200) {
        const { data, isShow } = result
        this.isShow = isShow
        if(isShow) {
          data.map((item) => {
            if (item.type === "facebook") {
              this.isShowFbBtn = true;
              this.fbTip = item.name;
              // this.unbundlType = 2;
            } else if (item.type === "google_oauth2") {
              this.isShowGlBtn = true;
              this.glTip = item.name;
              // this.unbundlType = 1;
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    // bind 是字符串
    if (bind === "true") {
      if (type === "google_oauth2") {
        this.titleModal = "Google綁定成功";
        this.contentModal = "歡迎直接使用Google登入";
        this.confirmTextModal = "好的";
        this.isModal = true;
        // this.isShowGlBtn = true;
      } else if (type === "facebook") {
        this.titleModal = "Facebook綁定成功";
        this.contentModal = "歡迎直接使用Facebook登入";
        this.confirmTextModal = "好的";
        this.isModal = true;
        // this.isShowFbBtn = true;
      }
    } else if (bind === "false") {
      if (type === "google_oauth2") {
        this.titleModal = "Google綁定失敗";
        this.contentModal = msg;
        this.confirmTextModal = "好的";
        this.isModal = true;
      } else if (type === "facebook") {
        this.titleModal = "Facebook綁定失敗";
        this.contentModal = msg;
        this.confirmTextModal = "好的";
        this.isModal = true;
      }
    }
  },
  methods: {
    onGotoFb() {
      // 跳转链接必须要token，不然会报错
      // facebook
      if (this.token) {
        window.location.href = `${this.baseUrl}/shop/thirdparty/thirdpartyRedirect/2/${this.token}`;
      } else {
        console.log("未登录");
      }
    },
    onGotoGoogle() {
      //google
      if (this.token) {
        window.location.href = `${this.baseUrl}/shop/thirdparty/thirdpartyRedirect/1/${this.token}`;
      } else {
        console.log("未登录");
      }
    },
    async onConfirmModal() {
      /**
       * unbundlType
       * 為 0 普通彈框
       *
       * 其他為解除綁定
       * 1 google
       * 2 facebook
       *
       */
      if (this.unbundlType) {
        try {
          const { code, result } = await unbind(this.unbundlType);
          if (result) {
            switch (this.unbundlType) {
              case 1:
                this.isShowGlBtn = false;
                this.glTip = '未綁定'
                break;
              case 2:
                this.isShowFbBtn = false;
                this.fbTip = '未綁定'
              default:
                break;
            }
          } else {
            this.$buefy.toast.open({
              duration: 2000,
              message: "解綁失敗，請稍後重試！",
            });
          }
          this.isModal = false;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isModal = false;
      }
    },
    onCancelModal() {
      this.isModal = false;
    },
    onBindFb() {
      this.titleModal = "提示";
      this.contentModal = "是否確認要解除Facebook綁定";
      this.confirmTextModal = "確認";
      this.cancelTextModal = "取消";
      this.unbundlType = 2;
      this.isModal = true;
    },
    onBindGoogle() {
      this.titleModal = "提示";
      this.contentModal = "是否確認要解除Google綁定";
      this.confirmTextModal = "確認";
      this.cancelTextModal = "取消";
      this.unbundlType = 1;
      this.isModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.binding-account {
  .binding-title {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0a0a0a;
    line-height: 36px;
    border-bottom: 4px solid #ffc1ad;
    padding-bottom: 4px;
  }
  .title-tip {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 19px;
    margin-top: 19px;
  }
  .binding-account-list {
    .binding-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 19px;
      padding-top: 23px;
      border-bottom: 1px solid #dcdcdc;
      .item-left {
        .left-title {
          margin-bottom: 8px;
          .img {
            width: 16px;
            height: 17px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #404040;
            line-height: 21px;
            vertical-align: text-bottom;
            margin-left: 8px;
          }
        }
        .unbound {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c2c2c2;
          line-height: 21px;
        }

        .unbound.active {
          color: #404040;
        }
      }
      .item-right {
        .item-btn {
          width: 90px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #ffc1ad;
          border-radius: 18px;
          cursor: pointer;
          .icon {
            width: 11px;
            height: 11px;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #363636;
            line-height: 36px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .binding-account {
    .binding-account-title {
      padding: 30px 15px;
      background-color: #ffffff;
      .binding-title {
        border: none;
        font-size: 18px;
      }
      .title-tip {
        margin-top: 5px;
      }
    }
    .binding-account-list {
      margin: 0 auto;
      width: 75%;
      max-width: 400px;
    }
  }
}
</style>
