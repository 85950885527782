<template>
  <div ref="infiniteWrapper">
    <template v-if="isChannel && !isLoading">
      <HomeSlide :isLoading="isLoading" :home_slides="current_channel.slides" />
      <Advertisement :id="35" :width="300" :height="100" />
      <div class="channel-title">
        <h1>
          <span class="underline">{{ current_channel.title }}</span>
        </h1>
        <div class="article-categories">
          <router-link
            v-for="(cate, index) in categories"
            :key="cate.key"
            :to="{ name: routeName, query: { category: cate.key } }"
          >
            <span class="category has-text-black" :class="cateClass(index + 1)">{{ cate.name }}</span>
          </router-link>
        </div>
      </div>
    </template>
    <template v-else-if="routeName === 'tag' && tagData">
      <HomeSlide v-if="tagData.images && tagData.images.length > 0" :home_slides="tagData.images" />
      <div class="channel-title tag-title">
        <h1>
          <span class="underline">{{ tagData.name }}</span>
        </h1>
        <p class="tag-description">
          {{ tagData.description }}
        </p>
      </div>
    </template>
    <div id="article-list">
      <template v-for="(article, i) in articles">
        <div class="list-article" :key="article.id">
          <p v-if="isMobile" class="author">
            <router-link :to="article.author_url">
              <img class="avatar" :src="authorAvatar(article.author_avatar)" />
              <span class="name has-text-black">{{ article.author_name }}</span>
            </router-link>
            <span class="date">{{ article.published_at }}</span>
          </p>
          <router-link
            class="cover img-container"
            :to="{
              path: `/${article.parent_path}/${article.child_path}/${article.id}`,
              params: {
                title: article.title
              }
            }"
          >
            <img :src="article.image_url" :alt="article.title" class="big-content-img" />
          </router-link>
          <div class="a-info">
            <div v-if="!isMobile">
              <p class="author">
                <router-link :to="article.author_url">
                  <img class="avatar" :src="authorAvatar(article.author_avatar)" />
                  <span class="name has-text-black">{{ article.author_name }}</span>
                </router-link>
                <span class="date">{{ article.published_at }}</span>
              </p>
              <h2 class="title has-text-black">
                <router-link :to="{ name: routeName, query: { category: article.category.path } }">
                  <span class="category has-text-black" :class="cateClass(article.category.id)">{{
                    article.category.title
                  }}</span>
                </router-link>
                <!-- <router-link
                  :to="{
                    name: 'article',
                    params: {
                      channel: article.parent_path,
                      sub_channel: article.child_path,
                      id: article.id,
                      title: article.title
                    }
                  }"
                >
                  {{ article.title }}
                </router-link> -->
                <router-link
                  :to="{
                    path: `/${article.parent_path}/${article.child_path}/${article.id}`,
                    params: {
                      title: article.title
                    }
                  }"
                >
                  {{ article.title }}
                </router-link>
              </h2>
              <p class="abstract">
                {{ article.abstract }}
              </p>
            </div>
            <p :class="isMobile ? 'has-text-centered tags' : 'tags'">
              <router-link
                v-for="tag in article.tags.slice(0, 3)"
                :key="tag"
                class="tag"
                :to="{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }"
                >#{{ tag }}</router-link
              >
            </p>
          </div>
          <h2 v-if="isMobile" class="title has-text-black">
            <span class="category has-text-black" :class="cateClass(article.category.id)">{{
              article.category.title
            }}</span>
            <router-link
              :to="{
                path: `/${article.parent_path}/${article.child_path}/${article.id}`,
                params: {
                  title: article.title
                }
              }"
            >
              {{ article.title }}
            </router-link>
          </h2>
        </div>
        <Advertisement v-if="isMobile && isChannel && i == 2" :id="37" :width="300" :height="250" />
        <Advertisement v-if="isChannel && !isMobile && i % 10 == 9" :id="30" :width="970" :height="250" />
      </template>
    </div>
    <transition name="fade">
      <Loading v-show="isLoading" />
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { categoryClass } from 'src/share'
import { throttle } from 'throttle-debounce'
import HomeSlide from './HomePage/HomeSlide'
import Loading from 'src/components/common/Loading'
import Advertisement from 'src/components/common/Advertisement'
import { getTagCarousel } from '../../api/tag'

export default {
  data() {
    return {
      isLoading: true,
      categories: [
        {
          key: 'topic',
          name: '話題'
        },
        {
          key: 'practical',
          name: '實用'
        },
        {
          key: 'experience',
          name: '體驗'
        }
      ],
      // tag轮播图数据
      tagData: null,
      // 是否还有下一页
      isNextPage: true
    }
  },
  components: { HomeSlide, Advertisement, Loading },
  computed: {
    ...mapState(['isMobile', 'articles', 'current_channel', 'next_page']),
    isChannel() {
      return ['channel', 'sub_channel'].includes(this.$route.name)
    },
    authorAvatar: function () {
      return avatar => (avatar ? avatar : require('@/../images/editor.png'))
    },
    routeName() {
      return this.$route.name
    }
  },
  watch: {
    '$route.params.category': {
      handler: function () {
        if (!this.isLoading) {
          this.apiCall()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cateClass: id => categoryClass(id),
    apiCall() {
      switch (this.$route.name) {
        case 'channel':
        case 'sub_channel':
          this.$store.dispatch('loadChannelArticles').then(res => {
            this.isLoading = false
            this.isNextPage = res.data?.next_page ?? false
          })
          break
        case 'tag':
          this.$store.dispatch('loadTagArticles').then(res => {
            this.isLoading = false
            this.isNextPage = res.data?.pagination_info?.next_page ?? false
          })
          break
        default: // new or hot
          this.$store.dispatch('loadArticles').then(res => {
            this.isLoading = false
            this.isNextPage = res.data?.pagination_info?.next_page ?? false
          })
          break
      }
    },
    async getTagCarouselApi() {
      try {
        const { slug } = this.$route.params
        const { code, result } = await getTagCarousel({ tagName: slug })
        if (code === 200 && result) {
          this.tagData = result
        }
      } catch (error) {
        console.error(error)
      }
    },
    scrollHandler: throttle(800, function () {
      let offSet =
        this.$refs.infiniteWrapper.scrollHeight -
        document.querySelector('html').scrollTop -
        document.documentElement.clientHeight
      if (!this.isLoading && this.articles && offSet < 100 && this.isNextPage) {
        this.isLoading = true
        // Load more page by state.current_page
        this.apiCall()
      }
    })
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
    this.apiCall()

    // 是tag页 获取tag轮播图数据
    if (this.routeName === 'tag') this.getTagCarouselApi()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style lang="scss">
@media screen and (min-width: 1200px) {
  #article-list {
    width: 1000px;
    margin: 10px auto;
    .list-article {
      display: flex;
      justify-content: space-between;
      height: 320px;
      max-width: 1000px;
      margin: 50px auto;
      p {
        padding: 0;
      }
      a.img-container {
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 500px;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .a-info {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 450px;
        padding: 15px 0;
        h2.title {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 30px;
          text-align: justify;
          margin: 10px 0 25px;
        }
        .abstract {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
          padding: 0;
          color: #989595;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .author {
          img.avatar {
            width: 35px;
            height: 35px;
          }
          .name {
            font-size: 17px;
            line-height: 10px;
            letter-spacing: 1px;
          }
          .date {
            font-size: 17px;
            line-height: 10px;
            letter-spacing: 1px;
            padding: 15px 0;
          }
        }
        .tags {
          position: absolute;
          bottom: 20px;
          left: -6px;
        }
      }
    }
  }
}

.channel-title {
  h1 {
    margin: 15px 0;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    & .underline {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        height: 6px;
        background: #ffc2ae;
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: -5px;
        z-index: -1;
      }
    }
  }
  @media only screen and (min-width: 700px) {
    h1 {
      font-size: 42px;
      line-height: 42px;
      letter-spacing: 3px;
      text-align: center;
    }
  }
  .article-categories {
    display: flex;
    justify-content: center;
    margin: 20px auto 34px;
    a span {
      margin: 9px;
      padding: 3px 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 1px;
    }
  }
  span.category.cat-1 {
    background: #bff6ef;
  }

  span.category.cat-2 {
    background: #fdff9e;
  }

  span.category.cat-3 {
    background: #ffd6e8;
  }
}

.list-article {
  position: relative;
  margin: 0 auto;
  max-width: 810px;
  .author {
    font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 10px;
    color: #000000;
    .avatar {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-right: 8px;
      object-fit: cover;
      vertical-align: bottom;
    }
    .name {
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 14px;
    }
    .date {
      float: right;
      padding: 7px;
    }
  }
  a.cover {
    width: 100%;
    img {
      border: 1px solid #ffc2ae;
      width: 100%;
    }
  }
  h2.title {
    font-size: 16px;
    line-height: 140%;
    text-align: justify;
    margin: 8px 14px 60px;
    span.category {
      font-size: 13px;
      line-height: 185%;
      letter-spacing: 1px;
      padding: 2px 6px;
    }
    a {
      color: #000000;
    }
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 71%;
    letter-spacing: 1px;
    padding: 5px;
  }
  .tag {
    font-size: 13px;
    line-height: 185%;
    text-align: center;
    letter-spacing: 1px;
    color: #f08f7a;
    background-color: transparent;
    margin: 6px 6px 0;
    border: 1px solid #f08f7a;
    border-radius: 50px;
  }
  &:last-child {
    h2.title {
      margin-bottom: 30px;
    }
  }
}

.load_tip__finished {
  font-weight: 400;
  font-size: 14px;
  color: #cccccc;
  // line-height: 14px;
  text-align: center;
  margin-bottom: 30px;
}

.tag-title {
  max-width: 800px;
  text-align: center;
  margin: 0 auto 40px;
  padding: 0 10px;
  .tag-description {
    color: #989595;
  }
}
</style>
