<template>
  <div class="coupon-list">
    <template v-if="list && list.length > 0">
      <CouponItem
        class="coupon-list-item"
        v-for="item in list"
        :key="item.id"
        :info="item"
        :isTask="!item.status"
        @jump="$emit('jump', item.id)"
      />
    </template>
    <Loading v-else-if="loading" />
    <template v-else>
      <div class="empty">
        <img class="e-img" src="../../../images/coupon/img_empty.svg" alt="" />
        <p class="e-text">還沒有票券哦～</p>
      </div>
    </template>
  </div>
</template>

<script>
import CouponItem from './Item.vue'
import Loading from '../common/Loading.vue'
export default {
  props: ['list', 'loading'],
  components: {
    CouponItem,
    Loading
  }
}
</script>

<style lang="scss" scoped>
.coupon-list {
  .empty {
    text-align: center;
    padding-top: 100px;
    .e-img {
      width: 100px;
      height: 100px;
    }
    .e-text {
      font-size: 14px;
      color: #404040;
      line-height: 19px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    ::v-deep .coupon-item {
      width: 452px;
      margin: 20px 15px;
      cursor: pointer;
      &:not(.active) {
        .coupon {
          border: 1px solid #e9e9ea;
          &::before {
            background-color: #ffffff;
            left: -1px;
            border: 1px solid #e9e9ea;
            border-left: 1px solid #ffffff;
          }
          &::after {
            background-color: #ffffff;
            right: -1px;
            border: 1px solid #e9e9ea;
            border-right: 1px solid #ffffff;
          }
        }
      }
    }
    .empty {
      margin: 100px auto;
      .e-img {
        width: 120px;
        height: 120px;
      }
      .e-text {
        font-size: 16px;
        color: #404040;
        line-height: 19px;
      }
    }
  }
}
</style>
