<template>
  <section v-if="!isLoading" id="lecture">
    <div class="content">
      <img class="enroll_icon" src="../../../images/coupon/enroll.png" alt="" @click="handleEnrollIcon" />
      <div class="img"><img :src="current_lecture.cover_image" /></div>
      <h1>
        <span>{{ current_lecture.title }}</span>
      </h1>
      <p class="ended-at">{{ current_lecture.ended_at }} 報名截止</p>
      <div class="article-content" v-html="current_lecture.content_html"></div>
    </div>
    <section class="sessions">
      <div class="session" v-for="(session, i) in current_lecture.lecture_sessions" :key="i">
        <div class="wrap">
          <span class="full has-text-black has-background-lookin" v-if="session.full">已額滿</span>
          <h3>{{ i + 1 }}. {{ session.title }}</h3>
          <p><span>時間：</span>{{ session.session_time }}</p>
          <p><span>地點：</span>{{ session.place }}</p>
        </div>
      </div>
    </section>
    <div class="apply" ref="applyRef">
      <template v-if="current_lecture.participated == true">
        <b-button disabled expanded>已報名</b-button>
      </template>
      <template v-else-if="!isOngoing(current_lecture.ended_time)">
        <b-button disabled expanded>報名已截止</b-button>
      </template>
      <template v-else>
        <b-button v-if="!isApplied && isMobile" expanded @click="showApplyDialog">我要報名</b-button>
        <div v-else class="apply-dialog">
          <div class="apply-header">
            <h3>我要報名</h3>
          </div>
          <div class="apply-body">
            <form @submit.prevent="apply">
              <b-field label="姓 名">
                <b-input v-model="info.name" @focus="setViaAndLogin()" placeholder="請輸入姓名" required> </b-input>
              </b-field>
              <b-field label="電 話">
                <b-input
                  type="phone"
                  v-model="info.phone"
                  @focus="setViaAndLogin()"
                  placeholder="請輸入聯絡電話"
                  pattern="[0-9]*"
                  inputmode="tel"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="住 址">
                <b-input v-model="info.address" placeholder="請輸入住址" required> </b-input>
              </b-field>
              <b-field label="Email">
                <b-input type="email" v-model="info.email" placeholder="請輸入電子郵件" inputmode="email" required>
                </b-input>
              </b-field>
              <b-field v-if="multiSessions" label="選擇場次">
                <b-select v-model="info.session_id" placeholder="請選擇場次">
                  <option
                    v-for="(session, i) in current_lecture.lecture_sessions.filter(session => session.full == false)"
                    :value="session.id"
                    :key="i"
                  >
                    {{ i + 1 }}. {{ session.session_time }} - {{ session.title }}
                  </option>
                </b-select>
              </b-field>
              <b-checkbox v-model="privacy">
                我已經詳細閱讀並同意<a href="/privacy" target="_blank">隱私權政策</a>
              </b-checkbox>
              <p class="notice">貼心小提醒：每個帳號僅能報名乙次，請務必確認填寫資料正確後再送出喔！</p>
              <b-button type="is-dark" rounded @click="apply">送 出</b-button>
            </form>
          </div>
        </div>
      </template>
    </div>
    <div class="event-max-width">
      <Comments :lecture_id="current_lecture.id" :comments="current_lecture.comments" />
    </div>
    <Modal
      :open="modal"
      :title="modalContent.title"
      :content="modalContent.content"
      :btnWord="modalContent.btnWord"
      @close="closeModal"
    />
    <CouponModal :visible.sync="couponShow" class="coupon-modal">
      <template #content>
        <h4 style="font-size: 20px; font-weight: 500; color: #333333; margin-bottom: 10px">報名成功</h4>
        <p>獎勵已放入【我的票券】</p>
        <Enroll
          v-for="item in couponList"
          class="coupon-madal-enroll"
          :key="item.id"
          :title="item.title"
          :time="formatTime(item.endTime)"
          :img="item.imgUrl"
          :status="item.status"
        />
        <!-- <Enroll title="LOOKin x SKll一日店長折價券" time="2023.08.13 23:59" /> -->
        <b-button
          type="is-primary"
          style="color: #fff; width: 226px; margin-top: 17px; background: #f08f7a"
          rounded
          @click="$router.push('/my/coupon')"
        >
          立即查看
        </b-button>
      </template>
    </CouponModal>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { isOngoing } from 'src/share'
import Comments from 'src/components/common/Comments'
import Modal from '../../components/dialog/Modal'
import CouponModal from '../../components/dialog'
import Enroll from '../../components/coupon/Enroll.vue'
import { couponReceive } from '../../../api/storeManager'
import { SET_LECTURE_PARTICIPATION } from '../../../store/mutation-types'
import dateFormat from '../../../tools/dateformat'

export default {
  data() {
    return {
      isLoading: true,
      isApplied: false,
      info: {
        name: null,
        address: null,
        phone: null,
        email: null,
        session_id: null
      },
      privacy: false,
      modal: false,
      modalContent: {
        title: null,
        content: null,
        btnWord: null
      },
      loginRequired: false,
      loginContent: {
        title: '請先登入',
        content: '登入會員再體驗喔！',
        btnWord: '好的'
      },
      inCompleted: {
        title: '未完成',
        content: '請確認填寫完畢，並勾選「同意隱私權政策」。',
        btnWord: '好的'
      },
      successApply: {
        title: '成功報名！',
        content: '請等候通知',
        btnWord: '好的'
      },
      errorContent: {
        title: '很抱歉！',
        content: '出錯了！',
        btnWord: '確認'
      },
      couponShow: false, // 报名成功弹框
      couponList: [] // 票券列表
    }
  },
  components: { Comments, Modal, CouponModal, Enroll },
  watch: {
    user: function () {
      this.initUserInfo()
    }
  },
  computed: {
    ...mapState(['user', 'isMobile', 'current_lecture']),
    multiSessions: function () {
      return this.current_lecture ? this.current_lecture.lecture_sessions.length > 1 : false
    },
    formatTime: () => time => dateFormat(time, 'yyyy.mm.dd HH:MM')
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setVia']),
    isOngoing,
    full_address: function () {
      return this.user.address ? `${this.user.zip_code}${this.user.city}${this.user.dist}${this.user.address}` : null
    },
    initUserInfo: function () {
      if (this.user != null && this.info.name == null) {
        this.info.name = this.user.realname
        this.info.address = this.user.full_address
        this.info.phone = this.user.phone
        this.info.email = this.user.email
      }
    },
    setViaAndLogin: function () {
      if (!this.user) {
        this.setVia({
          type: 'Lecture',
          id: this.current_lecture.id,
          path: 'apply'
        })
        this.loginRequired = true
        this.modalContent = this.loginContent
        this.modal = true
      }
    },
    showApplyDialog: function () {
      if (this.user) {
        this.name = this.user.realname
        this.address = this.full_address
        this.phone = this.user.phone
        this.email = this.user.email
        if (!this.multiSessions) {
          this.info.session_id = this.current_lecture.lecture_sessions[0].id
        }
        this.isApplied = true
      } else {
        this.setViaAndLogin()
      }
    },
    validForm: function () {
      if (!this.multiSessions) {
        this.info.session_id = this.current_lecture.lecture_sessions[0].id
      }
      return (
        this.privacy &&
        this.info.name &&
        this.info.address &&
        this.info.phone &&
        this.info.email &&
        this.info.session_id
      )
    },
    apply() {
      if (this.validForm()) {
        let params = Object.assign({ id: this.current_lecture.id }, this.info)
        this.$store
          .dispatch('applyLectureSession', params)
          .then(async () => {
            // if (this.current_lecture.id == 74) {
            //   // 一日店长活动
            //   const { code, message, result } = await couponReceive(this.current_lecture.id)
            //   if (code === 200) {
            //     this.couponList = result
            //     this.couponShow = true
            //   } else {
            //     // console.error(message)
            //     this.$buefy.toast.open({
            //       type: 'is-danger',
            //       message
            //     })
            //   }
            // } else {
            //   this.$store.commit(SET_LECTURE_PARTICIPATION)
            //   this.isApplied = false
            //   this.modalContent = this.successApply
            //   this.modal = true
            // }
            // 一日店长活动
            const { code, result } = await couponReceive(this.current_lecture.id)
            if (code === 200) {
              // 有票券，弹框显示票券
              this.couponList = result
              this.couponShow = true
            } else {
              // 没有票券表示是其他活动
              this.$store.commit(SET_LECTURE_PARTICIPATION)
              this.isApplied = false
              this.modalContent = this.successApply
              this.modal = true
            }
          })
          .catch(error => {
            this.modalContent = this.errorContent
            if (error.data.user) {
              this.modalContent.content = error.data.user[0]
            } else if (error.data.lecture_session) {
              this.modalContent.content = error.data.lecture_session[0]
            }
            this.modal = true
          })
      } else {
        this.modalContent = this.inCompleted
        this.modal = true
      }
    },
    closeModal() {
      this.modal = false
      if (this.loginRequired == true) {
        this.loginRequired = false
        this.setLoginDrawer(true)
      }
    },
    handleEnrollIcon() {
      // 滚动到报名处
      const enrollTop = this.$refs.applyRef.offsetTop
      window.scrollTo({
        top: enrollTop,
        behavior: 'smooth'
      })
    }
  },
  created() {
    this.$store.dispatch('loadLecture').then(() => {
      this.isLoading = false
      this.initUserInfo()
    })
  }
}
</script>
<style lang="scss">
#lecture {
  width: 100%;
  .coupon-modal {
    .new_box {
      padding: 20px 30px 25px;
      .coupon-madal-enroll {
        width: 340px;
      }
    }
  }
  .event-max-width {
    max-width: 650px;
    margin: 0 auto;
  }
  .content {
    width: 100%;
    .enroll_icon {
      width: 56px;
      height: 56px;
      position: fixed;
      bottom: 140px;
      right: 34px;
      cursor: pointer;
      z-index: 99;
    }
    .img {
      width: 100%;
      background-color: #fff0ee;
      img {
        width: 100%;
        max-width: 950px;
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }
    h1 {
      width: 95%;
      max-width: 1200px;
      margin: 12px auto 14px;
      text-align: center;
      @media screen and (min-width: 1024px) {
        font-size: 28px;
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 31px;
        padding: 1px 6px;
        background-color: #ffdbcf;
        color: #000000;
      }
    }
    .ended-at {
      text-align: center;
      display: block;
      color: #f08f7a;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 15px;
    }
  }
  section.sessions {
    width: 100%;
    padding: 2rem 0;
    overflow: hidden;
    margin: 0 auto;
    box-sizing: initial;
    .session {
      width: 92%;
      max-width: 650px;
      padding: 40px 0;
      margin: auto;
      word-break: break-word;
      border-top: 1px solid #ffdbcf;
      h3 {
        margin: 7px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: 1px;
      }
      .full {
        padding: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 1px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 1px;
        color: #989595;
        span {
          font-weight: 700;
        }
      }
    }
  }
  .apply {
    margin: 0 auto 60px;
    width: 80vw;
    max-width: 650px;
    > button {
      height: 53px;
      background: #ffdbcf;
      border-color: #ffdbcf;
      filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.6));
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
  .apply-dialog {
    .apply-header {
      background: #ffdbcf;
      filter: drop-shadow(0px 3px 2px rgba(240, 143, 122, 0.6));
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 53px;
        text-align: center;
        vertical-align: middle;
      }
    }
    .apply-body {
      border: 1px solid #ffc2ae;
      box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 25px 8px;
        .field {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          width: 100%;
          // .label {}
          .control {
            min-width: 76%;
            max-width: 76%;
            .select {
              width: 100%;
            }
            .input,
            .select select {
              width: 100%;
              padding: 3px;
              line-height: 24px;
              border-width: 0 0 1px 0;
              border-bottom: 1px solid #ffc2ae;
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
              border-radius: 0;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              letter-spacing: 1px;
            }
            .select select:active,
            .select select:focus {
              -webkit-box-shadow: none;
              -moz-box-shadow: none;
              box-shadow: none;
            }
          }
          p.help {
            width: 100%;
            text-align: center;
          }
        }
        .checkbox {
          margin-top: 15px;
          .control-label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-align: justify;
          }
        }
        .notice {
          padding: 0 5%;
          margin: 25px 0 20px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 1px;
        }
        button {
          width: 50%;
          height: 34px;
        }
      }
    }
  }
}

// 移动端样式
@media screen and (max-width: 768px) {
  #lecture {
    .coupon-modal {
      .new_box {
        padding: 20px 20px 25px;
        .coupon-madal-enroll {
          width: 269px;
        }
      }
    }
  }
}
</style>
