<template>
  <section id='activities'>
    <h1>活動紀錄</h1>
    <p class="user-info" v-if="user">親愛的 <span class="has-text-lookin-darker">{{ user.nickname }}</span> 妳好！</p>
    <div class="condition">
      <b-field label="查詢" class="months">
        <b-select id='interval-select'
          v-model="interval"
          @change.native="updateActivities"
          rounded
        >
          <option
              v-for="option in months"
              :value="option.interval"
              :key="option.interval">
              {{ option.text }}
          </option>
        </b-select>
      </b-field>
      <p>*僅提供近一年活動紀錄查詢</p>
    </div>
    <div class="event-records">
      <ul v-if="!isLoading">
        <li v-for="(event, i) in event_records" :key="event.id">
          <p class="time">{{ event.date }}</p>
          <p class="event-title"><span class="has-text-black">參加{{ event.type }}：</span><span class="has-text-lookin-darker">{{ event.title }}</span></p>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      months: [
        { interval: 1,
          text: '近一個月'
        },
        { interval: 3,
          text: '近三個月'
        },
        { interval: 6,
          text: '近半年'
        },
        { interval: 12,
          text: '近一年'
        }
      ],
      interval: 1
    }
  },
  computed: {
    ...mapState(['user', 'event_records']),
  },
  methods: {
    updateActivities: function() {
      this.isLoading = true
      this.$store.dispatch('loadEventRecords', { interval: this.interval }).then(() => {
        this.isLoading = false
      })
    }
  },
  created() {
    this.$store.dispatch('loadEventRecords', { interval: this.interval }).then(() => {
      this.isLoading = false
    })
  },
}
</script>
<style lang="scss">
#activities {
  h1 {
    margin: 20px 15px 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
  }
  .user-info {
    margin: 10px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
  }
  .condition {
    margin: 24px auto 0;
    label.label {
      display: inline-block;
      vertical-align: top;
      line-height: 2em;
      margin: 4px 12px 0 20px;
    }
    .control {
      display: inline-block;
    }
    #interval-select {
      border: 1px solid #FFC2AE;
      box-sizing: border-box;
      filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.6));
    }
    p {
      margin: 20px;
      font-size: 13px;
      line-height: 15px;
      color: #404040;
    }
  }
  .event-records {
    margin-bottom: 100px;

    li {
      padding: 0 20px;
      border-bottom: 1px solid #FFDBCF;
      .time {
        margin-top: 11px;
        color: #989595;
        font-family: Ubuntu Condensed;
        padding: 2px 0 10px;
        font-size: 13px;
        line-height: 10px;
        letter-spacing: 1px;
      }
      .event-title {
        margin-bottom: 11px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        text-align: justify;
        letter-spacing: 1px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #activities {
    max-width: 1000px;
    width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #FFC2AE;
    }
  }
}
</style>
