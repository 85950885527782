<template>
  <div>
    <h2 class="title eng-font">
      <span class="underline">{{ title }}</span>
    </h2>
    <div class="tags">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped></style>
