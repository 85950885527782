import axios from 'axios'

const host = window.location.host || ''
let baseURL = 'https://apis.look-in.com.tw' // 默認正式服域名

if (host.includes('staging')) {
  // baseURL = 'http://172.24.213.224:8904'
  baseURL = 'https://staging-mc.look-in.com.tw'
} else if (host.includes('localhost')) {
  baseURL = 'https://staging-mc.look-in.com.tw'
  // baseURL = 'http://172.24.213.224:8904'
} else {
  // 正式服地址  暂未提供
  baseURL = '#'
}

function getToken() {
  return '_8fe1s2ff-ae33-4eb5-956b-61cf05ad4f64_d6a21s60-f098-41b9-b0c7-cb0eew6c0e37_'
}

const http = axios.create({
  baseURL,
  timeout: 50000,
  withCredentials: true,
  headers: {
    Authorization: getToken(),
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use(config => {
  console.log('headers信息:', config.headers)
  return config
})

http.interceptors.response.use(null, error => {
  switch (error.response.status) {
    case 0:
      console.log('错误:', error)
      break
  }
  return Promise.reject(error)
})

/**
 * userId
 * deviceId
 * fireBaseToken
 * platform IOS:0  android:1
 * @param {*} data
 * @returns
 */
export function recordFcmToken(data) {
  // return http.post('/message-center/spp/lookin/collect-fireBase-token', data)
  return http({
    url: '/apimessage-center/spp/lookin/collect-fireBase-token',
    method: 'POST',
    data
  })
}

// 解绑fcm token
export function unbindFcmToken(userId) {
  // return http.delete(`/message-center/spp/lookin/fcmToken/${userId}`)
  return http.delete(`/apimessage-center/spp/lookin/fcmToken/${userId}`)
}
