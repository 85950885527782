<template>
  <section>
    <b-sidebar :type="'is-nav-bg'" :fullheight="true" :fullwidth="true" :can-cancel="false" v-model="open">
      <div id="signup-drawer" class="has-background-nav-bg drawer">
        <div id="signup-inner-wrapper">
          <img :src="require('@/../images/icon-close.svg')" alt="Close" class="tail-icon close" @click="close()" />
          <h2 class="title eng-font"><span class="underline">New Account</span></h2>
          <div style="text-align: center">
            <b-button rounded class="facebook-btn" @click="facebookRegister">
              <img :src="require('@/../images/member_fb.svg')" alt="Facebook" />
              <p>使用Facebook註冊</p>
            </b-button>
            <b-button rounded class="google-btn" @click="googleRegister">
              <img :src="require('@/../images/member_google.svg')" alt="Google" />
              <p>使用Google註冊</p>
            </b-button>
          </div>
          <h3 class="sub-title"><span class="has-background-nav-bg">LOOKin 帳號註冊</span></h3>
          <form class="signup" @submit.prevent="signup">
            <b-field label="暱稱*">
              <b-input
                rounded
                v-model="newUser.nickname"
                icon-right="user"
                placeholder="請輸入暱稱，之後仍可以修改"
                ref="nickname"
                required
              >
              </b-input>
            </b-field>
            <b-field label="Email*">
              <b-input
                rounded
                type="email"
                icon-right="envelope"
                v-model="newUser.email"
                placeholder="example@mail.com"
                inputmode="email"
                required
              >
              </b-input>
            </b-field>
            <b-field label="密碼*">
              <b-input
                rounded
                type="password"
                v-model="newUser.password"
                placeholder="長度 6 字元以上，需區分大小寫"
                password-reveal
                required
              >
              </b-input>
            </b-field>
            <b-field label="確認密碼*">
              <b-input
                rounded
                type="password"
                v-model="newUser.password_confirmation"
                placeholder="請再次輸入密碼"
                password-reveal
                required
              >
              </b-input>
            </b-field>
            <b-field label="性別*">
              <b-radio-button v-model="newUser.gender" :native-value="2" type="is-lookin-darker">
                <span>女性</span>
              </b-radio-button>
              <b-radio-button v-model="newUser.gender" :native-value="1" type="is-lookin-darker">
                <span>男性</span>
              </b-radio-button>
              <b-radio-button v-model="newUser.gender" :native-value="0" type="is-lookin-darker">
                <span>不透露</span>
              </b-radio-button>
            </b-field>
            <b-field>
              <b-checkbox class="edm" v-model="newUser.edm" required> 是否願意收到電子報* </b-checkbox>
            </b-field>
            <p class="note">
              註冊即表示已詳細閱讀並同意<router-link :to="{ name: 'terms' }" class="" target="_blank"
                >會員條款</router-link
              >
            </p>
            <button class="button btn-clear" @click.prevent="clearModal = true">清除重填</button>
            <button class="button btn-submit">送出</button>
          </form>
        </div>
      </div>
      <Modal :open="errorModal" :title="'Oh No!'" :content="errorMsg" :btnWord="'確認'" @close="errorModal = false" />
    </b-sidebar>
    <Modal
      :open="clearModal"
      :title="'確定清除嗎？'"
      :btnWord="'取消'"
      :confirm="'清除'"
      @close="clearModal = false"
      @submit="cleanForm"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Modal from '../dialog/Modal'
import { isLookinApp, openGoogleLogin, openFacebookLogin, compareVersion } from '../../../tools/bridge.js'
import { registerApi } from '../../../api/user'

export default {
  props: ['open'],
  data() {
    return {
      clearModal: false,
      errorModal: false,
      errorMsg: null,
      newUser: {
        nickname: null,
        email: null,
        password: null,
        password_confirmation: null,
        gender: 2, // 0 不透露 1 男 2 女
        edm: true
      },
      emptyUser: {
        nickname: null,
        email: null,
        password: null,
        password_confirmation: null,
        gender: 2,
        edm: true
      },
      originURL: null,
      baseUrl: '',
      nowAppVersion: '2.2.2'
    }
  },
  components: { Modal },
  watch: {
    open: function (val) {
      if (val == true) {
        this.updateURL()
      }
    },
    via: function (val) {
      this.updateURL()
    }
  },
  computed: {
    ...mapState(['baseURL', 'via', 'appVersion'])
  },
  created() {
    // 第三方基地址
    const host = window.location.host
    if (host.includes('localhost')) {
      this.baseUrl = 'https://ynstaging.look-in.com.tw/apis'
    } else if (host.includes('staging')) {
      this.baseUrl = 'https://ynstaging.look-in.com.tw/apis'
    } else {
      this.baseUrl = 'https://apis.look-in.com.tw'
    }
  },
  methods: {
    ...mapActions(['setSignUpDrawer']),
    cleanForm: function () {
      this.clearModal = false
      Object.assign(this.newUser, this.emptyUser)
      this.$nextTick(() => {
        this.$refs.nickname.focus()
      })
    },
    close: function () {
      this.newUser = this.emptyUser
      this.setSignUpDrawer(false)
      // 使用完后删除
      window.sessionStorage.removeItem('from-game')
      window.sessionStorage.removeItem('game-login')
    },
    async signup() {
      if (this.via) {
        this.newUser.via_type = this.via.type
        this.newUser.via_id = this.via.id
        this.newUser.via_path = this.via.path
      }

      try {
        const { code, message } = await registerApi(this.newUser)
        if (code === 200) {
          this.$emit('successSignUp')
          this.close()
          if (window.sessionStorage.getItem('from-game') == 'true') {
            // 使用完后删除
            window.sessionStorage.removeItem('from-game')
            window.sessionStorage.removeItem('game-login')
            // 添加游戏统计事件
            try {
              new TrackEvent().setEvent('水逆游戏点击注册', '点击', '水逆游戏点击', '', '')
            } catch (error) {
              console.log(error)
            }
          }
        } else if (code === 606) {
          this.errorMsg = '密碼與確認密碼不相符'
          this.errorModal = true
        } else {
          this.errorMsg = message
          this.errorModal = true
        }
      } catch (error) {
        console.error(error)
      }
      // this.$store
      //   .dispatch('signUp', this.newUser)
      //   .then(response => {
      //     this.$emit('successSignUp')
      //     this.close()
      //     if (window.sessionStorage.getItem('from-game') == 'true') {
      //       // 使用完后删除
      //       window.sessionStorage.removeItem('from-game')
      //       window.sessionStorage.removeItem('game-login')
      //       // 添加游戏统计事件
      //       try {
      //         new TrackEvent().setEvent('水逆游戏点击注册', '点击', '水逆游戏点击', '', '')
      //       } catch (error) {
      //         console.log(error)
      //       }
      //     }
      //   })
      //   .catch(error => {
      //     // console.log(error.data)
      //     // let errorArray = Object.keys(error.data).map(key => `${key}：${error.data[key][0]}`)
      //     // this.errorMsg = errorArray[0]
      //     const { email, error: err } = error.data
      //     // email有两条数据就只显示第二条，反之只显示第一条
      //     if (email) {
      //       if (email.length === 2) {
      //         this.errorMsg = email[1]
      //       } else {
      //         this.errorMsg = email[0]
      //       }
      //     }
      //     if (err) {
      //       this.errorMsg = '密碼與確認密碼不相符'
      //     }
      //     this.errorModal = true
      //   })
    },
    updateURL: function () {
      let url = new URL(window.location.href)
      if (this.via) {
        url.searchParams.append('via_type', this.via.type)
        url.searchParams.append('via_id', this.via.id)
        url.searchParams.append('via_path', this.via.path)
      }
      this.originURL = encodeURIComponent(url)
    },
    facebookRegister() {
      const viewUrl = window.location.href.includes('?') ? `${location.href}&` : `${location.href}?`
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          openFacebookLogin()
        } else {
          window.location.replace(`${this.baseUrl}/shop/thirdparty/thirdpartyRedirect/6/_login?viewUrl=${viewUrl}`)
        }
      } else {
        window.location.replace(`${this.baseUrl}/shop/thirdparty/thirdpartyRedirect/6/_login?viewUrl=${viewUrl}`)
      }

      try {
        new TrackEvent().setEvent('facebook_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    },
    googleRegister() {
      const viewUrl = window.location.href.includes('?') ? `${location.href}&` : `${location.href}?`
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          openGoogleLogin()
        } else {
          window.location.href = `${this.baseUrl}/shop/thirdparty/thirdpartyRedirect/5/_login?viewUrl=${viewUrl}`
        }
      } else {
        window.location.href = `${this.baseUrl}/shop/thirdparty/thirdpartyRedirect/5/_login?viewUrl=${viewUrl}`
      }

      try {
        new TrackEvent().setEvent('google_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss">
#signup-drawer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    margin: 60px 0 25px;
  }
  #signup-inner-wrapper {
    margin: 0 auto;
    width: 281px;
    max-width: 281px;
    h3.sub-title {
      width: 100%;
      text-align: center;
      border-bottom: 2px solid #404040;
      line-height: 0.1em;
      margin: 60px 0 30px;
      span {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0 10px;
      }
    }

    form.signup {
      margin-bottom: 100px;
      .field {
        margin-top: 15px;
        color: #404040;
        line-height: 24px;
        width: 100%;
        label {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 0;
          margin-left: 11px;
        }
        input {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 1px;
          border: none;
        }
        input:focus + span.has-text-primary {
          color: #f08f7a !important;
        }
        label.b-radio.button {
          height: 38px;
          border-radius: 50px;
          border: none;
          min-width: 57px;
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          padding: 10px;
        }
        label.b-radio.button.is-lookin-darker {
          box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
        }
        .boolean .b-radio.radio.button {
          width: 125px;
        }
        .edm {
          font-weight: 600;
          color: #373737;
        }
      }
      .field.has-addons {
        margin-top: 0;
        // justify-content: space-between;
        .control {
          margin-right: 10px;
        }
      }
      p.note {
        color: #404040;
        font-size: 15px;
        line-height: 24px;
        a {
          font-weight: 600;
          color: #000000;
        }
      }
    }

    // button.button {
    //   width: 135px;
    //   height: 41px;
    //   font-size: 16px;
    //   font-weight: 700;
    //   line-height: 24px;
    //   border-radius: 50px;
    //   margin-top: 20px;
    // }
    button.btn-clear {
      width: 135px;
      height: 41px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      border-radius: 50px;
      margin-top: 20px;
      border: 2px solid #000000;
      background-color: transparent;
      box-sizing: border-box;
      color: black;
    }
    button.btn-submit {
      width: 135px;
      height: 41px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      border-radius: 50px;
      margin-top: 20px;
      background-color: #000;
      color: #ffffff;
      float: right;
    }
  }
}

@media screen and (min-width: 1200px) {
  #signup-drawer {
    #signup-inner-wrapper {
      width: 400px;
      max-width: 400px;
      form.signup {
        .field {
          label.b-radio.button {
            min-width: 90px;
          }
          .boolean .b-radio.radio.button {
            width: 180px;
          }
        }
      }
      // button.button {
      //   width: 180px;
      // }
    }
  }
}
</style>
