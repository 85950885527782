<template>
  <div class="answer">
    <h3 class="a-title">答對問題即可兌換抽獎券</h3>
    <p class="a-topic">{{ task.content }}</p>
    <div class="c-radio">
      <div
        ref="optionRef"
        class="advice"
        :class="{ correct: isTask && item.trueOption }"
        v-for="item in task.answerVos"
        :key="item.id"
        @click="handleOption($event, item.trueOption)"
      >
        <div>{{ item.option }}</div>
        <div class="correct-tip">
          <span>答對啦</span>
          <span class="icon"></span>
        </div>
        <div class="incorrect-tip">
          <span>答錯啦</span>
          <span class="icon"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // isTask 任务是否完成(0：未完成，1：已完成)
  props: ['task', 'isTask'],
  data() {
    return {}
  },
  methods: {
    handleOption(e, isCorrect) {
      if (this.isTask) return

      if (isCorrect) {
        // 回答正确
        this.clearAnswer()
        e.target.classList.add('correct')
        this.$emit('change', true)
      } else {
        // 回答错误
        this.clearAnswer()
        e.target.classList.add('incorrect')
        this.$emit('change', false)
      }
    },
    clearAnswer() {
      this.$refs.optionRef.forEach(el => {
        el.classList.remove('correct', 'incorrect')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.answer {
  .a-title {
    font-size: 15px;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
    margin: 15px 0;
  }
  .a-topic {
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    padding: 0 15px;
  }
  .c-radio {
    .advice {
      margin: 20px 0;
      width: 100%;
      height: 40px;
      background: #f4f6f8;
      border-radius: 48px;
      font-size: 15px;
      color: #333333;
      line-height: 40px;
      padding: 0 18px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .correct-tip,
      .incorrect-tip {
        display: none;
      }
      &.correct {
        background: rgba(35, 193, 108, 0.1);
        border: 1px solid #23c16c;
        color: #23c16c;
        .correct-tip {
          display: block;
          .icon {
            margin-left: 5px;
            &::before {
              content: '\2713';
              color: #23c16c;
            }
          }
        }
      }
      &.incorrect {
        background: rgba(253, 136, 116, 0.1);
        border: 1px solid #f08f7a;
        color: #f08f7a;
        .incorrect-tip {
          display: block;
          .icon {
            margin-left: 5px;
            &::before {
              content: '\2716';
              color: #f08f7a;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .answer {
    .a-topic {
      width: 360px;
      margin-left: 124px;
    }
    .c-radio {
      width: 360px;
      margin-left: 124px;
    }
  }
}
</style>
