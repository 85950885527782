import http from './request.js'

// 获取token, 沒有token值為-1
function getToken() {
  return window.localStorage.getItem('auth-token') || -1
}

// 获取赠品列表
export function getPrize() {
  return http.get('/shop/prize/prizeInfo')
}

// 奖品详情接口
export function getPrizeDetail(id) {
  return http.get(`/shop/prize/detail/${getToken()}/${id}`)
}

// 獲取推薦列表
export function getRecommendList(id) {
  return http.get(`/shop/prize/recommend/${getToken()}/${id}`)
}

// 最新热点兑换商品
export function newPrizes() {
  return http.get('/shop/prize/newPrizes')
}

// 綁定郵箱
export function bindingEmail(data) {
  return http({
    method: 'post',
    url: `/shop/user/setContactEmail/${getToken()}`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 修改用户联系邮箱
export function modifyContactEmail(data) {
  return http({
    method: 'post',
    url: `/shop/user/modifyContactEmail/${getToken()}`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

// 判断用户是否有联系邮箱
export function decideContactEmail() {
  return http.get(`/shop/user/decideContactEmail/${getToken()}`)
}

// 获取用户联系邮箱
export function getContactEmail() {
  return http.get(`/shop/user/getContactEmail/${getToken()}`)
}

// 获取赠品信息
export function getPrizeInfo(type) {
  // type 1 阅读得点；2 每日得点
  return http.get(`/shop/recommendrecord/info/${type}`)
}

// 獲取上架提醒訂閱狀態
export function getSubscribeStatusApi() {
  return http.get('/shop/prize/assertSubscribe')
}

// 上架提醒 订阅
export function subscribeApi(data) {
  return http.post('/shop/prize/subscribe', data)
}
