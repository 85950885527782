<template>
  <li class="item" @click="handleItem">
    <div class="item-info">
      <img class="info-img" :src="item.imgUrl || ''" alt="" />
      <div class="info-right">
        <h4 class="r-title">{{ item.title }}</h4>
        <p class="r-point">
          <template v-if="this.type === 'record'">
            <img src="../../../images/prizes/icon_points.svg" alt="" />
            <span>{{ item.point }}</span>
          </template>
          <span v-else>已兌{{ item.amount }}張</span>
        </p>
        <p class="r-time">{{ time }}</p>
      </div>
      <div class="info-btn" v-if="type === 'result'">{{ btnText }}</div>
    </div>
    <p class="item-tip" :class="{ active: item.winStatus !== -1 }" v-if="item.winStatus !== null">
      {{ status[item.winStatus] }}
    </p>
  </li>
</template>

<script>
import dateFormat from '../../../tools/dateformat'

export default {
  name: 'ExchangeItem',
  /**
   * type
   * record:兌換記錄; result:開獎結果
   */
  props: ['item', 'type'],
  data() {
    return {
      // winStatus 开奖狀態 （-1：未中奖，0:待开奖，1:中奖）
      // receiveStatus 领取狀態 (0：未领取，1：领取）
      status: {
        '-1': '未中奖',
        0: '待开奖',
        1: '已中獎'
      }
    }
  },
  computed: {
    point() {
      return this.type === 'record' ? `-${this.item.point}熱點` : `已兌${this.item.amount}張`
    },
    time() {
      if (this.type === 'record') {
        return this.item.createdAt
      } else {
        return `${dateFormat(this.item.publishAt, 'yyyy-mm-dd')} ${this.item.winStatus == 1 ? '已開獎' : '開獎'}`
      }
    },
    btnText() {
      // 已中奖，未領取：按钮状态 【填寫地址】
      if (this.item.winStatus === 1 && this.item.receiveStatus === 0) {
        return '填寫地址'
      } else {
        return '查看詳情'
      }
    }
  },
  methods: {
    handleItem() {
      this.$emit('jump', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  border-radius: 6px;
  padding: 10px;
  background-color: #fff;
  .item-info {
    display: flex;
    align-items: center;
    .info-img {
      width: 96px;
      height: 96px;
      background: #d9d9d9;
      border-radius: 5px;
    }
    .info-right {
      flex: 1;
      margin-left: 15px;
      .r-title {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        cursor: pointer;
      }
      .r-point {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 8px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
        span {
          font-size: 16px;
          font-weight: 500;
          color: #f08f7a;
          line-height: 16px;
        }
      }
      .r-time {
        font-size: 12px;
        color: #7f7f7f;
        line-height: 14px;
      }
    }
    .info-btn {
      display: none;
      width: 96px;
      height: 32px;
      border-radius: 16px;
      border: 1px solid #f08f7a;
      font-size: 14px;
      color: #f08f7a;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      margin-right: 30px;
    }
  }
  .item-tip {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 24px;
    background: #999999;
    border-radius: 6px 0px 6px 0px;
    font-size: 12px;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    &.active {
      background: #f08f7a;
    }
  }
}

// web
@media screen and (min-width: 1200px) {
  .item {
    .item-info {
      .info-btn {
        display: block;
      }
    }
  }
}
</style>
