var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"home-slide"}},[(_vm.isLoading)?_c('div',[_c('img',{staticClass:"default-image",attrs:{"src":require('@/../images/default.jpg')}})]):_c('div',{class:_vm.desktopSlickClass},[_c('slick',{ref:"homeSliderSlick",attrs:{"options":_vm.slickOptions},on:{"afterChange":_vm.handleAfterChange}},_vm._l((_vm.home_slides),function(slide,i){return _c('div',{key:i,ref:"slickRef",refInFor:true,staticClass:"content has-text-centered slide-content",attrs:{"data-show":slide.ga_show,"data-click":slide.ga_click,"data-title":slide.title},on:{"click":function($event){return _vm.clickSlide(slide)}}},[(slide.type == 'article')?_c('router-link',{attrs:{"to":{
            name: 'article',
            params: {
              channel: slide.parent_path,
              sub_channel: slide.child_path,
              id: slide.id,
              title: slide.title
            }
          }},nativeOn:{"click":function($event){return _vm.jumpArticle(slide.id, i)}}},[_c('div',{staticClass:"slide-bg"},[_c('img',{staticClass:"slide-image",attrs:{"width":"100vw","height":"62.5vw","srcset":((slide.image_s_url) + " 800w, " + (slide.image_url) + " 1024w"),"src":slide.image_s_url}})])]):_c('a',{attrs:{"href":slide.link,"target":"_blank"}},[_c('div',{staticClass:"slide-bg"},[_c('img',{staticClass:"slide-image",attrs:{"width":"100vw","height":"62.5vw","srcset":((slide.image_s_url) + " 800w, " + (slide.image_url) + " 1024w"),"src":slide.image_s_url}})])]),_vm._v(" "),_c('div',{staticClass:"slide-text"},[(slide.tags)?_c('p',_vm._l((slide.tags.slice(0, 3)),function(tag){return _c('router-link',{key:tag,staticClass:"tag",attrs:{"to":{
                name: 'tag',
                params: { slug: tag.toLowerCase(), title: tag }
              }}},[_vm._v("#"+_vm._s(tag))])}),1):_vm._e(),_vm._v(" "),(slide.type == 'article')?_c('router-link',{attrs:{"to":{
              name: 'article',
              params: {
                channel: slide.parent_path,
                sub_channel: slide.child_path,
                id: slide.id,
                title: slide.title
              }
            }},nativeOn:{"click":function($event){return _vm.jumpArticle(slide.id, i)}}},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(slide.title))])])]):_c('a',{attrs:{"href":slide.link,"target":"_blank"}},[_c('h3',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(slide.title))])])])],1)],1)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }