<template>
  <section id="points">
    <h1>積點紀錄</h1>
    <!-- <p class="user-info" v-if="user">親愛的 <span class="has-text-lookin-darker">{{ user.nickname }}</span> 妳好！現有點數 <span class="has-text-lookin-darker">{{ user.points.toLocaleString("en-US") }}</span></p> -->
    <div class="overview">
      <div class="total">
        <img src="../../../images/point/icon_points.svg" alt="" />
        <span class="num">{{ user.points.toLocaleString('en-US') }}</span>
      </div>
      <div class="expire" v-if="expire_points.expiringSoonPoint">
        您有 <span class="has-text-lookin-darker">{{ expire_points.expiringSoonPoint || 0 }}</span
        >點數將於{{ expire_points.expired_at }}到期
        <div class="btn-use" @click="$router.push('/prizes')">去使用</div>
      </div>
    </div>
    <!-- <div class="condition">
      <b-field label="查詢" class="months">
        <b-select id="interval-select" v-model="queryParams.interval" @change.native="updatePointRecords" rounded>
          <option v-for="option in months" :value="option.interval" :key="option.interval">
            {{ option.text }}
          </option>
        </b-select>
      </b-field>
      <p>
        *僅提供近一年積點紀錄查詢 (<router-link :to="{ name: 'point-rules' }" class="has-text-black"
          >LOOKin 點數說明</router-link
        >)
      </p>
    </div> -->
    <div class="point-records">
      <ul v-if="!isLoading">
        <li v-for="(point, index) in pointList" :key="index">
          <div class="time-title-block">
            <span class="point-title has-text-black">{{ point.type }}</span>
            <span class="time">{{ point.date }}</span>
          </div>
          <span class="amount">
            <div class="has-text-lookin-darker">{{ showAmount(point.point) }}</div>
            <div class="exp">{{ showExpired(point.expired_at) }}</div>
          </span>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import api from '../../../api'
export default {
  data() {
    return {
      isLoading: true,
      pointList: [],
      queryParams: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      months: [
        { interval: 1, text: '近一個月' },
        { interval: 3, text: '近三個月' },
        { interval: 6, text: '近半年' },
        { interval: 12, text: '近一年' }
      ],
      interval: 1
    }
  },
  computed: {
    ...mapState(['user', 'point_records', 'token', 'expire_points']),
    showAmount() {
      return amount => (amount >= 0 ? `+${amount}` : amount)
    },
    showExpired() {
      return expired_at => (expired_at != null ? `${expired_at}到期` : '')
    }
  },
  methods: {
    load() {
      api.getPointList(this.queryParams, this.token).then(({ data }) => {
        if (data.code == 200) {
          this.isLoading = false
          this.pointList = data.result.list || []
          this.total = data.result.total || 0
        }
        // console.log('data:', data)
      })
    },
    loadExpirePoints() {
      this.$store.dispatch('loadExpirePoints')
      // .then(() => {
      //   console.log('expire_points:', this.expire_points)
      // })
    },
    updatePointRecords() {
      this.isLoading = true
      this.load()
    },
    showTitle(point) {
      let title = point.title
      if (point.source) {
        title += ` - ${point.source}`
      }
      if (point.reason) {
        title += ` - ${point.reason}`
      }
      return title
    },
    throttle(fun, delay) {
      let last, deferTimer
      return function () {
        let that = this
        let _args = arguments
        let now = +new Date()
        if (last && now < last + delay) {
          clearTimeout(deferTimer)
          deferTimer = setTimeout(function () {
            last = now
            fun.apply(that, _args)
          }, delay)
        } else {
          last = now
          fun.apply(that, _args)
        }
      }
    },

    // 触底加载更多
    onScrollLoad() {
      if (this.total > 0 && this.pointList.length >= this.total) return
      // 获取滚动位置
      const scrollY = window.scrollY
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight

      // 判断是否触底，并且不处于加载中
      if (scrollY + windowHeight >= documentHeight && !this.isLoading) {
        // 触底操作
        this.isLoading = true
        this.queryParams.pageNo++
        api
          .getPointList(this.queryParams, this.token)
          .then(({ data }) => {
            if (data.code == 200) {
              this.isLoading = false
              this.pointList = this.pointList.concat(data.result.list || [])
              // this.total = data.result.total || 0
            }
            // console.log('data:', data)
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    }
  },
  created() {
    // this.$store.dispatch('loadPointRecords', this.queryParams).then(res => {
    //   this.isLoading = false
    // })
    this.load()
    this.loadExpirePoints()
    this.throttledOnScrollLoad = this.throttle(this.onScrollLoad, 500)
    window.addEventListener('scroll', this.throttledOnScrollLoad)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledOnScrollLoad)
  }
}
</script>
<style lang="scss">
#points {
  h1 {
    margin: 20px 15px 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
  }
  .user-info {
    margin: 10px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
  }
  .overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    .total {
      display: flex;
      gap: 5px;
      .num {
        font-weight: bold;
        font-size: 30px;
        color: #0a0a0a;
        line-height: 35px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .expire {
      display: flex;
      height: 20px;
      font-weight: 400;
      font-size: 13px;
      color: #0a0a0a;
      line-height: 20px;
      .btn-use {
        margin-left: 8px;
        width: 54px;
        height: 20px;
        font-size: 11px;
        color: #fff;
        text-align: center;
        background: #f08e7a;
        border-radius: 10px;
        user-select: none;
        transition: all 0.2s;
        &:hover {
          cursor: pointer;
          transform: scale(103%);
        }
        &::after {
          content: '\003E';
          margin-left: 4px;
        }
      }
    }
  }
  .condition {
    margin: 24px auto 0;
    label.label {
      display: inline-block;
      vertical-align: top;
      line-height: 2em;
      margin: 4px 12px 0 20px;
    }
    .control {
      display: inline-block;
    }
    #interval-select {
      border: 1px solid #ffc2ae;
      box-sizing: border-box;
      filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.6));
    }
    p {
      margin: 20px;
      font-size: 13px;
      line-height: 15px;
      color: #404040;
      a {
        text-decoration: underline;
      }
    }
  }
  .point-records {
    margin-bottom: 100px;
    li {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 0 20px;
      overflow: hidden;
      border-bottom: 1px solid #ffdbcf;
      .time-title-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        // align-items: center;
        min-height: 45px;
        .time {
          color: #989595;
          font-family: Ubuntu Condensed;
          padding: 10px 0 5px;
          font-size: 13px;
          line-height: 10px;
          letter-spacing: 1px;
        }
        .point-title {
          // margin-left: 20px;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 1px;
          padding-top: 6px;
        }
      }
      .amount {
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 1px;
        text-align: right;
        .exp {
          height: 15px;
          font-weight: 400;
          font-size: 13px;
          color: #989595;
          line-height: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #points {
    max-width: 1000px;
    width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #ffc2ae;
    }
  }
}
</style>
