<template>
  <section id="pick-up">
    <div class="home-block-title has-text-centered has-text-black">
      <h2><span>P</span>ick Up<span class="has-text-lookin-darker">特別企劃</span></h2>
    </div>
    <div class="slick-container">
      <b-icon icon="angle-left" class="slick-arrow prev" @click.native="prev()"></b-icon>
      <slick ref="pickUpSlick" :options="slickOptions" v-if="!isLoading">
        <div class="content has-text-centered" v-for="article in pick_up" :key="article.id">
          <router-link
            :to="{
              path: `/${article.parent_path}/${article.child_path}/${article.id}`,
              params: {
                title: article.title
              }
            }"
          >
            <!-- <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" /> -->
            <LazyImage :src="article.image_url" class="content-img" />
          </router-link>
          <div class="slide-text">
            <h3 class="title has-text-black">
              <router-link :to="{ name: 'new', query: { category: article.category.path } }">
                <span class="category" :class="cateClass(article.category.id)">{{ article.category.title }}</span>
              </router-link>
              <router-link
                :to="{
                  path: `/${article.parent_path}/${article.child_path}/${article.id}`,
                  params: {
                    title: article.title
                  }
                }"
                class="has-text-black"
              >
                {{ article.title }}
              </router-link>
            </h3>
          </div>
        </div>
      </slick>
      <b-icon icon="angle-right" class="slick-arrow next" @click.native="next()"></b-icon>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { categoryClass } from 'src/share'
import LazyImage from '../../components/common/LazyImage.vue'

export default {
  name: 'pick-up',
  props: ['isLoading', 'pick_up'],
  components: { LazyImage },
  data() {
    return {
      slickOptions: {
        // autoplay: true,
        infinite: true,
        slidesToShow: 2,
        arrows: false,
        swipeToSlide: true
      }
    }
  },
  methods: {
    prev() {
      this.$refs.pickUpSlick.prev()
    },
    next() {
      this.$refs.pickUpSlick.next()
    },
    cateClass: id => categoryClass(id)
  },
  computed: {
    ...mapState(['isMobile'])
  }
}
</script>
<style lang="scss">
#pick-up {
  position: relative;
  h2 {
    span:nth-child(1) {
      width: 36px;
    }
    span.has-text-lookin-darker {
      left: 96px;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      z-index: 1;
      &::after {
        content: '';
        height: 8px;
        background: #ffdbcf;
        position: absolute;
        left: 6px;
        right: 7px;
        bottom: 8px;
        z-index: -1;
      }
    }
  }
  .slick-container {
    position: relative;
    .slick-slider {
      width: 90%;
      margin: 0 auto;
      .slick-slide {
        .content {
          padding: 3px 0 100px;
          img {
            border: 1px solid #f08f7a;
            padding: 2px;
            top: 0;
            height: auto;
            width: 80%;
            max-width: 450px;
            // max-height: 280px;
            max-height: 200px;
            margin: 0 auto;
            object-fit: cover;
          }
          .slide-text {
            width: 80%;
            max-width: 450px;
            margin: 0 auto;
            h3.title {
              font-size: 13px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 140%;
              text-align: justify;
              span.category {
                font-size: 13px;
                line-height: 185%;
                letter-spacing: 1px;
                padding: 1px 6px;
              }
            }
          }
        }
      }
    }
    .slick-arrow {
      color: #f08f7a;
      font-size: 24px;
      position: absolute;
      top: 35%;
      z-index: 3;
    }
    .slick-arrow.prev {
      left: 7px;
    }
    .slick-arrow.next {
      right: 7px;
    }
  }
}
</style>
