<template>
  <section>
    <b-sidebar :type="'is-nav-bg'" :fullheight="true" :fullwidth="true" v-model="open">
      <div id="forget-password-drawer" class="has-background-nav-bg drawer">
        <img :src="require('@/../images/icon-close.svg')" alt="Close" class="tail-icon close" @click="close()" />
        <h2 class="title eng-font"><span class="underline">Reset</span></h2>
        <h3 class="sub-title">重新驗證信箱</h3>
        <p>請輸入您申請帳號時的 Email，我們將會寄送密碼重設連結。</p>
        <form class="forget-password" @submit.prevent="send">
          <b-field label="Email">
            <b-input
              rounded
              type="email"
              icon-right="user"
              v-model="email"
              placeholder="請輸入 Email 帳號"
              inputmode="email"
              required
            >
            </b-input>
          </b-field>
          <div v-if="forgetPasswordDrawer" class="recaptcha">
            <vue-recaptcha :sitekey="this.sitekey" :loadRecaptchaScript="true" @verify="validate"> </vue-recaptcha>
          </div>
          <button class="button btn-login" :disabled="!this.submitButtonEnabled">送出</button>
        </form>
      </div>
    </b-sidebar>
    <Modal
      :open="submitModal"
      :title="'已送出'"
      :content="'請至 Email 信箱確認重設密碼'"
      :btnWord="'我知道了'"
      @close="closeSubmitModal"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Modal from '../dialog/Modal'
import { forgetApi } from '../../../api/user'

export default {
  props: ['open'],
  data() {
    return {
      email: null,
      submitModal: false,
      sitekey: process.env.RECAPTCHA_PUBLIC_KEY,
      submitButtonEnabled: process.env.NODE_ENV == 'development'
    }
  },
  components: {
    Modal,
    VueRecaptcha: () => import('vue-recaptcha')
  },
  computed: {
    ...mapState(['forgetPasswordDrawer'])
  },
  methods: {
    ...mapActions(['setForgetPasswordDrawer']),
    close: function () {
      this.email = null
      this.setForgetPasswordDrawer(false)
    },
    async send() {
      try {
        const { code, message } = await forgetApi(this.email)
        if (code === 200) {
          this.submitButtonEnabled = false
          this.submitModal = true
          this.setForgetPasswordDrawer(false)
          // 關閉第三方登录绑定彈框
          this.$store.commit('BIND_VISIBLE', false)
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.error(error)
      }
      // this.$store.dispatch('forgetPassword', this.email).then((response) => {
      //   this.submitModal = true
      //   this.setForgetPasswordDrawer(false)
      //   // 關閉第三方登录绑定彈框
      //   this.$store.commit('BIND_VISIBLE', false)
      // }).catch((error) => {
      //   this.submitModal = true
      //   this.setForgetPasswordDrawer(false)
      // })
    },
    closeSubmitModal() {
      this.submitModal = false
      this.setForgetPasswordDrawer(false)
    },
    validate(response) {
      this.$store
        .dispatch('verifyRecaptcha', { response: response })
        .then(result => {
          this.submitButtonEnabled = result.success
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#forget-password-drawer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    margin: 60px 0 25px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
  }

  p {
    margin: 40px auto;
    width: 281px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1px;
  }
  form.login {
    .field {
      margin-top: 15px;
      color: #404040;
      line-height: 24px;
      width: 281px;
      label {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 0;
        margin-left: 11px;
      }
      input {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        border: none;
      }
    }
  }

  button.btn-login {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 281px;
    height: 41px;
    background-color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    color: #ffffff;
    border-radius: 50px;
    margin-top: 20px;
  }
}
</style>
