<template>
  <section ref="infiniteWrapper" id="my" :style="myStyle">
    <!-- <div class="my-header has-background-lookin-bg">
      <div v-if="user" class="basic">
        <img class="avatar" :src="userAvatar(user.avatar_url)" />
        <div class="info">
          <div class="user-name">{{ user.nickname }}</div>
          <div class="my-points" @click="gotoPoints">
            <span class="point-title">累積點數</span>
            <span class="points has-text-lookin-darker">{{ user.points.toLocaleString('en-US') }}</span>
            <img :src="require('@/../images/arrow-lookin-darker.svg')" class="arrow" />
          </div>
        </div>
      </div>
    </div> -->
    <DesktopMenu v-if="!isMobile" :user="user" :menu="desktopMenu" />
    <div class="my-content">
      <router-view />
    </div>
    <!-- <b-tabs v-model="activeTab" :destroy-on-hide="true">
      <b-tab-item value="notices" label="最新消息">
        <Notices @gotoPointsPage="activeTab = 'points'" />
      </b-tab-item>
      <b-tab-item value="info" label="會員資料">
        <MyInfo />
      </b-tab-item>
      <b-tab-item value="announces" label="會員公告">
        <AnnouncesList />
      </b-tab-item>
      <b-tab-item value="points" label="積點紀錄">
        <MyPoints />
      </b-tab-item> -->
    <!-- <b-tab-item value='prizes' label="熱點兌換">
        <MyPrizes />
      </b-tab-item> -->
    <!-- <b-tab-item value='exchangeRecord' label="兌換紀錄">
        <ExchangeRecord />
      </b-tab-item>
      <b-tab-item value='addressManagement' label="地址管理">
        <AddressManagement />
      </b-tab-item> -->
    <!-- <b-tab-item value="collection" label="收藏紀錄">
        <Collection />
      </b-tab-item>
      <b-tab-item value="subscriptions" label="訂閱紀錄">
        <Subscriptions />
      </b-tab-item>
      <b-tab-item value="activities" label="活動紀錄">
        <MyActivities />
      </b-tab-item>
      <b-tab-item value="coupon" label="我的票券">
        <MyCoupon />
      </b-tab-item>
    </b-tabs> -->
  </section>
</template>
<script>
import { mapState } from 'vuex'
// import Notices from './Notices'
// import MyInfo from './Info'
// import AnnouncesList from './AnnouncesList'
// import MyPoints from './Points'
// // import MyPrizes from './Prizes'
// import Collection from './Collection'
// import Subscriptions from './Subscriptions'
// import MyActivities from './Activities'
// import MyCoupon from './Coupon.vue'
// import ExchangeRecord from './ExchangeRecord'
// import AddressManagement from './AddressManagement'
import DesktopMenu from '../../components/layout/DesktopMenu.vue'
import { throttle } from 'throttle-debounce'

export default {
  name: 'My',
  data() {
    return {
      // activeTab: this.$route.params.activePage
    }
  },
  components: {
    // Notices,
    // MyInfo,
    // AnnouncesList,
    // MyPoints,
    // // MyPrizes,
    // Collection,
    // Subscriptions,
    // MyActivities,
    // MyCoupon
    // ExchangeRecord,
    // AddressManagement,
    DesktopMenu
  },
  computed: {
    ...mapState(['user', 'desktopMenu', 'isMobile']),
    myStyle() {
      return this.isMobile ? '' : { paddingTop: '60px' }
    }
    // userAvatar() {
    //   return avatar => (avatar ? avatar : require('@/../images/user_default.png'))
    // }
  },
  // watch: {
  //   activeTab: function () {
  //     this.$router.push({ name: 'my', params: { activePage: this.activeTab } })
  //   }
  // },
  methods: {
    // gotoPoints() {
    //   this.$router.push({ name: 'my', params: { activePage: 'points' } })
    // }
    lazyLoad: throttle(800, function () {
      let offSet =
        this.$refs.infiniteWrapper.scrollHeight -
        document.querySelector('html').scrollTop -
        document.documentElement.clientHeight
      if (offSet <= 100) {
        this.$bus.$emit('/my/lazyLoad')
      }
    })
  },
  mounted() {
    window.addEventListener('scroll', this.lazyLoad)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.lazyLoad)
  }
}
</script>

<style lang="scss">
#my {
  // padding-top: 60px !important;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  .my-content {
    max-width: 1000px;
    margin: 0 auto;
  }
  // .my-header {
  //   height: 220px;
  //   margin: 0;
  //   width: 100%;
  //   padding: 40px;
  //   .basic {
  //     display: inline-block;
  //     position: relative;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     -moz-transform: translateX(-50%);
  //     -ms-transform: translateX(-50%);
  //     -o-transform: translateX(-50%);
  //     -webkit-transform: translateX(-50%);
  //     .avatar {
  //       display: inline-block;
  //       height: 75px;
  //       width: 75px;
  //       margin: 0 10px;
  //       border-radius: 50%;
  //     }
  //     .info {
  //       display: inline-block;
  //       .user-name {
  //         font-size: 18px;
  //         font-weight: 500;
  //         line-height: 24px;
  //         letter-spacing: 1px;
  //       }
  //       .my-points {
  //         cursor: pointer;
  //         span.point-title {
  //           font-size: 13px;
  //           font-weight: 500;
  //           letter-spacing: 1px;
  //           color: #404040;
  //         }
  //         span.points {
  //           font-size: 16px;
  //           font-weight: 700;
  //           letter-spacing: 1px;
  //           vertical-align: middle;
  //         }
  //         img.arrow {
  //           height: 8px;
  //           width: 8px;
  //           float: right;
  //           margin: 10px 8px 0;
  //         }
  //       }
  //     }
  //   }
  // }
  // .b-tabs {
  //   width: 1000px;
  //   margin-top: -60px;
  //   .tabs {
  //     font-style: normal;
  //     font-weight: bold;
  //     font-size: 16px;
  //     line-height: 15px;
  //     background-color: #fff0ee;
  //     padding-bottom: 20px;
  //     ul {
  //       justify-content: space-evenly;
  //       border-bottom-color: #fff0ee;
  //       a {
  //         border-bottom-color: #fff0ee;
  //         border-bottom-width: 3px;
  //         &:hover {
  //           color: #f08f7a;
  //         }
  //       }
  //       li.is-active a {
  //         color: inherit;
  //         border-bottom: 3px solid #f08f7a;
  //       }
  //     }
  //   }
  // }
}
</style>
