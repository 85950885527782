import http from './request.js'

/**
 * 获取tag轮播图
 * @param {String} params.tagName 标签名
 * @returns
 */
export function getTagCarousel(params) {
  return http.get('/shop/tag/tagPageCarouselImg', { params })
}
