<template>
  <div class="enroll">
    <img class="e-img" :src="img" alt="" />
    <div class="e-text">
      <p class="name">{{ title }}</p>
      <p class="time">有效期至{{ time }}</p>
    </div>
    <img class="e-tip" v-show="!status" src="../../../images/coupon/nonactivated.png" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Enroll',
  props: {
    // 票券标题
    title: {
      type: String,
      default: ''
    },
    // 票券有效期
    time: {
      type: String,
      default: ''
    },
    // 票券提示
    tip: {
      type: Boolean,
      default: false
    },
    // 票券图片
    img: {
      type: String,
      default: ''
    },
    // status 优惠券状态，0：未激活；1：已激活；2：已核销；3：已过期；
    status: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.enroll {
  display: flex;
  align-items: center;
  position: relative;
  width: 269px;
  background: #fff9f7;
  border-radius: 6px;
  border: 1px solid #ffcdcf;
  padding: 10px;
  margin-top: 15px;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 69px;
    width: 9px;
    height: 5px;
    background: #fff;
    border: 1px solid #ffcdcf;
    border-radius: 0 0 18px 18px;
    border-top: none;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 69px;
    width: 9px;
    height: 5px;
    background: #fff;
    border: 1px solid #ffcdcf;
    border-radius: 18px 18px 0 0;
    border-bottom: none;
  }
  .e-img {
    width: 58px;
    height: 58px;
    background: #d9d9d9;
    border-radius: 6px;
  }
  .e-text {
    text-align: left;
    padding-left: 8px;
    .name {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
    .time {
      font-size: 10px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
    }
  }
  .e-tip {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
  }
}
</style>
