<template>
  <div class="history-more" @click.stop="">
    <div class="more-top flex justify-between">
      <div class="text">歷史搜索</div>
      <div class="del" @click="$emit('delAll')">
        <!-- <NuxtIcon name="search/delAll" class="m-5px" /> -->
        <span>清空</span>
      </div>
    </div>
    <div class="more-box">
      <TagItem v-for="(item, index) in list" :key="index" style="border: none; background: #f2f2f2">
        <span @click="$emit('click', item)">{{ item }}</span>
        <img src="../../../images/close.svg" alt="" @click.stop="$emit('del', item)" />
      </TagItem>
    </div>
  </div>
</template>

<script>
import TagItem from './TagItem.vue'
export default {
  name: 'HistoryMore',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  components: { TagItem },
  data() {
    return {}
  },
  mounted() {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.history-more {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(240, 144, 122, 0.6);
  border-radius: 10px;
  border: 1px solid #ffc1ad;
  padding: 20px 24px;
  .more-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      font-size: 14px;
      color: #222222;
      line-height: 16px;
    }
    .del {
      font-size: 12px;
      color: #959595;
      line-height: 14px;
      cursor: pointer;
    }
  }
  .more-box {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
  }
}
</style>
