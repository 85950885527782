<template>
  <div class="coupon-invite-friends">
    <p class="f-text" v-if="status">恭喜您，優惠券已激活成功</p>
    <p class="f-text" v-else>
      邀請<span class="f-num">{{ helpTimes }}位</span>好友即可讓票券生效
    </p>
    <template v-if="info && info.length > 0">
      <img
        class="f-img-success"
        :src="item.avatarUrl ? item.avatarUrl : imgSuccessUrl"
        alt=""
        v-for="(item, index) in info"
        :key="index"
      />
    </template>
    <!-- <img class="f-img" src="../../../images/coupon/add.svg" alt="" v-else /> -->
    <b-button v-if="!status" type="is-primary" class="f-btn" rounded @click="handleShare"> 立即邀請 </b-button>
  </div>
</template>

<script>
import { shareAndroid, isLookinApp, Android } from '../../../tools/bridge'
import imgSuccess from '../../../images/coupon/user.png'
import { APP_UPDATE_TIP } from '../../../store/mutation-types'

export default {
  name: 'CouponInviteFriends',
  props: {
    // status 优惠券状态，0：未激活；1：已激活；2：已核销；3：已过期；
    status: {
      type: Number,
      default: 0
    },
    // 邀请几名好友激活
    helpTimes: {
      type: Number,
      default: 1
    },
    // 票券id
    couponId: {
      type: Number,
      default: 0
    },
    // 票券信息
    info: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imgSuccessUrl: imgSuccess
    }
  },
  methods: {
    handleShare() {
      const shareObj = {
        title: '快來幫我集氣，一起領LOOKin【一日店長】活動限定優惠券。',
        text: '快來幫我集氣，一起領LOOKin【一日店長】活動限定優惠券。',
        url: `${window.location.origin}/assistance/${this.couponId}`
      }
      if (isLookinApp) {
        // app 调用bridge
        try {
          if (Android) {
            shareAndroid(shareObj)
          } else {
            navigator.share(shareObj)
          }
        } catch (error) {
          // 打开提示升级弹框
          this.$store.commit(APP_UPDATE_TIP, true)
        }
      } else {
        try {
          // 调用分享
          navigator.share(shareObj)
        } catch (err) {
          // 不支持 提示使用其他激活方式
          this.$buefy.toast.open({
            type: 'is-danger',
            message: '請使用LooKin App分享'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-invite-friends {
  padding: 28px 30px 0;
  text-align: center;
  .f-text {
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    .f-num {
      color: #f08f7a;
    }
  }
  .f-img {
    width: 38px;
    height: 38px;
    margin-top: 15px;
  }
  .f-img-success {
    width: 38px;
    height: 38px;
    margin: 15px 5px 0;
    border-radius: 50%;
  }
  .f-btn {
    color: #ffffff;
    width: 100%;
    background-color: #f08f7a;
    margin-top: 20px;
  }
}
</style>
