<template>
  <button class="tag-item">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'TageItem',
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.tag-item {
  display: flex;
  align-items: center;
  color: black;
  font-size: 13px;
  border-radius: 999px;
  outline: none;
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  margin: 5px;
  background-color: transparent;
  border: black solid 1px;
  cursor: pointer;
  &:hover {
    background-color: #f08f7a !important;
    border: 1px solid #f08f7a;
    color: white;
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
  img {
    width: 16px;
    height: 16px;
    padding: 3px;
    cursor: pointer;
    margin-left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .tag-item {
    span {
      max-width: 230px;
    }
  }
}
</style>
