<template>
  <div class="login">
    <div class="login_icon">
      <img class="login_icon_left" src="../../../images/login/icon_facebook.png" alt="" @click="facebookLogin" />
      <img class="login_icon_left" src="../../../images/login/icon_google.png" alt="" @click="googleLogin" />
      <img
        class="login_icon_left"
        src="../../../images/login/icon_apple.png"
        alt=""
        @click="appleLogin"
        v-if="showAppleIcon"
      />
    </div>
    <div class="login_tip">
      <span>LOOKin 帳號登入</span>
    </div>
    <form class="login_form" @submit.prevent="onLogin">
      <b-field label="Email">
        <b-input
          v-model="loginForm.email"
          type="email"
          required
          placeholder="請輸入信箱帳號"
          inputmode="email"
          rounded
        ></b-input>
      </b-field>
      <b-field label="密碼">
        <b-input
          v-model="loginForm.password"
          type="password"
          required
          placeholder="請輸入密碼（長度6字位以上，需區分大小寫）"
          password-reveal
          rounded
        ></b-input>
      </b-field>
      <b-field>
        <button class="button">
          <span>登入</span>
        </button>
      </b-field>
    </form>
    <div class="login_footer">
      <p class="forgot">
        <span @click="onForgotPassword">忘記密碼</span>
      </p>
      <p>
        <span>還沒有會員？</span>
        <span class="register" @click="gotoRegister">立即註冊</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'
import apis from '../../../api'
import { login } from '../../../api/user'
import {
  isLookinApp,
  Android,
  isMobile,
  openGoogleLogin,
  openFacebookLogin,
  compareVersion,
  openAppleSignIn,
  setAppRecordFCM
} from '../../../tools/bridge.js'
import { baseURL } from '../../../api/request'
import { LOGIN_SUCCESS } from '../../../store/mutation-types'

export default {
  name: 'LoginModal',

  props: {
    thirdPartyUrl: {
      type: String,
      default: ''
    }
  },
  emits: ['loginSuccess', 'gotoRegister', 'appThirdPartyLogin'],
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      nowAppVersion: '2.2.2', // 使用的app版本
      showAppleIcon: false
    }
  },
  computed: {
    ...mapState(['appVersion'])
  },
  mounted() {
    // 獲取蘋果登錄token app會調用
    window.sendUserInformation = userMessage => {
      console.log(userMessage, 'sendUserInformation')
      const { code, message, result } = JSON.parse(userMessage) || {}
      if (code == 200) {
        const { token, user_id, userid } = result || {}
        // apple登录返回的是userid, 其他登录 user_id
        const _userId = user_id || userid
        // 設置token
        window.localStorage.setItem('auth-token', token)
        // eslint-disable-next-line
        $cookies.set('user_id', _userId)
        setAppRecordFCM(_userId)
        // 刷新頁面
        // window.location.reload()
        this.$emit('appThirdPartyLogin')
      } else if (code == 100 || code == '611' || code == '500') {
        Toast.open({
          duration: 3000,
          type: 'is-danger',
          message
        })
      } else if (code == '701') {
        Toast.open({
          duration: 3000,
          type: 'is-danger',
          message: '認證失敗，請重新登入'
        })
      }
    }

    // 显示苹果图标
    if (isMobile && !Android) {
      this.showAppleIcon = true
    }
  },
  destroyed() {
    // 清除變量
    window.sendUserInformation = null
  },
  methods: {
    ...mapActions(['setForgetPasswordDrawer']),
    async onLogin({ captchaVerification = '' }) {
      try {
        // eslint-disable-next-line
        const deviceId = $cookies.get('device_id') || ''
        const params = {
          email: this.loginForm.email,
          password: this.loginForm.password,
          deviceId,
          captchaVerification
        }
        const { code, message, result } = await login(params)
        if (code === 200) {
          window.localStorage.setItem('auth-token', result)

          // 获取用户信息
          try {
            const { data } = await apis.getProfile(result)
            // 设置用户信息
            // eslint-disable-next-line
            $cookies.set('user_id', data.id)
            // eslint-disable-next-line
            $cookies.set('toc_user_id', data.id)
            setAppRecordFCM(data.id)
            const user = Object.assign(data, { auth_token: result })
            this.$store.commit(LOGIN_SUCCESS, user)
            //  登录成功回调
            this.$emit('loginSuccess', user)
            Toast.open({
              duration: 3000,
              type: 'is-success',
              message: '登録成功！'
            })
          } catch (err) {
            const { error } = err.data
            Toast.open({
              duration: 3000,
              type: 'is-danger',
              message: error
            })
          }
        } else if (code === 411) {
          // 打开滑动验证框
          this.$emit('showVerify')
        } else {
          Toast.open({
            duration: 3000,
            type: 'is-danger',
            message: message
          })
        }
      } catch (error) {
        Toast.open({
          duration: 3000,
          type: 'is-danger',
          message: '系統異常！'
        })
      }

      // try {
      //   const data = await apis.login(this.loginForm)
      //   // 登录成功回调
      //   this.$emit('loginSuccess', data.data)
      //   Toast.open({
      //     duration: 3000,
      //     type: 'is-success',
      //     message: '登録成功！'
      //   })
      // } catch (err) {
      //   console.log(err?.response)
      //   if (err?.response?.status === 401) {
      //     Toast.open({
      //       duration: 3000,
      //       type: 'is-danger',
      //       message: err.response.data.error
      //     })
      //   } else if (err?.response?.status === 405) {
      //     Toast.open({
      //       duration: 3000,
      //       type: 'is-danger',
      //       message: err.response.data.error
      //     })
      //   } else if (err?.response?.status === 500) {
      //     Toast.open({
      //       duration: 3000,
      //       type: 'is-danger',
      //       message: '系統異常！'
      //     })
      //   }
      // }

      try {
        new TrackEvent().setEvent('ht_email_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    },
    gotoRegister() {
      this.$emit('gotoRegister')
      try {
        new TrackEvent().setEvent('ht_skip_register', 'click', 'register', '', '')
      } catch (error) {
        console.warn(error)
      }
    },
    onForgotPassword() {
      this.setForgetPasswordDrawer(true)
      try {
        new TrackEvent().setEvent('ht_skip_password', 'click', 'password', '', '')
      } catch (error) {
        console.warn(error)
      }
    },
    facebookLogin() {
      const viewUrl = this.thirdPartyUrl
        ? this.thirdPartyUrl
        : window.location.href.includes('?')
        ? `${location.href}&`
        : `${location.href}?`
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          // 调起原生app方法
          openFacebookLogin()
        } else {
          window.location.replace(`${baseURL}/shop/thirdparty/thirdpartyRedirect/6/_login?viewUrl=${viewUrl}`)
        }
      } else {
        window.location.replace(`${baseURL}/shop/thirdparty/thirdpartyRedirect/6/_login?viewUrl=${viewUrl}`)
      }

      try {
        new TrackEvent().setEvent('ht_facebook_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    },
    googleLogin() {
      const viewUrl = this.thirdPartyUrl
        ? this.thirdPartyUrl
        : window.location.href.includes('?')
        ? `${location.href}&`
        : `${location.href}?`
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          openGoogleLogin()
        } else {
          window.location.href = `${baseURL}/shop/thirdparty/thirdpartyRedirect/5/_login?viewUrl=${viewUrl}`
        }
      } else {
        window.location.href = `${baseURL}/shop/thirdparty/thirdpartyRedirect/5/_login?viewUrl=${viewUrl}`
      }

      try {
        new TrackEvent().setEvent('ht_google_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    },
    appleLogin() {
      const viewUrl = this.thirdPartyUrl
        ? this.thirdPartyUrl
        : window.location.href.includes('?')
        ? `${location.href}&`
        : `${location.href}?`
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          openAppleSignIn()
        } else {
          window.location.href = `${baseURL}/shop/auth/apple?viewUrl=${viewUrl}`
        }
      } else {
        window.location.href = `${baseURL}/shop/auth/apple?viewUrl=${viewUrl}`
      }

      try {
        new TrackEvent().setEvent('ht_google_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  &_icon {
    display: flex;
    justify-content: center;
    img {
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    &_left {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_tip {
    padding: 30px 0 20px;
    text-align: center;
    span {
      position: relative;
      font-size: 14px;
      font-weight: 600;
      color: #a9a9a9;
      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -72px;
        width: 60px;
        height: 1px;
        border: 1px solid #e3e3e3;
      }
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        right: -72px;
        width: 60px;
        height: 1px;
        border: 1px solid #e3e3e3;
      }
    }
  }
  ::v-deep &_form {
    max-width: 336px;
    margin: 0 auto;
    .field {
      .label {
        font-size: 12px;
        font-weight: 600;
        color: #363636;
        padding-left: 15px;
      }
      .help {
        padding-left: 15px;
      }
      .control {
        height: 42px;
      }
      .button {
        width: 100%;
        height: 42px;
        background: #000000;
        margin-top: 20px;
        border-radius: 21px;
        span {
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  &_footer {
    font-size: 14px;
    color: #999999;
    text-align: center;
    .forgot {
      margin: 15px 0;
      cursor: pointer;
    }
    .register {
      color: #f08f7a;
      cursor: pointer;
    }
  }
}
</style>
