<template>
  <div>
    <nav>
      <div class="nav-item burger-menu">
        <img :src="require('@/../images/icon-hamburger.svg')" alt="OpenMenu" @click="openMenuDrawer = true" />
      </div>
      <router-link v-if="logos" :to="{ name: 'home' }" class="nav-item logo">
        <img :src="logos.mobile_url" alt="LOOKin" />
      </router-link>
      <div class="mb-nav-right">
        <div @click="userNoticePage" class="notice-btn" v-if="user">
          <img :src="require('@/../images/notice.svg')" alt="最新消息" />
          <span v-if="user.notices_count > 0" class="has-background-lookin-darker">
            {{ user.notices_count }}
          </span>
        </div>
        <img
          :src="require('@/../images/icon-search.svg')"
          class="search-icon"
          alt="Search"
          @click="openSearchDrawer = true"
        />
      </div>
    </nav>
    <MainMenuDrawer @closeDrawer="openMenuDrawer = false" :open="openMenuDrawer" />
    <SearchDrawer @closeDrawer="openSearchDrawer = false" :open="openSearchDrawer" />
    <template v-if="inMemberZone">
      <div
        id="menu-list"
        :style="{ top: showYellowTip ? '100px' : '60px' }"
        v-on="handleScroll()"
        v-dragscroll.x="true"
        :class="['headroom', { 'headroom--unpinned': scrolled }]"
      >
        <ul>
          <li
            :class="$route.path == item.path ? 'has-background-nav-bg' : ''"
            v-for="(item, i) in memberZoneItems"
            :key="i"
          >
            <router-link :to="item.path" class="content has-text-black">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <div
        id="menu-list"
        :style="{ top: showYellowTip ? '100px' : '60px' }"
        v-if="channels"
        v-on="handleScroll()"
        v-dragscroll.x="true"
        :class="['headroom', { 'headroom--unpinned': scrolled }]"
      >
        <ul>
          <li :class="currentPath == item.path ? 'has-background-nav-bg' : ''" v-for="(item, i) in menuItems" :key="i">
            <router-link
              :to="{
                name: item.name,
                params: {
                  title: item.name == 'hot' || item.name == 'new' ? `${item.title}文章` : item.title,
                  channel: item.name == 'channel' ? item.slug : null
                }
              }"
              :target="item.external ? '_blank' : '_self'"
              class="content has-text-black"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MainMenuDrawer from '../MainMenuDrawer'
import SearchDrawer from '../SearchDrawer'
import { dragscroll } from 'vue-dragscroll'
import { isLookinApp } from '../../../../tools/bridge'

const displayItems = [
  { id: 13, title: '推薦', name: 'recommend', path: '/recommend' },
  { id: 2, title: '全部', name: 'new', path: '/new' },
  { id: 3, title: '熱門', name: 'hot', path: '/hot' }
]

export default {
  name: 'Mobile',
  data() {
    return {
      openMenuDrawer: false,
      openSearchDrawer: false,
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      displayItems: [
        { id: 1, title: '首頁', name: 'home', path: '/' },
        { id: 2, title: '最新', name: 'new', path: '/new' },
        { id: 3, title: '熱門', name: 'hot', path: '/hot' },
        { id: 13, title: '推薦', name: 'recommend', path: '/recommend' }
      ],
      mainItems: [
        { id: 4, title: 'LOOK V 影音', name: 'videos', path: '/videos' },
        { id: 5, title: '線上電子雜誌', name: 'choc', path: '/choc', external: true }, // NOTE: external real link in router
        { id: 8, title: '獨家企劃', name: 'specials', path: '/specials' },
        { id: 11, title: '網友來做主', name: 'votes', path: '/votes' },
        { id: 9, title: '體驗試用', name: 'trials', path: '/trials' },
        { id: 10, title: '活動募集', name: 'lectures', path: '/lectures' },
        { id: 6, title: '駐站名人', name: 'authors', path: '/authors' },
        { id: 12, title: '熱點兌換', name: 'prizes', path: '/prizes' }
      ]
    }
  },
  directives: { dragscroll: dragscroll },
  components: { MainMenuDrawer, SearchDrawer },
  computed: {
    ...mapState(['channels', 'user', 'memberZoneItems', 'logos', 'showYellowTip']),
    currentPath() {
      // return this.$route.path.match(/^\/[^\/]+/)
      return this.$route.path
    },
    inMemberZone() {
      return this.$route.path.startsWith('/my')
    },
    menuItems() {
      let menu = [].concat(this.displayItems)
      if (this.channels) {
        menu = menu.concat(...this.channels)
      }
      return menu.concat(...this.mainItems)
    }
  },
  methods: {
    ...mapActions(['setSignUpDrawer', 'setForgetPasswordDrawer']),
    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = false
        // move down
      }
      this.lastPosition = window.scrollY
      // this.scrolled = window.scrollY > 250;
    },
    userNoticePage() {
      this.$router.push({ name: 'my', params: { activePage: 'notices' } })
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    if (isLookinApp) {
      this.displayItems = displayItems
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
#menu-list {
  top: 60px;
  position: fixed;
  z-index: 6;
  background: white;
  width: 100vw;
  overflow: auto;
  box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6) !important;
  border-top: 1px solid #ffc2ae;
  ul {
    height: 36px;
    white-space: nowrap;
    li {
      height: 36px;
      display: inline-block;
      width: fit-content !important;
      padding: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 1px;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
#menu-list::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
#menu-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

header nav {
  position: fixed;
  top: 0;
  z-index: 30;
  height: 60px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  .burger-menu {
    padding: 20px 15px;
  }
  .logo {
    width: 107px;
    height: 39px;
    padding-top: 15px;
  }
  .mb-nav-right {
    display: flex;
    align-items: center;
    .search-icon {
      width: 25px;
      margin-right: 15px;
      // padding: 18px;
    }
    .notice-btn {
      position: relative;
      margin-right: 20px;
      margin-top: 5px;
      img {
        width: 25px;
        // margin-top: -3px;
        cursor: pointer;
      }
      span {
        position: absolute;
        top: -7px;
        right: -10px;
        min-width: 16px;
        height: 16px;
        padding: 3px;
        cursor: pointer;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 11px;
        text-align: center;
        border-radius: 16px;
        box-shadow: 0 3px 6px rgba(240, 143, 122, 0.6);
      }
    }
  }
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}
</style>
