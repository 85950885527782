<template>
  <div id="exchange_modal" v-if="show">
    <div class="modal_box">
      <div class="close">
        <img :src="require('@/../images/icon-close.svg')" alt="Close" @click="onClose" />
      </div>
      <div class="box-title">
        <slot name="header">{{ title }}</slot>
      </div>
      <div class="box_content">
        <slot name="content"> {{ content }} </slot>
      </div>
      <div class="box_footer">
        <slot name="footer">
          <b-button v-if="confirmText" expanded rounded type="is-primary" @click="onConfirm">
            {{ confirmText }}
          </b-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'show',
    event: 'update:show'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('update:show', false)
    },
    onConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
#exchange_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
  .modal_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 540px;
    width: 345px;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    padding: 27px 0;
    .close {
      width: 36px;
      height: 36px;
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-title {
      font-size: 18px;
      color: #0a0a0a;
      line-height: 27px;
      text-align: center;
    }
    .box_content {
      font-size: 15px;
      color: #404040;
      line-height: 22px;
      text-align: center;
      margin-top: 20px;
    }
    .box_footer {
      button {
        width: 231px;
        height: 42px;
        background: #f08f7a;
        font-size: 15px;
        color: #ffffff;
        margin: 25px auto 0;
      }
    }
  }
}
</style>
