<template>
  <div class="list">
    <ul v-if="list && list.length > 0">
      <Item
        class="list-item"
        v-for="(item, index) in list"
        :key="index"
        :item="item"
        :type="type"
        @jump="$emit('jump', item)"
      />
    </ul>
    <Loading v-else-if="loading" />
    <div class="empty" v-else>
      <img class="e-img" src="../../../images/exchange/empty.svg" alt="" />
      <p class="e-text">還沒有兌換記錄哦~</p>
    </div>
  </div>
</template>

<script>
import Item from './Item.vue'
import Loading from '../common/Loading.vue'
export default {
  name: 'ExchangeRecordList',
  components: { Item, Loading },
  props: ['type', 'list', 'loading']
}
</script>

<style lang="scss" scoped>
.list {
  .list-item {
    margin-bottom: 10px;
  }
  .empty {
    text-align: center;
    padding-top: 100px;
    .e-img {
      width: 100px;
      height: 100px;
    }
    .e-text {
      font-size: 14px;
      color: #404040;
      line-height: 19px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .list {
    .list-item {
      border: 1px solid #e8e8e9;
    }
  }
}
</style>
