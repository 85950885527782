<template>
  <transition name="el-fade-in">
    <div v-show="visible" class="floatNotice" :class="{ active }">
      <div @click="active = !active" class="icon">
        <img :src="require('@/../images/message.svg')" alt="Message" />
      </div>
      <a class="message-box" :href="fixed_widget.data.link" target="_blank">{{ fixed_widget.data.text }}</a>
    </div>
  </transition>
</template>

<script>
import { throttle } from 'throttle-debounce'
import { mapState } from 'vuex'

export default {
  name: 'FixedWidget',
  data() {
    return {
      visibilityHeight: 200,
      el: null,
      container: null,
      visible: false,
      active: false
    }
  },
  computed: {
    ...mapState(['fixed_widget'])
  },
  mounted() {
    this.container = document
    this.el = document.documentElement
    this.throttledScrollHandler = throttle(300, this.onScroll)
    this.container.addEventListener('scroll', this.throttledScrollHandler)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.el
      this.visible = scrollTop >= this.visibilityHeight
    }
  },
  beforeDestroy() {
    this.container.removeEventListener('scroll', this.throttledScrollHandler)
  }
}
</script>
<style lang="scss">
.floatNotice {
  position: fixed;
  bottom: 136px;
  width: 220px;
  left: calc(100% - 50px);
  display: flex;
  z-index: 5;
  transition: left 0.3s ease-in-out;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    > img {
      width: 40px;
    }
  }
  .message-box {
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 20px;
    margin-left: 10px;
    max-width: 180px;
    color: white;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &.active {
    left: calc(100% - 240px);
    .message-box {
      opacity: 1;
    }
  }
}
</style>
