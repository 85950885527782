import request from "./request.js";

// 获取token
function getToken() {
  return window.localStorage.getItem("auth-token") || "";
}

// 獲取綁定狀況
export function getBindStatus() {
  return request({
    method: "get",
    url: "/shop/thirdparty/bindInfo",
    headers: {
      "auth-token": getToken(),
    },
  });
}

// 解除綁定
export function unbind(id) {
  return request({
    method: "get",
    url: `/shop/thirdparty/removeBinding/${id}`,
    headers: {
      "auth-token": getToken(),
    },
  });
}

// 第三方綁定登錄接口
export function bindLogin(data) {
  return request({
    method: "post",
    url: "/shop/thirdparty/bind/lookin",
    headers: {
      "content-type": "application/json",
    },
    data: JSON.stringify(data),
  });
}

// 第三方綁定註冊接口
export function bindRegisiter(data) {
  return request({
    method: "post",
    url: "/shop/register/lookin",
    headers: {
      "content-type": "application/json",
      AppKey: "a98f0c977e8fc3537e7a89378c7e26aa",
    },
    data: JSON.stringify(data),
  });
}
