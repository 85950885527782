<template>
  <div id="new_module">
    <div class="box">
      <img
        class="close"
        @click="onClose"
        src="../../../images/icon-close.svg"
        alt="close"
      />
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
      <div class="btn">
        <b-button
          rounded
          class="cancel"
          v-if="cancelText"
          @click="onCancel"
          >{{ cancelText }}</b-button
        >
        <b-button
          rounded
          class="confirm"
          v-if="confirmText"
          @click="onConfirm"
          >{{ confirmText }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "new_module",
  /**
   * title 标题
   * content 内容
   * cancelText 取消按钮文本 有 则显示，反之隐藏此按钮
   * confirmText 确认按钮文本 有 则显示，反之隐藏此按钮
   * onCancel 取消事件 关闭弹框
   * onConfirm 确认事件
   * 
   */
  props: {
    title: String,
    content: String,
    cancelText: String,
    confirmText: String,
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("onCancel");
    },
    onConfirm() {
      this.$emit("onConfirm");
    },
    onClose() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
#new_module {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: 0 35px;
  z-index: 9;
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 305px;
    text-align: center;
    .close {
      width: 25.46px;
      height: 25.46px;
      position: absolute;
      top: 7px;
      right: 9px;
      padding: 5px;
      opacity: 0.5;
      cursor: pointer;
    }
    .title {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #0a0a0a;
      line-height: 25px;
    }
    .content {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 26px;
    }
    .btn {
      height: 44px;
      padding: 0 30px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      display: flex;
      justify-content: center;
      .cancel {
        width: 104px;
        margin-right: 20px;
        background-color: #f2f2f2;
         border: none;
      }
      .confirm {
        width: 104px;
        background-color: #f08f7a;
        color: #ffffff;
        border: none;
      }
    }
  }
}
</style>
