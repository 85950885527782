<template>
  <section v-if="!isLoading" id="vote">
    <div class='content'>
      <h1><span>{{ current_vote.title }}</span></h1>
      <div class="img"><img :src="current_vote.cover_image"></div>
      <p class="ended-at">{{ current_vote.ended_at }} 投票截止</p>
      <div class='event-max-width'>
        <div class="article-content" v-html="current_vote.content"></div>
      </div>
    </div>
    <div class='event-max-width'>
      <section class='options'>
        <div class="option" v-for="option in current_vote.vote_options">
          <div class="wrap">
            <slick :options="slickOptions">
              <div class="slide-content" v-for="(image, i) in option.images">
                <img class="slide-image" :src="image || require('@/../images/default.jpg')">
              </div>
            </slick>
            <h3>{{ option.title }}</h3>
            <p>{{ option.desc }}</p>
            <p class="heart-votes">
              <img class="v-heart" @click="vote(option)" :src="heartSrc(option.voted)">
              <span>
                {{ option.users_count }} 票
                <img v-if="topVote(option)" class="crown" :src="require('@/../images/crown.png')">
              </span>
            </p>
          </div>
        </div>
      </section>
      <Comments :vote_id="current_vote.id" :comments="current_vote.comments"/>
    </div>
    <Modal
      :open="modal"
      :title="modalContent.title"
      :content="modalContent.content"
      :btnWord="modalContent.btnWord"
      @close="closeModal"
    />
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Comments from 'src/components/common/Comments'
import Modal from '../../components/dialog/Modal'
import { isOngoing } from 'src/share'

export default {
  data() {
    return {
      isLoading: true,
      slickOptions: {
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        swipeToSlide: true
      },
      modal: false,
      modalContent: {
        title: null,
        content: null,
        btnWord: null
      },
      loginRequired: false,
      loginContent: {
        title: '請先登入',
        content: '登入會員再投票喔！',
        btnWord: '好的'
      },
      errorContent: {
        title: '今日已投票成功',
        content: '別忘記明天回來繼續投票囉！',
        btnWord: '好的'
      },
      voteContent: {
        title: '投票成功',
        content: '別忘記天天都能投票喔！',
        btnWord: '好的'
      },
      expiredContent: {
        title: '投票已結束',
        content: '',
        btnWord: '好的'
      },
      alreadyVoted: {
        title: '投票後就不能變心囉~',
        content: '別忘記明天回來繼續投票！',
        btnWord: '好的'
      }
    }
  },
  components: { Comments, Modal },
  watch: {
    user: function (val) {
      this.isLoading = true
      this.$store.dispatch('loadVote').then(() => {
        this.isLoading = false
      })
    }
  },
  computed: {
    ...mapState(['user', 'isMobile', 'current_vote']),
    multiSessions: function () {
      return (this.current_vote) ? (this.current_vote.vote_options.length > 1) : false
    },
    topVote: function () {
      return option => (!isOngoing(this.current_vote.ended_time) && option.users_count == Math.max.apply(Math, this.current_vote.vote_options.map(function(v) { return v.users_count })))
    },
    heartSrc() {
      return voted => voted ? require('@/../images/follow.svg') : require('@/../images/unfollow.svg')
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setVia']),
    closeModal() {
      this.modal = false
      if (this.loginRequired == true) {
        this.loginRequired = false
        this.setLoginDrawer(true)
      }
    },
    vote(vote_option) {
      if (isOngoing(this.current_vote.ended_time)) {
        if (!!this.user) {
          if (vote_option.voted) {
            this.modalContent = this.alreadyVoted
            this.modal = true
          } else {
            this.$store.dispatch('voteFor', vote_option.id).then(() => {
              this.modalContent = this.voteContent
              this.modal = true
            }).catch((error) => {
              console.log(error.data)
              this.modalContent = this.errorContent
              this.modal = true
            })
          }
        } else {
          this.setVia({
            type: 'Vote',
            id: this.current_vote.id,
            path: 'apply'
          })
          this.loginRequired = true
          this.modalContent = this.loginContent
          this.modal = true
        }
      } else {
        this.modalContent = this.expiredContent
        this.modal = true
      }
    }
  },
  created() {
    this.$store.dispatch('loadVote').then(() => {
      this.isLoading = false
    })
  },
}
</script>
<style lang="scss">
#vote {
  width: 100%;
  .event-max-width {
    max-width: 810px;
    margin: 0 auto;
  }
  .content {
    .img {
      width: 100%;
      background-color: #FFF0EE;
      img {
        width: 100%;
        max-width: 950px;
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }
    h1 {
      width: 95%;
      max-width: 1200px;
      margin: 12px auto 14px;
      text-align: center;
      @media screen and (min-width: 1024px) {
        font-size: 28px;
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 31px;
        padding: 1px 6px;
        background-color: #FFDBCF;
        color: #000000;
      }
    }
    .ended-at {
      margin-top: 10px;
      text-align: center;
      display: block;
      color: #F08F7A;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }
  }
  section.options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px;
    max-width:1200px;
    margin:auto;
    .option {
      width: 40vw;
      margin: 10px;
      max-width: 215px;
      border: 1px solid #FFC2AE;
      box-sizing: border-box;
      box-shadow: 4px 4px 0 #FFC2AE;
      img.slide-image {
        width: 40vw;
        height: 40vw;
      }
      h3 {
        margin-top: 15px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 1px;
      }
      p {
        padding: 7px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #404040;
        word-break: break-word;
      }
      .heart-votes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .v-heart {
          height: 24px;
          width: 24px;
          cursor: pointer;
        }
        span {
          position: relative;
          font-weight: bold;
          font-size: 13px;
          line-height: 24px;
          text-align: right;
          letter-spacing: 1px;
          color: #989595;
          .crown {
            position: absolute;
            top: -21px;
            right: 12px;
            max-width: 40px;
          }
        }
      }
      .slick-slider {
        box-sizing: border-box;
        margin: 0 auto;
        clear: both;
        max-width: 215px;
        width: 100%;
        .slick-slide {
          height: auto;
          width: 100%;
          max-width: 215px;
          .slide-image {
            width: 100%;
            display: block;
            max-width: 215px;
            max-height: 215px;
            object-fit: cover;
          }
        }
        .slick-dots {
          bottom: -20px;
          li {
            width: 5px;
            button {
              border-radius: 50%;
              border: 1px solid #FFC2AE;
              width: 5px;
              height: 5px;
              padding: 2px;
            }
            button:before {
              content: '';
            }
          }
          li.slick-active button {
            background-color: #FFC2AE;
          }
        }
      }
    }
  }
}
</style>
