var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"infiniteWrapper"},[_c('div',{attrs:{"id":"article-list"}},_vm._l((_vm.recommendArticleList),function(article,index){return _c('div',{key:article.id,ref:"articleRef",refInFor:true,staticClass:"list-article",on:{"click":function($event){return _vm.handleArticle(index, article.id)}}},[(_vm.isMobile)?_c('p',{staticClass:"author"},[_c('router-link',{attrs:{"to":""}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.authorAvatar(article.author_avatar)}}),_vm._v(" "),_c('span',{staticClass:"name has-text-black"},[_vm._v(_vm._s(article.author_name))])]),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.published_at))])],1):_vm._e(),_vm._v(" "),_c('router-link',{staticClass:"cover img-container",attrs:{"to":{
          path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
          params: {
            title: article.title
          }
        }}},[_c('LazyImage',{staticClass:"big-content-img",attrs:{"src":article.image_url,"alt":article.title}})],1),_vm._v(" "),_c('div',{staticClass:"a-info"},[(!_vm.isMobile)?_c('div',[_c('p',{staticClass:"author"},[_c('router-link',{attrs:{"to":""}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.authorAvatar(article.author_avatar)}}),_vm._v(" "),_c('span',{staticClass:"name has-text-black"},[_vm._v(_vm._s(article.author_name))])]),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.published_at))])],1),_vm._v(" "),_c('h2',{staticClass:"title has-text-black"},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v("\n              "+_vm._s(article.category.title)+"\n            ")]),_vm._v(" "),_c('router-link',{attrs:{"to":{
                path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
                params: {
                  title: article.title
                }
              }}},[_vm._v("\n              "+_vm._s(article.title)+"\n            ")])],1),_vm._v(" "),_c('p',{staticClass:"abstract"},[_vm._v("\n            "+_vm._s(article.abstract)+"\n          ")])]):_vm._e(),_vm._v(" "),_c('p',{class:_vm.isMobile ? 'has-text-centered tags' : 'tags'},_vm._l((article.tags.slice(0, 3)),function(tag){return _c('router-link',{key:tag,staticClass:"tag",attrs:{"to":{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }}},[_vm._v("\n            #"+_vm._s(tag)+"\n          ")])}),1)]),_vm._v(" "),(_vm.isMobile)?_c('h2',{staticClass:"title has-text-black"},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v("\n          "+_vm._s(article.category.title)+"\n        ")]),_vm._v(" "),_c('router-link',{attrs:{"to":{
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          }}},[_vm._v("\n          "+_vm._s(article.title)+"\n        ")])],1):_vm._e()],1)}),0),_vm._v(" "),(_vm.isShowFinishedTip)?_c('div',{staticClass:"load_tip__finished"},[_vm._v("-已顯示全部-")]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }