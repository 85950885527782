<template>
  <div class="coupon-item" :class="{ active: isTask }" @click="onJump">
    <div class="coupon">
      <div class="c-top">
        <img class="t-img" :src="info.imgUrl" alt="" />
        <div class="t-text">
          <p class="name">{{ info.title }}</p>
          <p>有效期 {{ beginTime }} 至 {{ endTime }}</p>
        </div>
        <img class="t-tip" v-if="tipImg[info.status]" :src="tipImg[info.status]" alt="" />
      </div>
      <div class="c-line"></div>
      <div class="c-bottom">{{ info.subTitle }}</div>
      <div class="c-tip">{{ couponType[info.type] }}</div>
    </div>
    <div class="c-task" v-if="isTask">
      <span class="t1">完成任務票券才會生效</span>
      <span class="t2">做任務 &gt;</span>
    </div>
  </div>
</template>

<script>
import dateFormat from '../../../tools/dateformat'
import utilizedImg from '../../../images/coupon/used.png'
import unfinishedImg from '../../../images/coupon/nonactivated.png'

export default {
  name: 'CouponItem',
  props: {
    // 票券信息
    info: {
      type: Object,
      require: true
    },
    // 是否需要做任务
    isTask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tipImg: {
        0: unfinishedImg, // 待完成
        1: '', // 未使用
        2: utilizedImg, // 已使用
        3: '' // 已过期
      },
      couponType: {
        1: '兌換券',
        2: '直播限定券'
      }
    }
  },
  computed: {
    beginTime() {
      return dateFormat(this.info.beginTime, 'yyyy.mm.dd')
    },
    endTime() {
      return dateFormat(this.info.endTime, 'yyyy.mm.dd')
    }
  },
  methods: {
    onJump() {
      this.$emit('jump', this.info.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-item.active {
  background: #f08f7a;
  padding: 2px;
  border-radius: 6px;
}

.coupon-item.active .coupon::before {
  background: #f08f7a;
}
.coupon-item.active .coupon::after {
  background: #f08f7a;
}

.coupon-item {
  // width: 343px;
  margin-bottom: 15px;
  .coupon {
    position: relative;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    padding: 14px 12px 9px;
    &::before {
      content: '';
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 7px;
      height: 15px;
      background: #f5f5f5;
      border-radius: 0 7px 7px 0;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 30px;
      right: 0;
      width: 7px;
      height: 15px;
      background: #f5f5f5;
      border-radius: 7px 0 0 7px;
    }
    .c-top {
      display: flex;
      align-items: center;
      position: relative;
      .t-img {
        width: 76px;
        height: 76px;
        background: #d9d9d9;
        border-radius: 4px;
      }
      .t-text {
        font-size: 11px;
        color: #999999;
        line-height: 17px;
        padding-left: 12px;
        .name {
          font-size: 15px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
      .t-tip {
        position: absolute;
        bottom: 0;
        right: -12px;
        width: 60px;
        height: 60px;
      }
    }
    .c-line {
      width: 100%;
      border-bottom: 1px dashed #e1e5e7;
      margin: 14px 0 9px;
    }
    .c-bottom {
      font-size: 11px;
      color: #999999;
      line-height: 18px;
    }
    .c-tip {
      position: absolute;
      top: 0;
      right: 0;
      width: 48px;
      height: 19px;
      background: #f08f7a;
      border-radius: 0px 6px;
      font-size: 11px;
      color: #ffffff;
      line-height: 19px;
      text-align: center;
    }
  }
  .c-task {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    padding: 7px 12px;
  }
}
</style>
