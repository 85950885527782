<template>
  <div class="operation">
    <p class="tip">註銷帳號前，请先进行確認以下說明並同意後再送出</p>
    <div class="user-name">
      <span>帳號</span>
      <span>{{ userName }}</span>
    </div>
    <div class="user-point">
      <span>帳號累積點數</span>
      <span class="num">{{ userPoint }}</span>
    </div>
    <div class="user-why">
      <span>註銷帳號原因</span>
      <div class="selection" @click="onSelect">
        <span>{{ selectName }}</span>
        <img
          src="../../../images/cancellation-selection.svg"
          alt="cancellation-selection"
        />
      </div>
    </div>
    <div class="pay-attention-to">
      <h2>注意事項</h2>
      <p>1. 帳號註銷後即無法復原，您儲值的點數累積，經帳號刪除後，將無法繼續使用。</p>
      <p>
        2.
        您的收藏、訂閱、活動紀錄、已兌換贈品信息，經帳號刪除後，將無法繼續使用。
      </p>
      <p>
        3.
        若您已閱讀完本服務注意事項並同意，請按下「確認送出」，驗證信發送成功後請至信箱完成驗證。
      </p>
    </div>
    <div class="btn">
      <button class="send" @click="onSend">確認送出</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancellationSelect",
  props: {
    userName: String,
    userPoint: String,
    selectName: String,
  },
  data() {
    return {};
  },
  methods: {
    onSelect() {
      this.$emit("onSelect");
    },
    onSend() {
      this.$emit("onSend");
    },
  },
};
</script>

<style lang="scss">
.operation {
  .tip {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 19px;
    padding: 0 14px 8px;
  }
  .user-name {
    display: flex;
    justify-content: space-between;
    padding: 17px 18px 16px 14px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #424242;
    line-height: 22px;
    border-bottom: 1px solid #dcdcdc;
  }
  .user-point {
    display: flex;
    justify-content: space-between;
    padding: 17px 18px 16px 14px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #424242;
    line-height: 22px;
    border-bottom: 1px solid #dcdcdc;
    .num {
      font-weight: 500;
      color: #ef8e7a;
    }
  }
  .user-why {
    display: flex;
    justify-content: space-between;
    padding: 17px 18px 16px 14px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 22px;
    border-bottom: 1px solid #dcdcdc;
    .selection {
      display: flex;
      align-items: center;
      span {
        color: #404040;
        margin-right: 16px;
      }
      img {
        width: 9px;
        height: 15px;
      }
    }
  }
  .pay-attention-to {
    padding: 16px 18px 30px 14px;
    h2 {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ef8e7a;
      line-height: 21px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
    }
  }
  .btn {
    text-align: center;
    .send {
      width: 271px;
      height: 38px;
      background: #0a0a0a;
      border-radius: 19px;
      border: none;
      outline: none;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 4px;
    }
  }
}
</style>
