import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import HomePage from '../src/pages/HomePage'

import ConfirmPage from '../src/pages/ConfirmPage'
import ResetPasswordPage from '../src/pages/ResetPasswordPage'
import ArticleList from '../src/pages/ArticleList'
import RecommendArticle from '../src/pages/RecommendArticle.vue'
// import InfiniteArticles from '../src/pages/InfiniteArticles'
import InfiniteWaterfall from '../src/pages/InfiniteArticles/Waterfall.vue'
// import SearchResult from '../src/pages/SearchResult'
// import SearchResultNew from '../src/pages/SearchResultNew'
import VideoList from '../src/pages/VideoList'
import VideoPage from '../src/pages/VideoPage'
import AuthorList from '../src/pages/AuthorList'
import AuthorPage from '../src/pages/AuthorPage'

// Events
import SpecialList from '../src/pages/Events/SpecialList'
import SpecialCategory from '../src/pages/Events/SpecialCategory'
import LectureList from '../src/pages/Events/LectureList'
import Lecture from '../src/pages/Events/Lecture'
import TrialList from '../src/pages/Events/TrialList'
import Trial from '../src/pages/Events/Trial'
import VoteList from '../src/pages/Events/VoteList'
import Vote from '../src/pages/Events/Vote'

// Member Zone
import DesktopMy from '../src/pages/My'
import MobileMe from '../src/pages/My/Me'
import MyInfo from '../src/pages/My/Info'
import MyNotices from '../src/pages/My/Notices'
import MyAnnounces from '../src/pages/My/AnnouncesList'
import Announce from '../src/pages/My/Announce'
import MyPoints from '../src/pages/My/Points'
import MyPrizes from '../src/pages/My/Prizes'
// import PrizeDetails from '../src/pages/My/PrizeDetails'
import PrizesPlan from '../src/pages/My/PrizesPlan.vue'
import MyCollection from '../src/pages/My/Collection'
import MySubscriptions from '../src/pages/My/Subscriptions'
import MyActivities from '../src/pages/My/Activities'
import MyCancellation from '../src/pages/My/Cancellation.vue'
import MyCancellationSuccess from '../src/pages/My/CancellationSuccess.vue'
import MyCoupon from '../src/pages/My/Coupon.vue'
import MyCouponVerification from '../src/pages/My/CouponVerification.vue'
import MyCouponDetail from '../src/pages/My/CouponDetail.vue'
import MyExchangeRecord from '../src/pages/My/ExchangeRecord.vue'
import MyExchangeRecordDetail from '../src/pages/My/ExchangeRecordDetail.vue'

// import Assistance from '../src/pages/Assistance.vue'
import SiteMap from '../src/pages/SiteMap'
import PrivacyPage from '../src/pages/PrivacyPage'
import PointRules from '../src/pages/PointRules'
import TermsPage from '../src/pages/TermsPage'
import NotFound from '../src/pages/NotFound'
import AboutLookin from '../src/pages/AboutLookin'

import { isLookinApp } from '../tools/bridge'

Vue.use(VueRouter)

// 鉴权
const ifAuthed = function (to, from, next) {
  if (store.getters.isAuthed) {
    // TODO: update Cookie expired time
    next()
    return
  }

  // 这些路由登录成功后需要重新回到这些路径
  if (to.path === '/my/me') {
    next(`/?to=${to.path}`)
  } else {
    next('/')
  }

  // 弹出登录页
  store.dispatch('setLoginDrawer', true)
}

// 重定向到nuxt项目的搜索页
const gotoNuxtSearch = to => {
  const { keyword } = to.query
  location.href = `/nuxt/search?keyword=${keyword || ''}`
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: '【LOOKin】美人時髦話題網 － 女性時尚，流行髮型 · 穿搭 · 彩妝保養教學'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: HomePage,
    meta: { title: '會員登入' }
  },
  {
    path: '/search',
    name: 'search',
    beforeEnter: gotoNuxtSearch
    // component: SearchResultNew
  },
  {
    path: '/searchV2',
    name: 'search-v2',
    beforeEnter: gotoNuxtSearch
    // component: SearchResult
  },
  {
    path: '/hot',
    name: 'hot',
    component: ArticleList,
    meta: { title: '熱門文章' }
  },
  {
    path: '/new',
    name: 'new',
    component: ArticleList,
    meta: { title: '最新文章' }
  },
  {
    path: '/videos',
    name: 'videos',
    component: VideoList,
    meta: { title: 'LOOK V 影音' }
  },
  {
    path: '/videos/:category',
    name: 'videos-category',
    component: VideoList,
    beforeEnter: (to, from, next) => {
      let id = to.params.category
      if (isNaN(id)) {
        next()
      } else {
        next({ name: 'video', params: { id: id, title: to.params.title } })
      }
    }
  },
  {
    path: '/videos/:id',
    name: 'video',
    component: VideoPage
  },
  {
    path: '/authors',
    name: 'authors',
    component: AuthorList,
    meta: { title: '駐站名人' }
  },
  {
    path: '/authors/:slug',
    name: 'author',
    component: AuthorPage
  },
  {
    path: '/specials',
    name: 'specials',
    component: SpecialList,
    meta: { title: '獨家企劃' }
  },
  {
    path: '/specials/:category',
    name: 'specials-category',
    component: SpecialCategory
  },
  {
    path: '/trials',
    name: 'trials',
    component: TrialList,
    meta: { title: '體驗試用' }
  },
  {
    path: '/trials/:id',
    name: 'trial',
    component: Trial
  },
  {
    path: '/lectures',
    name: 'lectures',
    component: LectureList,
    meta: { title: '活動募集' }
  },
  {
    path: '/lectures/:id',
    name: 'lecture',
    component: Lecture
  },
  {
    path: '/votes',
    name: 'votes',
    component: VoteList,
    meta: { title: '網友來作主' }
  },
  {
    path: '/votes/:id',
    name: 'vote',
    component: Vote
  },
  {
    path: '/user/confirmation',
    name: 'confirm',
    component: ConfirmPage,
    meta: { title: 'Email 認證' }
  },
  {
    path: '/verify',
    name: 'verify',
    component: ResetPasswordPage,
    meta: { title: '重設密碼' }
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: SiteMap,
    meta: { title: '網站總覽' }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage,
    meta: { title: '隱私權政策' }
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutLookin,
    meta: { title: '關於我們' }
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsPage,
    meta: { title: '會員條款' }
  },
  {
    path: '/point-rules',
    name: 'point-rules',
    component: PointRules,
    meta: { title: '點數說明' }
  },
  {
    path: '/choc',
    name: 'choc',
    beforeEnter() {
      location.href = 'https://choc.look-in.com.tw/?utm_source=lookin_desktop_menu&utm_medium=lookin_index_to_choc'
    }
  },
  {
    path: '/my',
    name: 'my',
    component: DesktopMy,
    redirect: '/my/notices',
    beforeEnter: ifAuthed,
    children: [
      {
        path: 'me',
        name: 'my_me',
        component: MobileMe,
        meta: { title: '會員專區' }
      },
      {
        path: 'notices',
        name: 'my_notices',
        component: MyNotices,
        meta: { title: '最新消息' }
      },
      {
        path: 'info',
        name: 'my_info',
        component: MyInfo,
        meta: { title: '會員資料' }
      },
      {
        path: 'announces',
        name: 'my_announces',
        component: MyAnnounces,
        meta: { title: '會員公告' }
      },
      {
        path: 'announces/:id',
        name: 'announce',
        component: Announce,
        meta: { title: '會員公告' }
      },
      {
        path: 'points',
        name: 'my_points',
        component: MyPoints,
        meta: { title: '會員積點紀錄' }
      },
      {
        path: 'collection',
        name: 'my_collection',
        component: MyCollection,
        meta: { title: '收藏紀錄' }
      },
      {
        path: 'subscriptions',
        name: 'my_subscriptions',
        component: MySubscriptions,
        meta: { title: '訂閱紀錄' }
      },
      {
        path: 'activities',
        name: 'my_activities',
        component: MyActivities,
        meta: { title: '活動紀錄' }
      },
      {
        path: 'cancellation',
        name: 'my_cancellation',
        component: MyCancellation,
        meta: { title: '註銷帳號' }
      },
      {
        path: 'coupon',
        name: 'my_coupon',
        component: MyCoupon,
        meta: { title: '我的票券' }
      },
      {
        path: 'coupon/verification',
        name: 'my_coupon_verification',
        component: MyCouponVerification,
        meta: { title: '掃碼核銷' }
      },
      {
        path: 'coupon/:id',
        name: 'my_coupon_detail',
        component: MyCouponDetail,
        meta: { title: '票券詳情' }
      },
      {
        path: 'exchange-record',
        name: 'my_exchange_record',
        component: MyExchangeRecord,
        meta: {
          title: '兌換記錄',
          keepAlive: true // 开启组件缓存
        }
      },
      {
        path: 'exchange-record/:id',
        name: 'my_exchange_record_detail',
        component: MyExchangeRecordDetail,
        meta: { title: '兌換記錄' }
      }
    ]
  },
  {
    path: '/prizes',
    name: 'prizes',
    component: MyPrizes,
    meta: { title: '熱點兌換' }
  },
  {
    path: '/prizes/plan',
    name: 'prizes_plan',
    component: PrizesPlan,
    meta: { title: '熱點兌換計劃' }
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: RecommendArticle,
    meta: { title: '推薦' }
  },
  // {
  //   path: '/prizes/:id',
  //   name: 'prizes_details',
  //   component: PrizeDetails,
  //   meta: { title: '商品詳情' }
  // },
  {
    path: '/assistance/:id',
    name: 'assistance',
    component: () => import('../src/pages/Assistance.vue'),
    meta: { title: '一日店長' }
  },
  {
    path: '/cancellation-success',
    name: 'cancellation-success',
    component: MyCancellationSuccess
  },
  {
    path: '/tag/:slug',
    name: 'tag',
    component: ArticleList
  },
  {
    path: '/2022/switchgame',
    name: 'game',
    // meta: { title: 'LOOKin．開運集集樂' },
    component: () => import('../src/pages/H5Game.vue')
  },
  {
    path: '/2023/switchgamehny',
    name: 'game2',
    // meta: { title: 'LOOKin．開運集集樂' },
    component: () => import('../src/pages/H5Game2.vue')
  },
  {
    path: '/2023/dreammall',
    name: 'game3',
    // meta: { title: '【夢時代粉紅野餐集集樂】' },
    component: () => import('../src/pages/H5Game3.vue')
  },
  {
    path: '/2023/teashop-game',
    name: 'game4',
    // meta: { title: '【LOOKin手搖專賣所】玩遊戲賺熱點．美妝好禮等你帶回家' },
    component: () => import('../src/pages/H5Game4.vue')
  },
  {
    path: '/2023/teashop-game_friend',
    name: 'gameFriend',
    meta: { title: '好友邀請' },
    component: () => import('../src/pages/GameFriend.vue')
  },
  {
    path: '/ig/list',
    name: 'ig_list',
    component: () => import('../src/pages/Ig/ig_list.vue'),
    meta: {
      title: 'ig'
    }
  },
  {
    path: '/ig/list/item',
    name: 'ig_info',
    component: () => import('../src/pages/Ig/ig_item.vue'),
    meta: {
      title: 'igItem'
    }
  },
  // {
  //   path: '/fashion/luxury/:id',
  //   name: 'fashion-luxury',
  //   component: InfiniteWaterfall
  // },
  {
    path: '/articles/preview/:id',
    name: 'preview',
    // component: InfiniteArticles
    component: InfiniteWaterfall
  },
  {
    path: '/:channel',
    name: 'channel',
    component: ArticleList
  },
  {
    path: '/:channel/:sub_channel',
    name: 'sub_channel',
    component: ArticleList
  },
  {
    path: '/:channel/:sub_channel/:id',
    name: 'article',
    // component: InfiniteArticles
    component: InfiniteWaterfall
  },
  { path: '*', component: NotFound }
]

if (isLookinApp) {
  const homeIndex = routes.findIndex(v => v.name === 'home')
  if (homeIndex !== -1) {
    routes[homeIndex] = {
      path: '/',
      name: 'home',
      redirect: {
        name: 'recommend'
      }
    }
  }
  const newIndex = routes.findIndex(v => v.name === 'new')
  if (newIndex !== -1) {
    routes[newIndex].meta = { title: '全部文章' }
  }
}
const router = new VueRouter({
  mode: 'history',
  routes,
  beforeRouteEnter(to, from, next) {
    if (to.params.title) {
      to.meta.title = to.params.title
    }
    next()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.title) {
      to.meta.title = to.params.title
    }
    next()
  }
})

router.beforeEach((to, from, next) => {
  store.commit('RESET_ARTICLES')
  if (store.state.notFound404 == true) {
    store.commit('SET_404_NOT_FOUND', false)
  }
  if (store.state.serverError500 == true) {
    store.commit('SET_500_ERROR', false)
  }

  window.scrollTo(0, 0)

  /**
   * 第三方登录新流程
   * url上有token和user_id说明是第三方登录
   *
   */
  const { token = '', user_id = '', toc_user_id = '' } = to.query
  if (token) {
    // const href = window.location.href
    const userId = user_id || toc_user_id
    store.commit('AUTH_SUCCESS', { token, userId })
    if (!user_id) {
      // 添加统计代码，如果有token,但user_id为空，则统计
      try {
        Vue.nextTick(() => {
          setTimeout(() => {
            new TrackEvent().setEvent('not_user_id', 'auto', 'login', '', '')
          }, 1000)
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
  next()
})

export default router
