var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.isMobile ? '' : 'has-background-lookin-bg',attrs:{"id":"hot-issue"}},[_c('div',{ref:"titleRef",staticClass:"home-block-title has-text-centered has-text-black"},[_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"issues"},_vm._l((_vm.hot_issues),function(article,i){return (i < _vm.itemsToShow)?_c('div',{ref:"articleRef",refInFor:true,staticClass:"issue"},[_c('div',{staticClass:"content"},[_c('router-link',{attrs:{"to":{
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          }},nativeOn:{"click":function($event){return _vm.jumpArticle(article.id, i)}}},[_c('LazyImage',{staticClass:"content-img",attrs:{"src":article.image_url}})],1),_vm._v(" "),_c('span',{staticClass:"image-tag eng-font has-text-centered has-text-black has-background-lookin"},[_vm._v("\n          Hot! No."+_vm._s(i + 1)+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"slide-text"},[_c('p',{staticClass:"has-text-centered"},_vm._l((article.tags.slice(0, 3)),function(tag){return _c('router-link',{key:tag,staticClass:"tag",attrs:{"to":{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }}},[_vm._v("#"+_vm._s(tag))])}),1),_vm._v(" "),_c('p',{staticClass:"has-text-lookin-darker has-text-centered"},[_c('router-link',{attrs:{"to":{ name: 'new', query: { category: article.category.path } }}},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))])]),_vm._v(" "),_c('span',{staticClass:"date-author"},[_vm._v(" "+_vm._s(article.published_at)+" ‧ "+_vm._s(article.author_name))])],1),_vm._v(" "),_c('router-link',{attrs:{"to":{
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          }},nativeOn:{"click":function($event){return _vm.jumpArticle(article.id, i)}}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(article.title))])])],1)]):_vm._e()}),0),_vm._v(" "),(_vm.showMore)?_c('button',{staticClass:"load-more has-text-white has-background-black has-text-centered",on:{"click":function($event){_vm.showMore = false}}},[_vm._v("\n    LOAD MORE\n  ")]):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('Advertisement',{attrs:{"id":25,"width":970,"height":250}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_c('span',[_vm._v("H")]),_vm._v("ot Issue"),_c('span',{staticClass:"has-text-lookin-darker"},[_vm._v("熱門話題")])])}]

export { render, staticRenderFns }