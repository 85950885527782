<template>
  <div class="my-header">
    <div v-if="user" class="basic">
      <img class="avatar" :src="user.avatar_url || defaultImg" />
      <div class="info">
        <div class="user-name">{{ user.nickname }}</div>
        <div class="my-points" @click="gotoPoints">
          <span class="point-title">累積點數</span>
          <span class="points has-text-lookin-darker">{{ user.points.toLocaleString('en-US') }}</span>
          <img :src="require('@/../images/arrow-lookin-darker.svg')" class="arrow" />
        </div>
        <div class="expire" v-show="expire_points.expiringSoonPoint">
          您有 <span class="has-text-lookin-darker">{{ expire_points.expiringSoonPoint || 0 }}</span
          >點數將於{{ expire_points.expired_at }}到期，請盡快使用哦！
          <div class="btn-use" @click="$router.push('/prizes')">去使用</div>
        </div>
      </div>
    </div>
    <ul class="my-menu">
      <li v-for="item in menu" :key="item.id">
        <router-link :to="item.path">
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import defaultImg from '../../../images/user_default.png'
import { mapState } from 'vuex'

export default {
  name: 'DesktopMenu',
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultImg
    }
  },
  computed: {
    ...mapState(['expire_points'])
  },
  methods: {
    gotoPoints() {
      this.$router.push({ name: 'my', params: { activePage: 'points' } })
    }
  },
  created() {
    this.$store.dispatch('loadExpirePoints')
  }
}
</script>

<style lang="scss" scoped>
.my-header {
  height: 220px;
  width: 100%;
  padding: 40px;
  background-color: #fff0ee;
  .basic {
    // display: inline-block;
    // position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    // -moz-transform: translateX(-50%);
    // -ms-transform: translateX(-50%);
    // -o-transform: translateX(-50%);
    // -webkit-transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      display: inline-block;
      height: 75px;
      width: 75px;
      margin: 0 10px;
      border-radius: 50%;
    }
    .info {
      .user-name {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1px;
      }
      .my-points {
        cursor: pointer;
        span.point-title {
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          color: #404040;
        }
        span.points {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 1px;
          vertical-align: middle;
        }
        img.arrow {
          height: 8px;
          width: 8px;
          // float: right;
          margin: 10px 8px 0;
        }
      }
      .expire {
        display: flex;
        height: 20px;
        font-weight: 400;
        font-size: 13px;
        color: #0a0a0a;
        line-height: 20px;
        .btn-use {
          margin-left: 8px;
          width: 54px;
          height: 20px;
          font-size: 11px;
          color: #fff;
          text-align: center;
          background: #f08e7a;
          border-radius: 10px;
          user-select: none;
          transition: all 0.2s;
          &:hover {
            cursor: pointer;
            transform: scale(103%);
          }
          &::after {
            content: '\003E';
            margin-left: 4px;
          }
        }
      }
    }
  }
  .my-menu {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    margin: 35px auto 0;
    li {
      a {
        font-size: 16px;
        font-weight: 700;
        color: #4a4a4a;
        padding: 6px 12px;
        &:hover {
          color: #f08f7a;
        }
      }
      a.router-link-active {
        border-bottom: 3px solid #f08f7a;
        color: #000;
      }
    }
  }
}
</style>
