var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"colomn is-one-quarter has-background-lookin-bg",attrs:{"id":"pick-up-desktop"}},[_vm._m(0),_vm._v(" "),(_vm.pick_up)?_c('div',{staticClass:"pick-ups"},[_vm._l((_vm.pick_up),function(article,i){return (i < 5)?[_c('div',{staticClass:"issue"},[_c('div',{staticClass:"recmd-img-container"},[_c('router-link',{attrs:{"to":{
              name: 'article',
              params: {
                channel: article.parent_path,
                sub_channel: article.child_path,
                id: article.id,
                title: article.title
              }
            }}},[_c('LazyImage',{staticClass:"content-img",attrs:{"src":article.image_url}})],1)],1),_vm._v(" "),_c('h3',{staticClass:"has-text-black"},[_c('router-link',{attrs:{"to":{ name: 'new', query: { category: article.category.path } }}},[_c('span',{staticClass:"category",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))])]),_vm._v(" "),_c('router-link',{staticClass:"has-text-black",attrs:{"to":{
              name: 'article',
              params: {
                channel: article.parent_path,
                sub_channel: article.child_path,
                id: article.id,
                title: article.title
              }
            }}},[_vm._v("\n            "+_vm._s(article.title)+"\n          ")])],1)]),_vm._v(" "),(i == 1)?_c('Advertisement',{attrs:{"id":26,"width":300,"height":250}}):_vm._e()]:_vm._e()})],2):_vm._e(),_vm._v(" "),_c('Advertisement',{attrs:{"id":27,"width":300,"height":600}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pick-up-title home-block-title has-text-centered has-text-black"},[_c('h2',[_c('span',[_vm._v("P")]),_vm._v("ick Up"),_c('span',{staticClass:"has-text-lookin-darker"},[_vm._v("特別企劃")])])])}]

export { render, staticRenderFns }