import request from './request.js'

/**
 * 领取票券
 * @param {Number} id h活动id
 */
export function couponReceive(id) {
  return request.post('/shop/coupon/receive', { id })
}

/**
 * 优惠券列表
 * @param {Number} typeId 0 1 未使用；2 已使用； 3 已過期；
 */
export function couponList(typeId) {
  return request.get(`/shop/coupon/list/${typeId}`)
}

/**
 * 优惠券详情信息
 * @param {Number} couponId 票券id
 */
export function couponInfo(couponId) {
  return request.get(`/shop/coupon/info/${couponId}`)
}

/**
 * 答題激活
 * @param {Object} data
 * @param {Number} data.id 票券id
 * @param {Number} data.questionId 问题id
 * @param {Number} data.answerId 答案id
 *
 */
export function couponAnswer(data) {
  return request.put('/shop/coupon/answer', data)
}

/**
 * 用户核销接口
 * @param {Object} data
 * @param {String} data.convertCode 核銷碼
 * @param {String} data.storeCode 商家碼
 */
export function couponUserUse(data) {
  return request.put('/shop/coupon/user/use', data)
}

/**
 * 阅读激活
 * @param {Object} data
 * @param {Number} data.id 票券id
 * @param {Number} data.articleId 文章id
 */
export function couponReading(data) {
  return request.put('/shop/coupon/reading', data)
}

/**
 * 助力激活
 * @param {Object} data
 * @param {Number} data.id 票券id
 * @param {String} data.deviceId 设备id
 */
export function couponHelp(data) {
  return request.put('/shop/coupon/help', data)
}

/**
 * 是否为商家
 */
export function userIsStore() {
  return request.get('/shop/user/isStore')
}

/**
 * 優惠券助力展示
 * @param {Number} couponId 票券id
 */
export function couponAbstractInfo(couponId) {
  return request.get(`/shop/coupon/abstractInfo/${couponId}`)
}
