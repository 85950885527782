<template>
  <section>
    <div v-if="!isLoading" id="top-video" class="has-background-lookin-bg">
      <div id='top-video-container'>
        <div class="content">
          <iframe :src="topVideo.embed_link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <h2 v-if="!isLoading"><span class="has-text-black has-background-nav-bg">{{ topVideo.title }}</span></h2>
    <div class="videos-container">
      <div v-if="!isLoading" class="videos">
        <div class="video-categories">
          <router-link v-for="category in videoCategories"
            :key="category.id" class="category has-text-lookin-darker"
            :to="{ name: 'videos-category', params: { category: category.slug, title: category.title } }"
          >{{ category.title }}</router-link>
        </div>
        <h1><span class="underline">{{ isCategory ? currentCategory.title : 'New! 最新' }}</span></h1>
        <div class="video" v-for="(video, i) in videos">
          <p class="date has-text-lookin-darker">{{ video.published_at }}</p>
          <router-link class='img-container' :to="{ name: 'video', params: { id: video.id, title: video.title } }">
            <img class='cover big-content-img' :src="isMobile ? video.thumbnail : video.hi_res_image">
          </router-link>
          <div class="a-info">
            <h2 class="title has-text-black">
              <router-link :to="{ name: 'video', params: { id: video.id, title: video.title } }">
                {{ video.title }}
              </router-link>
            </h2>
            <p v-if="!isMobile" class="abstract">
              {{ video.abstract }}
            </p>
          </div>
          <Advertisement v-if="isMobile && (i == 2)" :id="37" :width="300" :height="250" />
        </div>
      </div>
    </div>
    <transition name="fade">
      <Loading v-show="isLoading" />
    </transition>
    <Advertisement v-if="!isMobile" :id="30" :width="970" :height="250" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Loading from 'src/components/common/Loading'
import Advertisement from 'src/components/common/Advertisement'

export default {
  data() {
    return {
      channel: '',
      page: 1,
      isLoading: true,
      follow: false,
    };
  },
  components: { Advertisement, Loading },
  computed: {
    ...mapState(['isMobile', 'topVideo', 'videos', 'videoCategories']),
    isCategory() {
      return ('videos-category' === this.$route.name)
    },
    currentCategory: function () {
      return this.videoCategories.find(c => c.slug == this.$route.params.category)
    }
  },
  methods: {},
  mounted() {
    if (this.isCategory) {
      this.$store.dispatch('loadVideos', this.$route.params.category).then(() => {
        this.isLoading = false
      })
    } else {
      this.$store.dispatch('loadVideos').then(() => {
        this.isLoading = false
      })
    }
  },
};
</script>

<style lang="scss">
#top-video {
  width: 100%;
  min-height: 160px;
  #top-video-container {
    max-width: 1200px;
    margin: auto;
    .content {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-bottom: 56.25%;
      margin: 0 auto 10px;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

#top-video + h2 {
  margin: 0 auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  span {
    padding: 3px;
  }
}

@media only screen and (min-width: 700px) {
  #top-video + h2 {
    margin: 10px auto;
    max-width: 1000px;
    font-size: 28px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
  }
}

.videos-container {
  min-height: 100vh;
}

.videos {
  margin: 0 auto;
  max-width: 810px;
  h1 {
    margin: 88px 0 15px;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    & .underline {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        height: 6px;
        background: #FFC2AE;
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: -5px;
        z-index: -1;
      }
    }
  }
  .video-categories {
    margin-top: 30px;
    text-align: center;
    .category {
      display: inline-block;
      height: 32px;
      min-width: 90px;
      padding: 4px 12px;
      margin: 10px 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #F08F7A;
      box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
      border-radius: 50px;
    }
  }
  .video {
    position: relative;
    .date {
      position: absolute;
      top: 0px;
      right: 4vw;
    }
    .cover {
      margin: 20px 14px 0px;
      width: 93vw;
      border: 1px solid #FFC2AE;
    }
    h2.title {
      font-size: 16px;
      line-height: 140%;
      text-align: justify;
      margin: 8px 14px 60px;
      span.category {
        font-size: 13px;
        line-height: 185%;
        letter-spacing: 1px;
        padding: 1px 6px;
      }
      a {
        color: #000000;
      }
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 71%;
      letter-spacing: 1px;
      padding: 5px;
    }
  }
  @media only screen and (min-width: 700px) {
    h1 {
      font-size: 42px;
      line-height: 42px;
      letter-spacing: 3px;
      text-align: center;
    }
    .video {
      .date {
        top: -12px;
        right: -10px;
      }
      p {
        font-size: 16px;
      }
      h2.title {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .videos-container {
    width: 1000px;
    margin: 10px auto;
    .videos {
      max-width: 960px;
      .video-categories {
        .category {
          height: 34px;
          padding: 5px 30px;
          margin: 12px 20px;
        }
      }
      .video {
        display: flex;
        justify-content: space-between;
        height: 320px;
        max-width: 1000px;
        margin: 50px auto;
        .date {
          top: 0px;
        }
        a.img-container {
          flex-grow: 0;
          flex-shrink: 0;
          max-width: 480px;
          img.cover {
            margin: 10px 0;
            width: 480px;
            height: auto;
            object-fit: cover;
          }
        }
        .a-info {
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;
          max-width: 450px;
          padding: 15px 0;
          h2.title {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            text-align: justify;
            margin: 36px 0 28px;
          }
          .abstract {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: justify;
            padding: 0;
            color: #989595;
            display: -webkit-box;
            overflow : hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
