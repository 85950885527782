<template>
  <div>
    <nav v-if="!inMemberZone" class="navbar">
      <div class="join-us">
        <label class="switch" for="join-us-switch">
          <img id="join-share" :src="require('@/../images/link.svg')" alt="JoinUs" />
          <span>加入我們</span>
        </label>
        <input type="checkbox" id="join-us-switch" style="display: none" />

        <a
          href="https://www.youtube.com/channel/UC9FymR5DhAubxbjWM9JmS_Q?sub_confirmation=1"
          target="_blank"
          class="join-link has-background-nav-bg"
        >
          <img :src="require('@/../images/youtube.svg')" alt="youtube" />
        </a>
        <a href="https://www.facebook.com/lookin.tw/" target="_blank" class="join-link has-background-nav-bg">
          <img :src="require('@/../images/facebook.svg')" alt="facebook" />
        </a>
        <a href="https://www.instagram.com/ilovelookin/" target="_blank" class="join-link has-background-nav-bg">
          <img :src="require('@/../images/instagram.svg')" alt="instagram" />
        </a>
        <a href="https://line.me/R/ti/p/@tci1308w" target="_blank" class="join-link has-background-nav-bg">
          <img :src="require('@/../images/line.svg')" alt="line" />
        </a>
      </div>
      <div id="desktop-big-logo">
        <router-link v-if="logos" :to="{ name: 'home' }" class="logo">
          <img :src="logos.desktop_url" width="415" height="78" alt="LOOKin" />
        </router-link>
      </div>
      <div v-if="user" class="me">
        <div class="my">
          <router-link :to="{ name: 'my' }">
            <img class="avatar" :src="user.avatar_url || require('@/../images/user_default.png')" />
          </router-link>
          <span class="nickname">{{ user.nickname }}</span>
          <img :src="require('@/../images/arrow.svg')" class="black-arrow" />
          <div class="menu-wrapper">
            <div class="arrow"></div>
            <div class="menu has-background-nav-bg">
              <router-link
                :key="item.id"
                class="item-link has-text-black"
                :class="item.class"
                v-for="item in desktopMenu"
                :to="item.path"
              >
                {{ item.title }}
              </router-link>
              <b-button rounded @click="logout">登出</b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="login-btn" @click="setLoginDrawer(true)">會員登入/註冊</div>
    </nav>

    <nav
      id="channel-list"
      v-if="channels"
      :style="{ top: showYellowTip ? '40px' : '0' }"
      :class="fixed || inMemberZone ? 'fixed-header' : ''"
    >
      <router-link :to="{ name: 'home' }" id="small-logo">
        <img :src="require('@/../images/logo_small.svg')" alt="LOOKin" />
      </router-link>
      <template v-for="parent in menuItems">
        <div class="main-channel" :key="parent.id">
          <router-link
            :to="{
              name: parent.name,
              params: { title: parent.title, channel: parent.name == 'channel' ? parent.slug : null }
            }"
            :target="parent.external ? '_blank' : '_self'"
            class="menu-link has-text-black"
          >
            {{ parent.title }}
          </router-link>
          <template v-if="parent.channels">
            <div class="submenu-wrapper">
              <div class="arrow"></div>
              <div class="submenu">
                <router-link
                  v-for="subChannel in parent.channels"
                  :key="subChannel.path"
                  :to="{
                    name: subChannel.name,
                    params: { channel: parent.slug, sub_channel: subChannel.slug, title: subChannel.title }
                  }"
                  :target="subChannel.external ? '_blank' : '_self'"
                  class="item-link has-text-black"
                >
                  {{ subChannel.title }}
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </template>
      <div class="search-btn">
        <img
          @click.stop="openDesktopSearchDrawer = !openDesktopSearchDrawer"
          :src="require('@/../images/icon-search.svg')"
          alt="Search"
          class="search-sm"
        />
      </div>
      <div v-if="user && (fixed || inMemberZone)" @click.stop="userNoticePage" class="notice-btn">
        <img :src="require('@/../images/notice.svg')" alt="最新消息" />
        <span v-if="user.notices_count > 0" class="has-background-lookin-darker">{{ user.notices_count }}</span>
      </div>
      <div v-if="user" class="me">
        <div class="my">
          <router-link :to="{ name: 'my' }">
            <img class="avatar" :src="user.avatar_url || require('@/../images/user_default.png')" />
          </router-link>
          <div class="menu-wrapper">
            <div class="arrow"></div>
            <div class="menu has-background-nav-bg">
              <router-link
                :key="item.id"
                class="item-link has-text-black"
                :class="item.class"
                v-for="item in desktopMenu"
                :to="item.path"
              >
                {{ item.title }}
              </router-link>
              <b-button rounded @click="logout">登出</b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="login-btn" @click="setLoginDrawer(true)">
        <img :src="require('@/../images/user.svg')" alt="User" />
      </div>
    </nav>
    <transition name="fade">
      <DesktopSearchDrawer v-if="openDesktopSearchDrawer" @closeDrawer="openDesktopSearchDrawer = false" />
    </transition>
    <Modal
      :open="logoutModal"
      :title="'成功登出'"
      :content="'下次再回來喔～'"
      :btnWord="'好的'"
      @close="logoutModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DesktopSearchDrawer from '../DesktopSearchDrawer'
import Modal from '../../dialog/Modal'

export default {
  name: 'Desktop',
  data() {
    return {
      openDesktopSearchDrawer: false,
      limitPosition: 140,
      fixed: false,
      logoutModal: false,
      mainItems: [
        { id: 4, title: 'LOOK V 影音', name: 'videos' },
        { id: 6, title: '駐站名人', name: 'authors' },
        {
          id: 8,
          title: '活動專區',
          path: '',
          channels: [
            { title: '獨家企劃', name: 'specials' },
            { title: '網友來做主', name: 'votes' },
            { title: '體驗試用', name: 'trials' },
            { title: '活動募集', name: 'lectures' }
          ]
        }, // NOTE: external real link in router
        { id: 10, title: '線上電子雜誌', name: 'choc', external: true },
        { id: 11, title: '熱點兌換', name: 'prizes' }
      ],
      myItems: [
        { title: '會員資料', page: 'info' },
        { title: '會員公告', page: 'announces' },
        { title: '積點紀錄', page: 'points', class: 'light' },
        // { title: '熱點兌換', page: 'prizes', class: 'light' },
        { title: '收藏紀錄', page: 'collection', class: 'light' },
        { title: '訂閱紀錄', page: 'subscriptions', class: 'light' },
        { title: '活動紀錄', page: 'activities', class: 'light' }
      ]
    }
  },
  components: { DesktopSearchDrawer, Modal },
  computed: {
    ...mapState(['channels', 'user', 'memberZoneItems', 'desktopMenu', 'logos', 'showYellowTip']),
    inMemberZone() {
      return this.$route.path.startsWith('/my')
    },
    menuItems() {
      return this.channels.concat(...this.mainItems)
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer']),
    handleScroll() {
      if (this.limitPosition < window.scrollY) {
        this.fixed = true // move up!
      } else {
        this.fixed = false // move down
      }
    },
    userNoticePage() {
      this.$router.push({ name: 'my', params: { activePage: 'notices' } })
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.logoutModal = true
      })
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    const { key } = this.$route.query
    if (key === 'wlog') {
      this.setLoginDrawer(true)
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
header .navbar {
  height: 140px;
  z-index: 33 !important;
  #desktop-big-logo {
    margin: 35px 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .join-us {
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    height: 28px;
    color: black;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    text-decoration: none;
    outline: 0;
    padding: 20px;
    display: flex;
    #join-share {
      width: 25px;
    }
    .switch {
      cursor: pointer;
      justify-content: center;
      display: flex;
    }
    img + span {
      margin-left: 12px;
    }
    input[type='checkbox']:checked ~ .join-link {
      opacity: 1;
    }
    .join-link {
      width: 30px;
      height: 30px;
      padding: 5px;
      border: 1px #000000 solid;
      border-radius: 50%;
      text-align: center;
      line-height: 10px;
      margin: 6px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      img {
        line-height: 30px;
        vertical-align: middle;
      }
    }
  }
  .login-btn {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
    width: 130px;
    height: 27px;
    border: 1px solid #404040;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
  }
}

.me {
  position: absolute;
  right: 20px;
  top: 20px;
  .my {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    .nickname {
      margin: 6px;
      width: 51px;
      height: 24px;
      overflow: hidden;
    }
    .black-arrow {
      height: 8px;
      transform: rotate(90deg);
    }
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .menu-wrapper {
      position: absolute;
      top: 32px;
      left: -53px;
      padding-top: 15px;
      display: none;
      .menu {
        top: 30px;
        min-width: 135px;
        border-top: 3px solid #ffdbcf;
        background-color: #fff;
        text-align: center;
        a {
          display: block;
          border-top-style: none;
          font-size: 14px;
          font-weight: 400;
          line-height: 36px;
          letter-spacing: 1px;
          text-align: center;
        }
        .light {
          background-color: rgba(255, 255, 255, 0.4);
        }
        a:hover {
          background-color: #fbf2ed;
        }
        button {
          height: 26px;
          margin: 5px;
          border-color: #ffffff;
          span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 1px;
          }
        }
      }
    }
    &:hover {
      > a {
        font-weight: 600;
        color: #f08f7a !important;
      }
      .menu-wrapper {
        display: block;
        .arrow:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #ffdbcf;
          left: 60px;
          top: 7px;
        }
      }
    }
  }
}

nav#channel-list {
  z-index: 30;
  height: 60px;
  width: 100%;
  background: white;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  width: 100%;
  #small-logo {
    display: none;
  }
  .main-channel {
    position: relative;
    display: inline-block;
    padding: 8px 1.3%;
    height: 16px;
    line-height: 0px;
    .submenu-wrapper {
      position: absolute;
      top: 17px;
      left: -2px;
      padding-top: 15px;
      display: none;
      z-index: 10;
      .submenu {
        top: 30px;
        min-width: 105px;
        filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.6));
        border-top: 3px solid #ffdbcf;
        background-color: #fff;
        a {
          display: block;
          border: 1px solid #ffdbcf;
          border-top-style: none;
          font-size: 14px;
          font-weight: 400;
          line-height: 37px;
          letter-spacing: 1px;
          text-align: center;
        }
        a:hover {
          background-color: #ffdbcf;
        }
      }
    }
    &:hover {
      > a {
        font-weight: 600;
        color: #f08f7a !important;
      }
      .submenu-wrapper {
        display: block;
        .arrow:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #ffdbcf;
          left: 45px;
          top: 7px;
        }
      }
    }
  }

  .main-channel:nth-child(n + 3) {
    border-left: 1px solid #000;
  }
  .search-btn {
    margin-left: 30px;
    img.search-sm {
      margin-top: -8px;
      cursor: pointer;
    }
  }
}

nav#channel-list.fixed-header {
  position: fixed;
  top: 0;
  #small-logo {
    display: block;
    width: 125px;
    margin: -8px 100px 0 0;
  }
  .search-btn {
    img.search-sm {
      margin-top: -3px;
      width: 23px;
    }
  }
  .notice-btn {
    position: relative;
    margin-left: 20px;
    img {
      width: 25px;
      margin-top: -3px;
      cursor: pointer;
    }
    span {
      position: absolute;
      top: -10px;
      right: -10px;
      min-width: 16px;
      height: 16px;
      padding: 3px;
      cursor: pointer;
      color: white;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 11px;
      text-align: center;
      border-radius: 16px;
      box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
    }
  }
  .me {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    margin: -3px 20px 0;
    .my {
      display: block;
      cursor: pointer;
      .avatar {
        width: 23px;
        height: 23px;
        border-radius: 50%;
      }
    }
  }
  .login-btn {
    cursor: pointer;
    width: 23px;
    margin: -4px 20px 0;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
#channel-list::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
#channel-list {
  height: 40px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
