<template>
  <div class="video-task">
    <video ref="videoRef" class="t-video" :src="task.videoUrl"></video>
    <!-- <iframe ref="iframeRef" class="t-video" :src="videoUrl" frameborder="0"></iframe> -->
    <div class="t-tip" @click="handleTip">
      <span v-if="currentTime >= task.viewingTime">關閉視頻</span>
      <template v-else>
        <img src="../../../images/exchange/gift.svg" alt="" />
        <span>獎勵倒數{{ time }}s</span>
      </template>
      <!-- <img v-if="time === 0" class="t-close" src="../../../images/point/close.svg" alt="" @click="handleClose" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['task'],
  data() {
    return {
      time: this.task.viewingTime,
      timeId: null,
      currentTime: 0
    }
  },
  mounted() {
    if (this.$refs.videoRef) {
      this.$refs.videoRef.addEventListener('timeupdate', this.handleTimeUpdate)
      this.$refs.videoRef.addEventListener('play', () => {
        // 开始倒计时
        this.countdown()
      })
      this.$refs.videoRef.play()
    }
  },
  beforeDestroy() {
    this.$refs.videoRef.removeEventListener('timeupdate', this.handleTimeUpdate)
  },
  methods: {
    countdown() {
      if (!this.time) return
      this.timeId = setInterval(() => {
        if (this.time === 0) {
          clearInterval(this.timeId)
        } else {
          this.time--
        }
      }, 1000)
    },
    handleTimeUpdate() {
      this.currentTime = this.$refs.videoRef.currentTime
    },
    handleTip() {
      if (this.time === 0) {
        this.$emit('close', this.currentTime)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-task {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 999;
  .t-video {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 240px;
    transform: translateY(-50%);
  }
  .t-tip {
    position: absolute;
    top: 61px;
    left: 15px;
    height: 26px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 13px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #ffffff;
    line-height: 26px;
    justify-content: center;
    padding: 0 4px;
    span {
      margin: 0 6px;
      cursor: pointer;
    }
    .t-close {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1200px) {
  .video-task {
    width: 1298px;
    height: 729px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .t-video {
      height: 100%;
    }
    .t-tip {
      left: unset;
      right: 15px;
    }
  }
}
</style>
