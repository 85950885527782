export function categoryClass(id) {
  return 'cat-' + id
}

export function resizeFont(content, delta) {
  let nodes = content.children
  for(let i = 0; i < nodes.length; i++) {
    if (!!nodes[i].style && nodes[i].style.fontSize != '') {
      let newSize = parseInt(nodes[i].style.fontSize) + delta + 'px'
      nodes[i].style.fontSize = newSize
    }
    if (nodes[i].children.length > 0) {
      resizeFont(nodes[i], delta)
    }
  }
}

export function fullTitle(title) {
  return `${title} － 【LOOKin】美人時髦話題網`
}

export function isOngoing(ended_time) {
  let today = new Date()
  return Date.parse(ended_time) > today
}
