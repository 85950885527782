<template>
  <div class="coupon-title">
    <p class="t-title">{{ info.content }}</p>
    <div class="t-option">
      <div class="t-radio" v-for="item in info.answerVos" :key="item.id">
        <div
          class="advice"
          :class="item.trueOption ? 'correct' : ''"
          @click="handleOption($event, item.id)"
          ref="optionRef"
        >
          <div>{{ item.option }}</div>
          <div class="correct-tip">
            <span>答對啦</span>
            <span class="icon"></span>
          </div>
          <div class="incorrect-tip">
            <span>答錯啦</span>
            <span class="icon"></span>
          </div>
        </div>
        <!-- <input
          type="radio"
          name="title"
          :id="`title${item.id}`"
          v-model="titleModel"
          :value="item.option"
          checked
          hidden
          :disabled="disabled"
        />
        <label :for="`title${item.id}`" class="advice" :class="item.trueOption ? 'correct' : 'incorrect'">
          <div>{{ item.option }}</div>
          <div class="tip">
            <span>{{ item.trueOption ? '答對啦' : '答錯啦' }}</span>
            <span class="icon"></span>
          </div>
        </label> -->
      </div>
      <b-button class="t-btn" type="is-primary" rounded expanded v-if="isAgain" @click="handleAgain">
        再來一次
      </b-button>
    </div>
  </div>
</template>

<script>
import { couponAnswer } from '../../../api/storeManager'
export default {
  name: 'CouponTitle',
  props: {
    // 票券id
    couponId: {
      type: Number,
      default: 0
    },
    // 票券信息
    info: {
      type: Object,
      require: true,
      default: () => {}
    }
  },
  data() {
    return {
      // 是否禁用选择
      flag: false,
      isAgain: false
    }
  },
  created() {
    if (Array.isArray(this.info.answerVos)) {
      this.info.answerVos.forEach(item => {
        // trueOption为真 证明已激活 则不能点击选择
        if (item.trueOption) {
          this.flag = true
        }
      })
    }
  },
  methods: {
    // 点击选项
    async handleOption(e, answerId) {
      if (this.flag) {
        return
      }

      const params = {
        id: this.couponId,
        questionId: this.info.id,
        answerId
      }
      const { code, message } = await couponAnswer(params)
      this.flag = true
      if (code === 200) {
        // 回答正确 刷新详情接口
        this.$emit('reloadDetail')
        this.isAgain = false
      } else if (code === -1) {
        // 回答错误 先清除状态
        e.target.classList.remove('correct', 'incorrect')
        e.target.classList.add('incorrect')
        this.isAgain = true
      } else {
        this.$buefy.toast.open({
          type: 'is-danger',
          message
        })
      }
    },
    handleAgain() {
      // 取消限制点击和清空样式
      this.flag = false
      this.$refs.optionRef.forEach(el => {
        el.classList.remove('correct', 'incorrect')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-title {
  .t-title {
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    margin-top: 15px;
  }
  .t-option {
    padding: 0 15px;
    .t-radio {
      margin-top: 20px;
      .advice {
        width: 100%;
        height: 40px;
        background: #f4f6f8;
        border-radius: 48px;
        font-size: 15px;
        color: #333333;
        line-height: 40px;
        padding: 0 18px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .correct-tip,
        .incorrect-tip {
          display: none;
        }
        &.correct {
          background: rgba(35, 193, 108, 0.1);
          border: 1px solid #23c16c;
          color: #23c16c;
          .correct-tip {
            display: block;
            .icon {
              margin-left: 5px;
              &::before {
                content: '\2713';
                color: #23c16c;
              }
            }
          }
        }
        &.incorrect {
          background: rgba(253, 136, 116, 0.1);
          border: 1px solid #f08f7a;
          color: #f08f7a;
          .incorrect-tip {
            display: block;
            .icon {
              margin-left: 5px;
              &::before {
                content: '\2716';
                color: #f08f7a;
              }
            }
          }
        }
      }
      // input[type='radio']:checked + .correct {
      //   background: rgba(35, 193, 108, 0.1);
      //   border: 1px solid #23c16c;
      //   color: #23c16c;
      //   .tip {
      //     display: block;
      //     .icon {
      //       margin-left: 5px;
      //       &::before {
      //         content: '\2713';
      //         color: #23c16c;
      //       }
      //     }
      //   }
      // }
      // input[type='radio']:checked + .incorrect {
      //   background: rgba(253, 136, 116, 0.1);
      //   border: 1px solid #f08f7a;
      //   color: #f08f7a;
      //   .tip {
      //     display: block;
      //     .icon {
      //       margin-left: 5px;
      //       &::before {
      //         content: '\2716';
      //         color: #f08f7a;
      //       }
      //     }
      //   }
      // }
    }
    .t-btn {
      background-color: #f08f7a;
      color: #ffffff;
      margin-top: 18px;
    }
  }
}
</style>
