/* eslint-disable no-underscore-dangle */
import api from '@/../api'
import * as types from './mutation-types'
import router from '@/../router'
import { getPrizeInfo } from '../api/prize'
import { setAppRecordFCM } from '../tools/bridge'

export const setMobile = ({ commit }, value) => {
  commit(types.SET_Mobile, value)
}

export const setLoginDrawer = ({ commit }, value) => {
  commit(types.SET_LOGIN_DRAWER, value)
}

export const setSignUpDrawer = ({ commit }, value) => {
  commit(types.SET_SIGNUP_DRAWER, value)
}

export const setForgetPasswordDrawer = ({ commit }, value) => {
  commit(types.SET_FORGET_PASSWORD_DRAWER, value)
}

export const loadHome = ({ commit }) => {
  return api.getHome().then(response => commit(types.GET_HOMEPAGE, response.data))
}

export const loadHomeOthers = ({ commit }) => {
  return api.getHomeOthers().then(response => commit(types.GET_HOMEOTHERS, response.data))
}

export const loadEssentials = ({ commit }) => {
  return api.getEssentials().then(response => commit(types.GET_ESSENTIALS, response.data))
}

export const loadChannelArticles = ({ commit, state }) => {
  let channel = router.currentRoute.params.sub_channel
    ? router.currentRoute.params.sub_channel
    : router.currentRoute.params.channel
  let params = Object.assign({ channel: channel }, router.currentRoute.query)
  if (state.articles) {
    params = Object.assign({ page: state.current_page + 1 }, params)
  }
  return api.getChannel(params).then(response => {
    commit(types.GET_A_CHANNEL, response.data)
    return response
  })
}

export const setCurrentChannel = ({ commit }, channel) => {
  commit(types.SET_CURRENT_CHANNEL, channel)
}

export const loadArticles = ({ commit, state }) => {
  var params = router.currentRoute.query
  if (router.currentRoute.name == 'hot') {
    params = Object.assign({ hot: true }, params)
  }
  if (state.articles) {
    params = Object.assign({ page: state.current_page + 1 }, params)
  }
  return api.getArticles(params).then(response => {
    commit(types.RESET_CURRENT_CHANNEL)
    commit(types.GET_ARTICLES, response.data)
    return response
  })
}

export const loadArticle = ({ commit, state }) => {
  let merged_params = Object.assign({ id: router.currentRoute.params.id }, router.currentRoute.query)
  merged_params = state.token ? Object.assign({ auth_token: state.token }, merged_params) : merged_params
  return api.getArticle(merged_params).then(response => commit(types.GET_AN_ARTICLE, response.data))
}

export const previewArticle = ({ commit, state }, payload) => {
  let merged_params = Object.assign(payload, { id: router.currentRoute.params.id })
  return api.preview(merged_params).then(response => commit(types.GET_AN_ARTICLE, response.data))
}

export const loadRelatedArticles = ({ commit, state }) => {
  const params = state.token
    ? Object.assign({ auth_token: state.token }, router.currentRoute.params)
    : router.currentRoute.params
  return api.getRelatedArticles(params).then(response => {
    commit(types.RESET_CURRENT_CHANNEL)
    commit(types.GET_RELATED_ARTICLES, response.data)
    try {
      // 推荐文章的加载次数
      const count = response.data.length
      new TrackEvent().setEvent('recommen_article_record', 'recommend', params.id, count, null)
    } catch (error) {
      console.log(error)
    }
  })
}

export const submitArticleEventForm = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  return new Promise((resolve, reject) => {
    api
      .applyArticleEvent(params)
      .then(response => {
        commit(types.SET_ARTICLE_EVENT_PARTICIPATION, payload.article_id)
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadRecommends = ({ commit }) => {
  return api.getRecommends().then(response => commit(types.GET_RECOMMENDS, response.data))
}

export const loadVideos = ({ commit }, category) => {
  return api.getVideos({ category: category }).then(response => commit(types.GET_VIDEOS, response.data))
}

export const loadVideo = ({ commit }) => {
  return api.getVideo(router.currentRoute.params).then(response => commit(types.GET_A_VIDEO, response.data))
}

export const loadAnnounces = ({ commit, state }) => {
  const params = Object.assign({ auth_token: state.token }, router.currentRoute.params)
  return api.getAnnounces(params).then(response => commit(types.GET_ANNOUNCES, response.data))
}

export const loadAnnounce = ({ commit, state }) => {
  const params = { id: router.currentRoute.params.id, auth_token: state.token }
  return api.getAnnounce(params).then(response => commit(types.GET_AN_ANNOUNCE, response.data))
}

export const loadPrizes = ({ commit, state }) => {
  // return api.getPrizes().then(response => commit(types.GET_PRIZES, response.data.result))
  // const params = Object.assign({ auth_token: state.token }, router.currentRoute.params)
  // return api.getPrizes(params).then(response => commit(types.GET_PRIZES, response.data))

  // 没有登录传-1， 已登录传token
  const params = state.token ? state.token : '-1'
  return api.getPrizes(params).then(response => commit(types.GET_PRIZES, response.data.result))
}

export const exchangePrize = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  return new Promise((resolve, reject) => {
    api
      .exchangePrize(params)
      .then(response => {
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadPointRecords = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  // return api.getPoints(params).then(response => commit(types.GET_POINT_RECORDS, response.data))
  return api.getPointList(params).then(response => commit(types.GET_POINT_RECORDS, response.data.result.list))
}

export const loadEventRecords = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  return api.getEventRecords(params).then(response => commit(types.GET_EVENT_RECORDS, response.data))
}

export const loadArticleComments = ({ commit }, payload) => {
  return api.getComments(payload).then(response => {
    const data = { id: payload.article_id, data: response.data }
    commit(types.GET_ARTICLE_COMMENTS, data)
  })
}

export const loadTrialComments = ({ commit }, payload) => {
  return api.getComments(payload).then(response => {
    commit(types.GET_TRIAL_COMMENTS, response.data)
  })
}

export const loadLectureComments = ({ commit }, payload) => {
  return api.getComments(payload).then(response => {
    commit(types.GET_LECTURE_COMMENTS, response.data)
  })
}

export const loadVoteComments = ({ commit }, payload) => {
  return api.getComments(payload).then(response => {
    commit(types.GET_VOTE_COMMENTS, response.data)
  })
}

export const sendComment = ({ commit, state }, commentFormData) => {
  return new Promise((resolve, reject) => {
    api
      .createComment(commentFormData)
      .then(response => {
        let payload = { response: response.data.comment, comment_id: commentFormData.get('comment_id') }
        if (commentFormData.get('article_id') != undefined) {
          payload = Object.assign({ article_id: commentFormData.get('article_id') }, payload)
          commit(types.SET_ARTICLE_COMMENT, payload)
        } else if (commentFormData.get('trial_item_id') != undefined) {
          payload = Object.assign({ trial_item_id: commentFormData.get('trial_item_id') }, payload)
          commit(types.SET_TRIAL_ITEM_COMMENT, payload)
        } else if (commentFormData.get('lecture_id') != undefined) {
          commit(types.SET_LECTURE_COMMENT, payload)
        } else if (commentFormData.get('vote_id') != undefined) {
          commit(types.SET_VOTE_COMMENT, payload)
        }
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const deleteComment = ({ commit, state }, payload) => {
  const params = { id: payload.id, auth_token: state.token }
  return api.deleteComment(params).then(response => {
    commit(types.DELETE_COMMENT, payload)
  })
}

export const loadSpecials = ({ commit }) => {
  return api.getSpecials().then(response => commit(types.GET_SPECIALS, response.data))
}

export const loadTrials = ({ commit }) => {
  return api.getTrials().then(response => commit(types.GET_TRIALS, response.data))
}

export const loadTrial = ({ commit, state }) => {
  let params = { id: router.currentRoute.params.id }
  let merged_params = state.token !== '' ? Object.assign({ auth_token: state.token }, params) : params
  return api.getTrial(merged_params).then(response => commit(types.GET_A_TRIAL, response.data))
}

export const sumbitTrialItemForm = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  return new Promise((resolve, reject) => {
    api
      .applyTrial(params)
      .then(response => {
        commit(types.SET_TRIAL_PARTICIPATION)
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadLectures = ({ commit }) => {
  return api.getLectures().then(response => commit(types.GET_LECTURES, response.data))
}

export const loadLecture = ({ commit, state }) => {
  let params = { id: router.currentRoute.params.id }
  let merged_params = state.token !== '' ? Object.assign({ auth_token: state.token }, params) : params
  return api.getLecture(merged_params).then(response => commit(types.GET_A_LECTURE, response.data))
}

export const applyLectureSession = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  return new Promise((resolve, reject) => {
    api
      .applyLecture(params)
      .then(response => {
        commit(types.SET_LECTURE_PARTICIPATION)
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadVotes = ({ commit }) => {
  return api.getVotes().then(response => commit(types.GET_VOTES, response.data))
}

export const loadVote = ({ commit, state }) => {
  var params = { id: router.currentRoute.params.id }
  if (state.token) {
    params = Object.assign({ auth_token: state.token }, params)
  }
  return api.getVote(params).then(response => commit(types.GET_A_VOTE, response.data))
}

export const voteFor = ({ commit, state }, option_id) => {
  const params = {
    id: router.currentRoute.params.id,
    auth_token: state.token,
    option_id: option_id
  }
  return new Promise((resolve, reject) => {
    api
      .vote(params)
      .then(response => {
        commit(types.VOTE_OPTION, response.data)
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadTagArticles = ({ commit, state }) => {
  var params = { tag: router.currentRoute.params.slug }
  if (state.articles) {
    params = Object.assign({ page: state.current_page + 1 }, params)
  } else {
    commit(types.RESET_CURRENT_CHANNEL)
  }
  return api.getTag(params).then(response => {
    commit(types.GET_ARTICLES, response.data)
    return response
  })
}

export const loadSearchResult = ({ commit, state }) => {
  var params = router.currentRoute.query
  if (state.articles) {
    params = Object.assign({ page: state.current_page + 1 }, router.currentRoute.query)
  }
  return api.search(params).then(response => commit(types.GET_ARTICLES, response.data))
}

export const verifyRecaptcha = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .recaptcha(payload)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const login = ({ commit }, user) => {
  return new Promise((resolve, reject) => {
    api
      .login(user)
      .then(response => {
        localStorage.setItem('auth-token', response.data.auth_token)
        $cookies.set('user_id', response.data.id)
        // == Add Begin ============================================================
        $cookies.set('toc_user_id', response.data.id)
        setAppRecordFCM(response.data.id)
        // == Add End   ============================================================
        commit(types.LOGIN_SUCCESS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const signUp = ({ commit }, newUser) => {
  return new Promise((resolve, reject) => {
    api
      .signup(newUser)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const confirmEmail = ({ commit }, token) => {
  return new Promise((resolve, reject) => {
    api
      .confirmEmail(token)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const logout = ({ commit }) => {
  // TODO:
  // return api.logout(token)
  //           .then(response => commit(types.LOGOUT))
  return new Promise((resolve, reject) => {
    commit(types.LOGOUT)
    if (router.currentRoute.path.startsWith('/my')) {
      router.push({ name: 'home' })
    }
    resolve()
  })
}

export const loadUserProfile = ({ commit, state }) => {
  return api.getProfile(state.token).then(response => commit(types.GET_PROFILE, response.data))
}

export const updateProfile = ({ commit }, user) => {
  return new Promise((resolve, reject) => {
    api
      .updateProfile(user)
      .then(response => {
        commit(types.GET_PROFILE, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

// Reset Password Step #1
export const forgetPassword = ({ commit }, email) => {
  return new Promise((resolve, reject) => {
    api
      .forgetPassword(email)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

// Reset Password Step #2
export const resetTokenVerify = ({ commit }, token) => {
  return new Promise((resolve, reject) => {
    api
      .resetTokenVerify(token)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

// Reset Password Step #3
export const resetPassword = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    api
      .resetPassword(params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const updatePassword = ({ commit, state }, payload) => {
  const params = Object.assign({ auth_token: state.token }, payload)
  return new Promise((resolve, reject) => {
    api
      .updatePassword(params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadCollections = ({ commit, state }) => {
  return api.getCollections(state.token).then(response => commit(types.GET_COLLECTIONS, response.data))
}

export const addBookmark = ({ commit, state }, article_id) => {
  const params = { article_id: article_id, auth_token: state.token }
  return new Promise((resolve, reject) => {
    api
      .addCollection(params)
      .then(response => {
        commit(types.ADD_COLLECTION, article_id)
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const removeBookmark = ({ commit, state }, article_id) => {
  const params = { article_id: article_id, auth_token: state.token }
  return new Promise((resolve, reject) => {
    api
      .removeCollection(params)
      .then(response => {
        commit(types.REMOVE_COLLECTION, article_id)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadSubscriptions = ({ commit, state }) => {
  return api.getSubscriptions(state.token).then(response => commit(types.GET_SUBSCRIPTIONS, response.data))
}

export const follow = ({ commit, state }, payload) => {
  const params = { id: payload.author_id, auth_token: state.token }
  return new Promise((resolve, reject) => {
    api
      .addSubscription(params)
      .then(response => {
        commit(types.ADD_SUBSCRIPTION, payload)
        commit(types.SET_POINTS, response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const unfollow = ({ commit, state }, payload) => {
  const params = { id: payload.author_id, auth_token: state.token }
  return new Promise((resolve, reject) => {
    api
      .removeSubscription(params)
      .then(response => {
        commit(types.REMOVE_SUBSCRIPTION, payload)
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const loadAuthors = ({ commit, state }) => {
  const params = state.token
    ? Object.assign({ auth_token: state.token }, router.currentRoute.params)
    : router.currentRoute.params
  return api.getAuthors(params).then(response => commit(types.GET_AUTHORS, response.data))
}

export const loadAuthor = ({ commit, state }, queryParams) => {
  const params = state.token
    ? Object.assign({ auth_token: state.token }, router.currentRoute.params, queryParams)
    : Object.assign(router.currentRoute.params, queryParams)
  return api.getAuthor(params).then(response => commit(types.GET_AN_AUTHOR, response.data.result))
}

export const loadNotices = ({ commit }, payload) => {
  const params = payload
  return api.getNotices(params).then(res => {
    if (res?.code === 200) {
      commit(types.GET_NOTICES, res?.result)
    } else {
      return Promise.reject(res)
    }
  })
}

export const readNotice = ({ commit }, payload) => {
  const params = payload
  return new Promise((resolve, reject) => {
    api
      .readNotice(params)
      .then(res => {
        if (res?.code === 200) {
          commit(types.READ_NOTICE, payload.id)
        }
        resolve(res)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const click = ({ commit, state }, payload) => {
  return new Promise((resolve, reject) => {
    api
      .click(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

export const set404NotFound = ({ commit }, value) => {
  commit(types.SET_404_NOT_FOUND, value)
}

export const setVia = ({ commit }, value) => {
  commit(types.SET_VIA, value)
}

export const prizeInfo = async ({ commit }, type) => {
  const { code, messge, result } = await getPrizeInfo(type)
  if (code === 200) {
    commit(types.SET_PRIZE_INFO, result)
  } else {
    console.error(messge)
  }
}

export const loadExpirePoints = ({ commit, state }, payload) => {
  const params = !!state.token ? Object.assign({ auth_token: state.token }, payload) : payload
  return api.getExpirePoints(params).then(response => commit(types.GET_EXPIRE_POINTS, response.data.result))
}
