import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'
import request from './request'
import { delAppFcm } from '../tools/bridge'
export const baseURL = process.env.API_URL
const appKey = process.env.APP_KEY
// export const baseURL = process.env.VUE_APP_APIURL // 开发时使用
// const appKey = process.env.VUE_APP_KEY // 开发时使用

// 基本的 api
const baseRequest = axios.create({
  baseURL: `${baseURL}`,
  headers: {
    common: {
      'App-Key': appKey
    }
  }
})
baseRequest.interceptors.request.use(config => {
  if (config.requestBase === 'VUE_APP_NEWURL') {
    config.baseURL = process.env.APP_NEWURL
    // config.baseURL = process.env.VUE_APP_NEWURL // 开发时使用
  }
  // console.log(config)
  return config
})

baseRequest.interceptors.response.use(null, error => {
  switch (error.response.status) {
    case 401:
      // store.commit('AUTH_FAIL')
      // token过期，删除token和userid, 并且注销fcm token
      window.localStorage.removeItem('auth-token')
      const userId = Vue.$cookies.get('user_id')
      Vue.$cookies.remove('user_id')
      Vue.$cookies.remove('user')
      router.push('/')
      // 弹出登录页
      store.dispatch('setLoginDrawer', true)
      delAppFcm(userId)
      break
    case 404:
      store.commit('SET_404_NOT_FOUND', true)
      break
    case 500:
      store.commit('SET_500_ERROR', true)
      break
  }
  return Promise.reject(error)
})

const getTokenConfig = tokenString => {
  const config = { ...baseRequest.defaults }
  // 添加额外的请求头参数
  const common = config.headers.common
  common['auth-token'] = tokenString
  config.headers = {
    common: { ...common }
  }
  return config
}

export default {
  getHome: () => baseRequest.get('home'),
  getHomeOthers: () => baseRequest.get('home_others'),
  getEssentials: () => baseRequest.get('essentials'),
  getChannel(params) {
    let { channel, ...filtered_params } = params
    return baseRequest.get(`channels/${params.channel}`, { params: filtered_params })
  },
  getArticles(params) {
    return baseRequest.get('articles', { params: params })
  },
  getArticle(params) {
    let { id, ...other_params } = params
    return baseRequest.get(`articles/${id}`, { params: other_params })
  },
  preview(params) {
    let { id, ...other_params } = params
    return baseRequest.get(`articles/preview/${id}`, { params: other_params })
  },
  getRelatedArticles(params) {
    let { id, ...other_params } = params
    return baseRequest.get(`related/${id}`, { params: other_params })
  },
  applyArticleEvent(params) {
    const { article_id, ...other_params } = params
    return baseRequest.post(`articles/${article_id}`, other_params)
  },
  getRecommends: () => baseRequest.get('recommends'),
  getVideos(params) {
    return baseRequest.get('videos', { params: params })
  },
  getVideo(params) {
    return baseRequest.get(`videos/${params.id}`)
  },
  getSpecials(params) {
    return baseRequest.get('specials', { params: params })
  },
  getSpecialCategories(category) {
    return baseRequest.get(`specials/${category}`)
  },
  getTrials(params) {
    return baseRequest.get('trials', { params: params })
  },
  getTrial(params) {
    let { id, ...other_params } = params
    return baseRequest.get(`trials/${id}`, { params: other_params })
  },
  applyTrial(params) {
    const { id, item_id, ...other_params } = params
    return baseRequest.post(`trials/${id}/${item_id}`, other_params)
  },
  getLectures(params) {
    return baseRequest.get('lectures', { params: params })
  },
  getLecture(params) {
    let { id, ...other_params } = params
    return baseRequest.get(`lectures/${id}`, { params: other_params })
  },
  applyLecture(params) {
    const { id, session_id, ...other_params } = params
    return baseRequest.post(`lectures/${id}/${session_id}`, other_params)
  },
  getVotes(params) {
    return baseRequest.get('votes', { params: params })
  },
  getVote(params) {
    const { id, ...other_params } = params
    return baseRequest.get(`votes/${id}`, { params: other_params })
  },
  vote(params) {
    const { id, ...other_params } = params
    return baseRequest.patch(`votes/${id}`, other_params)
  },
  getAnnounces(params) {
    return baseRequest.get('announces', { params: params })
  },
  getAnnounce(params) {
    const { id, ...other_params } = params
    return baseRequest.get(`announces/${id}`, { params: other_params })
  },
  getPrizes(params) {
    return baseRequest.get(`prize/prizeInfo/${params}`, { requestBase: 'VUE_APP_NEWURL' })
    // return baseRequest.get(`prize/prizeInfotest`, {requestBase:'VUE_APP_NEWURL' })
    // return baseRequest.get('prizes', { params: params })
  },
  exchangePrize(params) {
    const { id, ...other_params } = params
    return baseRequest.post(`prizes/${id}`, other_params)
  },
  getPoints(params) {
    return baseRequest.get('points', { params: params })
  },
  getEventRecords(params) {
    return baseRequest.get('events', { params: params })
  },
  getComments(params) {
    return baseRequest.get('comments', { params: params })
  },
  createComment(comment) {
    return baseRequest.post('comments', comment)
  },
  deleteComment(params) {
    const { id, ...other_params } = params
    return baseRequest.delete(`comments/${id}`, { params: other_params })
  },
  getTag(params) {
    const { tag, ...other_params } = params
    return baseRequest.get(`tags/${tag}`, { params: other_params })
  },
  search(params) {
    return baseRequest.get('search', { params: params })
  },
  recaptcha(params) {
    return baseRequest.post('recaptcha', params)
  },
  login(user) {
    return baseRequest.post('login', user)
  },
  getProfile(token) {
    return baseRequest.get('profile', { params: { auth_token: token } })
  },
  updateProfile(user) {
    return baseRequest.put('profile', user)
  },
  signup(user) {
    return baseRequest.post('register', user)
  },
  confirmEmail(token) {
    return baseRequest.get('confirm_email', { params: token })
  },
  forgetPassword(email) {
    return baseRequest.post('forget_password', { email })
  },
  resetTokenVerify(token) {
    return baseRequest.get('verify', { params: { reset_password_token: token } })
  },
  resetPassword(params) {
    return baseRequest.post('reset_password', params)
  },
  updatePassword(params) {
    return baseRequest.patch('password', params)
  },
  getCollections(token) {
    return baseRequest.get('collection', { params: { auth_token: token } })
  },
  addCollection(params) {
    return baseRequest.post('collection', params)
  },
  removeCollection(params) {
    return baseRequest.delete('collection', { params: params })
  },
  getSubscriptions(token) {
    return baseRequest.get('subscription', { params: { auth_token: token } })
  },
  addSubscription(params) {
    return baseRequest.post('subscription', params)
  },
  removeSubscription(params) {
    return baseRequest.delete('subscription', { params: params })
  },
  getAuthors(params) {
    return baseRequest.get('authors', { params: params })
  },
  getAuthor(params) {
    const { slug, ...other_params } = params
    // return baseRequest.get(`authors/${slug}`, { params: other_params })
    return baseRequest.get(`authors/${slug}`, { params: other_params, requestBase: 'VUE_APP_NEWURL' })
  },
  getNotices(params) {
    const { pageNo, pageSize } = params
    return request.get('/shop/userNotice/list', {
      params: {
        pageNo,
        pageSize
      }
    })
  },
  readNotice(params) {
    const { id } = params
    return request.post(`/shop/userNotice/markRead/${id}`)
  },
  click(params) {
    return baseRequest.get('click', { params: params })
  },
  getExpirePoints(params) {
    // const { auth_token } = params
    const config = getTokenConfig(params?.auth_token)
    return baseRequest.get(`userPoint/detail`, { params: params, requestBase: 'VUE_APP_NEWURL' }, config)
  },
  getPointList(params, token) {
    // const { auth_token } = params
    const config = getTokenConfig(params?.auth_token || token)
    return baseRequest.get(`userPoint/list`, { params: params, requestBase: 'VUE_APP_NEWURL' }, config)
  }
}
