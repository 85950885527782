const getters = {
  isAuthed: state => !!state.token,
  // currentUser(state) {
  //   return state.profile.token
  // },
  // userName(state) {
  //   return state.profile.name
  // },
};

export default getters
