<template>
  <section id="prizes">
    <div class="header">
      <h1>熱點兌換</h1>
      <h2 @click="showDialog('pointTask')">
        得點攻略
        <img :src="require('@/../images/tips.png')" alt="" />
      </h2>
      <div class="subscription">
        <span> 上架提醒 </span>
        <b-switch
          size="is-small"
          v-model="subSwitch"
          @input="changeSub"
        ></b-switch>
      </div>
    </div>
    <p class="user-info">
      親愛的
      <span class="has-text-lookin-darker">{{
        user ? user.nickname : '用戶'
      }}</span>
      妳好！現有點數
      <span class="has-text-lookin-darker">{{
        user ? user.points : '- -'
      }}</span>
      <span class="notice">(確定兌換後恕無法更改或返還點數唷！)</span>
    </p>
    <div class="expire" v-if="expire_points.expiringSoonPoint">
      您有
      <span class="has-text-lookin-darker">{{
        expire_points.expiringSoonPoint || 0
      }}</span>
      點數將於{{ expire_points.expired_at }}到期，請盡快使用哦！
    </div>
    <!-- <div class="listing-reminders">
      <div class="l-text">
        <img src="../../../images/prizes/icon_remind.svg" alt="" />
        <span>訂閱上架提醒，商品上架提前通知您</span>
      </div>
      <button class="l-btn" @click="handleSubscribe">{{ subscribeStatus ? '取消提醒' : '上架提醒' }}</button>
    </div> -->
    <div class="prizes-box" v-if="prizes">
      <div v-for="(val, key, index) in prizes" :key="index">
        <div v-if="val.length !== 0" class="prizes-content">
          <div class="prizes-header" v-if="!isApp">
            <h3>{{ prizesTitle[key] }}</h3>
            <p
              v-if="key === 'app'"
              class="spancolor"
              @click="showDialog('download')"
            >
              立即下載>>
            </p>
            <a
              class="spancolor"
              v-else-if="key === 'lottery'"
              :href="eventAnnouncementsUrl"
              target="_blank"
            >
              活動公告請看>>
            </a>
          </div>
          <div class="prizes-list">
            <div class="prize-item" v-for="item in val" :key="item.id">
              <div class="app-tip" v-if="key === 'app'">APP獨家</div>
              <div class="app-tip" v-else-if="item.noticeStatus">即将上架</div>
              <div class="item-image" @click="gotoDetails(item.id)">
                <img class="img" :src="item.image" />
                <div
                  class="no-inventory"
                  :class="{ warn: item.noticeStatus }"
                  v-if="prizeTip(item)"
                >
                  <span>{{ prizeTip(item) }}</span>
                </div>
              </div>
              <h3 class="ellipsis">{{ item.title }}</h3>
              <div class="price">
                <div class="point">
                  <img src="../../../images/prizes/icon_points.svg" alt="" />
                  <span>{{ item.point }}</span>
                </div>
                <div class="market" v-if="item.originPrice">
                  市價： NT{{ item.originPrice }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :visible.sync="openModal">
      <template #content>
        <div class="point-task" v-if="modalType === 'pointTask'">
          <h3 class="p-title">得點攻略</h3>
          <h4 class="p-title2">熱點說明</h4>
          <p class="tip-text">
            LOOKin提供獨家專屬熱點，透過閱讀文章、遊戲等參與網站皆有機會可獲得點數唷！
          </p>
          <h4 class="p-title2">得點任務</h4>
          <p class="p-tip">天天看文章輕鬆累積點數！</p>
          <p class="tip-text">
            仔細閱讀LOOKin文章15秒以上可獲得<span>1</span>點，每日最多可獲得<span>10</span>點
          </p>
        </div>
        <div
          class="download"
          v-else-if="modalType === 'download' || modalType === 'remind'"
        >
          <h3 class="d-title">
            {{
              modalType === 'download' ? '直接下載APP兌換獨家好禮' : '上架提醒'
            }}
          </h3>
          <div class="d-box">
            <div class="box_text" v-if="modalType === 'remind'">
              <p>商品上架前會透過推播消息提前通知您</p>
              <p>下載APP可即時接收通知消息哦！</p>
            </div>
            <div class="box_code">
              <div class="code">
                <img src="../../../images/ios-code.png" alt="" />
                <p>iOS</p>
              </div>
              <div class="code">
                <img src="../../../images/andriod-code.png" alt="" />
                <p>Android</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Modal from '../../components/dialog'
import {
  getPrize,
  subscribeApi,
  getSubscribeStatusApi,
} from '../../../api/prize'
import { isLookinApp, clearBadge } from '../../../tools/bridge'

export default {
  data() {
    return {
      // formatPrizes: [],
      isApp: isLookinApp,
      // isLoading: true,
      openModal: false,
      exchangeItem: null,
      prizesTitle: {
        pc: '立即兌換區',
        app: 'APP獨家兌換區',
        lottery: '抽獎券專區',
      },
      eventAnnouncementsUrl: `${window.location.origin}/beauty/news/35997`,
      modalType: 'pointTask',
      prizes: null,
      subSwitch: false,
      subscribeStatus: false, // 是否訂閱上架提醒
      subscribeFlag: true, // 订阅上架提醒阀门
    }
  },
  components: { Modal },
  computed: {
    ...mapState(['user', 'token', 'expire_points']),
    prizeTip: () => (item) => {
      if (item.remains === 0) {
        return '兌換完畢'
      }
      if (item.exchanged) {
        return '已兌換'
      }
      if (item.noticeStatus) {
        return '上架提醒我'
      }
      return false
    },
  },
  watch: {
    // 监听是否登录
    token() {
      // 刷新上架提醒状态
      this.getSubscribeStatusFn()
      // 刷新列表数据
      this.loadData()
    },
    // subSwitch(val) {
    //   console.log('====', val)
    //   this.handleSubscribe()
    // }
  },
  methods: {
    ...mapActions(['setLoginDrawer']),
    gotoDetails(id) {
      // this.$router.push(`/prizes/${id}`)
      window.location.href = `/nuxt/prizes/${id}`
    },
    showDialog(type) {
      this.modalType = type
      this.openModal = true
    },
    async loadData() {
      const { code, result } = await getPrize()
      if (code == 200) {
        this.prizes = result
      }
    },
    async getSubscribeStatusFn() {
      const { code, result } = await getSubscribeStatusApi()
      if (code === 200) {
        this.subscribeStatus = result
        // 开关组件状态同步
        this.subSwitch = result
      }
    },
    changeSub(val) {
      this.handleSubscribe()
    },
    async handleSubscribe() {
      if (!this.user) {
        // 没有登录 打开登录弹框
        this.$nextTick(() => {
          this.subSwitch = !this.subSwitch
        })
        this.setLoginDrawer(true)
        return
      }
      // 不是app弹出下载app提示
      if (!isLookinApp) {
        this.showDialog('remind')
        this.$nextTick(() => {
          this.subSwitch = !this.subSwitch
        })
        return
      }
      // 只有接口返回数据才能通过
      if (!this.subscribeFlag) {
        this.$nextTick(() => {
          this.subSwitch = !this.subSwitch
        })
        return
      }
      this.subscribeFlag = false
      try {
        // 上架提醒 订阅全部赠品: ALL_GOODS： 取消訂閱： CANCEL_ALL_GOODS
        const { code, message } = await subscribeApi({
          subscribeType: this.subscribeStatus
            ? 'CANCEL_ALL_GOODS'
            : 'ALL_GOODS',
        })
        if (code === 200) {
          this.$buefy.toast.open({
            type: 'is-success',
            message: this.subscribeStatus ? '取消訂閱成功' : '訂閱成功！',
          })
          // 更改状态
          this.subscribeStatus = !this.subscribeStatus
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message,
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.subscribeFlag = true
        this.$nextTick(() => {
          this.subSwitch = this.subscribeStatus
        })
      }
    },
    // 清除红点
    clearAppBadge() {
      try {
        const isProd =
          !location.href.includes('staging') &&
          !location.href.includes('localhost')
        const url = isProd
          ? 'https://www.look-in.com.tw/prizes'
          : 'https://staging.look-in.com.tw/prizes'
        setTimeout(() => {
          clearBadge({ url: url, tabbarIndex: 3, removeAll: false })
          console.log('清除[热点兑换]红点')
        }, 5000)
      } catch (err) {
        console.error('清除[热点兑换]红点 err ', err)
      }
    },
  },
  created() {
    this.getSubscribeStatusFn()
    this.loadData()
    this.$store.dispatch('loadExpirePoints')
    this.clearAppBadge()
  },
}
</script>
<style lang="scss" scoped>
#prizes {
  max-width: 1000px;
  width: 1000px;
  margin: 30px auto;
  // padding: 0 20px;
  padding-left: 20px;
  padding-right: 20px;
  .ellipsis {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }
  .header {
    display: flex;
    align-items: center;
    position: relative;
    h1 {
      font-size: 24px;
      font-weight: 600;
      color: #080808;
      line-height: 36px;
      border-bottom: 4px solid #ffc2ae;
    }
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 22px;
      font-size: 15px;
      margin-left: 10px;
      background: #ef8e79;
      border-radius: 20px;
      filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.3));
      color: #fff;
      cursor: pointer;
    }
    .subscription {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 18px;
        text-align: center;
        margin-right: 6px;
      }
    }
    .question-icon {
      margin-left: 2px;
      border: 1px solid #000;
      border-radius: 50%;
    }
    img {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }
  .user-info {
    margin: 25px 0 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
  }
  .expire {
    width: 100%;
    height: 38px;
    line-height: 38px;
    background: rgba(255, 194, 174, 0.2);
    border-radius: 0px 0px 0px 0px;
    font-weight: 400;
    font-size: 14px;
    color: #404040;
    margin: 0 0 17px 0;
    padding: 0 20px;
  }
  .listing-reminders {
    background-color: #fff7d8;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .l-text {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        color: #723d23;
        line-height: 20px;
        margin-left: 6px;
      }
    }
    .l-btn {
      padding: 1px 8px;
      background: #ca8434;
      border-radius: 9999px;
      font-size: 12px;
      color: #fff7d8;
      line-height: 17px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  .notice {
    // margin: 7px 20px;
    margin-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(152, 149, 149, 1);
  }
  .prizes-box {
    .prizes-content {
      margin-bottom: 30px;
    }
    .prizes-header {
      display: flex;
      // flex-direction: row;
      h3 {
        font-size: 22px;
        font-weight: 600;
        color: #0a0a0a;
        line-height: 24px;
        padding-left: 8px;
        border-left: 3px solid #ffc2ae;
      }
      p,
      a {
        font-size: 14px;
        margin-left: 10px;
        // width: 96px;
        height: 22px;
        background: #ef8e79;
        // box-shadow: 0px 2px 4px 1px rgb(239 142 121 / 60%);
        filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.3));
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        color: #fff;
        text-align: center;
        line-height: 22px;
        padding: 0 12px;
      }
    }
    .prizes-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 220px);
      grid-gap: 18px;
      padding-top: 25px;
      .prize-item {
        position: relative;
        .app-tip {
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          background-color: rgba(254, 97, 97, 1);
          font-size: 13px;
          padding: 2px 10px;
          border-radius: 6px 0px 6px 0px;
          z-index: 4;
        }
        .item-image {
          position: relative;
          width: 220px;
          height: 220px;
          cursor: pointer;
          .img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 6px;
          }
          .exchanged {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 105px;
            height: 42px;
            opacity: 0.6;
            border: 4px solid #000000;
            border-radius: 16px;
            transform: translate(-50%, -50%) rotate(-12.28deg);
          }
          .no-inventory {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 32px;
            background: rgba(0, 0, 0, 0.4);
            text-align: center;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            &.warn {
              background: rgba(240, 143, 122, 0.8);
            }
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 32px;
            }
            // img {
            //   width: 100%;
            //   height: 100%;
            //   object-fit: contain;
            // }
          }
        }
        h3 {
          // height: 42px;
          font-size: 14px;
          color: #333333;
          margin: 10px 0;
        }
        .price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 0 5px;
          .point {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 18px;
              font-weight: 600;
              color: #ff272f;
              line-height: 20px;
            }
          }
          .market {
            font-size: 14px;
            color: #989595;
            line-height: 21px;
            text-decoration: line-through;
          }
        }
        button.ok {
          width: 70%;
          height: 32px;
          border: 1px solid #ffc2ae;
          box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
          color: #f08f7a;
          span {
            font-style: normal;
            // font-weight: bold;
            font-size: 16px;
          }
          .okcolor {
            color: #989595;
          }
        }
        button.okcolor {
          color: #989595;
        }

        button.not-enough {
          background: #989595;
          width: 70%;
          height: 32px;
          span {
            color: #ffffff;
          }
        }
      }
    }
    // .mobile-item {
    //   flex-basis: 50%;
    //   img {
    //     height: 148px;
    //   }
    // }
  }
  .spancolor {
    color: #f08f7a;
    cursor: pointer;
  }
  ::v-deep #new_module .new_box {
    padding: 20px;
  }
  .point-task {
    text-align: left;
    padding-bottom: 100px;
    .p-title {
      font-size: 18px;
      color: #0a0a0a;
      line-height: 20px;
      text-align: center;
      font-weight: 600;
    }
    .p-title2 {
      font-size: 16px;
      color: #0a0a0a;
      font-weight: 600;
      border-left: 3px solid #ef8e79;
      line-height: 20px;
      padding-left: 6px;
      margin: 20px 0 15px;
    }
    .p-tip {
      font-size: 13px;
      font-weight: 600;
      color: #0a0a0a;
      line-height: 21px;
      margin-bottom: 5px;
    }
    .tip-text {
      font-size: 12px;
      color: #979595;
      line-height: 20px;
      span {
        color: #f08f7a;
      }
    }
  }
  .download {
    .d-title {
      font-size: 18px;
      color: #0a0a0a;
      line-height: 20px;
      text-align: center;
      font-weight: 600;
    }
    .d-box {
      .box_text {
        font-size: 16px;
        color: #404040;
        line-height: 22px;
        margin-top: 15px;
      }
      .box_code {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .code {
          margin: 0 40px;
          img {
            width: 150px;
            height: 150px;
          }
          p {
            font-size: 12px;
            color: #7f7f7f;
          }
        }
      }
    }
  }
}

// 移动端样式
@media screen and (max-width: 768px) {
  #prizes {
    width: 100%;
    margin-top: 20px;
    // padding: 0 14px;
    padding-left: 14px;
    padding-right: 14px;
    .header {
      h1 {
        font-size: 18px;
        border-bottom: none;
      }
    }
    .user-info {
      font-size: 15px;
      margin: 15px 0 20px;
    }
    .prizes-box {
      .prizes-header {
        align-items: center;
        flex-direction: row;
        h3 {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .prizes-list {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 7px;
        padding-top: 15px;
        .prize-item {
          .item-image {
            width: 100%;
            height: 170px;
            // .no-inventory {
            //   width: 100%;
            //   height: 100%;
            // }
          }
          .price {
            .point {
              span {
                font-size: 16px;
                line-height: 16px;
              }
            }
            .market {
              font-size: 11px;
              line-height: 11px;
            }
          }
        }
      }
    }
    .point-task {
      .p-title {
        font-size: 16px;
      }
      .p-title2 {
        font-size: 15px;
      }
    }
    .download {
      .d-title {
        font-size: 16px;
      }
      .d-box {
        .box_text {
          font-size: 13px;
        }
        .box_code {
          .code {
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  #prizes {
    .prizes-box {
      .prizes-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
</style>
