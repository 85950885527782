<template>
  <section id="me" class="has-background-lookin-bg">
    <div v-if="!!user" class="my-basic">
      <img class="avatar" :src="userAvatar(user.avatar_url)" />
      <div class="info">
        <div class="user-name">{{ user.nickname }}</div>
        <router-link :to="{ name: 'my_points' }">
          <div class="my-points">
            <span class="point-title">累積點數</span>
            <span class="points has-text-lookin-darker">{{ user.points.toLocaleString('en-US') }}</span>
            <img :src="require('@/../images/arrow-lookin-darker.svg')" class="arrow" />
          </div>
        </router-link>
        <div class="expire" v-if="expire_points.expiringSoonPoint">
          <div>
            您有
            <span class="has-text-lookin-darker">{{ expire_points.expiringSoonPoint || 0 }}</span>
            點數將於{{ expire_points.expired_at }}到期，<br />請盡快使用哦！
            <span class="btn-use" @click="$router.push('/prizes')">去使用</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="dashboard">
    </div> -->
    <div class="my-menu has-background-white">
      <ul>
        <template v-for="item in memberZoneItems.slice(1)">
          <!--  @click="handleClick(item.path)" -->
          <li :key="item.id" v-if="setShowCancellation(item.path)">
            <div
              style="position: relative"
              :to="item.path"
              class="content has-text-black"
              @click="handleClick(item.path)"
            >
              <img :src="getIconUrl(item.icon)" class="my-icon" :alt="item.title" />
              <span>
                {{ item.title }}
              </span>
              <!-- 开奖的红点 -->
              <div class="opprize" v-if="item.path == '/my/exchange-record' && showPrizeNotice">開獎了</div>
              <img :src="require('@/../images/arrow.svg')" class="arrow" />
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="my-menu has-background-white" style="margin-top: 20px; border-radius: 18px" v-if="isStore">
      <ul style="margin: 4px 0">
        <li @click="handleScanCode" class="scan_code">
          <img src="../../../images/coupon/member_qrcode.svg" class="my-icon" alt="" />
          掃碼核銷
          <img src="../../../images/arrow-lookin-darker.svg" class="arrow" />
        </li>
      </ul>
    </div>
    <div class="my-logout">
      <b-button rounded @click="logout" class="has-background-black">登 出</b-button>
    </div>
    <Modal :open="logoutModal" :title="'成功登出'" :content="'下次再回來喔～'" :btnWord="'好的'" @close="goHome" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '../../components/dialog/Modal'
import { isLookinApp, Android, IOS, qrcodeScan, clearBadge } from '../../../tools/bridge.js'
import { userIsStore } from '../../../api/storeManager'
import { APP_UPDATE_TIP } from '../../../store/mutation-types'

export default {
  name: 'MePage',
  data() {
    return {
      logoutModal: false,
      isStore: false,
      showPrizeNotice: false,
      redPoints: []
      /**
       * 红点标记定义
       * 1---开奖提醒
       * */
      // badgeActions: [1]
    }
  },
  components: { Modal },
  created() {
    window.showWebViewBadge = this.showWebViewBadge
    // 是app
    if (isLookinApp) this.userIsStoreFn()
    this.loadExpirePoints()
    this.renderBadges()
  },
  computed: {
    ...mapState(['user', 'memberZoneItems', 'expire_points']),
    userAvatar() {
      return avatar => (avatar ? avatar : require('@/../images/user_default.png'))
    }
  },
  methods: {
    // 获取app红点数据,检查此页面是否有需要渲染的内容,有则渲染
    renderBadges() {
      setTimeout(() => {
        const data = getRedPointsDataByStorage()
        console.log('红点数据:', data)
        // 有数据
        if (data && Array.isArray(data)) {
          data.forEach(item => {
            console.log('i==', item)
            if (item.tab == 4 && Array.isArray(item.rspData)) {
              this.redPoints = item.rspData
              item.rspData.forEach(rd => {
                // rd: {createTime: 1714015027.160176,reder: 1,tabPage: 4,url: "https://staging.look-in.com.tw/my/me"}
                // 1 == 开奖提醒
                if (rd.reder === 1) this.showPrizeNotice = true
              })
            }
          })
        }
      }, 200)
    },
    handleClick(path) {
      // const isProd = !location.href.includes('staging') && !location.href.includes('localhost')
      let action = 0
      try {
        // 消除tabbar红点及'开奖了'的标记
        if (path === '/my/exchange-record' && this.showPrizeNotice) {
          action = 1
          let clearUrl = this.redPoints.filter(item => item.tabPage == 4 && item.reder == 1)[0].url
          clearBadge({ url: clearUrl, tabbarIndex: 4, removeAll: false })
          this.showPrizeNotice = false
          console.log('清除红点的参数:', { url: clearUrl, tabbarIndex: 4, removeAll: false })
          // 清除完之后要更新app、本地缓存的红点数据
          getAppRedPoints() // 此方法已挂在window下
        }
      } catch {}
      setTimeout(() => {
        if (path === '/my/exchange-record' && action) {
          this.$router.push(path + '?action=' + action)
        } else {
          this.$router.push(path)
        }
      }, 200)
    },
    loadExpirePoints() {
      this.$store.dispatch('loadExpirePoints')
    },
    getIconUrl(icon) {
      return require('@/../images/' + icon + '.svg')
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        // this.logoutModal = true
        if (isLookinApp) this.$router.push('/?to=/my/me')
      })
    },
    goHome: function () {
      this.$router.push({ name: 'home' })
    },
    setShowCancellation(path) {
      /**
       * 判断是否是注销路由，是加入判断，反之都显示
       * ios系统才显示注销按钮, 尖端app里判断ios有错误
       */
      let isShowCancellation = false
      if (path === '/my/cancellation') {
        if (isLookinApp) {
          if (!Android) {
            isShowCancellation = true
          }
        } else {
          if (IOS) {
            isShowCancellation = true
          }
        }
      } else {
        isShowCancellation = true
      }
      return isShowCancellation
    },
    async userIsStoreFn() {
      try {
        // 是否为商家
        const { code, result } = await userIsStore()
        if (code === 200) {
          this.isStore = result
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleScanCode() {
      // 调用app扫码功能
      if (isLookinApp) {
        try {
          const token = window.localStorage.getItem('auth-token') || ''
          qrcodeScan(token)
        } catch (error) {
          console.warn(error)
          this.$store.commit(APP_UPDATE_TIP, true)
        }
      }
    },
    /**
     * app调用 显示webview与app红点相关的标记
     */
    showWebViewBadge(tabbarIndex, url) {
      console.log(tabbarIndex, url)
    }
  }
}
</script>

<style lang="scss">
#me {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .my-basic {
    height: 100px;
    margin: 20px;
    display: flex;
    align-items: center;
    .avatar {
      display: inline-block;
      height: 75px;
      width: 75px;
      margin: 0 10px;
      border-radius: 50%;
    }
    .info {
      display: inline-block;
      .user-name {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 1px;
        height: 24px;
        width: 130px;
        overflow: hidden;
      }
      .my-points {
        span.point-title {
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          color: #404040;
        }
        span.points {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 1px;
          vertical-align: middle;
        }
        img.arrow {
          height: 8px;
          width: 8px;
          // float: right;
          margin: 10px 18px 0;
        }
      }
      .expire {
        display: flex;
        height: 20px;
        line-height: 20px;
        font-weight: 400;
        font-size: 13px;
        color: #0a0a0a;
        line-height: 20px;
        .btn-use {
          display: inline-block;
          margin-left: 8px;
          width: 54px;
          height: 20px;
          font-size: 11px;
          color: #fff;
          text-align: center;
          background: #f08e7a;
          border-radius: 10px;
          user-select: none;
          transition: all 0.2s;
          &:hover {
            cursor: pointer;
            transform: scale(103%);
          }
          &::after {
            content: '\003E';
            margin-left: 4px;
          }
        }
      }
    }
  }
  .my-menu {
    width: 320px;
    opacity: 0.9;
    box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
    border-radius: 46px;
    ul {
      margin: 20px 0;
      li {
        font-size: 15px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0px;
        &.scan_code {
          color: #f08f7a;
        }
        .my-icon {
          height: 18px;
          width: 18px;
          margin: 0 6px 0 17px;
          vertical-align: middle;
        }
        a {
          display: inline-block;
          width: 100%;
        }
        img.arrow {
          height: 8px;
          width: 8px;
          float: right;
          margin: 16px 12px 0;
          vertical-align: middle;
        }
      }
      li + li {
        border-top: 1px solid #ffdbcf;
      }
    }
  }
  .my-logout {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 40px;
    button {
      width: 282px;
      color: #ffffff;
    }
  }
  // 开奖提醒的标记
  .opprize {
    position: absolute;
    top: 0;
    left: 97px;
    width: 40px;
    height: 16px;
    line-height: 16px;
    background: #eb5145;
    border-radius: 7px 8px 8px 0px;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 9px;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
  }
}
</style>
