<template>
  <section class="section has-background-white" id="root-section" :style="style">
    <ServerError v-if="serverError500" class="error-500" />
    <div v-else>
      <Tip v-if="showYellowTip" @close="handleClose" :point="yellowTip.point" @gotoPlan="onGotoPlan" />
      <Header />
      <div style="min-height: 100vh">
        <NotFound v-if="notFound404" class="not-found" />
        <template v-else>
          <keep-alive>
            <router-view
              v-if="$route.meta.keepAlive"
              :key="$route.path"
              class="main-content"
              :class="showYellowTip ? 'ht-tip' : ''"
            />
          </keep-alive>
          <router-view
            v-if="!$route.meta.keepAlive"
            :key="$route.path"
            class="main-content"
            :class="showYellowTip ? 'ht-tip' : ''"
          />
        </template>
        <!-- <router-view
            :key="$route.path"
            class="main-content"
            :class="showYellowTip ? 'ht-tip' : ''"
          /> -->
      </div>
      <Footer />
      <FixedWidget v-if="fixed_widget && fixed_widget.data.open" />
      <a href="javascript:void(0)" @click="backToTop" v-show="fixedTop" id="back-top">
        <img :src="require('@/../images/top.svg')" width="40" height="40" alt="TOP" />
      </a>
      <Modal :open="authFail" :title="'認證失敗'" :content="'請重新登入'" :btnWord="'好的'" @close="clearAuthToken" />
      <!-- <bind-modal :visible.sync="$store.state.bindVisible" /> -->
      <UpdateTip v-if="isShowUpdateTip" @comfig="handleUpdateTipClose" @close="handleUpdateTipClose" />
    </div>
  </section>
</template>

<script>
import { throttle } from 'throttle-debounce'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import NotFound from './pages/NotFound'
import ServerError from './pages/ServerError'
import FixedWidget from './components/layout/FixedWidget'
import Modal from './components/dialog/Modal'
import { mapState, mapActions, mapGetters } from 'vuex'
// import BindModal from 'src/components/bindingAccount/bindModal.vue'
import Tip from './components/yellowStrip/tip.vue'
import { UPDATE_SHOW_YELLOW_TIP, UPDATE_YELLOW_TIP, APP_UPDATE_TIP } from '../store/mutation-types'
import { getPointApi, pointsToHousehold } from '../api/yellowStrip'
import 'froala-editor/css/froala_style.min.css'
import UpdateTip from './components/common/UpdateTip.vue'
import { isLookinApp, compareVersion } from '../tools/bridge'
import fcmToken from './mixins/fcmToken'
// import VConsole from 'vconsole'
import VConsole from '../tools/vconsole.min.js'

export default {
  mixins: [fcmToken],
  data() {
    return {
      fixedTop: false,
      padding: 94
    }
  },
  metaInfo() {
    let title = this.fullTitle
    return {
      title: title
    }
  },
  components: { Header, Footer, NotFound, ServerError, Modal, FixedWidget, Tip, UpdateTip },
  watch: {
    $route: function (to, from) {
      this.fullTitle
    }
  },
  computed: {
    ...mapState([
      'user',
      'home',
      'fixed_widget',
      'notFound404',
      'serverError500',
      'authFail',
      'showYellowTip',
      'yellowTip',
      'isShowUpdateTip',
      'appVersion'
    ]),
    ...mapGetters(['isAuthed']),
    style() {
      return {
        padding: `${this.padding}px 0 0`
      }
    },
    fullTitle() {
      const postfix = ' － 【LOOKin】美人時髦話題網'
      if (this.$route.params.title) {
        switch (this.$route.name) {
          case 'search':
            return `最新${this.$route.params.title}推薦文章！女性、生活話題分享${postfix}`
          case 'tag':
            return `${this.$route.params.title}推薦分享(持續更新)${postfix}`
          default:
            return `${this.$route.params.title}${postfix}`
        }
      } else if (this.$route.meta.title) {
        return `${this.$route.meta.title}${postfix}`
      } else {
        return document.title
      }
    }
  },
  methods: {
    ...mapActions(['setMobile', 'setLoginDrawer']),
    init() {
      const throttledResizeHandler = throttle(300, this.onResize)
      const throttledScrollHandler = throttle(300, this.onScroll)
      window.addEventListener('resize', throttledResizeHandler)
      document.addEventListener('scroll', throttledScrollHandler)
      this.onResize()
      this.onScroll()
    },
    onResize() {
      this.setMobile(window.innerWidth < 1200)
    },
    onScroll() {
      const { scrollTop } = document.documentElement
      if (this.isMobile) {
        this.padding = 94
      } else {
        this.padding = 0
        this.fixedTop = scrollTop > 135
      }
    },
    backToTop: function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      // $('html,body').stop().animate(
      //   {
      //     scrollTop: 0
      //   },
      //   'slow',
      //   'swing'
      // )
    },
    clearAuthToken() {
      this.$store.dispatch('logout').then(() => {
        this.$router.go(0) // refresh current page
      })
    },
    handleClose() {
      this.$store.commit(UPDATE_SHOW_YELLOW_TIP, false)
      window.sessionStorage.setItem('closeMonitor', true)
    },
    onGotoPlan() {
      this.$store.commit(UPDATE_SHOW_YELLOW_TIP, false)
      this.$router.push('/prizes/plan')
    },
    async getPoint() {
      try {
        const fd = new FormData()
        // eslint-disable-next-line
        fd.append('deviceId', $cookies.get('device_id'))
        const data = await getPointApi(fd)
        const { code, message, result } = data.data
        if (code === 200) {
          if (result.point > 0) {
            this.$store.commit(UPDATE_SHOW_YELLOW_TIP, true)
            this.$store.commit(UPDATE_YELLOW_TIP, { point: result.point, code: result.webCode })
            // 添加曝光統計事件
            try {
              new TrackEvent().setEvent('exposure_articleYellow', '', '', null, null)
            } catch (error) {
              console.log(error)
            }
          }
        } else {
          console.log(message)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async belongToFami() {
      try {
        const fd = new FormData()
        // eslint-disable-next-line
        fd.append('deviceId', $cookies.get('device_id'))
        // eslint-disable-next-line
        fd.append('userId', $cookies.get('user_id'))
        // webCode直接传空就可以了
        fd.append('webCode', '')
        await pointsToHousehold(fd)
        this.$store.commit(UPDATE_SHOW_YELLOW_TIP, false)
        try {
          new TrackEvent().setEvent('test_monitor_readypost', '', '', null, null)
        } catch (error) {
          console.warn(error)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    handleUpdateTipClose() {
      this.$store.commit(APP_UPDATE_TIP, false)
    },
    appUpdateTipFn() {
      setTimeout(() => {
        // 有版本号，且大于这个版本号, ios获取版本可能有延迟
        if ((isLookinApp && compareVersion('2.1.1', this.appVersion)) || (isLookinApp && !this.appVersion)) {
          this.$store.commit(APP_UPDATE_TIP, true)
        }
      }, 1000)
    }
  },
  mounted() {
    this.$store.dispatch('loadEssentials')
    if (this.isAuthed && this.user === null) {
      this.$store.dispatch('loadUserProfile')
    }
    // 未登録，查看是否有黃條點數
    const authToken = window.localStorage.getItem('auth-token')
    const closeMonitor = window.sessionStorage.getItem('closeMonitor')
    if (!authToken && !this.user && !closeMonitor) {
      this.getPoint()
    }

    // 第三方登录点数归户
    const { token, to } = this.$route.query
    if (token) {
      this.belongToFami()
    }

    // app 升级提示弹框
    this.appUpdateTipFn()

    // app 我的会员 登录弹框
    // 判断是否在app
    if (isLookinApp) {
      // 判断是否有携带to参数
      if (to === '/my/me') {
        // 有登录 调整跳转到会员中心
        if (authToken) {
          this.$router.push(to)
        } else {
          // 没有登录 打开登录弹框
          this.setLoginDrawer(true)
        }
      }
    }

    this.init()
    // 初始化vconsole
    // development/staging
    const isStaging = location.href.includes('staging')
    if (isStaging || ['385683'].includes($cookies.get('user_id'))) {
      new VConsole()
    }
    if (isStaging) {
      const robots = document.querySelector("meta[name='robots']")
      if (robots) robots.setAttribute('content', 'noindex')
      else {
        let metaTag = document.createElement('meta')
        metaTag.setAttribute('name', 'robots')
        metaTag.setAttribute('content', 'noindex')
        document.head.appendChild(metaTag)
      }
    }
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 1199px) {
  #root-section {
    .main-content,
    .not-found {
      padding-top: 97px;
    }
    .main-content.ht-tip {
      padding-top: 130px;
    }
  }
}

@media screen and (min-width: 1200px) {
  #root-section {
    .main-content,
    .not-found {
      padding-top: 200px;
    }
    .main-content.ht-tip {
      padding-top: 215px;
    }
  }
}

#back-top {
  position: fixed;
  left: calc(100% - 50px);
  bottom: 90px;
  z-index: 6;
}

.tail-icon.close {
  cursor: pointer;
}

a h3:hover,
h2.title a:hover,
.article-references a p:hover {
  color: #f08f7a !important;
}

a img.content-img:hover,
.article-references a img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

a img.big-content-img:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

a.tag:hover,
.video-categories a.category:hover {
  background-color: #f08f7a !important;
  color: white !important;
  text-decoration: none !important;
}

a.tag-link:hover {
  background-color: #f08f7a;
  border-color: #f08f7a;
  color: white;
  text-decoration: none;
}
</style>
