import axios from 'axios'

const host = window.location.host || ''
let baseURL = 'https://verify.cmh.com.tw/lv' // 默認正式服域名

if (host.includes('staging')) {
  baseURL = 'https://verify-staging.cmh.com.tw/lv'
} else if (host.includes('localhost')) {
  baseURL = 'https://verify-staging.cmh.com.tw/lv'
} else {
  baseURL = 'https://verify.cmh.com.tw/lv'
}

axios.defaults.baseURL = baseURL

const service = axios.create({
  timeout: 40000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json; charset=UTF-8'
  }
})
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    return Promise.reject(error)
  }
)
export default service
