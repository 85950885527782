<template>
  <div class="cancellation-success">
    <div class="img">
      <img
        :src="
          type === 'success'
            ? require('../../../images/cancellation-success.svg')
            : require('../../../images/cancellation-error.svg')
        "
        alt="success"
      />
    </div>
    <p class="succ">{{ type === "success" ? "帳號註銷成功" : msg }}</p>
    <div class="tip" v-if="type === 'success'">
      <p>您的帳號已註銷成功</p>
      <p>歡迎您再次註冊LOOKin會員帳號</p>
    </div>
    <button class="btn" @click="onSuccess">好的</button>
  </div>
</template>

<script>
export default {
  name: "cancellation-success",
  data() {
    return {
      msg: "",
      type: "success",
    };
  },
  created() {
    console.log(this.$route.query, "##");
    const { msg } = this.$route.query;
    if (msg) {
      this.type = "error";
      this.msg = msg;
    }
  },
  methods: {
    onSuccess() {
      // 跳回首页
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.cancellation-success {
  text-align: center;
  .img {
    padding-top: 150px;
    img {
      width: 56px;
      height: 56px;
    }
  }
  .succ {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #424242;
    line-height: 25px;
    margin-top: 24px;
    margin-bottom: 14px;
  }
  .tip {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #424242;
    line-height: 20px;
  }
  .btn {
    width: 140px;
    height: 38px;
    background: #0a0a0a;
    border-radius: 19px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    letter-spacing: 4px;
    margin-top: 27px;
    outline: none;
    border: none;
    cursor: pointer;
  }
}
</style>
