<template>
  <section id="members-only" class="has-background-lookin-bg">
    <div class="home-block-title has-text-centered has-text-black">
      <h2><span>M</span>embers Only<span class="has-text-lookin-darker">會員限定</span></h2>
    </div>
    <b-icon icon="angle-left" class="slick-arrow prev" @click.native="prev()"></b-icon>
    <slick ref="membersOnlySlick" :options="slickOptions" v-if="!isLoading">
      <template v-for="(article, i) in member_only">
        <div class="content has-text-centered">
          <router-link
            :to="{
              name: 'article',
              params: {
                channel: article.parent_path,
                sub_channel: article.child_path,
                id: article.id,
                title: article.title
              }
            }"
            class="image"
          >
            <img :src="article.member_only_image" class="content-img" />
            <!-- <LazyImage :src="article.member_only_image" class="content-img" /> -->
          </router-link>
          <div class="slide-text">
            <p>
              <router-link
                v-for="tag in article.tags.slice(0, 3)"
                :key="tag"
                class="tag"
                :to="{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }"
                >#{{ tag }}</router-link
              >
            </p>
            <p class="has-text-lookin-darker">
              <router-link :to="{ name: 'new', query: { category: article.category.path } }">
                <span class="category has-text-black" :class="cateClass(article.category.id)">{{
                  article.category.title
                }}</span>
              </router-link>
              <span class="date-author"> {{ article.published_at }} ‧ {{ article.author_name }}</span>
            </p>
            <router-link
              :to="{
                name: 'article',
                params: {
                  channel: article.parent_path,
                  sub_channel: article.child_path,
                  id: article.id,
                  title: article.title
                }
              }"
            >
              <h3 class="title">{{ article.title }}</h3>
            </router-link>
          </div>
        </div>
      </template>
    </slick>
    <b-icon icon="angle-right" class="slick-arrow next" @click.native="next()"></b-icon>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { categoryClass } from 'src/share'
// import LazyImage from '../../components/common/LazyImage.vue'

export default {
  props: ['isLoading', 'member_only'],
  components: {},
  data() {
    return {
      slickOptions: {
        // autoplay: true,
        infinite: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        swipeToSlide: true
      }
    }
  },
  watch: {
    isMobile: function () {
      this.slickOptions.slidesToShow = this.isMobile ? 1 : 3
      if (this.$refs.membersOnlySlick !== undefined) {
        this.$refs.membersOnlySlick.reSlick()
      }
    }
  },
  methods: {
    prev() {
      this.$refs.membersOnlySlick.prev()
    },
    next() {
      this.$refs.membersOnlySlick.next()
    },
    cateClass: id => categoryClass(id)
  },
  computed: {
    ...mapState(['isMobile'])
  }
}
</script>
<style lang="scss">
#members-only {
  position: relative;
  .slick-slider {
    height: 465px;
    .slick-slide {
      height: 420px;
      .content {
        padding: 3px 0 100px;
        .image {
          display: inline-block;
          width: 250px;
          height: 250px;
          border: 1px solid #f08f7a;
          border-radius: 50%;
          margin: 2px;
          padding: 3px;
          img {
            height: 100%;
            width: 100%;
            margin: 0 auto;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .slide-text {
          margin-top: 10px;
          max-width: 450px;
          margin: 0 auto;
          p {
            padding: 5px;
            margin: 0;
            span.category {
              font-size: 13px;
              line-height: 185%;
              letter-spacing: 1px;
              padding: 1px 6px;
            }
            span.date-author {
              font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
              font-size: 14px;
              line-height: 71%;
              letter-spacing: 1px;
            }
          }
          h3.title {
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 1px;
            padding: 0 18px;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .tag {
            font-size: 13px;
            line-height: 185%;
            text-align: center;
            letter-spacing: 1px;
            color: #f08f7a;
            background-color: transparent;
            margin: 6px 6px 0;
            border: 1px solid #f08f7a;
            border-radius: 50px;
          }
        }
      }
    }
    .slick-dots {
      top: 250px;
      height: 35px;
      li {
        button {
          border-radius: 50%;
          border: 1px solid #f08f7a;
          width: 6px;
          height: 6px;
          padding: 3px;
        }
        button:before {
          content: '';
        }
      }
      li.slick-active button {
        background-color: #f08f7a;
      }
    }
  }

  .slick-arrow {
    color: #f08f7a;
    font-size: 24px;
    position: absolute;
    top: 43%;
    z-index: 3;
  }
  .slick-arrow.prev {
    left: 8px;
  }
  .slick-arrow.next {
    right: 8px;
  }
}
</style>
