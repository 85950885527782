<template>
  <div class="product">
    <div class="wrap">
      <div class="pic"><img :src="item.image"></div>
      <div class="title">
        <div>體驗數量<span>{{ item.amount }}</span>份</div>
        <h2>{{ item.title }}</h2>
      </div>
    </div>
    <div class="desc">
        {{ item.desc }}
    </div>
  </div>
</template>
<script>

export default {
  props: ['item'],
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
  },
}
</script>
<style lang="scss">

</style>
