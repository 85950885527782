<template>
  <section v-if="!isLoading" id="trial">
    <div :class="`event event-${design}`">
      <div class="img"><img :src="current_trial.cover_image"></div>
      <h1><span>{{ current_trial.title }}</span></h1>
      <p class="deadline">{{ current_trial.ended_at }} 報名截止</p>
      <div class="article-content" v-html="current_trial.content_html"></div>
      <div class="apply">
        <template v-if="current_trial.participated == true">
          <b-button disabled expanded>已報名</b-button>
        </template>
        <template v-else-if="!isOngoing(current_trial.ended_time)">
          <b-button disabled expanded>報名已截止</b-button>
        </template>
        <template v-else>
          <b-button v-if="!isApplied && isMobile" expanded @click="showApplyDialog">我要體驗</b-button>
          <div v-else class="apply-dialog">
            <div class="apply-header">
              <h3>我要體驗</h3>
            </div>
            <div class="apply-body">
              <form @submit.prevent="apply">
                <b-field label="姓 名">
                  <b-input
                    v-model="info.name"
                    @focus="setViaAndLogin()"
                    placeholder="請輸入姓名"
                    required>
                  </b-input>
                </b-field>
                <b-field label="電 話">
                  <b-input type="phone"
                    v-model="info.phone"
                    @focus="setViaAndLogin()"
                    placeholder="請輸入聯絡電話"
                    pattern="[0-9]*"
                    inputmode="tel"
                    required>
                  </b-input>
                </b-field>
                <b-field label="住 址">
                  <b-input
                    v-model="info.address"
                    placeholder="請輸入住址"
                    required>
                  </b-input>
                </b-field>
                <b-field label="Email">
                  <b-input type="email"
                    v-model="info.email"
                    placeholder="請輸入電子郵件"
                    inputmode="email"
                    required>
                  </b-input>
                </b-field>
                <b-field v-if="multiProducts" label="選擇產品">
                  <b-select v-model="info.item_id" placeholder="請選擇試用產品">
                    <option v-for="item in current_trial.trial_items" :value="item.id">
                      {{ item.title }}
                    </option>
                  </b-select>
                </b-field>
                <b-checkbox v-model="privacy">
                  我已經詳細閱讀並同意<a href="/privacy" target="_blank">隱私權政策</a>
                </b-checkbox>
                <p class="notice">貼心小提醒：每個帳號僅能報名乙次，請務必確認填寫資料正確後再送出喔！</p>
                <b-button type="is-dark" rounded @click="apply">送 出</b-button>
              </form>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class='event-max-width'>
      <section :class="`items ${design}`">
        <TrialItem v-for="item in current_trial.trial_items" :item="item" :key="item.id"/>
      </section>

      <Comments :trial_id="current_trial.id" :trial_items="current_trial.trial_items" :comments="current_trial.comments"/>
    </div>
    <Modal
      :open="modal"
      :title="modalContent.title"
      :content="modalContent.content"
      :btnWord="modalContent.btnWord"
      @close="closeModal"
    />
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { isOngoing } from 'src/share'
import TrialItem from './TrialItem'
import Comments from 'src/components/common/Comments'
import Modal from '../../components/dialog/Modal'

export default {
  data() {
    return {
      isLoading: true,
      isApplied: false,
      info: {
        name: null,
        address: null,
        phone: null,
        email: null,
        item_id: null
      },
      privacy: false,
      modal: false,
      modalContent: {
        title: null,
        content: null,
        btnWord: null
      },
      loginRequired: false,
      loginContent: {
        title: '請先登入',
        content: '登入會員再體驗喔！',
        btnWord: '好的'
      },
      inCompleted: {
        title: '未完成',
        content: '請確認填寫完畢，並勾選「同意隱私權政策」。',
        btnWord: '好的'
      },
      successApply: {
        title: '成功送出！',
        content: '請等候通知',
        btnWord: '好的'
      },
      errorContent: {
        title: '很抱歉！',
        content: '出錯了！',
        btnWord: '確認'
      }
    }
  },
  components: { TrialItem, Comments, Modal },
  watch: {
    user: function (val) {
      this.initUserInfo()
    }
  },
  computed: {
    ...mapState(['user', 'isMobile', 'current_trial']),
    design: function () {
      return (this.current_trial) ? this.current_trial.style : ''
    },
    multiProducts: function () {
      return (this.current_trial) ? (this.current_trial.trial_items.length > 1) : false
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setVia']),
    isOngoing,
    initUserInfo: function () {
      if (this.user != null && this.info.name == null) {
        this.info.name = this.user.realname
        this.info.address = this.user.full_address
        this.info.phone = this.user.phone
        this.info.email = this.user.email
      }
    },
    setViaAndLogin: function () {
      if (!this.user) {
        this.setVia({
          type: 'Trial',
          id: this.current_trial.id,
          path: 'apply'
        })
        this.loginRequired = true
        this.modalContent = this.loginContent
        this.modal = true
      }
    },
    showApplyDialog: function () {
      if (!!this.user) {
        this.name    = this.user.realname
        this.address = this.full_address
        this.phone   = this.user.phone
        this.email   = this.user.email
        if (!this.multiProducts) {
          this.info.item_id = this.current_trial.trial_items[0].id
        }
        this.isApplied = true
      } else {
        this.setViaAndLogin()
      }
    },
    validForm: function () {
      if (!this.multiProducts) {
        this.info.item_id = this.current_trial.trial_items[0].id
      }
      return this.privacy && this.info.name && this.info.address && this.info.phone && this.info.email && this.info.item_id
    },
    apply: function () {
      if (this.validForm()) {
        let params = Object.assign({ id: this.current_trial.id }, this.info)
        this.$store.dispatch('sumbitTrialItemForm', params).then(() => {
          this.isApplied = false
          this.modalContent = this.successApply
          this.modal = true
        }).catch((error) => {
          this.modalContent = this.errorContent
          this.modalContent.content = error.data.user[0]
          this.modal = true
        })
      } else {
        this.modalContent = this.inCompleted
        this.modal = true
      }
    },
    closeModal() {
      this.modal = false
      if (this.loginRequired == true) {
        this.loginRequired = false
        this.setLoginDrawer(true)
      }
    },
  },
  created() {
    this.$store.dispatch('loadTrial').then(() => {
      this.isLoading = false
      this.initUserInfo()
    })
  },
}
</script>
<style lang="scss">
#trial {
  width: 100%;
  .event-max-width {
    max-width: 650px;
    margin: 0 auto;
  }
  .event {
    width: 100%;
    .img {
      width: 100%;
      img {
        width: 100%;
        max-width: 950px;
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }
    h1 {
      width: 95%;
      max-width: 1200px;
      font-weight: bolder;
      font-size: 22px;
      letter-spacing: 1px;
      margin: 12px auto 14px;
      text-align: center;
      line-height: 177%;
      @media screen and (min-width: 1024px) {
        font-size: 28px;
      }
      span {
        padding: 1px 6px;
      }
    }
    .deadline {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      display: block;
    }
    .apply {
      margin: 60px auto;
      width: 80vw;
      max-width: 650px;
      > button {
        height: 53px;
        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 21px;
          text-align: center;
        }
      }
    }
    .apply-dialog {
      .apply-header {
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 53px;
          text-align: center;
          vertical-align: middle;
        }
      }
      .apply-body {
        form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: 25px 8px;
          .field {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            width: 100%;
            .label {
            }
            .control {
              min-width: 76%;
              max-width: 76%;
              .select {
                width: 100%;
              }
              .input, .select select {
                width: 100%;
                padding: 3px;
                line-height: 24px;
                border-width: 0 0 1px 0;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                border-radius: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                letter-spacing: 1px;
              }
              .select select:active, .select select:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
              }
            }
            p.help {
              width: 100%;
              text-align: center;
            }
          }
          .checkbox {
            margin-top: 15px;
            .control-label {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              text-align: justify;
            }
          }
          .notice {
            padding: 0 5%;
            margin: 25px 0 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;
          }
          button {
            width: 50%;
            height: 34px;
          }
        }
      }
    }

    &.event-white {
      .img {
        background-color: #FFF0EE;
      }
      h1 {
        span {
          background-color: #ffdbcf;
          color: #000000;
        }
      }
      .deadline {
        color: #F08F7A;
      }
      .apply {
        > button {
          background: #FFDBCF;
          border-color: #FFDBCF;
          filter: drop-shadow(0px 3px 6px rgba(240, 143, 122, 0.6));
        }
        .apply-dialog {
          .apply-header {
            background: #FFDBCF;
            filter: drop-shadow(0px 3px 2px rgba(240, 143, 122, 0.6));
          }
          .apply-body {
            border: 1px solid #FFC2AE;
            box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
            form .control .input, .select select {
              border-bottom: 1px solid #FFC2AE;
            }
          }
        }
      }
    }

    &.event-pink {
      .img {
        @media screen and (min-width: 1024px) {
          background-color: #FFF0EE;
          margin-bottom: 80px;
        }
        img {
          position: relative;
          @media screen and (min-width: 1024px) {
            top: 60px;
          }
        }
      }
      h1 {
        span {
          background-color: #FF7BAC;
          color: #ffffff;
        }
      }
      .deadline {
        color: #FF7BAC;
      }
      .apply {
        > button {
          color: #FFFFFF;
          background: #FF7BAC;
          border-color: #FF7BAC;
        }
        .apply-dialog {
          .apply-header {
            color: #FFFFFF;
            background: #FF7BAC;
          }
          .apply-body {
            border: 1px solid #FF7BAC;
            form .control .input, .select select {
              border-bottom: 1px solid #FF7BAC;
            }
          }
        }
      }
    }

    &.event-yellow {
      .img {
        @media screen and (min-width: 1024px) {
          margin-bottom: 94px;
        }
        img {
          position: relative;
          @media screen and (min-width: 1024px) {
            top: 60px;
            border: 10px solid #FFB5B4;
            box-shadow: 4px 4px 0 #ffffff, 14px 14px 0 #FFB5B4;
          }
        }
      }
      h1 {
        span {
          background-color: #FC908F;
          color: #FFFFFF;
        }
      }
      .deadline {
        color: #989595;
      }
      .apply {
        > button {
          color: #FFFFFF;
          background: #FC908F;
          border-color: #FC908F;
        }
        .apply-dialog {
          .apply-header {
            color: #FFFFFF;
            background: #FC908F;
          }
          .apply-body {
            border: 1px solid #FFC2AE;
            form .control .input, .select select {
              border-bottom: 1px solid #FFC2AE;
            }
          }
        }
      }
    }
  }
}

#trial {
  section.items {
    width: 100%;
    padding: 2rem 0;
    overflow: hidden;
    margin: 2rem auto;
    box-sizing: initial;
    .product {
      margin: auto;
      padding: 40px 0;
      max-width: 650px;
      word-break: break-word;
      .pic {
        display: inline-block;
        img {
          width: 150px;
          height: 150px;
          overflow: hidden;
          object-fit: cover;
        }
      }
      .title {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        div {
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          letter-spacing: .5px;
          box-sizing: initial;
          line-height: normal;
          span {
            font-size: 17px;
            font-weight: bolder;
          }
        }
        h2 {
          font-size: 18px;
          font-weight: bolder;
          letter-spacing: .5px;
          line-height: 1.5rem;
        }
      }
      .desc {
        font-size: 15px;
        line-height: 1.4rem;
        margin-top: 20px;
        text-align: justify;
      }
    }
    .product:nth-child(1) {
      padding-top: 0;
      border-top: none;
    }
  }

  .white {
    .product {
      width: 92%;
      border-top: 1px solid #FFDBCF;
      .pic {
        img {
          border: #FFC2AE solid 1px;
          box-shadow: 3px 3px 0 #FFC2AE;
        }
      }
      .title {
        width: calc(94% - 150px);
        max-width: 350px;
        margin-top: 15px;
        margin-left: 10px;
        div {
          background-color: #FFDBCF;
          padding: 3px;
          span {
            padding: 0 3px;
            color: #F08F7A;
          }
        }
        h2 {
          margin-top: 20px;
        }
      }
    }
  }

  .pink {
    background-color: #FFF6F5;
    .product {
      width: 92%;
      border-top: 1px solid #FF7BAC;
      @media screen and (min-width: 1024px) {
        text-align: -webkit-left;
      }
      .wrap {
        max-width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        @media screen and (min-width: 1024px) {
          margin: 0;
        }
        .pic {
          border-radius: 50%;
          position: relative;
          z-index: 1;
          &::before {
            content: '';
            width: 130px;
            height: 130px;
            border: 1.5px dashed #FF7BAC;
            border-radius: 50%;
            z-index: 0;
            position: absolute;
            right: -8px;
            top: 24px;
          }
          &::after {
            content: '';
            width: 22px;
            height: 22px;
            background-color: #FF7BAC;
            border-radius: 50%;
            z-index: 0;
            position: absolute;
            right: 0px;
            bottom: 4px;
          }
          img {
            border-radius: 50%;
            position: relative;
            z-index: 1;
          }
        }
        .title {
          width: 44%;
          margin-top: 15px;
          margin-left: 4%;
          position: relative;
          &::before {
            content: '';
            height: 18px;
            border-left: 6px solid #FF7BAC;
            transform: rotate(45deg);
            position: absolute;
            top: -10px;
            left: 128px;
            @media screen and (min-width: 1024px) {
              left: 158px;
            }
          }
          &::after {
            content: '';
            height: 18px;
            border-left: 6px solid #FF7BAC;
            transform: rotate(-45deg);
            position: absolute;
            top: -10px;
            left: 128px;
            @media screen and (min-width: 1024px) {
              left: 158px;
            }
          }
          div {
            width: 150px;
            background-color: #FF7BAC;
            padding: 3px 15px;
            border-radius: 0px 20px 20px 0px;
            position: relative;
            top: 12px;
            left: calc(50% - 36px);
            text-align: right;
            transform: translateX(-50%);
            -moz-transform:  translateX(-50%);
            -ms-transform:  translateX(-50%);
            -o-transform:  translateX(-50%);
            -webkit-transform:  translateX(-50%);
            span {
              padding: 0 3px;
              color: #FFFFFF;
            }
          }
          h2 {
            width: 100%;
            margin-top: 30px;
            text-align: left;
            @media screen and (min-width: 1024px) {
              width: 300px;
              max-width: 300px;
            }
          }
        }
      }
    }
    .product:nth-child(2n) {
      @media screen and (min-width: 1024px) {
        text-align: -webkit-right;
      }
      .wrap {
        .pic {
          order: 2;
          &::before {
            content: '';
            position: absolute;
            right: -8px;
            top: 22px;
          }
          &::after {
            content: '';
            display: none;
          }
        }
        .title {
          width: 45%;
          margin-top: 12px;
          margin-left: 0;
          margin-right: 3%;
          &::before {
            content: '';
            position: absolute;
            top: 40px;
            left: 10px;
          }
          &::after {
            content: '';
            position: absolute;
            top: 40px;
            left: 10px;
          }
          div{
            position: relative;
            left: calc(50% + 36px);
            text-align: left;
            border-radius: 20px 0px 0px 20px;
          }

          h2{
            margin-top: 40px;
            @media screen and (min-width: 1024px) {
              position: relative;
              left: 15%;
              transform: translateX(-50%);
              -moz-transform:  translateX(-50%);
              -ms-transform:  translateX(-50%);
              -o-transform:  translateX(-50%);
              -webkit-transform:  translateX(-50%);
              text-align: right;
            }
          }
        }
      }
    }
  }

  .yellow {
    .product {
      width: 96%;
      background-color: #FFF6DE;
      padding: 0 2% !important;
      position: relative;
      &::before {
        content: '';
        width: 80px;
        height: 26px;
        background-color: #D2D2D2;
        opacity: .7;
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%) rotate(-10deg);
        -moz-transform:  translateX(-50%) rotate(-10deg);
        -ms-transform:  translateX(-50%) rotate(-10deg);
        -o-transform:  translateX(-50%) rotate(-10deg);
        -webkit-transform:  translateX(-50%) rotate(-10deg);
      }
      .wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px auto 0;
        padding: 35px 0 0;
        .pic {
          border-radius: 50%;
          position: relative;
          z-index: 1;
          &::before {
            content: '';
            width: 90px;
            height: 60px;
            background-image: radial-gradient(#FFC2AE 20%, transparent 20%);
            background-size: 10px 10px;
            display: inline-block;
            position: absolute;
            left: 85px;
            bottom: -5px;
            opacity: .9;
            z-index: 0;
          }
          img {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            @media screen and (min-width: 1024px) {
              width: 150px;
              height: 150px;
            }
          }
        }
        .title {
          width: 44%;
          margin-top: 15px;
          margin-left: 4%;
          position: relative;
          vertical-align: top;
          @media screen and (min-width: 1024px) {
            margin-left: 40px;
          }
          div {
            background-color: #FFB5B4;
            padding: 4px 5px;
            z-index: 1;
            position: relative;
            span {
              padding: 0 3px;
              color: #FFFFFF;
            }
          }
          h2 {
            width: 100%;
            margin-top: 15px;
            z-index: 1;
            position: relative;
            @media screen and (min-width: 1024px) {
              width: 300px;
              max-width: 300px;
            }
          }
        }
      }
      .desc {
        padding: 0 10px 20px;
        text-align: justify;
      }
    }
    .product:nth-child(2n) {
      &::before {
        content: '';
        width: 60px;
        height: 26px;
        background-color: #D2D2D2;
        opacity: .7;
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%) rotate(2deg);
        -moz-transform:  translateX(-50%) rotate(2deg);
        -ms-transform:  translateX(-50%) rotate(2deg);
        -o-transform:  translateX(-50%) rotate(2deg);
        -webkit-transform:  translateX(-50%) rotate(2deg);
      }
      .wrap {
        .pic {
          order: 2;
          &::before {
            content: '';
            width: 90px;
            height: 70px;
            background-image: radial-gradient(#FFC2AE 20%, transparent 20%);
            background-size: 10px 10px;
            display: inline-block;
            position: absolute;
            left: -40px;
            bottom: initial;
            top: 15px;
            opacity: .9;
            z-index: 0;
          }
        }
        .title {
          margin-left: 0;
          margin-right: 4%;
          @media screen and (min-width: 1024px) {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
</style>
