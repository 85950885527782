<template>
  <div class="coupon-icon">
    <i class="c-icon"></i>
  </div>
</template>

<script>
export default {
  name: 'CouponIcon',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.coupon-icon {
  .c-icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: #f08f7a;
    border-radius: 999px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 18px;
      left: 17px;
      width: 5px;
      height: 14px;
      background-color: #ffffff;
      border-radius: 5px;
      transform: rotate(-45deg);
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 13px;
      left: 33px;
      width: 5px;
      height: 28px;
      background-color: #ffffff;
      border-radius: 5px;
      transform: rotate(45deg);
    }
  }
}
</style>
