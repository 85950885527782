<template>
  <section id='my-collections'>
    <h1>收藏紀錄</h1>
    <div class="collections">
      <ul v-if="!isLoading">
        <transition-group name="list">
          <li v-for="(item, i) in collections" :key="item.id" class="list-item">
            <router-link :to="item.path" class="title-wrapper">
              <p>{{ item.date }}</p>
              <h3 class="has-text-black">{{ item.title }}</h3>
            </router-link>
            <img :src="require('@/../images/keep_delete.svg')" @click="removeCollection(item.id)">
          </li>
        </transition-group>
      </ul>
    </div>
    <Modal
      :open="confirmModal"
      :title="'刪除收藏'"
      :content="'確定要刪除這則收藏嗎？'"
      :btnWord="'取消'"
      :confirm="'刪除'"
      @close="confirmModal = false"
      @submit="confirmRemove"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '../../components/dialog/Modal'

export default {
  data() {
    return {
      isLoading: true,
      remove_id: null,
      confirmModal: false,
    };
  },
  components: { Modal },
  computed: {
    ...mapState(['collections']),
  },
  methods: {
    removeCollection(id) {
      this.remove_id = id
      this.confirmModal = true
    },
    confirmRemove() {
      this.$store.dispatch('removeBookmark', this.remove_id).then(() => {
        this.confirmModal = false
        this.remove_id = null
      })
    },
  },
  created() {
    this.$store.dispatch('loadCollections').then(() => {
      this.isLoading = false
    })
  },
};
</script>
<style lang="scss">
#my-collections {
  max-width: 1000px;
  margin: 10px auto;
  h1 {
    margin: 20px 15px 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
    letter-spacing: 2px;
  }
  .collections {
    margin-bottom: 100px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-height: 85px;
      overflow: hidden;
      padding: 10px 14px 10px 26px;
      border-bottom: 1px solid #FFDBCF;
      background: rgba(255,240,238, 0.7);
      p {
        color: #989595;
        font-family: Ubuntu Condensed;
        padding: 2px 0 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 10px;
        letter-spacing: 1px;
      }
      .title-wrapper {
        width: 76vw;
        h3 {
          font-size: 15px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 1px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      img {
        height: 20px;
        cursor: pointer;
      }
    }
    .list-enter-active, .list-leave-active {
      transition: all 1s;
    }
    .list-enter, .list-leave-to {
      opacity: 0;
      transform: translateY(-30px);
    }
  }
}

@media screen and (min-width: 1200px) {
  #my-collections {
    max-width: 1000px;
    width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #FFC2AE;
    }
  }
}
</style>
