<template>
  <section>
    <b-sidebar :type="'is-nav-bg'" :fullheight="true" :fullwidth="true" v-model="open">
      <div id="menu-drawer" class="has-background-nav-bg drawer">
        <a v-if="false" class="logout" @click="logout">登出</a>
        <img
          :src="require('@/../images/icon-close.svg')"
          alt="Close"
          class="tail-icon close"
          @click="emitCloseDrawer()"
        />
        <h2 class="join-us-title title eng-font">Join Us</h2>
        <div v-if="user">
          <b-button rounded id="member-btn" @click="gotoMy">
            <img :src="userAvatar(user.avatar_url)" id="avatar" />
            <div id="wording">
              <div class="m-title">會員專區</div>
              <div class="member-name">{{ user.nickname }}</div>
            </div>
            <img :src="require('@/../images/arrow.svg')" class="arrow" />
          </b-button>
        </div>
        <div v-else>
          <b-button rounded @click="openLogin">會員登入</b-button>
          <b-button rounded @click="openSignUp">會員註冊</b-button>
        </div>
        <div>
          <a href="https://choc.look-in.com.tw/" target="_blank">
            <b-button type="is-nav-btn" rounded>線上電子雜誌</b-button>
          </a>
        </div>

        <h2 class="hot-tag title eng-font"><span class="underline">Hot Tag</span></h2>
        <div v-if="hot_tags" class="hot-tags">
          <b-icon icon="angle-left" class="slick-arrow prev" @click.native="prev()"></b-icon>
          <slick ref="tagSlick" :options="slickOptions">
            <router-link
              v-for="tag in hot_tags"
              :key="tag"
              class="tag-link"
              :to="{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }"
              @click.native="emitCloseDrawer"
              >#{{ tag }}</router-link
            >
          </slick>
          <b-icon icon="angle-right" class="slick-arrow next" @click.native="next()"></b-icon>
        </div>
        <h2 class="category-title title eng-font">Categories</h2>
        <div v-if="menu" class="categories">
          <template v-for="item in menu">
            <!-- <router-link :to="item.link" @click.native="emitCloseDrawer">
              <div class="category">
                <img :src="item.image_url" class="bg">
                  <div slot="error" class="image-error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </img>
                <div class="name">{{ item.title }}</div>
              </div>
            </router-link> -->
            <a :href="item.link" @click.native="emitCloseDrawer">
              <div class="category">
                <img :src="item.image_url" class="bg" />
                <div slot="error" class="image-error">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <!-- </img> -->
                <div class="name">{{ item.title }}</div>
              </div>
            </a>
          </template>
        </div>
        <div class="social-links">
          <a href="https://www.youtube.com/channel/UC9FymR5DhAubxbjWM9JmS_Q?sub_confirmation=1" target="_blank">
            <img :src="require('@/../images/youtube.svg')" alt="YouTube" />
          </a>
          <a href="https://www.facebook.com/lookin.tw/" target="_blank">
            <img :src="require('@/../images/facebook.svg')" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/ilovelookin/" target="_blank">
            <img :src="require('@/../images/instagram.svg')" alt="Instagram" />
          </a>
          <a href="https://line.me/R/ti/p/@tci1308w" target="_blank">
            <img :src="require('@/../images/line.svg')" alt="LINE" />
          </a>
        </div>
      </div>
    </b-sidebar>
    <Modal
      :open="logoutModal"
      :title="'成功登出'"
      :content="'下次再回來喔～'"
      :btnWord="'好的'"
      @close="logoutModal = false"
    />
  </section>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Modal from '../dialog/Modal'

export default {
  props: ['open'],
  data() {
    return {
      logoutModal: false,
      slickOptions: {
        infinite: true,
        slidesToShow: 3,
        arrows: false,
        variableWidth: true,
        swipeToSlide: true
      },
      tagIndex: 0
    }
  },
  components: { Modal },
  computed: {
    ...mapState(['menu', 'hot_tags', 'user']),
    // ...mapGetters(['isAuthed']),
    tabGoLeftDisabled() {
      return this.tagIndex <= 0
    },
    tabGoRightDisabled() {
      return this.tagIndex + 3 >= this.tags.length
    },
    userAvatar() {
      return avatar => (avatar ? avatar : require('@/../images/user_default.png'))
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setSignUpDrawer']),
    emitCloseDrawer: function () {
      this.$emit('closeDrawer', true)
    },
    openLogin: function () {
      this.setLoginDrawer(true)
      this.$emit('closeDrawer', true)
    },
    openSignUp: function () {
      this.setSignUpDrawer(true)
      this.$emit('closeDrawer', true)
    },
    prev() {
      this.$refs.tagSlick.prev()
    },
    next() {
      this.$refs.tagSlick.next()
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.logoutModal = true
        this.$emit('closeDrawer', true)
        if (this.$route.name != 'home') {
          this.$router.push({ name: 'home' })
        }
      })
    },
    gotoMy: function () {
      this.$emit('closeDrawer', true)
      if (this.$route.name != 'my_me') {
        this.$router.push({ name: 'my_me' })
      }
    }
  }
}
</script>
<style lang="scss">
#menu-drawer {
  position: absolute;
  width: 100vw;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .logout {
    position: absolute;
    left: 4.8%;
    top: 2.22%;
    border: 1px solid #000000;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    color: black;
    padding: 0 10px;
    margin: 5px 6px;
    border-radius: 24px;
    text-decoration: none;
    outline: 0;
    padding: 0 10px;
  }

  .join-us-title {
    margin-top: 15%;
  }

  .button.is-rounded {
    margin: 5px 9px;
    border: none;
    height: 36px;
    box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
  }

  .hot-tag.title {
    margin-top: 13%;
  }

  .hot-tags {
    width: 100%;
    height: 3rem;
    position: relative;
  }

  .category-title.title {
    margin-top: 1.6rem;
  }

  .slick-list {
    width: 76%;
    margin: 0 auto;
    .slick-slide {
      margin: 0 5px;
      a.tag-link {
        width: 92% !important;
      }
    }
  }
  .slick-arrow {
    color: #000000;
    font-size: 24px;
    position: absolute;
    top: 6px;
    z-index: 40;
  }
  .prev {
    left: 15px;
  }
  .next {
    right: 15px;
  }

  .social-links {
    margin: 8vw auto 6vw;
    display: flex;
    justify-content: space-around;
    width: 68vw;
    padding: 1rem 0;
    img {
      width: 30px;
      height: 30px;
    }
  }
  #member-btn {
    height: 51px;
    padding: 5px;
    span {
      position: relative;
      display: flex;
      align-items: center;
      #avatar {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        margin: 4px;
        object-fit: cover;
      }
      #wording {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 4px;
        .m-title {
          font-size: 13px;
          font-weight: 400;
          line-height: 13px;
          letter-spacing: 1px;
          margin: 2px;
        }
        .member-name {
          width: 60px;
          overflow: hidden;
          font-size: 15px;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: 1px;
          margin: 2px;
        }
      }
      .arrow {
        height: 8px;
        width: 8px;
      }
    }
  }
}

.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
  & .underline {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      height: 6px;
      background: #f08f7a;
      position: absolute;
      left: -10px;
      right: -10px;
      bottom: 5px;
      z-index: -1;
    }
  }
}

.categories {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin: 8px;
    flex: 0 0 35%;
    line-height: 3rem;
    .category {
      position: relative;
      width: 40vw;
      height: 16vw;
      .name {
        font-size: 16px;
        font-weight: 700;
        line-height: 16vw;
        letter-spacing: 2px;
        text-align: center;
        color: white;
      }
      img.bg {
        border-radius: 6px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        -webkit-filter: brightness(50%);
        filter: brightness(50%);
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #menu-drawer {
    .hot-tag.title {
      margin-top: 8vw;
    }
    .category-title.title {
      margin-top: 2vw;
    }
    .button.is-rounded.is-nav-btn {
      width: 38vw;
      margin: 5px;
    }
    h2.title {
      margin-top: 1rem;
    }
    .categories a .category .name {
      font-size: 15px;
    }
  }
}
</style>
