<template>
  <div class="popup">
    <transition name="fade">
      <div class="popup-overlay" @click="clickMask" v-show="visible"></div>
    </transition>
    <transition name="slide">
      <div class="popup-content" v-show="visible">
        <img
          class="close"
          @click="onClose"
          src="../../../images/icon-close.svg"
          alt="close"
        />
        <div class="title">
          <h1>{{ title }}</h1>
        </div>
        <div class="options">
          <p
            v-for="(item, index) in list"
            :key="index"
            :class="{ active: active === index + 1 }"
            @click="onSelect"
          >
            {{ item }}
          </p>
        </div>
        <button class="confirm" @click="onConfirm">確定</button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "popup",
  props: {
    title: String,
    list: Array,
    visible: Boolean,
  },
  data() {
    return {
      selectName: "",
    };
  },
  mounted() {
    this.selectName = this.list[this.active - 1];
  },
  computed: {
    active() {
      const leg = this.list.length / 2;
      return Math.ceil(leg);
    },
  },
  methods: {
    onSelect(e) {
      const target = e.target;
      const className = target.className;
      const siblings = target.parentNode.children;
      if (className !== "active") {
        for (let i = 0; i < siblings.length; i++) {
          siblings[i].className = "";
        }
        target.className = "active";
      }
      this.selectName = target.innerText;
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    clickMask() {
      this.$emit("update:visible", false);
    },
    onConfirm() {
      this.$emit("onConfirm", this.selectName);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transform: translateY(100%);
}

.popup {
  .popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    z-index: 98;
  }
  .popup-content {
    height: 55%;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    transition: transform 0.3s;
    border-radius: 11px 11px 0px 0px;
    text-align: center;
    padding: 21px 14px;
    z-index: 99;
    .close {
      position: absolute;
      top: 24px;
      left: 14px;
      width: 17px;
      height: 17px;
      opacity: 0.5;
    }
    .title {
      h1 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 22px;
      }
    }
    .options {
      // height: 50%;
      height: 150px;
      overflow-y: scroll;
      margin-bottom: 35px;
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #989595;
        line-height: 40px;
      }
      .active {
        color: #404040;
      }
    }
    .confirm {
      width: 200px;
      height: 50px;
      background: #f08f7a;
      border-radius: 25px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
      border: none;
      outline: none;
    }
  }
}
</style>
