<template>
  <section id="form-wrapper">
    <form v-if="userData" id="user-form" enctype="multipart/form-data" @submit.prevent="edit ? update() : signUp()">
      <div v-if="edit" class="field">
        <label class="label">會員圖像</label>
        <b-field class="file is-primary" :class="{ 'has-name': !!avatar }">
          <b-upload v-model="avatar" class="file-label" accept="image/*">
            <img class="avatar" :src="displayAvatar" />
            <div class="upload-icon">
              <img :src="require('@/../images/upload_image.svg')" />
            </div>
          </b-upload>
        </b-field>
        <label class="label fixed-email">Email</label>
        <p>{{ displayEmail }}</p>
      </div>
      <b-field label="暱稱*">
        <b-input
          rounded
          v-model="userData.nickname"
          icon-right="user"
          placeholder="請輸入暱稱，之後仍可以修改"
          ref="nickname"
          required
        >
        </b-input>
      </b-field>
      <div v-if="!edit">
        <b-field label="Email*">
          <b-input
            rounded
            type="email"
            icon-right="envelope"
            v-model="userData.email"
            placeholder="example@mail.com"
            inputmode="email"
            required
          >
          </b-input>
        </b-field>
        <b-field label="密碼*">
          <b-input
            rounded
            type="password"
            v-model="userData.password"
            placeholder="長度 6 字元以上，需區分大小寫"
            password-reveal
            required
          >
          </b-input>
        </b-field>
        <b-field label="確認密碼*">
          <b-input
            rounded
            type="password"
            v-model="userData.password_confirmation"
            placeholder="請再次輸入密碼"
            password-reveal
            required
          >
          </b-input>
        </b-field>
      </div>
      <b-field label="姓名*">
        <b-input rounded v-model="userData.realname" placeholder="請輸入真實姓名，用於抽獎" required> </b-input>
      </b-field>
      <b-field label="手機號碼">
        <b-input rounded v-model="userData.phone" placeholder="請輸入手機號碼" pattern="[0-9]*" inputmode="tel">
        </b-input>
      </b-field>
      <b-field label="聯絡信箱">
        <b-input rounded v-model="userData.contactEmail" type="email" placeholder="請輸入聯絡信箱" inputmode="email">
        </b-input>
      </b-field>
      <div class="line"></div>
      <b-field label="性別*">
        <b-radio-button v-model="userData.gender" native-value="female" type="is-lookin-darker">
          <span>女性</span>
        </b-radio-button>
        <b-radio-button v-model="userData.gender" native-value="male" type="is-lookin-darker">
          <span>男性</span>
        </b-radio-button>
        <b-radio-button v-model="userData.gender" native-value="hide" type="is-lookin-darker">
          <span>不透露</span>
        </b-radio-button>
        <!-- <b-radio-button v-model="userData.gender"
          native-value="agender"
          type="is-lookin-darker">
          <span>無性別</span>
        </b-radio-button> -->
      </b-field>
      <div v-if="edit">
        <b-field label="生日">
          <b-input
            rounded
            v-model="userData.birthday"
            type="date"
            @click.native="setBirthday"
            placeholder="請點選生日日期"
          >
          </b-input>
        </b-field>
        <div class="line"></div>
        <b-field label="居住地">
          <b-radio-button v-model="userData.foreign" class="boolean" native-value="false" type="is-lookin-darker">
            <span>國內</span>
          </b-radio-button>
          <b-radio-button v-model="userData.foreign" class="boolean" native-value="true" type="is-lookin-darker">
            <span>國外</span>
          </b-radio-button>
        </b-field>
        <transition name="slide">
          <div v-show="isNotForeign" class="address-set">
            <b-input type="hidden" id="zip-code"></b-input>
            <b-field label="縣市" class="city">
              <b-select id="city-select" v-model="userData.city" placeholder="選擇縣市" rounded> </b-select>
            </b-field>
            <b-field label="區域" class="dist">
              <b-select
                id="dist-select"
                v-model="userData.dist"
                @change.native="updateZipCode"
                placeholder="選擇區域"
                rounded
              >
              </b-select>
            </b-field>
          </div>
        </transition>
        <b-field label="地址" class="address">
          <b-input rounded v-model="userData.address" placeholder="請輸入地址，用於寄送贈品"> </b-input>
        </b-field>
      </div>
      <div class="line"></div>
      <b-field>
        <!-- <b-radio-button v-model="userData.edm"
          class="boolean"
          native-value="true"
          type="is-lookin-darker">
          <span>同意</span>
        </b-radio-button>
        <b-radio-button v-model="userData.edm"
          class="boolean"
          native-value="false"
          type="is-lookin-darker">
          <span>不同意</span>
        </b-radio-button> -->
        <b-checkbox v-model="userData.edm"> 是否願意收到電子報 </b-checkbox>
      </b-field>
      <div v-if="!edit">
        <p class="note">
          註冊即表示已詳細閱讀並同意<router-link :to="{ name: 'terms' }" class="" target="_blank">會員條款</router-link>
        </p>
        <button class="button btn-clear" @click.prevent="clean">清除重填</button>
        <button class="button btn-submit">送出</button>
      </div>
      <div v-else class="submit-block">
        <p class="note">
          貼心小提醒：首次填寫完整會員資料，再回饋 LOOKin 點數喔！相關說明請參閱
          <router-link :to="{ name: 'point-rules' }">LOOKin 點數說明</router-link>
        </p>
        <button class="button btn-update">更新資料</button>
      </div>
    </form>
    <Modal
      :open="modal.open"
      :title="modal.title"
      :content="modal.message"
      :btnWord="'確認'"
      @close="modal.open = false"
    />
  </section>
</template>
<script>
import TwCitySelector from 'tw-city-selector'
import { mapState } from 'vuex'
import Modal from '../dialog/Modal'
import { getContactEmail, modifyContactEmail } from '../../../api/prize'

export default {
  name: 'user_form',
  props: ['edit'],
  data() {
    return {
      initialized: false,
      isLoading: true,
      displayEmail: '',
      displayAvatar: require('@/../images/user_default.png'),
      userData: {
        avatar_url: '',
        nickname: null,
        email: null,
        password: null,
        password_confirmation: null,
        realname: null,
        gender: 'female',
        birthday: null,
        phone: null,
        foreign: 'false',
        zip_code: null,
        city: null,
        dist: null,
        address: null,
        edm: true,
        contactEmail: ''
      },
      avatar: null,
      emptyUser: {
        nickname: null,
        email: null,
        password: null,
        password_confirmation: null,
        realname: null,
        gender: 'female',
        birthday: null,
        phone: null,
        foreign: 'false',
        zip_code: null,
        city: null,
        dist: null,
        address: null,
        edm: true
      },
      modal: {
        open: false,
        message: null,
        title: null
      }
    }
  },
  components: { Modal },
  computed: {
    ...mapState(['user', 'token']),
    isNotForeign() {
      return this.userData.foreign !== 'true'
    }
  },
  watch: {
    user: function (val) {
      if (!this.initialized && !!val) {
        // 用 initialized 確認 state.user 已經從 API 讀到
        // TODO: 應該有更好的做法...
        this.initialized = true
      }
    },
    initialized: function (val) {
      if (this.edit) {
        this.contactEmail()
        this.displayEmail = this.user.email
        Object.assign(this.userData, this.user)
        delete this.userData.email
        this.userData.foreign = this.user.foreign !== true ? 'false' : 'true'
        this.userData.edm = this.user.edm !== false ? 'true' : 'false'
        new TwCitySelector({
          el: '.address-set',
          elCounty: '#city-select',
          elDistrict: '#dist-select',
          elZipcode: '#zip-code',
          countyValue: this.userData.city,
          districtValue: this.userData.dist
        })
      } else {
        Object.assign(this.userData, this.emptyUser)
      }
    },
    'userData.avatar_url': function (val) {
      this.displayAvatar = val
    },
    avatar: function (val) {
      let reader = new FileReader()
      reader.onload = e => {
        this.displayAvatar = e.target.result
      }
      reader.readAsDataURL(this.avatar)
    }
  },
  methods: {
    clean() {
      Object.assign(this.userData, this.emptyUser)
      this.$nextTick(() => {
        this.$refs.nickname.focus()
      })
    },
    setBirthday() {
      if (this.userData.birthday === null) {
        let d = new Date()
        let year = d.getFullYear() - 20
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()

        if (month.length < 2) {
          month = '0' + month
        }
        if (day.length < 2) {
          day = '0' + day
        }
        this.userData.birthday = [year, month, day].join('-')
      }
    },
    updateZipCode() {
      // Workaround for zip_code update, will not update v-model
      this.userData.zip_code = document.getElementById('zip-code').value
    },
    update: function () {
      let formData = new FormData()
      if (this.avatar != null) {
        formData.append('avatar', this.avatar)
      }
      formData.append('auth_token', this.token)
      Object.keys(this.userData).forEach(key => {
        if (this.userData[key] === null) {
          formData.append(key, '')
        } else {
          formData.append(key, this.userData[key])
        }
      })
      this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          const fd = new FormData()
          fd.append('email', this.userData.contactEmail)
          modifyContactEmail(fd)
            .then(res => {
              const { code, message } = res
              if (code === 200) {
                this.modal.title = 'Success!'
                this.modal.message = '成功更新資料！'
                this.modal.open = true
              } else {
                this.modal.title = 'Oh No!'
                this.modal.message = message
                this.modal.open = true
              }
            })
            .catch(e => {
              console.warn(e)
              this.modal.title = 'Oh No!'
              this.modal.message = '網絡異常，請稍後再試'
              this.modal.open = true
            })
        })
        .catch(error => {
          console.log(error.data)
          let errorArray = Object.keys(error.data).map(key => `${key}：${error.data[key][0]}`)
          this.modal.title = 'Oh No!'
          this.modal.message = errorArray[0]
          this.modal.open = true
        })
    },
    signup: function () {
      this.$store
        .dispatch('signUp', this.userData)
        .then(() => {
          // this.$emit('successSignUp')
          // this.emitClose()
        })
        .catch(error => {
          console.log(error.data)
          let errorArray = Object.keys(error.data).map(key => `${key}：${error.data[key][0]}`)
          this.modal.title = 'Oh No!'
          this.modal.message = errorArray[0]
          this.modal.open = true
        })
    },
    async contactEmail() {
      try {
        const { code, message, result } = await getContactEmail()
        if (code === 200 && result) {
          this.userData.contactEmail = result
        } else {
          console.warn(message)
        }
      } catch (error) {
        console.warn(error)
      }
    }
  },
  mounted() {
    if (!this.initialized && !!this.user) {
      this.initialized = true
    }
  }
}
</script>

<style lang="scss">
#form-wrapper {
  margin: 0 auto;
  width: 75%;
  max-width: 400px;
  #user-form {
    padding-top: 1px;
    .avatar {
      height: 85px;
      width: 85px;
      border-radius: 50%;
      margin: 8px;
      object-fit: cover;
    }
    .line {
      width: 100%;
      height: 1px;
      background: #dcdcdc;
      margin: 36px 0 30px;
    }
    margin-bottom: 100px;
    .field.file.is-primary {
      margin-top: 0;
      position: relative;
      .upload-icon {
        position: absolute;
        background-color: #f08f7a;
        height: 20px;
        width: 20px;
        right: 12px;
        bottom: 12px;
        border-radius: 50%;
        img {
          width: 13px;
          margin: 3px;
        }
      }
    }
    .field {
      margin-top: 18px;
      line-height: 24px;
      width: 100%;
      label {
        color: #404040;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 0;
        margin-left: 11px;
      }
      label.fixed-email {
        margin-top: 8px;
      }
      label.fixed-email + p {
        margin-left: 24px;
        word-break: break-word;
      }
      input {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        border: none;
      }
      input:focus + span.has-text-primary {
        color: #f08f7a !important;
      }
      label.b-radio.button {
        height: 38px;
        border-radius: 50px;
        border: none;
        min-width: 57px;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        padding: 10px;
      }
      label.b-radio.button.is-lookin-darker {
        color: #ffffff;
        box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
      }
      label.b-checkbox.checkbox {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #404040;
      }
      .boolean .b-radio.radio.button {
        width: 125px;
      }
    }
    .field.address {
      margin-top: 0;
    }
    .field.city {
      display: inline-block;
      width: 140px;
    }
    .field.dist {
      display: inline-block;
      width: 122px;
      float: right;
    }
    #zip-code {
      // display: none;
    }
    .field.has-addons {
      margin-top: 0;
      // justify-content: space-between;
      .control {
        margin-right: 10px;
      }
    }
    .submit-block {
      flex-direction: column;
      display: flex;
      align-items: center;
      p.note {
        margin: 20px 0;
        color: #404040;
        font-size: 13px;
        font-style: normal;
        font-weight: normal;
        line-height: 17px;
        text-align: justify;
        letter-spacing: 1px;
        a {
          font-weight: 600;
          color: #000000;
        }
      }
      button.btn-update {
        background-color: #000;
        color: #ffffff;
        margin-bottom: 40px;
      }
    }
  }

  button.button {
    width: 135px;
    height: 41px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 50px;
    margin-top: 20px;
  }

  button.btn-clear {
    border: 2px solid #000000;
    background-color: transparent;
    box-sizing: border-box;
    color: black;
  }

  button.btn-submit {
    background-color: #000;
    color: #ffffff;
    float: right;
  }
}

.slide-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

@media screen and (min-width: 1200px) {
  #form-wrapper {
    width: 400px;
    max-width: 400px;
    #user-form {
      .field {
        label.b-radio.button {
          min-width: 90px;
        }
        .boolean .b-radio.radio.button {
          width: 180px;
        }
        input,
        select {
          border: 1px solid #ffc2ae;
        }
        label.b-radio.button {
          border: 1px solid #ffc2ae;
        }
      }
    }
    button.button {
      width: 180px;
    }
  }
}
</style>
