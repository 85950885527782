<template>
  <div class="coupon-read" @click="gotoArticle">
    <img class="r-img" :src="info.imagUrl" alt="" />
    <p class="r-text">{{ info.title }}</p>
  </div>
</template>

<script>
export default {
  name: 'CouponRead',
  props: {
    // 阅读类型：0 阅读文章激活票券； 1 答题阅读文章；
    type: {
      type: Number,
      default: 0
    },
    // 票券信息
    info: {
      type: Object,
      require: true,
      default: () => {}
    },
    // 票券id
    couponId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {
    async gotoArticle() {
      // 给文章使用，有证明是票券激活任务
      const { parentPath, childPath, id } = this.info
      window.sessionStorage.setItem(
        'coupon_read',
        JSON.stringify({ id: this.couponId, articleId: id, type: this.type })
      )
      this.$router.push(`/${parentPath}/${childPath}/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-read {
  display: flex;
  align-items: center;
  background: #f4f6f8;
  border-radius: 6px;
  padding: 7px 10px;
  margin-top: 15px;
  .r-img {
    width: 84px;
    height: 64px;
    background: #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
  }
  .r-text {
    flex: 1;
    padding-left: 10px;
    font-size: 14px;
    color: #333333;
    line-height: 16px;
    cursor: pointer;
  }
}
</style>
