<template>
  <section class="comments-block">
    <p v-if="article_id" class="article-comments-title">留言 ({{ commentsCount }})</p>
    <p v-else-if="trial_id" class="event-comments-title"><span class="underline">我要分享</span></p>
    <p v-else-if="lecture_id" class="event-comments-title"><span class="underline">活動募集特派員分享</span></p>
    <p v-else-if="vote_id" class="event-comments-title"><span class="underline">留言</span></p>

    <b-field v-if="trial_items" label="請選擇產品：" class="tiral-item-selection">
      <b-select v-model="trial_item_id" placeholder="請記得先點選您試用的產品喔！">
        <option
          v-for="item in trial_items"
          :value="item.id"
          :key="item.id">
          {{ item.title }}
        </option>
      </b-select>
    </b-field>

    <div v-if="images.length > 0" class="upload-images">
      <template v-for="(file, i) in images">
        <img class='preview' v-bind:ref="'image' + parseInt(i)">
        <img :src="require('@/../images/delete.svg')" alt="Delete" class="delete-upload-image" @click="deleteImage(i)">
      </template>
    </div>
    <form class="say" @submit.prevent="submit" ref='testForm'>
      <img :src="avatar" class="avatar">
      <b-input rounded
        v-model="message"
        placeholder="我要留言..."
        icon-right="camera"
        icon-right-clickable
        @icon-right-click="uploadImage"
        type="textarea"
        maxlength="250"
        :rows="rows"
        @click.native="loginRequired"
        required
      >
      </b-input>
      <b-upload v-model="images" ref="images" class="file-label" accept="image/*" multiple></b-upload>
      <b-button type="is-lookin" :disabled='isDisabled' @click="submit" class="btn-submit">送出</b-button>
    </form>
    <p v-if="this.$route.name == 'lecture'" class="comment-hint has-text-lookin-darker">* 送出前請確認填寫完整後再送出喔！LOOKin 審核後會再顯示在這裡喔！</p>

    <transition name="fade">
      <div class="loading has-background-lookin-darker" v-show="isLoading">
        <b-icon icon="spinner"></b-icon> 讀取中...
      </div>
    </transition>

    <div v-if="trial_id" class="trail-comments">
      <div class="event-comments-title">
        <span class="underline">體驗大隊試用報告</span>
      </div>
      <div class="item-selection">
        <div v-for="item in trial_items" :key="item.id" @click="selectItem(item)" class="item-icon">
          <img :src="item.image" :class="activeItem(item.id)">
        </div>
      </div>
    </div>

    <div class="comments">
      <p class="no-trial-reports" v-if="trial_id && !!updatedComments && updatedComments.length < 1">
        本產品尚無試用報告
      </p>
      <transition-group>
        <div class="comment" v-for="comment in updatedComments" :key="`comment-${comment.id}`">
          <img :src="userAvatar(comment.user_avatar)" class="avatar">
          <div class="content">
            <p class="message has-text-black has-background-lookin-bg"  :id="`comment-${comment.id}`">
              <span class="has-text-lookin-darker">{{ comment.user_name }}</span>
              {{ comment.message }}
            </p>
            <div v-if="!!comment.images && comment.images.length > 0" class="comment-images">
              <div v-for="image in comment.images" class="cmnt-image">
                <img :src="image" @click="showImage(image)">
              </div>
            </div>
            <p class="sub-actions has-text-grey">
              <span class="created-at">{{ comment.created_at }}</span>
              <span class="actions" @click="openReply(comment.id)">回覆</span>
              <span v-if="user && deleteable(comment.user_id)" class="actions" @click="deleteComment(comment.id)">刪除</span>
            </p>
            <transition-group>
              <div class="child-comment" v-for="child_comment in comment.comments" :key="`comment-${child_comment.id}`">
                <img :src="userAvatar(child_comment.user_avatar)" class="mini avatar">
                <div class="content">
                  <p class="message has-text-black has-background-lookin-bg" :id="`comment-${child_comment.id}`">
                    <span class="has-text-lookin-darker">{{ child_comment.user_name }}</span>
                    {{ child_comment.message }}
                  </p>
                  <div v-if="!!child_comment.images && child_comment.images.length > 0" class="comment-images">
                    <div v-for="image in child_comment.images" class="cmnt-image">
                      <img :src="image" @click="showImage(image)">
                    </div>
                  </div>
                  <p class="sub-actions has-text-grey">
                    <span class="created-at">{{ child_comment.created_at }}</span>
                    <span class="actions" @click="openReply(comment.id)">回覆</span>
                    <span v-if="user && deleteable(comment.user_id)" class="actions" @click="deleteComment(child_comment.id)">刪除</span>
                  </p>
                </div>
              </div>
            </transition-group>
            <div v-if="showPreviews(comment.id)" class="upload-images">
              <template v-for="(file, i) in replyImages">
                <img class='preview' v-bind:ref="'reply-image' + parseInt(i)">
                <img :src="require('@/../images/delete.svg')" alt="Delete" class="delete-upload-image" @click="deleteReplyImage(i)">
              </template>
            </div>
            <form v-if="showReplyArea(comment.id)" class="reply" @submit.prevent="replyComment(comment.id)">
              <img :src="avatar" class="mini avatar">
              <b-input rounded
                v-model="replyMessage"
                placeholder="回覆留言..."
                icon-right="camera"
                icon-right-clickable
                @icon-right-click="uploadReplyImage"
                type="textarea"
                maxlength="250"
                :rows="rows"
                required
              >
              </b-input>
              <b-upload v-model="replyImages" id="reply-images" class="file-label" accept="image/*" multiple></b-upload>
              <b-button type="is-lookin" :disabled='replyDisabled' @click="replyComment(comment.id)" class="btn-submit">送出</b-button>
            </form>
          </div>
        </div>
      </transition-group>
      <div class="end-of-comments"></div>
      <button v-if="!showFullComments && (commentsCount > 2)" @click="loadComments" class="see-more has-text-white has-background-black has-text-centered">看全部留言</button>
    </div>
    <Modal v-if="trial_id"
      :open="trialModal"
      :title="'選擇產品'"
      :content="'請記得先點選您試用的產品喔！'"
      :btnWord="'好的'"
      @close="trialModal = false"
    />
    <Modal
      :open="modal"
      :title="'請先登入'"
      :content="'登入會員再留言喔！'"
      :btnWord="'好的'"
      @close="closeModal"
    />
    <Modal
      :open="confirmModal"
      :title="'刪除留言'"
      :content="'確定要刪除這則留言嗎？'"
      :btnWord="'取消'"
      :confirm="'刪除'"
      @close="confirmModal = false"
      @submit="confirmDelete"
    />
    <b-modal
      v-model="imageModal"
      trap-focus
      :destroy-on-hide="false"
      @close="imageModal = false"
    >
      <p class="image">
        <img :src="bigImage">
      </p>
    </b-modal>
  </section>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Modal from '../../components/dialog/Modal'

export default {
  props: ['article_id', 'lecture_id', 'trial_id', 'trial_items', 'vote_id', 'count', 'comments'],
  data() {
    return {
      message: null,
      modal: false,
      trialModal: false,
      imageModal: false,
      updatedComments: [],
      bigImage: null,
      images: [],
      replyImages: [],
      confirmModal: false,
      isDisabled: true,
      isLoading: false,
      commentsCount: this.count,
      showFullComments: false,
      delete_id: null,
      rows: 1,
      replyDisabled: true,
      replyCommentID: null,
      replyMessage: null,
      trial_item_id: null,
      currentItemId: null,
      options: {
        container: 'body',
        easing: "ease",
        offset: -200,
        force: true,
        cancelable: false,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      }
    }
  },
  components: { Modal },
  watch: {
    comments: function (newVal, oldVal) {
      if (this.showFullComments == true) {
        return
      } else {
        this.updatedComments = newVal
      }
    },
    message: function (val) {
      this.isDisabled = val ? false : true
    },
    new_comment: function (val) {
      this.commentsCount += 1
    },
    replyMessage: function (val) {
      this.replyDisabled = val ? false : true
    },
    images: {
      deep: true,
      handler() {
        if (this.images.length > 5) {
          alert("最多只能上傳 5 張照片");
          this.images.splice(5, (this.images.length - 5))
          return
        }
        for (let i = 0; i < this.images.length; i++){
          let reader = new FileReader
          reader.onload = e => {
            this.$refs['image' + parseInt(i)][0].src = reader.result;
          }
          reader.readAsDataURL(this.images[i])
        }
      }
    },
    replyImages: {
      deep: true,
      handler() {
        if (this.replyImages.length > 5) {
          alert("最多只能上傳 5 張照片");
          this.replyImages.splice(5, (this.replyImages.length - 5))
          return
        }
        for (let i = 0; i < this.replyImages.length; i++){
          let reader = new FileReader
          reader.onload = e => {
            this.$refs['reply-image' + parseInt(i)][0].src = reader.result;
          }
          reader.readAsDataURL(this.replyImages[i])
        }
      }
    }
  },
  computed: {
    ...mapState(['user', 'new_comment', 'token']),
    ...mapGetters(['isAuthed']),
    avatar: function () {
      return (this.user && (this.user.avatar_url != '')) ? this.user.avatar_url : require('@/../images/user_default.png')
    },
    userAvatar: function () {
      return avatar => (!!avatar) ? avatar : require('@/../images/user_default.png')
    },
    activeItem: function () {
      return id => (id == this.currentItemId) ? 'active-item' : ''
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setVia']),
    loginRequired() {
      if (this.isAuthed) {
        this.rows = 3
      } else {
        this.viaData()
        this.modal = true
        return
      }

      if (this.trial_id && !this.trial_item_id) {
        this.trialModal = true
      }
    },
    viaData() {
      let via = {
        type: null,
        id: null,
        path: 'comment'
      }
      if (!!this.article_id) {
        via.type = 'Article'
        via.id = this.article_id
      } else if (!!this.trial_id) {
        via.type = 'Trial'
        via.id = this.trial_id
      } else if (!!this.lecture_id) {
        via.type = 'Lecture'
        via.id = this.lecture_id
      } else if (!!this.vote_id) {
        via.type = 'Vote'
        via.id = this.vote_id
      } else if (!!this.video_id) {
        via.type = 'Video'
        via.id = this.video_id
      }
      this.setVia(via)
    },
    closeModal() {
      this.modal = false
      this.setLoginDrawer(true)
    },
    uploadImage() {
      this.$refs.images.$el.click()
    },
    uploadReplyImage() {
      document.getElementById('reply-images').click()
    },
    deleteImage(i) {
      this.images.splice(i, 1)
    },
    deleteReplyImage(i) {
      this.replyImages.splice(i, 1)
    },
    submit() {
      let formData = new FormData()
      formData.append('auth_token', this.token)

      if (this.article_id != undefined) {
        formData.append('article_id', this.article_id)
      } else if (this.trial_id) {
        if (this.trial_item_id != null) {
          formData.append('trial_item_id', this.trial_item_id)
        } else {
          this.trialModal = true
          return
        }
      } else if (this.lecture_id != undefined) {
        formData.append('lecture_id', this.lecture_id)
      } else if (this.vote_id != undefined) {
        formData.append('vote_id', this.vote_id)
      }

      formData.append('message', this.message)
      if (this.images.length > 0) {
        for (const i of Object.keys(this.images)) {
          formData.append('attachments[][image]', this.images[i])
        }
      }
      this.$store.dispatch('sendComment', formData).then(() => {
        this.message = null
        this.images = []
        this.$nextTick(() => {
          this.$scrollTo(`#comment-${this.new_comment.id}`, 500, this.options)
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    replyComment(comment_id) {
      let formData = new FormData()
      formData.append('auth_token', this.token)
      if (this.article_id != undefined) {
        formData.append('article_id', this.article_id)
      }
      if (this.trial_id != null) {
        formData.append('trial_item_id', this.trial_item_id)
      }
      if (this.lecture_id != undefined) {
        formData.append('lecture_id', this.lecture_id)
      }
      if (this.vote_id != undefined) {
        formData.append('vote_id', this.vote_id)
      }
      formData.append('comment_id', comment_id)
      formData.append('message', this.replyMessage)
      if (this.replyImages.length > 0) {
        for (const i of Object.keys(this.replyImages)) {
          formData.append('attachments[][image]', this.replyImages[i])
        }
      }
      this.$store.dispatch('sendComment', formData).then(() => {
        this.replyMessage = null
        this.replyCommentID = null
        this.replyImages = []
        this.$nextTick(() => {
          this.$scrollTo(`#comment-${this.new_comment.id}`, 500, this.options)
        })
      }).catch((error) => {
        console.log(error)
      })
    },
    loadComments() {
      this.isLoading = true
      if (this.article_id != undefined) {
        this.$store.dispatch('loadArticleComments', { article_id: this.article_id }).then(() => {
          this.showFullComments = true
          this.isLoading = false
        })
      }
      if (this.lecture_id != undefined) {
        this.$store.dispatch('loadLectureComments', { lecture_id: this.lecture_id }).then(() => {
          this.showFullComments = true
          this.isLoading = false
        })
      }
      if (this.vote_id != undefined) {
        this.$store.dispatch('loadVoteComments', { vote_id: this.vote_id }).then(() => {
          this.showFullComments = true
          this.isLoading = false
        })
      }
    },
    deleteComment(id) {
      this.delete_id = id
      this.confirmModal = true
    },
    confirmDelete() {
      // TO FIX: delete other kinds
      this.$store.dispatch('deleteComment', { article_id: this.article_id, id: this.delete_id }).then(() => {
        this.confirmModal = false
        this.commentsCount -= 1
        this.delete_id = null
      })
    },
    openReply(id) {
      if (this.isAuthed) {
        this.replyCommentID = id
        this.replyMessage = null
        this.rows = 3
      } else {
        this.modal = true
      }
    },
    showPreviews(id) {
      return (this.replyCommentID == id) && (this.replyImages.length > 0)
    },
    showReplyArea(id) {
      return this.replyCommentID == id
    },
    deleteable(user_id) {
      return user_id == this.user.id
    },
    showImage(image) {
      this.bigImage = image
      this.imageModal = true
    },
    selectItem(item) {
      this.currentItemId = item.id
      this.updatedComments = item.comments
    }
  },
  mounted() {
    this.updatedComments = this.comments
    if (this.trial_id) {
      this.selectItem(this.trial_items[0])
    }
  }
}
</script>

<style lang="scss">
.comments-block {
  margin: 10px 5%;
  max-width: 810px;
  .article-comments-title {
    margin: 30px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #404040;
  }
  .event-comments-title {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 50px;
    .underline {
      position: relative;
      z-index: 1;
      &:after {
        content: '';
        height: 6px;
        background: #FFC2AE;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -8px;
        z-index: -1;
      }
    }
  }
  .tiral-item-selection.field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 80%;
    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 1px;
    }
    .control {
      min-width: 76%;
      max-width: 76%;
      .select {
        width: 100%;
      }
      .input, .select select {
        width: 100%;
        padding: 3px;
        line-height: 24px;
        border-width: 0 0 1px 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 1px;
      }
      .select select:active, .select select:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .upload-images {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: start;
    img.preview {
      object-fit: cover;
      width: 45px;
      height: 45px;
      margin: 8px -2px 12px 12px;
      border: 1px solid #FFC2AE;
      box-sizing: border-box;
      border-radius: 10px;
    }
    img.delete-upload-image {
      width: 14px;
      height: 14px;
    }
  }
  img.avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
  img.mini.avatar {
    height: 25px;
    width: 25px;
    object-fit: cover;
  }
  form input[type="file"] {
    display: none;
  }
  form.say {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .control {
      flex-grow: 2;
      textarea {
        border: 1px solid #FFC2AE;
        border-radius: 16px;
      }
    }
    img.avatar {
      margin-right: 8px;
      object-fit: cover;
    }
    button {
      border-radius: 15px;
      color: #FFFFFF;
      margin-left: 8px;
    }
  }
  button.see-more {
    margin-top: -5px;
    margin-left: 40px;
    margin-bottom: 22px;
    width: 144px;
    height: 34px;
    font-size: 16px;
    line-height: 150%;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }
  .comment-hint {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    letter-spacing: 1px;
    margin-left: 50px;
  }
  .trail-comments {
    .item-selection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item-icon {
        margin: 12px;
        img {
          width: 16vw;
          max-width: 120px;
          height: auto;
          opacity: 0.6;
          border: 1px solid #FFC2AE;
        }
        img.active-item {
          border: 3px solid #F08F7A;
          opacity: 1;
        }
      }
    }
  }
  .comments {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .comment {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 5px;
      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        p.message {
          border-radius: 15px;
          min-height: 35px;
          font-size: 15px;
          line-height: 22px;
          padding: 6px 10px;
          margin-left: 6px;
          margin-bottom: 6px;
          word-break: break-word;
          span {
            font-weight: bold;
          }
        }
        p.sub-actions {
          font-style: normal;
          font-size: 13px;
          line-height: 13px;
          font-weight: 700;
          letter-spacing: 1px;
          span.created-at {
            font-family: Ubuntu Condensed;
            margin-left: 20px;
            font-weight: normal;
            padding-right: 5px;
          }
          span.actions {
            color: #404040;
            padding: 0 10px;
            cursor: pointer;
          }
        }
        .child-comment {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }
        .child-comment + .child-comment {
          margin-top: 15px;
        }
        form.reply {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin: 10px 0;
          .control {
            flex-grow: 2;
            textarea {
              border: 1px solid #FFC2AE;
              border-radius: 16px;
            }
          }
          img.avatar {
            margin-right: 6px;
            object-fit: cover;
          }
          button {
            border-radius: 15px;
            color: #FFFFFF;
            margin-left: 6px;
          }
        }
      }
      .comment-images {
        display: flex;
        flex-wrap: wrap;
        .cmnt-image img {
          margin: 3px;
          width: 32vw;
          height: 32vw;
          max-width: 200px;
          max-height: 200px;
          object-fit: cover;
          border: 1px solid #FFF0EE;
          box-sizing: border-box;
          border-radius: 15px;
          cursor: pointer;
        }
      }
    }
    .no-trial-reports {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 3px;
      margin-bottom: 50px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .comments-block {
    margin: 10px 0;
    .trail-comments .item-selection {
      .item-icon {
        margin: 20px;
      }
    }
  }
}

.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 1s;
}
.v-enter-to {
  opacity: 1;
}

</style>
