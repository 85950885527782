import request from './request'

// 获取搜索列表
export function getSearchList(data) {
  return request({
    method: 'post',
    url: '/shop/article/search',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  })
}

// 搜索联想
export function getAssociateList(keyword) {
  return request.get('/shop/article/searchRecommend/', { params: { keyword } })
}
