<template>
  <div class="cancellation">
    <div class="cancellation-title">
      <h1>註銷帳號</h1>
    </div>
    <cancellation-select
      v-if="isShowCancelation"
      :userName="user.email || '--'"
      :userPoint="user.points.toLocaleString('en-US') || '--'"
      :selectName="selectName || '請選擇'"
      @onSelect="onSelect"
      @onSend="onSend"
    />
    <cancellation-success v-else :code="successCode" />
    <Popup
      title="註銷帳號原因"
      :list="contentData"
      :visible.sync="visible"
      @onConfirm="onConfirm"
    />
    <new-modal
      v-if="isShowModal"
      :title="titleModal"
      :content="contentModal"
      :cancelText="cancelText"
      :confirmText="confirmText"
      @onCancel="onCancelModal"
      @onConfirm="onConfirmModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Popup from "../../components/cancellation/Popup.vue";
import NewModal from "../../components/cancellation/NewModal.vue";
import CancellationSelect from "../../components/cancellation/CancellationSelect.vue";
import CancellationSuccess from "../../components/cancellation/CancellationSuccess.vue";
export default {
  name: "cancellation",
  components: {
    Popup,
    NewModal,
    CancellationSelect,
    CancellationSuccess,
  },
  data() {
    return {
      contentData: ["已有其他帳號", "介面不夠友善", "操作太過複雜", "其他原因"],
      titleModal: "",
      contentModal: "",
      cancelText: "",
      confirmText: "",
      selectName: "",
      isShowModal: false,
      isShowCancelation: true, // 默认显示注销流程组件，确认后显示邮件发送组件
      baseUrl: "", // 基地址
      successCode: 200,
      visible: false
    };
  },
  created() {
    const host = window.location.host;
    if (host.includes("localhost")) {
      this.baseUrl = "https://ynstaging.look-in.com.tw/apis";
    } else if (host.includes("staging")) {
      this.baseUrl = "https://ynstaging.look-in.com.tw/apis";
    } else {
      this.baseUrl = "https://apis.look-in.com.tw";
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    onConfirm(name) {
      this.selectName = name;
      this.visible = false
    },
    onCancelModal() {
      this.isShowModal = false;
    },
    async onConfirmModal() {
      if (this.selectName) {
        const token = window.localStorage.getItem("auth-token") || "";
        try {
          const { data } = await this.sendVerifyMail(token, this.selectName);
          this.successCode = data.code;
        } catch (err) {
          this.successCode = 500;
          console.log(err);
        }
        this.isShowModal = false;
        this.isShowCancelation = false;
      } else {
        this.isShowModal = false;
      }
    },
    onSelect() {
      this.visible = true
    },
    onSend() {
      if (this.selectName) {
        this.titleModal = "帳號註銷確認";
        this.contentModal =
          "帳戶註銷後即無法復原，您儲值的點數累積，將無法繼續使用。";
        this.cancelText = "再想一下";
        this.confirmText = "確認註銷";
        this.isShowModal = true;
      } else {
        this.titleModal = "提示";
        this.contentModal = "請選擇註銷帳號原因";
        this.confirmText = "好的";
        this.isShowModal = true;
      }
    },
    sendVerifyMail(token, reason) {
      return axios({
        method: "post",
        url: this.baseUrl + "/shop/user/cancel/sendVerifyMail",
        headers: {
          "Content-Type": "x-www-form-urlencoded",
        },
        params: {
          token, // 用户令牌
          reason, // 注销理由
        },
      });
    },
  },
};
</script>

<style lang="scss">
.cancellation {
  .cancellation-title {
    padding: 10px 14px 8px;
    h1 {
      font-size: 17px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0a0a0a;
      line-height: 24px;
    }
  }
}
</style>
