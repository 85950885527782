<template>
  <section class="article-event">
    <div class="event-header has-background-nav-bg">
      <div class="gift has-background-nav-bg"><img :src="require('@/../images/gift.svg')" /></div>
      <p>LOOKin 獨家體驗．好禮送給妳！</p>
    </div>
    <h3 class="event-title">
      <span>
        <strong>1</strong>
        名
      </span>
      {{ info.title }}
    </h3>
    <div class="event-image">
      <img :src="info.imgUrl" />
      <p class="img-tip">募集時間到 {{ info.deletedAt }} 為止</p>
    </div>
    <div class="event-content">
      <p class="c-title">{{ info.title }}</p>
      <p class="c-point">
        <img src="../../../images/prizes/icon_points.svg" alt="" />
        <span>{{ info.point }}</span>
      </p>
      <p class="c-tip">
        <span class="t-first">熱點說明：</span>
        <span>
          使用LOOKin熱點可直接兌換贈品，兌換成功後預寄一個月內寄出，請至【會員資料】確認收件地址，若資料不全或遺漏恕不補寄。
        </span>
      </p>
      <template v-if="taskType === 2">
        <MyLine />
        <AnswerTask :task="info.task" :isTask="info.isTask" @change="onAnswerChange" />
        <MyLine />
      </template>
      <p v-else class="c-share">{{ taskText }}</p>
      <b-button rounded expanded type="is-primary" class="c-btn" :disabled="exchangeDisabled" @click="handleExchange">
        {{ exchangeBtnText }}
      </b-button>
      <p class="c-note">
        ※寄送地區僅限台、澎、金、馬之郵局可送達地區，請恕 無法寄送至海外。贈品不挑款不挑色，且編輯部有權更改
        活動內容，敬請見諒 。
      </p>
    </div>

    <VideoTask v-if="isVideoShow" :task="info.task" @close="onVideoTaskClose" />

    <Modal
      :visible.sync="showModal"
      :title="modalTitle[modalType]"
      :cancelText="modalCancelText[modalType]"
      :confirmText="modalConfirmText[modalType]"
      :cancelActive="modalType === 'exchange' ? false : true"
      @onCancel="showModal = false"
      @onConfirm="handleModalConfirm"
    >
      <template #content v-if="modalType === 'tip'">
        <p>妳已經兌換抽獎券，</p>
        <p style="margin-bottom: 25px">可到【兌換記錄】頁面查看</p>
      </template>
      <template #content v-else-if="modalType === 'answer'">
        <p>很遺憾答錯啦</p>
        <p style="margin-bottom: 25px">點擊【再來一次】重新參與答題</p>
      </template>
      <template #content v-else>
        <p>繼續兌換將扣除{{ info.point }}熱點，確認兌換</p>
        <p>後恕無法更改也無法返還點數唷！</p>
      </template>
    </Modal>
  </section>
</template>

<script>
import Modal from '../../components/dialog'
import MyLine from '../../components/coupon/Line.vue'
import VideoTask from '../../components/exchangeRecord/VideoTask.vue'
import AnswerTask from '../../components/exchangeRecord/AnswerTask.vue'
import { taskSubmission, giftExchange } from '../../../api/exchange'
import { shareAndroid, isLookinApp, Android } from '../../../tools/bridge'
import { APP_UPDATE_TIP } from '../../../store/mutation-types'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ArticleEvent',
  /**
   * taskType 任务类型（1：读文章 ，2：答题，3：看视频，4：分享）
   * isTask 任务是否完成(0：未完成，1：已完成)
   * isDiscontinued 是否下架 （0：下架，1：上架中）
   */
  props: ['articleId', 'info'],
  components: { MyLine, Modal, VideoTask, AnswerTask },
  data() {
    return {
      showModal: false,
      modalType: 'tip', // tip 提示； answer 回答提示；exchange: 继续兑换
      modalTitle: {
        tip: '兌換成功！',
        answer: '回答错误',
        exchange: '是否確認兌換？'
      },
      modalCancelText: {
        tip: '好的',
        answer: '再来一次',
        exchange: '再想一下'
      },
      modalConfirmText: {
        exchange: '確認兌換'
      },
      taskType: this.info.taskType, // 任务类型
      isTask: this.info.isTask, // 任务是否完成
      isDiscontinued: this.info.isDiscontinued, // 是否下架 （0：下架，1：上架中）
      isVideoShow: false,
      exchangeDisabled: false // 是否禁用兑换按钮
    }
  },
  computed: {
    ...mapGetters(['isAuthed']),
    taskText() {
      if (this.isDiscontinued === 0) return ''
      if (this.isTask) return ''

      switch (this.taskType) {
        case 3:
          return '觀看影片即可兌換抽獎券'
        case 4:
          return '分享給好友即可兌換抽獎券'
        default:
          return ''
      }
    },
    exchangeBtnText() {
      if (this.isDiscontinued === 0) return '已經結束兌換'
      if (this.isTask) return '繼續兌換'

      switch (this.taskType) {
        case 3:
          return '立即觀看'
        case 4:
          return '立即分享'
        default:
          return '兌換抽獎券'
      }
    }
  },
  created() {
    if (this.isDiscontinued === 0) {
      // 商品已下架 禁用按钮
      this.exchangeDisabled = true
    } else {
      // 未完成答题任务，禁用按钮
      this.exchangeDisabled = this.info.isTask === 0 && this.info.taskType === 2
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer']),
    handleExchange() {
      console.log(this.isAuthed)
      // 未登录，打开登录弹框
      if (!this.isAuthed) return this.setLoginDrawer(true)
      // 任务已完成的调用兑换接口，未完成调用任务提交接口
      if (this.isTask) {
        this.modalType = 'exchange'
        this.showModal = true
      } else {
        if (this.taskType === 3) {
          this.isVideoShow = true
        } else if (this.taskType === 4) {
          this.handleShare()
        } else {
          this.taskSubmissionFn()
        }
      }
    },
    async taskSubmissionFn() {
      const params = { taskType: this.info.taskType, articleId: this.articleId }
      if (this.info.taskType === 2) {
        // 问题id
        params.questionId = this.info.task.id
        // 答案id
        this.info.task.answerVos.forEach(item => {
          if (item.trueOption) {
            params.answerId = item.id
          }
        })
      } else if (this.info.taskType === 3) {
        params.videoUrl = this.info.task.videoUrl
        params.viewingTime = this.videoWatchTime
      }
      try {
        const { code, message } = await taskSubmission(params)
        if (code === 200) {
          this.modalType = 'tip'
          this.showModal = true
          // 完成任务
          this.isTask = 1
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.err(error)
      }
    },
    async giftExchangeFn() {
      try {
        const { code, message } = await giftExchange(this.info.id)
        if (code === 200) {
          this.modalType = 'tip'
          this.showModal = true
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.err(error)
      }
    },
    onVideoTaskClose(currentTime) {
      this.isVideoShow = false
      // 更新状态，可以兑换优惠券
      this.taskType = 1
      // 视频观看时长
      this.videoWatchTime = currentTime
      // 显示提示弹框
      this.$toast('觀看完成')
    },
    onAnswerChange(isCorrect) {
      if (isCorrect) {
        this.exchangeDisabled = false
      } else {
        this.exchangeDisabled = true
        this.modalType = 'answer'
        this.showModal = true
      }
    },
    // 繼續兌換確認按鈕
    handleModalConfirm() {
      this.giftExchangeFn()
    },
    handleShare() {
      const shareObj = {
        url: window.location.href
      }
      if (isLookinApp) {
        // app 调用bridge
        try {
          if (Android) {
            shareAndroid(shareObj)
            setTimeout(() => {
              // 完成分享，提交任务
              this.taskSubmissionFn()
            }, 5000)
          } else {
            navigator.share(shareObj).then(() => {
              // 完成分享，提交任务
              this.taskSubmissionFn()
            })
          }
        } catch (error) {
          // 打开提示升级弹框
          this.$store.commit(APP_UPDATE_TIP, true)
        }
      } else {
        try {
          // 调用分享
          navigator.share(shareObj).then(() => {
            // 完成分享，提交任务
            this.taskSubmissionFn()
          })
        } catch (err) {
          // 不支持 提示使用其他激活方式
          this.$buefy.toast.open({
            type: 'is-danger',
            message: '請使用LooKin App分享'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.article-event {
  margin: 80px 0 40px;
  .event-header {
    position: relative;
    width: 100%;
    height: 65px;
    .gift {
      position: absolute;
      width: 91px;
      height: 71px;
      max-width: 101px;
      top: -36px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      -moz-border-radius: 12vw / 9vw;
      -webkit-border-radius: 12vw / 9vw;
      border-radius: 12vw / 9vw;
      img {
        position: relative;
        top: 33%;
        transform: translateY(-50%);
        display: block;
        margin: auto;
        width: 25px;
        height: 25px;
      }
    }
    p {
      position: absolute;
      top: 40%;
      left: 50%;
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 1px;
      transform: translateY(-50%);
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .event-title {
    margin: 40px 10vw 0;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
    span {
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      background-color: #f08f7a;
      padding: 0 8px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      position: relative;
      font-weight: 600;
      letter-spacing: 1px;
      vertical-align: top;
      margin-right: 5px;
      strong {
        color: #ffffff;
        font-size: 18px;
        line-height: 24px;
      }
    }
    span:before {
      content: '';
      border-left: 5px solid transparent;
      border-top: 12px solid #f08f7a;
      border-bottom: 12px solid #f08f7a;
      position: absolute;
      left: -5px;
    }
    span:after {
      content: '';
      border-right: 5px solid transparent;
      border-top: 12px solid #f08f7a;
      border-bottom: 12px solid #f08f7a;
      position: absolute;
      right: -5px;
    }
  }
  .event-image {
    position: relative;
    margin: 15px auto;
    height: 295px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img-tip {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 36px;
      font-size: 13px;
      text-align: center;
      line-height: 36px;
      background-color: #f9dcd1;
    }
  }
  .event-content {
    padding: 0 15px;
    .c-title {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
    }
    .c-point {
      margin: 10px 0;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        font-size: 19px;
        font-weight: 500;
        color: #ff272f;
        line-height: 24px;
        margin-left: 5px;
      }
    }
    .c-tip {
      background-color: #f8f8f8;
      padding: 12px 10px;
      font-size: 12px;
      color: #404040;
      line-height: 18px;
      margin-bottom: 15px;
      .t-first {
        color: #989595;
      }
    }

    .c-share {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      text-align: center;
      margin-bottom: 15px;
    }
    .c-btn {
      background-color: #f08f7a;
      height: 44px;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      line-height: 44px;
    }
    .c-note {
      font-size: 11px;
      color: #979595;
      line-height: 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .article-event {
    .event-image {
      height: 485px;
      margin-bottom: 25px;
      .img-tip {
        height: 42px;
        font-size: 14px;
        line-height: 42px;
      }
    }
    .event-content {
      .c-tip {
        margin-bottom: 25px;
      }
      .c-share {
        font-size: 18px;
        margin-bottom: 20px;
      }
      .c-answer {
        margin-bottom: 25px;
        .a-topic {
          padding-left: 124px;
        }
        .c-radio {
          padding-left: 124px;
          .advice {
            width: 360px;
          }
        }
      }
      .c-btn {
        height: 50px;
        width: 346px;
        font-size: 20px;
        margin: 0 auto;
      }
      .c-note {
        font-size: 14px;
        margin-top: 30px;
      }
    }
  }
}
</style>
