<template>
  <div>
    <header>
      <Mobile v-if="isMobile" />
      <Desktop v-else />
    </header>
    <LoginDrawer :open="loginDrawer" @successSignUp="signUpSuccess = true" />
    <SignUpDrawer :open="signUpDrawer" @successSignUp="signUpSuccess = true" />
    <ForgetPasswordDrawer :open="forgetPasswordDrawer" />
    <!-- <Modal
      v-if="user"
      :open="loginSuccess"
      :title="'嗨！ ' + user.nickname"
      :content="'歡迎回來～'"
      :btnWord="'OK'"
      @close="loginSuccess = false"
    /> -->
    <Modal
      :open="signUpSuccess"
      :title="'確認信已寄出'"
      :content="'請到您的 Email 信箱確認'"
      :btnWord="'好的'"
      @close="signUpSuccess = false"
    />
    <PointModal v-if="loginSuccess" type="everyday" :info="prizeInfo" @closeModal="loginSuccess = false" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoginDrawer from '../LoginDrawer'
import SignUpDrawer from '../SignUpDrawer'
import ForgetPasswordDrawer from '../ForgetPasswordDrawer'
import Mobile from './Mobile'
import Desktop from './Desktop'
import Modal from '../../dialog/Modal'
import PointModal from '../../haveToPoint/pointModal.vue'
import { getPrizeInfo } from '../../../../api/prize'

export default {
  name: 'LayoutHeader',
  data() {
    return {
      loginSuccess: false,
      signUpSuccess: false,
      prizeInfo: null
    }
  },
  components: { Mobile, Desktop, LoginDrawer, SignUpDrawer, ForgetPasswordDrawer, Modal, PointModal },
  computed: {
    ...mapState(['isMobile', 'loginDrawer', 'signUpDrawer', 'forgetPasswordDrawer', 'user'])
  },
  watch: {
    user(value) {
      if (value) {
        // 已登录 每日上线弹框
        this.recondingCookie()
      }
    }
  },
  methods: {
    // onSuccessLogin() {
    //   // 是否從商品兌換詳情登録的
    //   const prizeDetailLogin = window.localStorage.getItem('prize_detail_login')
    //   // true則不彈框
    //   if (prizeDetailLogin) {
    //     this.loginSuccess = false
    //   } else {
    //     this.loginSuccess = true
    //   }
    // },
    // 每日上线弹框
    async recondingCookie() {
      let t = new Date()
      t.setDate(t.getDate() + 1)
      t.setHours(0)
      t.setMinutes(0)
      t.setSeconds(0)
      // eslint-disable-next-line
      const dailyLoginUserIdArr = JSON.parse($cookies.get('daily_login_user_id') || '[]')
      const userId = this.user.id.toString()
      // 有登录过的用户不弹框，反之则弹框
      if (!dailyLoginUserIdArr.includes(userId)) {
        dailyLoginUserIdArr.push(userId)
        // eslint-disable-next-line
        $cookies.set('daily_login_user_id', JSON.stringify(dailyLoginUserIdArr), t) // 第二天0点失效

        const { code, result } = await getPrizeInfo(2)
        if (code === 200) {
          this.prizeInfo = result
          this.loginSuccess = true
        }
      }
    }
  }
}
</script>
<style lang="scss">
header {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  background: white;
}

.button.facebook-btn,
.button.google-btn,
.button.apple-btn,
.button.facebook-btn:hover,
.button.google-btn:hover,
.button.apple-btn:hover {
  color: #ffffff;
  width: 269px;
  height: 39px;
  span {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    width: 100%;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-left: 30px;
    }
  }
}

.button.facebook-btn {
  background: #3b5998;
}
.button.google-btn {
  background: #dd4b39;
  margin-top: 15px;
}
.button.apple-btn {
  background: #000000;
  margin-top: 15px;
}
</style>
