<template>
  <div class="exchange-record-detail" v-if="detail">
    <div class="detail-nav" @click="handleBack">&lt; 返回開獎結果</div>
    <bread-crumb class="detail-bread">
      <bread-crumb-item to="/">首頁</bread-crumb-item>
      <bread-crumb-item to="/my/exchange-record"> 開獎結果 </bread-crumb-item>
      <bread-crumb-item :to="`/my/exchange-record/${id}`">開獎詳情</bread-crumb-item>
    </bread-crumb>
    <div class="detail-content">
      <img class="c-img" :src="detail.imgUrl" alt="" />
      <p class="c-title">{{ detail.title }}</p>
      <p class="c-time">開獎時間：{{ detail.publishAt }}</p>
      <template v-if="detail.winStatus === 1">
        <p class="c-lottery">恭喜您中獎了唷！</p>
        <b-button
          rounded
          expanded
          type="is-primary"
          class="c-btn"
          @click="onConfirm"
          :disabled="detail.receiveStatus === 0 ? false : true"
        >
          {{ detail.receiveStatus ? '收件地址已確認' : '確認收件地址' }}
        </b-button>
      </template>
      <template v-else-if="detail.winStatus === -1">
        <p class="c-lottery">可惜沒中獎…再接再厲！</p>
      </template>
      <div class="c-show" v-if="detail.receiveStatus === 1">
        <h4>收件信息</h4>
        <ul>
          <li>
            <span>姓名</span>
            <span>{{ detail.userName }}</span>
          </li>
          <li>
            <span>電話</span>
            <span>{{ detail.userPhone }}</span>
          </li>
          <li>
            <span>地 址</span>
            <span>{{ detail.userAddress }}</span>
          </li>
          <li>
            <span>Email</span>
            <span>{{ detail.userEmail }}</span>
          </li>
        </ul>
      </div>
      <div class="c-roster">
        <div class="r-text">
          <template v-if="detail.winStatus === 0">
            <p>還未開獎……</p>
          </template>
          <template v-else>
            <p v-for="(item, index) in roster" :key="index">
              <span style="margin-right: 15px">{{ item.userName }}</span>
              <span>{{ item.userEmail }}</span>
            </p>
          </template>
        </div>
        <p class="r-tip">
          <img src="../../../images/exchange/gift.svg" alt="" />
          <span>得獎名單</span>
        </p>
      </div>
    </div>
    <Modal
      v-model="isShow"
      :title="modalTitle[modalType]"
      :confirmText="modalConfirmText[modalType]"
      @confirm="onModalConfirm"
    >
      <template #content v-if="modalType === 'address'">
        <AddressForm ref="addressForm" @submit="handleAddressSub" />
      </template>
      <template #content v-else-if="modalType === 'tip'">
        <p>收件地址已確認</p>
        <p>我們會儘快將商品寄出！</p>
      </template>
    </Modal>
  </div>
</template>

<script>
import breadCrumb from '../../components/breadCrumb'
import breadCrumbItem from '../../components/breadCrumb/item'
import Modal from '../../components/exchangeRecord/Modal.vue'
import AddressForm from '../../components/exchangeRecord/AddressForm.vue'
import { getExchangeDetail, listOfWinners, receive } from '../../../api/exchange'

export default {
  components: {
    breadCrumb,
    breadCrumbItem,
    Modal,
    AddressForm
  },
  data() {
    return {
      id: 0, // 赠品id
      modalType: 'address', // 弹框类型：address tip
      modalTitle: {
        address: '確認收件地址',
        tip: '提示'
      },
      modalConfirmText: {
        address: '',
        tip: '好的'
      },
      isShow: false,
      /**
       * 详情
       * winStatus 开奖狀態 （-1：未中奖，0:待开奖，1:中奖）
       * receiveStatus 领取狀態 (0：未领取，1：领取）
       */
      detail: null,
      roster: [] // 获奖名单
    }
  },
  created() {
    this.id = this.$route.params.id || 0
    // 兑换详情
    this.exchangeDetailFn(this.id)
  },
  methods: {
    onConfirm() {
      this.isShow = true
      // 设置默认地址
      this.$nextTick(() => {
        this.$refs.addressForm.info.name = this.detail.userName
        this.$refs.addressForm.info.email = this.detail.userEmail
        this.$refs.addressForm.info.phone = this.detail.userPhone
      })
    },
    handleBack() {
      this.$router.back()
    },
    async exchangeDetailFn(id) {
      try {
        const { code, result, message } = await getExchangeDetail(id)
        if (code === 200) {
          this.detail = result

          // 中奖 未中獎
          if (result.winStatus === 1 || result.winStatus === -1) {
            // 获取获奖名单
            const { code: listCode, result: listResult } = await listOfWinners(result.prizesId)
            if (listCode === 200) {
              this.roster = listResult || []
            }
          }
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        }
      } catch (error) {}
    },
    onModalConfirm() {
      // 彈框恢復默認值
      this.isShow = false
      this.modalType = 'address'
    },
    async handleAddressSub(formData) {
      const { code, message } = await receive({ id: this.detail.id, ...formData })
      if (code === 200) {
        // 領取成功 更改狀態
        this.modalType = 'tip'
        this.detail.receiveStatus = 1
        this.detail.userName = formData.name
        this.detail.userPhone = formData.phone
        this.detail.userAddress = `${formData.city}${formData.dist}${formData.address}`
        this.detail.userEmail = formData.email
      } else {
        this.$buefy.toast.open({
          type: 'is-danger',
          message
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.exchange-record-detail {
  padding: 20px 15px 100px;
  max-width: 1000px;
  .detail-nav {
    font-size: 13px;
    color: #f08f7a;
    line-height: 15px;
  }
  .detail-bread {
    display: none;
  }
  .detail-content {
    max-width: 600px;
    text-align: center;
    padding-top: 30px;
    margin: 0 auto;
    .c-img {
      width: 120px;
      height: 120px;
      border-radius: 6px;
      background: #d9d9d9;
      margin-bottom: 20px;
    }
    .c-title {
      font-size: 15px;
      color: #404040;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .c-time {
      font-size: 14px;
      color: #989595;
      line-height: 16px;
    }
    .c-lottery {
      font-size: 16px;
      color: #606060;
      line-height: 19px;
      margin-top: 14px;
      margin-bottom: 20px;
    }
    .c-btn {
      width: 345px;
      height: 44px;
      background: #f08f7a;
      font-size: 15px;
      color: #ffffff;
      margin: 0 auto;
    }
    .c-show {
      background: #f8f8f8;
      border-radius: 6px;
      padding: 15px;
      text-align: left;
      margin-top: 30px;
      h4 {
        font-size: 16px;
        font-weight: 500;
        color: #404040;
        line-height: 27px;
      }
      ul li {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        margin: 10px 0;
        span {
          flex: 1;
        }
        span:last-child {
          text-align: right;
        }
      }
    }
    .c-roster {
      position: relative;
      background: #f8f8f8;
      border-radius: 6px;
      margin-top: 30px;
      padding: 43px 0;
      .r-text {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        text-align: center;
      }
      .r-tip {
        position: absolute;
        top: 0;
        left: 0;
        width: 94px;
        height: 24px;
        background: #f08f7a;
        border-radius: 6px 0px 6px 0px;
        font-size: 14px;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        display: flex;
        align-items: center;
        padding-left: 4px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .exchange-record-detail {
    padding-bottom: 224px;
    .detail-nav {
      display: none;
    }
    .detail-bread {
      display: flex;
    }
    .detail-content {
      padding-top: 120px;
    }
  }
}
</style>
