<template>
  <form class="address_form" @submit.prevent="onSubmit">
    <b-field label="姓 名" horizontal>
      <b-input v-model="info.name" placeholder="請輸入您的姓名" required expanded> </b-input>
    </b-field>
    <b-field label="電 話" horizontal>
      <b-input v-model="info.phone" placeholder="請輸入您的電話" required expanded> </b-input>
    </b-field>
    <b-field label="縣 市" horizontal>
      <div class="address-set">
        <b-input type="hidden" id="zip-code"></b-input>
        <b-select id="city-select" v-model="info.city" placeholder="選擇縣市" required />
        <b-select id="dist-select" v-model="info.dist" @change.native="updateZipCode" placeholder="選擇區域" required />
      </div>
    </b-field>
    <b-field label="地 址" horizontal>
      <b-input v-model="info.address" placeholder="請輸入地址，用於寄送贈品" required expanded> </b-input>
    </b-field>
    <b-field label="Email" horizontal>
      <b-input type="email" v-model="info.email" placeholder="請輸入您的Email" inputmode="email" required> </b-input>
    </b-field>
    <b-field>
      <b-checkbox class="checkbox" v-model="info.check" required>
        我已經詳細閱讀並同意
        <router-link :to="{ name: 'terms' }" target="_blank">隱私權政策</router-link>
      </b-checkbox>
    </b-field>
    <div class="form_tip">貼心小提醒：每個賬號僅能報名乙次，請務必確認填寫資料整確認後再送出哦！</div>
    <div class="form_btn">
      <button>確認</button>
    </div>
  </form>
</template>

<script>
import TwCitySelector from 'tw-city-selector'

export default {
  name: 'ExchangeAddressForm',
  data() {
    return {
      info: {
        name: '',
        phone: '',
        city: '',
        dist: '',
        zip_code: '',
        address: '',
        email: '',
        check: true
      }
    }
  },
  mounted() {
    new TwCitySelector({
      el: '.address-set',
      elCounty: '#city-select',
      elDistrict: '#dist-select',
      elZipcode: '#zip-code',
      countyValue: this.info.city,
      districtValue: this.info.dist
    })
  },
  methods: {
    updateZipCode() {
      // Workaround for zip_code update, will not update v-model
      this.info.zip_code = document.getElementById('zip-code').value
    },
    onSubmit() {
      this.$emit('submit', this.info)
    }
  }
}
</script>

<style lang="scss" scoped>
.address_form {
  padding: 0 26px;
  text-align: left;
  ::v-deep .field.is-horizontal {
    display: flex;
    align-items: center;
    .field-label {
      width: 67px;
      text-align: left;
      margin-right: 0;
      flex-basis: unset;
      flex-grow: unset;
      .label {
        font-weight: 400;
      }
    }
    .field-body {
      flex: 1;
      .input,
      select {
        border: none;
        border-bottom: 1px solid #f8dddc;
        box-shadow: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .address-set {
      display: flex;
    }
  }
  .checkbox {
    font-size: 13px;
    color: #0a0a0a;
  }
  .form_tip {
    font-size: 11px;
    color: #989595;
    line-height: 16px;
  }
  .form_btn {
    padding-top: 14px;
    text-align: center;
    button {
      color: #fff;
      width: 231px;
      height: 42px;
      background: #f08f7a;
      border: none;
      outline: none;
      border-radius: 9999px;
      font-size: 15px;
    }
  }
}
</style>
