<template>
  <div id="video-container" class="columns is-5" ref="videoWrapper" v-if="!isLoading">
    <div id="video" class="column video-column">
      <BreadCrumb v-if="!isLoading" :breadcrumbList="breadcrumb" />
      <div class="content">
        <div class="thumbnail">
          <iframe :src="video.embed_link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="title-social-share">
          <h1>{{ video.title }}</h1>
          <div class="social-share">
            <span class="date-text">最後更新日</span>
            <span>{{ video.published_at }}</span>
            <div class="social-icons">
              <div v-if="sharable" class="has-background-nav-bg" @click="share()"><img :src="require('@/../images/share.svg')"></div>
              <div v-if="!sharable" class="has-background-nav-bg" @click="fbShare()">
                <img :src="require('@/../images/facebook.svg')">
              </div>
              <div v-if="!sharable" class="has-background-nav-bg">
                <a :href="lineShareURL()">
                  <img :src="require('@/../images/line.svg')">
                </a>
              </div>
              <div @click="resize" class="has-background-nav-bg"><img :src="resizeIcon"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="video-content" class="video-content" v-html="video.description"></div>
      <div class="tags has-background-nav-bg">
        <router-link v-for="tag in video.tags" :key="tag" class="tag-link"
          :to="{ name: 'search', query: { keyword: tag } }">
          #{{ tag }}
        </router-link>
      </div>
      <div class='video-footer'>
        <p>看更多影音請訂閱 <a href="https://www.youtube.com/channel/UC9FymR5DhAubxbjWM9JmS_Q?sub_confirmation=1" target="_blank">LOOKin Youtube ></a></p>
      </div>
      <Advertisement v-if="isMobile" :id="38" :width="300" :height="250" />
      <div v-else class="ads">
        <Advertisement :id="33" :width="300" :height="250" />
        <Advertisement :id="34" :width="300" :height="250" />
      </div>
    </div>
    <RightSideBar v-if="!isMobile" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resizeFont } from 'src/share'
import RightSideBar from 'src/components/layout/RightSideBar'
import BreadCrumb from 'src/components/common/BreadCrumb'
import Advertisement from 'src/components/common/Advertisement'

export default {
  name: 'Video',
  data() {
    return {
      isLoading: true,
      loading: false,
      sharable: !!navigator.share,
      wordSize: 'medium',
      fontList: ['small', 'big'],
    };
  },
  components: { RightSideBar, BreadCrumb, Advertisement },
  methods: {
    share() {
      navigator.share({
        title: this.video.title,
        text: this.video.title,
        url: `${window.location.href}?utm_medium=website&utm_campaign=${this.campaign()}`
      }).then()
      .catch(error => console.log('Error sharing:', error));
    },
    fbShare() {
      FB.ui({
        method: 'share',
        href: `${window.location.href}%3Futm_source=fb%26utm_medium=website%26utm_campaign=${this.campaign()}`
      }, function(response){});
    },
    lineShareURL() {
      return `https://social-plugins.line.me/lineit/share?url=${window.location.href}%3Futm_source=Line%26utm_medium=website%26utm_campaign=${this.campaign()}`
    },
    campaign() {
      return !!this.user ? this.user.id : 'share'
    },
    resize() {
      let delta = 3
      switch (this.wordSize) {
        case 'medium':
          this.wordSize = 'big'
          break;
        case 'big':
          this.wordSize = 'small'
          delta = -5
          break;
        case 'small':
          this.wordSize = 'medium'
          delta = 2
          break;
      }
      let content = document.getElementById('video-content')
      resizeFont(content, delta)
      for(let j = 0; j < this.fontList.length; j++) {
        if (this.fontList[j] == this.wordSize) {
          if (!content.classList.contains(this.wordSize)) {
            content.classList.add(this.wordSize)
          }
        } else {
          if (content.classList.contains(this.fontList[j])) {
            content.classList.remove(this.fontList[j])
          }
        }
      }
    },
  },
  computed: {
    ...mapState(['isMobile', 'video', 'user']),
    breadcrumb: function () {
      return [
        {
          title: '首頁',
          name: 'home'
        },
        {
          title: 'LOOK V 影音',
          name: 'videos'
        },
        {
          title: this.video.categories[0].title,
          name: 'videos-category',
          category: this.video.categories[0].path
        },
      ]
    },
    bookmarkClass: function () {
      return bookmarked => (bookmarked == true) ? 'has-background-lookin-darker' : 'has-background-nav-bg'
    },
    resizeIcon() {
      return (this.wordSize == 'big') ? require('@/../images/font.svg') : require('@/../images/font_plus.svg')
    }
  },
  mounted() {
    this.$store.dispatch('loadVideo').then(() => {
      this.isLoading = false
    })
  },
}
</script>

<style lang="scss">
#video {
  .thumbnail {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border: 1px solid #F08F7A;
    margin: 10px 0px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  h1 {
    margin: 0 4vw;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: justified;
  }
  .social-share {
    margin: 4vw;
  }
  .video-content {
    all: revert;
    max-width: 810px;
    margin: 15px auto;
    overflow-wrap: break-word;
    font-size: 18px;
    h2, h3, h4 {
      font-weight: 600 !important;
    }
    h2 {
      font-size: 1.35em !important;
    }
    h3 {
      font-size: 1.15em !important;
    }
    h4 {
      font-size: 1em !important;
    }

    @media only screen and (max-width: 700px) {
      margin: 15px 5%;
      img {
        width: 95% !important;
        height: auto !important;
      }
    }
  }
  #video-content.big {
    font-size: 21px !important;
  }
  #video-content.small {
    font-size: 16px !important;
  }
  .tags {
    margin: 20px 0;
    padding: 9px 12px;
    justify-content: flex-start;
  }
  .video-footer {
    margin-bottom: 70px;
    p {
      margin-left: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}

@media screen and (min-width: 1200px) {
  #video-container {
    width: 1200px;
    margin: 0 auto;
    #video {
      max-width: 830px;
      .content {
        display: flex;
        flex-direction: column-reverse;
        .title-social-share {
          h1 {
            margin: 16px 0;
          }
          .social-share {
            margin: 16px 0;
          }
        }
      }
      .video-footer {
        margin-bottom: 20px;
        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
