<template>
  <section id="instagram" class="has-background-lookin-bg">
    <div class="home-block-title has-text-centered has-text-black">
      <h2><span>L</span>OOKin Instagram</h2>
    </div>
    <b-icon icon="angle-left" class="slick-arrow prev" @click.native="prev()"></b-icon>
    <slick ref="igSlick" v-if="!isLoading" :options="slickOptions">
      <a v-for="(ig, i) in valid_ig_photos" :href="ig.permalink" target="_blank" :key="i">
        <div class="content">
          <div class="image">
            <img :src="ig.media_url" class="content-img" />
            <!-- <LazyImage :src="ig.media_url" class="content-img" /> -->
          </div>
        </div>
      </a>
    </slick>
    <b-icon icon="angle-right" class="slick-arrow next" @click.native="next()"></b-icon>
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import LazyImage from '../../components/common/LazyImage.vue'

export default {
  name: 'instagram',
  props: ['isLoading', 'ig_photos'],
  components: {},
  data() {
    return {
      slickOptions: {
        infinite: true,
        slidesToShow: 3,
        arrows: false,
        swipeToSlide: true
      }
    }
  },
  methods: {
    prev() {
      this.$refs.igSlick.prev()
    },
    next() {
      this.$refs.igSlick.next()
    }
  },
  watch: {
    isMobile: function () {
      this.slickOptions.slidesToShow = this.isMobile ? 3 : 5
      if (this.$refs.igSlick !== undefined) {
        this.$refs.igSlick.reSlick()
      }
    }
  },
  computed: {
    ...mapState(['isMobile']),
    valid_ig_photos: function () {
      return this.ig_photos.filter(ig => ig.media_type !== 'VIDEO')
    }
  },
  mounted() {}
}
</script>

<style lang="scss">
#instagram {
  position: relative;
  h2 span {
    width: 30px;
  }
  .slick-slider {
    margin: 0 auto;
    width: 73%;
    margin: 0 auto;
    .slick-slide {
      padding: 3px;
      margin-bottom: 40px;
      .content {
        position: relative;
        margin: 0 auto;
        max-height: 150px;
        max-width: 150px;
        border-radius: 50%;
        border: 1px solid #ffc2ae;
        background: #ffc2ae;
        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          border: 2px dotted #ffffff;
          border-radius: 50%;
          box-sizing: border-box;
          padding: 3px;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid #ffffff;
            border-radius: 50%;
            object-fit: cover;
            aspect-ratio: 1/1;
          }
        }
      }
      .content:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }
  .slick-arrow {
    color: #f08f7a;
    font-size: 24px;
    position: absolute;
    bottom: 32%;
    z-index: 3;
  }
  .prev {
    left: 15px;
  }
  .next {
    right: 15px;
  }
}
</style>
