<template>
  <section id='my-announces'>
    <h1>會員公告</h1>
    <div class="announces">
      <ul v-if="!isLoading">
        <li v-for="(item, i) in announces" :key="item.id">
          <router-link :to="'/my/announces/'+item.id" class="">
            <p>{{ item.published_at }}</p>
            <h3 class="has-text-black">{{ item.title }}</h3>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(['announces']),
  },
  methods: {
  },
  created() {
    this.$store.dispatch('loadAnnounces').then(() => {
      this.isLoading = false
    })
  },
};
</script>
<style lang="scss">
#my-announces {
  h1 {
    margin: 20px 15px 30px;
  }
  .announces {
    margin-bottom: 100px;
    li {
      max-height: 85px;
      overflow: hidden;
      padding: 10px 28px;
      border-bottom: 1px solid #FFDBCF;
      p {
        color: #989595;
        font-family: Ubuntu Condensed;
        padding: 2px 0 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 10px;
        letter-spacing: 1px;
      }
      h3 {
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 1px;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    li:nth-child(odd) {
      background: rgba(255,240,238, 0.7);
    }
  }
}

@media screen and (min-width: 1200px) {
  #my-announces {
    max-width: 1000px;
    width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #FFC2AE;
    }
  }
}
</style>
