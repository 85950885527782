import request from './request.js'

// 登录
export function login(data) {
  return request.post('/shop/user/login', data)
}

// 激活账号
export function activateAccount(data) {
  return request.post('/shop/register/resendEmail', data)
}

// 注册账号
export function registerApi(data) {
  return request.post('/shop/register/lookin', data)
}

// 忘记密码
export function forgetApi(email) {
  return request.post(`/shop/register/forget/${email}`)
}
