<template>
  <section>
    <b-sidebar
      :type="'is-nav-bg'"
      :fullheight="true"
      :fullwidth="true"
      :can-cancel="false"
      v-model="openForm">
      <div id='reset-password-drawer' class="has-background-nav-bg drawer">
        <div id='reset-password-inner-wrapper'>
          <img :src="require('@/../images/icon-close.svg')" alt="Close" class="tail-icon close" @click="close()">
          <h2 class="title eng-font"><span class="underline">Reset Password</span></h2>
          <h3 class="sub-title"><span class="has-background-nav-bg">重新設定密碼</span></h3>
          <form class="reset-password" @submit.prevent="resetPassword">
            <b-field label="密碼*">
              <b-input rounded
                type="password"
                v-model="password"
                placeholder="長度 6 字元以上，需區分大小寫"
                password-reveal
                required>
              </b-input>
            </b-field>
            <b-field label="確認密碼*">
              <b-input rounded
                type="password"
                v-model="password_confirmation"
                placeholder="請再次輸入密碼"
                password-reveal
                required>
              </b-input>
            </b-field>
            <button class="button btn-reset">送出</button>
          </form>
        </div>
      </div>
    </b-sidebar>
    <Modal
      :open="resetSuccess"
      :title="'密碼設定成功！'"
      :content="'密碼已重設，請重新登入。'"
      :btnWord="'我知道了'"
      @close="redirectToHome"
    />
    <Modal
      :open="errorModal"
      :title="'Oh No!'"
      :content="errorMsg"
      :btnWord="'我知道了'"
      @close="errorModal = false"
    />
    <Modal
      :open="verifyFail"
      :title="'Token 驗證失敗！請確認'"
      :btnWord="'好的'"
      @close="redirectToHome" />
  </section>
</template>

<script>
import Modal from '../components/dialog/Modal'

export default {
  data() {
    return {
      password: null,
      password_confirmation: null,
      openForm: false,
      resetSuccess: false,
      errorModal: false,
      errorMsg: null,
      verifyFail: false
    }
  },
  components: { Modal },
  methods: {
    close: function () {
      this.password = null
      this.password_confirmation = null
      this.openForm = false
    },
    resetPassword: function () {
      const { password, password_confirmation } = this
      const reset_password_token = this.$route.query.reset_password_token
      this.$store.dispatch('resetPassword', { reset_password_token, password, password_confirmation }).then(() => {
        this.resetSuccess = true
        this.close()
      }).catch((error) => {
        console.log(error.data)
        let errorArray = Object.keys(error.data.error).map(key => `${key}：${error.data.error[key][0]}`)
        this.errorMsg = errorArray[0]
        this.errorModal = true
      })
    },
    redirectToHome() {
      this.resetSuccess = false
      this.verifyFail = false
      this.$router.push({ name: 'home' })
    },
  },
  mounted() {
    this.$store.dispatch('resetTokenVerify', this.$route.query.reset_password_token).then(() => {
        this.openForm = true
      }).catch((error) => {
        this.verifyFail = true
      })
  },
};
</script>

<style lang="scss">
#reset-password-drawer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    margin: 60px 0 25px;
  }

  #reset-password-inner-wrapper {
    margin: 0 auto;
    width: 281px;
    max-width: 281px;
    h3.sub-title {
      width: 100%;
      text-align: center;
      border-bottom: 2px solid #404040;
      line-height: 0.1em;
      margin: 60px 0 30px;
      span {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0 10px;
      }
    }

    form.reset-password {
      .field {
        margin-top: 15px;
        color: #404040;
        line-height: 24px;
        width: 100%;
        label {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 0;
          margin-left: 11px;
        }
        input {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 1px;
          border: none;
        }
      }
    }

    button.btn-reset {
      width: 100%;
      height: 41px;
      background-color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      color: #FFFFFF;
      border-radius: 50px;
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  #reset-password-drawer {
    #reset-password-inner-wrapper {
      width: 400px;
      max-width: 400px;
    }
  }
}
</style>
