import store from '../store'
import { APP_VERSION } from '../store/mutation-types'
/**
 *
 * app 方法
 */

const ua = navigator.userAgent.toLowerCase()
// ios系统 布尔值
const IOS = /iphone|ipad|ipod/.test(ua) // 在尖端app裡面這個判斷不準確
// Android系统 布尔值
const Android = /android/.test(ua)
// 判断是不是app 布尔值
const isLookinApp = navigator.userAgent.toLowerCase().includes('lookin')
// ture 移動端， false pc端
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

// 判断是否webview
const isWebview = () => {
  var useragent = navigator.userAgent
  var rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)']
  var regex = new RegExp(`(${rules.join('|')})`, 'ig')
  return Boolean(useragent.match(regex))
}

// 调起苹果登录
const openAppleSignIn = () => {
  if (isLookinApp) {
    // 尖端app上判断ios有bug
    if (!Android) {
      window.webkit.messageHandlers.openAppleSignIn.postMessage('')
    }
  }
}

// 调起Google登录
const openGoogleLogin = () => {
  if (isLookinApp) {
    if (Android) {
      window.lookin.openGoogleLogin()
    } else {
      window.webkit.messageHandlers.openGoogleLogin.postMessage('')
    }
  }
}

// 调起Facebook登录
const openFacebookLogin = () => {
  if (isLookinApp) {
    if (Android) {
      window.lookin.openFacebookLogin()
    } else {
      window.webkit.messageHandlers.openFacebookLogin.postMessage('')
    }
  }
}

// ios 获取版本号 app會調用
window.getLatestAppVersion = version => {
  store.commit(APP_VERSION, version)
}
// 获取版本号
const getAppVersion = () => {
  if (isLookinApp) {
    if (Android) {
      const appVersion = window.lookin.getLatestAppVersion()
      // 存入仓库
      store.commit(APP_VERSION, appVersion)
    } else {
      window.webkit.messageHandlers.getLatestAppVersion.postMessage('')
    }
  }
}

/**
 * 安卓分享bridge
 * @param { title: '', text: '', url: ''} obj
 */
const shareAndroid = obj => {
  const json = JSON.stringify(obj)
  console.log(json, 'share')
  window.lookin.share(json)
}

// 扫码
const qrcodeScan = token => {
  if (Android) {
    window.lookin.jsQrcodeScan(token)
  } else {
    window.webkit.messageHandlers.jsQrcodeScan.postMessage(token)
  }
}

// 判斷app版本大小 v1大於v2為true，反之亦然
const compareVersion = (v1, v2) => {
  if (v1 == v2) {
    return 0
  }
  const vs1 = v1.split('.').map(a => parseInt(a))
  const vs2 = v2.split('.').map(a => parseInt(a))
  const length = Math.min(vs1.length, vs2.length)
  for (let i = 0; i < length; i++) {
    if (vs1[i] > vs2[i]) {
      return true
    } else if (vs1[i] < vs2[i]) {
      return false
    }
  }
  if (length == vs1.length) {
    return false
  } else {
    return true
  }
}

// 判断是否小米，红米手机
const isMi = () => {
  if (Android) {
    const m1 = ua.match(/android.*; ?(.*(?= build))/)
    if (m1 && m1.length > 1) {
      return m1[1].includes('m') // 小米10 以后的手机版本有点奇怪，所以直接判断有没到m
    } else {
      return false
    }
  } else {
    return false
  }
}

// 判断手机浏览器
function isBrowser() {
  const browserArr = [
    'ucbrowser',
    'uc',
    'baiduboxapp',
    'baidu',
    '360 alitephone browser',
    '360',
    '2345browser',
    '2345',
    'xiaomi',
    'miuibrowser',
    'swan-mibrowser',
    'sogou',
    'metasr',
    'quark'
  ]
  if (Android) {
    for (const item of browserArr) {
      const res = ua.includes(item)
      if (res) {
        return true
      }
    }
  } else {
    return false
  }
}

// 获取fcm token
function getAppFcmToken() {
  if (isLookinApp) {
    if (Android) {
      window.lookin.getFCMToken()
    } else {
      window.webkit.messageHandlers.getFCMToken.postMessage('')
    }
  }
}

// 呼叫app记录账号的fcm
function setAppRecordFCM(userId) {
  if (isLookinApp) {
    if (Android) {
      window.lookin.setRecordAccountFCM(userId)
    } else {
      window.webkit.messageHandlers.setRecordAccountFCM.postMessage(userId)
    }
  }
}

// 登出後刪除帳號的FCM
function delAppFcm(userId) {
  if (isLookinApp) {
    if (Android) {
      window.lookin.deleteAccountFCM(userId)
    } else {
      window.webkit.messageHandlers.deleteAccountFCM.postMessage(userId)
    }
  }
}

// 获取app红点信息
function getAppRedPointMsg() {
  if (isLookinApp) {
    try {
      if (Android) {
        window.lookin.getRedPointMsg()
      } else {
        window.webkit.messageHandlers.getRedPointMsg.postMessage('')
      }
    } catch (err) {
      console.warn(err)
    }
  }
}

/**
 * 清除tabbar的红点
 * @param {*} data
 * url: String, tabbarIndex: Int, removeAll: Bool
 */
function clearBadge(data) {
  if (isLookinApp) {
    if (Android) {
      window.lookin.clearBadge(JSON.stringify(data))
    } else {
      window.webkit.messageHandlers.clearBadge.postMessage(data)
    }
  }
}

export {
  IOS,
  Android,
  isLookinApp,
  isMobile,
  openAppleSignIn,
  openGoogleLogin,
  openFacebookLogin,
  getAppVersion,
  compareVersion,
  isMi,
  isBrowser,
  shareAndroid,
  isWebview,
  qrcodeScan,
  getAppFcmToken,
  setAppRecordFCM,
  delAppFcm,
  clearBadge,
  getAppRedPointMsg
}
