<template>
  <section id="my-notices">
    <h1>最新消息</h1>
    <div class="lookin-notices">
      <ul>
        <li
          v-for="notice in notices"
          :key="notice.id"
          :class="notice.read_at == null ? 'unread' : ''"
          @click="read(notice)"
        >
          <p>{{ notice.date }} ‧ {{ noticeType(notice.type) }}</p>
          <h3 class="has-text-black"><div v-html="notice.title"></div></h3>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <Loading v-show="isLoading" />
    </transition>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { updateUserNoticesCount } from '../../../api/message'
import { UPDATE_NOTICES_COUNT } from '../../../store/mutation-types'
import Loading from '../../components/common/Loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      pageConfig: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    ...mapState(['notices', 'noticesTotal', 'isMobile', 'user'])
  },
  methods: {
    ...mapActions(['loadNotices', 'readNotice']),
    read(notice) {
      this.readNotice({ id: notice.id }).catch(e => {
        console.log(e)
      })
      if (notice.type == 'Point') {
        this.$router.push({ name: 'my_points' })
      } else {
        this.$router.push(notice.url)
      }
    },
    noticeType(type) {
      let res = ''
      switch (type) {
        case 'Article':
          res = '駐站名人新文章'
          break
        case 'Announce':
          res = '會員公告'
          break
        case 'Point':
          res = '贈點'
          break
        case 'Comment':
          res = '留言回覆'
          break
      }
      return res
    },
    async loadNoticeList() {
      if (this.isLoading) return
      if (this.notices?.length >= this.noticesTotal) return
      this.isLoading = true
      try {
        await this.loadNotices(this.pageConfig)
        this.pageConfig.pageNo++
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }
  },
  async created() {
    this.$bus.$on('/my/lazyLoad', this.loadNoticeList)
    this.loadNoticeList()
    const token = window.localStorage.getItem('auth-token')
    try {
      const { code, result } = await updateUserNoticesCount(token)
      if (code === 200 && result) {
        // 小铃铛数量归零
        this.$store.commit(UPDATE_NOTICES_COUNT, 0)
      }
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    this.$bus.$off('/my/lazyLoad')
  }
}
</script>
<style lang="scss">
#my-notices {
  padding-top: 20px;
  padding-bottom: 100px;
  h1 {
    margin: 0px 15px 30px;
  }
  .lookin-notices {
    li {
      max-height: 85px;
      overflow: hidden;
      padding: 10px 28px;
      border-bottom: 1px solid #ffdbcf;
      cursor: pointer;
      p {
        color: #989595;
        font-family: Ubuntu Condensed;
        padding: 2px 0 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 10px;
        letter-spacing: 1px;
      }
      h3 {
        font-size: 15px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 1px;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    li.unread {
      background: rgba(255, 240, 238, 0.7);
    }
  }
}

@media screen and (min-width: 1200px) {
  #my-notices {
    max-width: 1000px;
    width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #ffc2ae;
    }
  }
}
</style>
