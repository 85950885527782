<template>
  <div class="outsiderbox">
    <div id="aboutus">
      <div class="container-custom">
        <h1>About LOOKin 關於我們</h1>
        <div class="box">
          <div class="articlebox">
            <div class="titlebox">
              <span>{{maintitle}}</span>
            </div>
            <div class="contentbox">
              <p>{{maincontent}}</p>
            </div>
          </div>
          <div class="insidebox contact">
            <div class="titlebox">
              <span>{{contactinfo.title}}</span>
            </div>
            <div class="contentbox">
              <ul>
                <li v-for="(item, index) in contactinfo.content" :key="index">
                  <div class="title">
                    <span>{{item.title}}</span>
                  </div>
                  <div class="content" v-html="item.content"></div>
                </li>
              </ul>
            </div>
          </div>
          <div class="insidebox address">
            <div class="titlebox">
              <span>{{addressinfo.title}}</span>
            </div>
            <div class="contentbox">
              <ul>
                <li v-for="(item, index) in addressinfo.content" :key="index">
                  <div class="title">
                    <span>{{item.title}}</span>
                  </div>
                  <div class="content" v-html="item.content"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  data () {
    return {
      chooseDate:'2021-12-18T11:06',
      maintitle:"LOOKin美人時髦話題網 －互動實用系女性流行網站－",
      maincontent:"以話題、實用與體驗的內容貫穿整個網站！從2015/9/9網站開站至今已舉辦上千場的網路與實體體驗活動！從美妝、保養、髮型、穿搭、球鞋、時尚精品、服裝配件、生活家電…等等，LOOKin致力於成為每位女性的專屬時髦祕書，讓妳全方位地輕鬆掌握流行趨勢！",
      contactinfo:{
        title:"About LOOKin 連絡我們",
        content:[
          {
            title:"E-Mail：",
            content:"<a href=\"mailto:ilovelookin520@gmail.com\">ilovelookin520@gmail.com</a>"
          },
          {
            title:"Call Us：",
            content:"<span>+886-2-2500-7600</span>"
          }
        ]
      },
      addressinfo:{
        title:"About LOOKin 尖端媒體集團",
        content:[
          {
            title:"電話：",
            content:"<span>+886-2-2500-7600</span>"
          },
          {
            title:"地址：",
            content:"<span class='cht'>104 台北市民生東路2段141號10樓</span><span class='en'>(10F, No. 141, Sec.2 Minsheng E. Rd., Taipei 104, Taiwan)</span>"
          }
        ]
      }
    }
  },
  methods: {},
  created() {},
  mounted() {},
  updated() {}
}
</script>
<style>
*{box-sizing: border-box;}
::selection {background: #ffc2ae;}
html{background-color: #fff;font-size: 16px;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;min-width: 300px;overflow-x: hidden;overflow-y: scroll;text-rendering: optimizeLegibility;-webkit-text-size-adjust: 100%;-moz-text-size-adjust: 100%;-ms-text-size-adjust: 100%;text-size-adjust: 100%;}
body{font-family: Helvetica,Arial,LiHei Pro,微軟正黑體,Microsoft JhengHei,sans-serif;}
ul{padding: 0;margin: 0;}
ul li{list-style: none;}
.container-custom{width: 100%;padding:0 15px;margin:0 auto;position: relative;z-index: 10;}
@media (min-width: 576px) { .container-custom {max-width: 540px;} }
@media (min-width: 768px) { .container-custom {max-width: 720px;} }
@media (min-width: 992px) { .container-custom{max-width: 960px;} }
@media (min-width: 1200px) { .container-custom{max-width: 1000px;} }
#aboutus{padding: 50px 0 0;}
#aboutus h1{font-size: 20px;font-weight: 700;line-height: 27px;letter-spacing: 1px; margin: 0 0 30px;color: #f08f7a;}
#aboutus .box{padding: 0;box-shadow: none;}
#aboutus .box .articlebox{display: inline-flex;flex-wrap: wrap;align-items: center;width: 100%; margin: 0 0 15px;}
#aboutus .box .articlebox .titlebox,
#aboutus .box .articlebox .contentbox{-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
#aboutus .box .articlebox .titlebox span,
#aboutus .box .articlebox .contentbox p,
#aboutus .box .insidebox .titlebox span,
#aboutus .box .insidebox .title span,
#aboutus .box .insidebox .contentbox span,
#aboutus .box .insidebox .content span{font-size: 16px;line-height: 27px;color:#000000;}
#aboutus .box .articlebox .titlebox span{font-weight: 600;margin: 1.1428em 0 0.5714em;}
#aboutus .box .articlebox .contentbox p{font-size: 16px;font-weight: 400;line-height: 27px;text-align: justified;margin-left: 20px;text-indent: 0;}
#aboutus .box .insidebox{display: inline-flex;flex-wrap: wrap;align-items: center;width: 100%; margin: 0 0 15px;}
#aboutus .box .insidebox .titlebox,
#aboutus .box .insidebox .contentbox{-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
#aboutus .box .insidebox .titlebox{margin: 0 0 10px;}
#aboutus .box .insidebox .titlebox span{font-weight: 600;}
#aboutus .box .insidebox .contentbox ul{display: inline-flex;flex-wrap: wrap;align-items: center;width: 100%;}
#aboutus .box .insidebox .contentbox ul li{margin: 0; -ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%; display: inline-flex;flex-wrap: wrap;align-items: center;width: 100%;}
#aboutus .box .insidebox .contentbox ul li .title{margin:0;line-height: normal;font-size: 0;}
@media (max-width: 767px) { #aboutus .box .insidebox .titlebox{margin: 0 0 5px;} }
</style>
