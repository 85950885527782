var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"infiniteWrapper"},[(_vm.isChannel && !_vm.isLoading)?[_c('HomeSlide',{attrs:{"isLoading":_vm.isLoading,"home_slides":_vm.current_channel.slides}}),_vm._v(" "),_c('Advertisement',{attrs:{"id":35,"width":300,"height":100}}),_vm._v(" "),_c('div',{staticClass:"channel-title"},[_c('h1',[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.current_channel.title))])]),_vm._v(" "),_c('div',{staticClass:"article-categories"},_vm._l((_vm.categories),function(cate,index){return _c('router-link',{key:cate.key,attrs:{"to":{ name: _vm.routeName, query: { category: cate.key } }}},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(index + 1)},[_vm._v(_vm._s(cate.name))])])}),1)])]:(_vm.routeName === 'tag' && _vm.tagData)?[(_vm.tagData.images && _vm.tagData.images.length > 0)?_c('HomeSlide',{attrs:{"home_slides":_vm.tagData.images}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"channel-title tag-title"},[_c('h1',[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.tagData.name))])]),_vm._v(" "),_c('p',{staticClass:"tag-description"},[_vm._v("\n        "+_vm._s(_vm.tagData.description)+"\n      ")])])]:_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"article-list"}},[_vm._l((_vm.articles),function(article,i){return [_c('div',{key:article.id,staticClass:"list-article"},[(_vm.isMobile)?_c('p',{staticClass:"author"},[_c('router-link',{attrs:{"to":article.author_url}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.authorAvatar(article.author_avatar)}}),_vm._v(" "),_c('span',{staticClass:"name has-text-black"},[_vm._v(_vm._s(article.author_name))])]),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.published_at))])],1):_vm._e(),_vm._v(" "),_c('router-link',{staticClass:"cover img-container",attrs:{"to":{
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          }}},[_c('img',{staticClass:"big-content-img",attrs:{"src":article.image_url,"alt":article.title}})]),_vm._v(" "),_c('div',{staticClass:"a-info"},[(!_vm.isMobile)?_c('div',[_c('p',{staticClass:"author"},[_c('router-link',{attrs:{"to":article.author_url}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.authorAvatar(article.author_avatar)}}),_vm._v(" "),_c('span',{staticClass:"name has-text-black"},[_vm._v(_vm._s(article.author_name))])]),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.published_at))])],1),_vm._v(" "),_c('h2',{staticClass:"title has-text-black"},[_c('router-link',{attrs:{"to":{ name: _vm.routeName, query: { category: article.category.path } }}},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))])]),_vm._v(" "),_c('router-link',{attrs:{"to":{
                  path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
                  params: {
                    title: article.title
                  }
                }}},[_vm._v("\n                "+_vm._s(article.title)+"\n              ")])],1),_vm._v(" "),_c('p',{staticClass:"abstract"},[_vm._v("\n              "+_vm._s(article.abstract)+"\n            ")])]):_vm._e(),_vm._v(" "),_c('p',{class:_vm.isMobile ? 'has-text-centered tags' : 'tags'},_vm._l((article.tags.slice(0, 3)),function(tag){return _c('router-link',{key:tag,staticClass:"tag",attrs:{"to":{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }}},[_vm._v("#"+_vm._s(tag))])}),1)]),_vm._v(" "),(_vm.isMobile)?_c('h2',{staticClass:"title has-text-black"},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))]),_vm._v(" "),_c('router-link',{attrs:{"to":{
              path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
              params: {
                title: article.title
              }
            }}},[_vm._v("\n            "+_vm._s(article.title)+"\n          ")])],1):_vm._e()],1),_vm._v(" "),(_vm.isMobile && _vm.isChannel && i == 2)?_c('Advertisement',{attrs:{"id":37,"width":300,"height":250}}):_vm._e(),_vm._v(" "),(_vm.isChannel && !_vm.isMobile && i % 10 == 9)?_c('Advertisement',{attrs:{"id":30,"width":970,"height":250}}):_vm._e()]})],2),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }