export default {
  welcome: '歡迎',
  profile: {
    account: '帳號',
    email: 'Email',
    password: '密碼',
    confirmPassword: '確認密碼',
    nickname: '暱稱',
    realname: '真實姓名',
    gender: '性別',
    birthday: '生日',
    phone: '電話',
    zipCode: '郵遞區號',
    city: '縣市',
    dist: '區域',
    address: '住址',
    genderOptions: {
      female: '女性',
      male: '男性',
      hide: '不透露',
      agender: '無性別',
    },
  },
};
