var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ 'has-background-lookin-bg': _vm.isMobile },attrs:{"id":"whats-new"}},[_c('div',{staticClass:"home-block-title has-text-centered has-text-black"},[_c('h2',{ref:"titleRef"},[_c('span',[_vm._v("W")]),_vm._v("\n      hat's New\n      "),_c('span',{staticClass:"has-text-lookin-darker"},[_vm._v("最新文章")])])]),_vm._v(" "),_c('div',{staticClass:"slick-container"},[_c('b-icon',{staticClass:"slick-arrow prev",attrs:{"icon":"angle-left"},nativeOn:{"click":function($event){return _vm.prev()}}}),_vm._v(" "),_c('slick',{ref:"whatsNewSlick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.new_articles),function(article,index){return _c('div',{key:article.id,ref:"articleRef",refInFor:true,staticClass:"content has-text-centered"},[_c('router-link',{attrs:{"to":{
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          }},nativeOn:{"click":function($event){return _vm.handleArticle(article.id, index)}}},[_c('LazyImage',{staticClass:"content-img",attrs:{"src":article.image_url}})],1),_vm._v(" "),_c('div',{staticClass:"slide-text"},[_c('p',_vm._l((article.tags.slice(0, 3)),function(tag){return _c('router-link',{key:tag,staticClass:"tag",attrs:{"to":{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }}},[_vm._v("#"+_vm._s(tag))])}),1),_vm._v(" "),_c('p',{staticClass:"has-text-lookin-darker"},[_c('router-link',{attrs:{"to":{ name: 'new', query: { category: article.category.path } }}},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))])]),_vm._v(" "),_c('span',{staticClass:"date-author"},[_vm._v(" "+_vm._s(article.published_at)+" ‧ "+_vm._s(article.author_name))])],1),_vm._v(" "),_c('router-link',{attrs:{"to":{
              path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
              params: {
                title: article.title
              }
            }},nativeOn:{"click":function($event){return _vm.handleArticle(article.id, index)}}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(article.title))])])],1)],1)}),0),_vm._v(" "),_c('b-icon',{staticClass:"slick-arrow next",attrs:{"icon":"angle-right"},nativeOn:{"click":function($event){return _vm.next()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }