<template>
  <Transition name="fadein">
    <section class="login-sidebar" v-if="open">
      <div id="login-signup-wrapper">
        <div id="login-drawer" class="has-background-nav-bg drawer">
          <div id="login-inner-wrapper">
            <img
              v-if="isMobile"
              :src="require('@/../images/icon-close.svg')"
              alt="Close"
              class="tail-icon close"
              @click="close()"
            />
            <h2 class="title eng-font"><span class="underline">Sign In</span></h2>
            <div style="text-align: center">
              <b-button
                rounded
                :class="isShowAppleBtn ? 'facebook-btn is-apple-login' : 'facebook-btn'"
                @click="facebookLogin"
              >
                <img :src="require('@/../images/member_fb.svg')" alt="Facebook" />
                <p>使用Facebook登入</p>
              </b-button>
              <b-button
                rounded
                :class="isShowAppleBtn ? 'google-btn is-apple-login' : 'google-btn'"
                v-if="isShowGoogleBtn"
                @click="googleLogin"
              >
                <img :src="require('@/../images/member_google.svg')" alt="Google" />
                <p>使用Google登入</p>
              </b-button>
              <b-button rounded class="apple-btn" v-if="isShowAppleBtn" @click="onAppleLogin">
                <img :src="require('../../../images/member_apple.svg')" alt="Apple" />
                <p>使用Apple登入</p>
              </b-button>
            </div>
            <h3 class="sub-title"><span class="has-background-nav-bg">LOOKin 帳號登入</span></h3>
            <form class="login" @submit.prevent="login">
              <b-field label="Email">
                <b-input
                  rounded
                  type="email"
                  icon-right="user"
                  v-model="email"
                  placeholder="請輸入 Email 帳號"
                  inputmode="email"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="密碼">
                <b-input rounded type="password" v-model="password" placeholder="請輸入密碼" password-reveal required>
                </b-input>
              </b-field>
              <!-- 人机验证 -->
              <Verify
                @success="onVerifySuccess"
                mode="pop"
                captchaType="blockPuzzle2"
                :imgSize="{ width: '330px', height: '155px' }"
                ref="verify"
              ></Verify>
              <button class="button btn-login">登入</button>
            </form>
            <a class="forget-link" @click="openForgetPassword">忘記密碼？</a>
            <div class="more-links">
              <a v-if="isMobile" @click="openSignUp">加入會員</a>
              <router-link :to="{ name: 'point-rules' }" target="_blank">LOOKin 點數說明</router-link>
            </div>
          </div>
          <Advertisement v-if="!isMobile" :id="28" :width="300" :height="250" />
        </div>
        <div v-if="!isMobile" id="signup-drawer" class="has-background-nav-bg drawer">
          <div id="signup-inner-wrapper">
            <img :src="require('@/../images/icon-close.svg')" alt="Close" class="tail-icon close" @click="close()" />
            <h2 class="title eng-font"><span class="underline">New Account</span></h2>
            <div style="text-align: center">
              <b-button rounded class="facebook-btn" @click="facebookLogin">
                <img :src="require('@/../images/member_fb.svg')" alt="Facebook" />
                <p>使用Facebook註冊</p>
              </b-button>
              <b-button rounded class="google-btn" @click="googleLogin">
                <img :src="require('@/../images/member_google.svg')" alt="Google" />
                <p>使用Google註冊</p>
              </b-button>
            </div>
            <h3 class="sub-title"><span class="has-background-nav-bg">LOOKin 帳號註冊</span></h3>
            <form class="signup" @submit.prevent="signup">
              <b-field label="暱稱*">
                <b-input
                  rounded
                  v-model="newUser.nickname"
                  icon-right="user"
                  placeholder="請輸入暱稱，之後仍可以修改"
                  ref="nickname"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Email*">
                <b-input
                  rounded
                  type="email"
                  icon-right="envelope"
                  v-model="newUser.email"
                  placeholder="example@mail.com"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="密碼*">
                <b-input
                  rounded
                  type="password"
                  v-model="newUser.password"
                  placeholder="長度 6 字元以上，需區分大小寫"
                  password-reveal
                  required
                >
                </b-input>
              </b-field>
              <b-field label="確認密碼*">
                <b-input
                  rounded
                  type="password"
                  v-model="newUser.password_confirmation"
                  placeholder="請再次輸入密碼"
                  password-reveal
                  required
                >
                </b-input>
              </b-field>
              <b-field label="性別*">
                <b-radio-button v-model="newUser.gender" :native-value="2" type="is-lookin-darker">
                  <span>女性</span>
                </b-radio-button>
                <b-radio-button v-model="newUser.gender" :native-value="1" type="is-lookin-darker">
                  <span>男性</span>
                </b-radio-button>
                <b-radio-button v-model="newUser.gender" :native-value="0" type="is-lookin-darker">
                  <span>不透露</span>
                </b-radio-button>
              </b-field>
              <b-checkbox class="edm" v-model="newUser.edm" required> 是否願意收到電子報* </b-checkbox>
              <p class="note">
                註冊即表示已詳細閱讀並同意<router-link :to="{ name: 'terms' }" class="" target="_blank"
                  >會員條款</router-link
                >
              </p>
              <button class="button btn-clear" @click.prevent="clearModal = true">清除重填</button>
              <button class="button btn-submit">送出</button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        :open="loginFailModal"
        :title="'Oh No!'"
        :content="loginErrorTip"
        :btnWord="loginBtnWord"
        :confirm="loginBtnConfirm"
        @close="loginFailModal = false"
        @submit="handleActivation"
      />
      <Modal
        :open="errorModal"
        :title="'Oh No!'"
        :content="errorMsg"
        :btnWord="'我知道了'"
        @close="errorModal = false"
      />
      <!-- </b-sidebar> -->
    </section>
  </Transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Modal from '../dialog/Modal'
import Advertisement from 'src/components/common/Advertisement'
import {
  IOS,
  Android,
  isLookinApp,
  openAppleSignIn,
  openGoogleLogin,
  openFacebookLogin,
  compareVersion,
  setAppRecordFCM,
  isWebview
} from '../../../tools/bridge.js'
import { pointsToHousehold } from '../../../api/yellowStrip'
import { UPDATE_SHOW_YELLOW_TIP } from '../../../store/mutation-types'
import Verify from '../verifition/Verify.vue'
import { login, activateAccount, registerApi } from '../../../api/user'
import apis from '../../../api'
import { LOGIN_SUCCESS } from '../../../store/mutation-types'
import { baseURL } from '../../../api/request'

export default {
  props: ['open'],
  data() {
    return {
      // sitekey: process.env.RECAPTCHA_PUBLIC_KEY,
      // validateRecaptcha: process.env.NODE_ENV == 'development',
      email: null,
      password: null,
      loginFailModal: false,
      loginErrorTip: '',
      errorMsg: '',
      errorModal: false,
      newUser: {
        nickname: null,
        email: null,
        password: null,
        password_confirmation: null,
        gender: 2, // 0 不透露 1 男 2 女
        edm: true
      },
      emptyUser: {
        nickname: null,
        email: null,
        password: null,
        password_confirmation: null,
        gender: 2,
        edm: true
      },
      originURL: null,
      isShowAppleBtn: false,
      nowAppVersion: '2.2.2', // 使用的app版本
      isShowGoogleBtn: true,
      loginBtnWord: '我知道了',
      loginBtnConfirm: ''
    }
  },
  components: {
    Modal,
    Advertisement,
    Verify
    // VueRecaptcha: () => import('vue-recaptcha')
  },
  created() {
    // webview中隐藏google按钮
    if (isWebview()) {
      this.isShowGoogleBtn = false
    } else {
      this.isShowGoogleBtn = true
    }

    // h5中显示按钮
    if (!isLookinApp) {
      if (IOS) {
        this.isShowAppleBtn = true
      }
    }
  },
  mounted() {
    // 獲取蘋果登錄token app會調用
    window.sendUserInformation = async userMessage => {
      console.log(userMessage, 'sendUserInformation')
      const { code, message, result } = JSON.parse(userMessage || '{}')
      if (code == 200) {
        const token = result.token || ''
        // const { token, user_id, userid } = result || {}
        // apple登录返回的是userid, 其他登录 user_id
        // const _userId = user_id || userid
        // 設置token
        window.localStorage.setItem('auth-token', token)
        // eslint-disable-next-line
        // $cookies.set('user_id', _userId)
        // 获取用户信息
        try {
          const { data } = await apis.getProfile(token)
          // 设置用户信息
          // eslint-disable-next-line
          $cookies.set('user_id', data.id)
          // eslint-disable-next-line
          $cookies.set('toc_user_id', data.id)
          setAppRecordFCM(data.id)
          const user = Object.assign(data, { auth_token: token })
          this.$store.commit(LOGIN_SUCCESS, user)

          // 参数值为 me 跳转到会员专区
          const { to } = this.$route.query
          if (to) {
            this.$router.push(to)
          }
          this.$emit('successLogin')
          this.setLoginDrawer(false)
        } catch (err) {
          const { error } = err.data
          this.loginErrorTip = error
          this.loginFailModal = true
        }
      } else if (code == 100 || code == '611' || code == '500') {
        this.errorMsg = message
        this.errorModal = true
      } else if (code == '701') {
        this.errorMsg = '認證失敗，請重新登入'
        this.errorModal = true
      }
    }
  },
  computed: {
    ...mapState(['isMobile', 'loginDrawer', 'via', 'isPointArticle', 'appVersion'])
  },
  watch: {
    open: function (val) {
      if (val == true) {
        // 禁止页面滚动
        if (document.documentElement) document.documentElement.style.overflow = 'hidden'
        this.updateURL()
      } else {
        if (document.documentElement) document.documentElement.style.overflow = 'auto'
      }
    },
    via: function () {
      this.updateURL()
    },
    appVersion() {
      if (isLookinApp) {
        // 在尖端app中用 ios判断的方法有bug
        if (!Android) {
          // app版本大於2.2.1，顯示apple登錄按鈕
          if (compareVersion(this.appVersion, '2.2.1')) {
            this.isShowAppleBtn = true
          }
        }
      }
    },
    loginFailModal(val) {
      if (!val) {
        this.loginBtnWord = '我知道了'
        this.loginBtnConfirm = ''
      }
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setSignUpDrawer', 'setForgetPasswordDrawer']),
    close: function () {
      this.email = null
      this.password = null
      this.setLoginDrawer(false)
      // 使用完后删除
      window.sessionStorage.removeItem('from-game')
      window.sessionStorage.removeItem('game-login')
    },
    async login({ captchaVerification = '' }) {
      // eslint-disable-next-line
      const deviceId = $cookies.get('device_id') || ''
      // eslint-disable-next-line
      const secureToken = $cookies.get('secure_token') || ''
      const params = {
        email: this.email,
        password: this.password,
        deviceId,
        captchaVerification,
        secure_token: secureToken
      }
      const { code, message, result } = await login(params)
      if (code === 200) {
        window.localStorage.setItem('auth-token', result)

        // 获取用户信息
        try {
          const { data } = await apis.getProfile(result)
          // 设置用户信息
          // eslint-disable-next-line
          $cookies.set('user_id', data.id)
          // eslint-disable-next-line
          $cookies.set('toc_user_id', data.id)
          setAppRecordFCM(data.id)
          const user = Object.assign(data, { auth_token: result })
          this.$store.commit(LOGIN_SUCCESS, user)

          // 参数值为 me 跳转到会员专区
          const { to } = this.$route.query
          if (to) {
            this.$router.push(to)
          }
          this.$emit('successLogin')
          this.setLoginDrawer(false)

          // 点数归户
          const fd = new FormData()
          fd.append('deviceId', deviceId)
          fd.append('userId', data.id)
          // webCode直接传空就可以了
          fd.append('webCode', '')
          pointsToHousehold(fd)
            .then(() => {
              // 关闭黄条
              this.$store.commit(UPDATE_SHOW_YELLOW_TIP, false)

              try {
                new TrackEvent().setEvent('test_monitor_readypost', '', '', null, null)
              } catch (error) {
                console.warn(error)
              }
            })
            .catch(err => {
              console.warn(err)
            })

          try {
            // true说明是游戏页进入登录的
            if (window.sessionStorage.getItem('from-game') == 'true') {
              // 使用完后删除
              window.sessionStorage.removeItem('from-game')
              window.sessionStorage.removeItem('game-login')
              // h5游戏，登录成功，携带token过去
              document.getElementById('h5-game-iframe').contentWindow.setLogin(result)
              document.body.style.overflow = 'scroll'
              // 添加游戏统计事件
              new TrackEvent().setEvent('水逆游戏点击登录', '点击', '水逆游戏点击', '', '')
            }
          } catch (err) {
            console.log(err)
          }
        } catch (err) {
          const { error } = err.data
          this.loginErrorTip = error
          this.loginFailModal = true
        }

        try {
          new TrackEvent().setEvent('email_login', 'click', 'login', '', '')
        } catch (error) {
          console.warn(error)
        }
      } else if (code === 411) {
        this.$refs.verify.show()
      } else if (code === 611) {
        this.loginErrorTip = '您的帳號尚未啟用，點擊發送後請到信箱查收啟用帳號信件以開通帳號，謝謝！'
        this.loginBtnWord = '取消'
        this.loginBtnConfirm = '發送'
        this.loginFailModal = true
      } else {
        this.loginErrorTip = message
        this.loginFailModal = true
      }
    },
    async handleActivation() {
      const userForm = {
        email: this.email,
        password: this.password
      }
      const { code, message, result } = await activateAccount(userForm)
      if (code === 200) {
        this.$buefy.toast.open({
          message: result,
          type: 'toast-color',
          duration: 3000
        })
        this.loginFailModal = false
      } else {
        this.$buefy.toast.open({
          type: 'is-danger',
          message
        })
      }
    },
    openForgetPassword: function () {
      this.setForgetPasswordDrawer(true)
      this.setLoginDrawer(false)
    },
    openSignUp: function () {
      this.setSignUpDrawer(true)
      this.setLoginDrawer(false)
    },
    updateURL: function () {
      let url = new URL(window.location.href)
      if (this.via) {
        url.searchParams.append('via_type', this.via.type)
        url.searchParams.append('via_id', this.via.id)
        url.searchParams.append('via_path', this.via.path)
      }
      this.originURL = encodeURIComponent(url)
    },
    cleanForm: function () {
      this.clearModal = false
      Object.assign(this.newUser, this.emptyUser)
      this.$nextTick(() => {
        this.$refs.nickname.focus()
      })
    },
    async signup() {
      if (this.via) {
        this.newUser.via_type = this.via.type
        this.newUser.via_id = this.via.id
        this.newUser.via_path = this.via.path
      }

      try {
        const { code, message } = await registerApi(this.newUser)
        if (code === 200) {
          this.$emit('successSignUp')
          this.close()
          if (window.sessionStorage.getItem('from-game') == 'true') {
            // 使用完后删除
            window.sessionStorage.removeItem('from-game')
            window.sessionStorage.removeItem('game-login')
            // 添加游戏统计事件
            try {
              new TrackEvent().setEvent('水逆游戏点击注册', '点击', '水逆游戏点击', '', '')
            } catch (error) {
              console.log(error)
            }
          }
        } else if (code === 606) {
          this.errorMsg = '密碼與確認密碼不相符'
          this.errorModal = true
        } else {
          this.errorMsg = message
          this.errorModal = true
        }
      } catch (error) {
        console.error(error)
      }
      // this.$store
      //   .dispatch('signUp', this.newUser)
      //   .then(() => {
      //     this.$emit('successSignUp')
      //     this.close()
      //     if (window.sessionStorage.getItem('from-game') == 'true') {
      //       // 使用完后删除
      //       window.sessionStorage.removeItem('from-game')
      //       window.sessionStorage.removeItem('game-login')
      //       // 添加游戏统计事件
      //       try {
      //         new TrackEvent().setEvent('水逆游戏点击注册', '点击', '水逆游戏点击', '', '')
      //       } catch (error) {
      //         console.log(error)
      //       }
      //     }
      //   })
      //   .catch(error => {
      //     // console.log(error.data)
      //     // let errorArray = Object.keys(error.data).map(key => `${key} ${error.data[key][0]}`)
      //     // this.errorMsg = errorArray[0]
      //     const { email, error: err } = error.data
      //     // email有两条数据就只显示第二条，反之只显示第一条
      //     if (email) {
      //       if (email.length === 2) {
      //         this.errorMsg = email[1]
      //       } else {
      //         this.errorMsg = email[0]
      //       }
      //     }
      //     if (err) {
      //       this.errorMsg = '密碼與確認密碼不相符'
      //     }
      //     this.errorModal = true
      //   })
    },
    onAppleLogin() {
      if (isLookinApp) {
        // 苹果登录
        openAppleSignIn()
      } else {
        const viewUrl = window.location.href.includes('?') ? `${location.href}&` : `${location.href}?`
        // eslint-disable-next-line
        const secureToken = $cookies.get('secure_token') || ''
        // h5跳转apple登录
        window.location.replace(`${baseURL}/shop/auth/apple?secure_token=${secureToken}&viewUrl=${viewUrl}`)
      }
    },
    facebookLogin() {
      const viewUrl = window.location.href.includes('?') ? `${location.href}&` : `${location.href}?`
      // eslint-disable-next-line
      const secureToken = $cookies.get('secure_token') || ''
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          // 调起原生app方法
          openFacebookLogin()
        } else {
          window.location.replace(
            `${baseURL}/shop/thirdparty/thirdpartyRedirect/6/_login?secure_token=${secureToken}&viewUrl=${viewUrl}`
          )
        }
      } else {
        window.location.replace(
          `${baseURL}/shop/thirdparty/thirdpartyRedirect/6/_login?secure_token=${secureToken}&viewUrl=${viewUrl}`
        )
      }

      try {
        new TrackEvent().setEvent('facebook_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    },
    googleLogin() {
      const viewUrl = window.location.href.includes('?') ? `${location.href}&` : `${location.href}?`
      // eslint-disable-next-line
      const secureToken = $cookies.get('secure_token') || ''
      if (isLookinApp) {
        if (compareVersion(this.appVersion, this.nowAppVersion)) {
          openGoogleLogin()
        } else {
          window.location.href = `${baseURL}/shop/thirdparty/thirdpartyRedirect/5/_login?secure_token=${secureToken}&viewUrl=${viewUrl}`
        }
      } else {
        window.location.href = `${baseURL}/shop/thirdparty/thirdpartyRedirect/5/_login?secure_token=${secureToken}&viewUrl=${viewUrl}`
      }

      try {
        new TrackEvent().setEvent('google_login', 'click', 'login', '', '')
      } catch (error) {
        console.log(error)
      }
    },
    onVerifySuccess(params) {
      // 人机验证成功 登录
      this.login(params)
    }
  }
}
</script>

<style lang="scss">
.toast-color {
  background-color: #ffd5ca;
}

.login-sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 39;
  overflow-y: auto;
}

.fadein-enter-active {
  animation: fadein 0.3s;
}
.fadein-leave-active {
  animation: fadein 0.3s reverse;
}

@keyframes fadein {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

#login-drawer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title {
    margin: 60px 0 25px;
  }

  #login-inner-wrapper {
    margin: 0 auto;
    width: 281px;
    max-width: 281px;
    padding-bottom: 100px;
    h3.sub-title {
      width: 100%;
      text-align: center;
      border-bottom: 2px solid #404040;
      line-height: 0.1em;
      margin: 60px 0 30px;
      span {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0 10px;
      }
    }

    form.login {
      .field {
        margin-top: 15px;
        color: #404040;
        line-height: 24px;
        width: 100%;
        label {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 0;
          margin-left: 11px;
        }
        input {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 1px;
          border: none;
        }
      }
      .recaptcha {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    button.btn-login {
      width: 100%;
      height: 41px;
      background-color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 1px;
      color: #ffffff;
      border-radius: 50px;
      margin-top: 20px;
    }

    .forget-link {
      display: block;
      margin-top: 10px;
      color: #404040;
      font-size: 13px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
    }
    .more-links {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        margin: 30px 10px 0;
        color: #404040;
        font-size: 15px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
      }
    }
    // .is-apple-login {
    //     width: 269px;
    //     margin-bottom: 15px;
    //     float: none;
    //   }
  }
}

@media screen and (min-width: 1200px) {
  #login-signup-wrapper {
    display: flex;
    justify-content: center;
    overflow-y: hidden;
    #login-drawer {
      position: relative;
      width: 50vw;
      overflow-y: hidden;
      border-right: white solid 1px;
      #login-inner-wrapper {
        width: 400px;
        max-width: 400px;
        .more-links {
          flex-direction: column;
          align-items: center;
        }
      }
    }
    #signup-drawer {
      position: relative;
      width: 50vw;
      overflow-y: hidden;
      min-height: 100vh;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .title {
        margin: 60px 0 25px;
      }
      #signup-inner-wrapper {
        margin: 0 auto;
        width: 400px;
        max-width: 400px;
        h3.sub-title {
          width: 100%;
          text-align: center;
          border-bottom: 2px solid #404040;
          line-height: 0.1em;
          margin: 60px 0 30px;
          span {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 1px;
            padding: 0 10px;
          }
        }

        form.signup {
          margin-bottom: 100px;
          .field {
            margin-top: 15px;
            color: #404040;
            line-height: 24px;
            width: 100%;
            label {
              font-size: 12px;
              font-weight: 700;
              letter-spacing: 1px;
              margin-bottom: 0;
              margin-left: 11px;
            }
            input {
              font-size: 15px;
              font-weight: 400;
              letter-spacing: 1px;
              border: none;
            }
            input:focus + span.has-text-primary {
              color: #f08f7a !important;
            }
            label.b-radio.button {
              height: 38px;
              border-radius: 50px;
              border: none;
              min-width: 90px;
              font-size: 15px;
              font-weight: 400;
              line-height: 24px;
              margin: 0;
              padding: 10px;
            }
            label.b-radio.button.is-lookin-darker {
              box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
            }
            .boolean .b-radio.radio.button {
              width: 180px;
            }
          }
          .field.has-addons {
            margin-top: 0;
            // justify-content: space-between;
          }
          p.note {
            text-align: center;
            color: #404040;
            font-size: 15px;
            line-height: 24px;
            margin: 20px auto;
            a {
              font-weight: 600;
              color: #000000;
              text-decoration: underline;
            }
          }
          .edm {
            margin: 15px 0 12px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #404040;
          }
        }

        // button.button {
        //   width: 180px;
        //   height: 41px;
        //   font-size: 16px;
        //   font-weight: 700;
        //   line-height: 24px;
        //   border-radius: 50px;
        //   margin-top: 20px;
        // }
        button.btn-clear {
          width: 180px;
          height: 41px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          border-radius: 50px;
          margin-top: 20px;
          border: 2px solid #000000;
          background-color: transparent;
          box-sizing: border-box;
          color: black;
        }
        button.btn-submit {
          width: 180px;
          height: 41px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          border-radius: 50px;
          margin-top: 20px;
          background-color: #000;
          color: #ffffff;
          float: right;
        }
      }
    }
  }
}
</style>
