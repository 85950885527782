<template>
  <div class="coupon-line">
    <div class="l-text" v-if="text">{{ text }}</div>
    <div class="l-solid" v-else></div>
  </div>
</template>

<script>
export default {
  name: 'CouponLine',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.coupon-line {
  position: relative;
  text-align: center;
  font-size: 11px;
  color: #f08f7a;
  line-height: 18px;
  margin: 10px 0;
  .l-solid {
    border-bottom: 1px dashed #e1e5e7;
  }
  .l-text {
    &::before,
    &::after {
      content: '';
      position: absolute;
      border-bottom: 1px dashed #e1e5e7;
      top: 50%;
      width: 100px;
    }
    &::before {
      left: 5px;
    }
    &::after {
      right: 5px;
    }
  }
}
</style>
