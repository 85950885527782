<template>
  <div class="cancellation-success">
    <img class="img" :src="imgUrl" alt="success" />
    <p class="succ">{{ titleText[code] }}</p>
    <div class="tip" v-if="code === 200">
      <p>註銷帳號驗證信已發送到您的信箱</p>
      <p>為確保您的權益與身份，請至郵箱确认</p>
    </div>
    <button class="btn" @click="onSuccess">好的</button>
    <p class="email">如收不到驗證郵件，請聯繫客服：lovelookin520@gmail.com</p>
  </div>
</template>

<script>
export default {
  name: "cancellation-success",
  props: {
    code: Number,
  },
  created() {
    switch (this.code) {
      case 200:
        this.imgUrl = require("../../../images/cancellation-success.svg");
        break;
      case 500:
        this.imgUrl = require("../../../images/cancellation-error.svg");
      default:
        this.imgUrl = require("../../../images/cancellation-success.svg");
        break;
    }
  },
  data() {
    return {
      titleText: {
        200: "郵件發送成功！",
        100: "已發送郵箱，請耐心等待",
        500: "網絡錯誤，請稍後重試",
      },
      imgUrl: "",
    };
  },
  methods: {
    onSuccess() {
      // 跳回首页
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.cancellation-success {
  text-align: center;
  padding-top: 54px;
  padding-bottom: 20px;
  .img {
    width: 56px;
    height: 56px;
  }
  .succ {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #424242;
    line-height: 25px;
    margin-top: 24px;
    margin-bottom: 14px;
  }
  .tip {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #424242;
    line-height: 20px;
  }
  .btn {
    width: 140px;
    height: 38px;
    background: #0a0a0a;
    border-radius: 19px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    letter-spacing: 4px;
    margin-top: 27px;
    outline: none;
    border: none;
  }
  .email {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #404040;
    line-height: 17px;
    margin-top: 170px;
  }
}
</style>
