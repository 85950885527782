import { recordFcmToken } from '../../api/fcm'
import { getAppFcmToken, Android, getAppRedPointMsg, setAppRecordFCM } from '../../tools/bridge'
export default {
  methods: {
    // 从cookie获取user id
    getUserId() {
      let userId = $cookies.get('toc_user_id')
      if (!userId) userId = $cookies.get('user_id')
      if (!userId) {
        try {
          let dailyUID = JSON.parse($cookies.get('daily_login_user_id'))
          userId = dailyUID?.[0]
        } catch {}
      }
      // 仍然取不到 =0
      if (!userId) userId = '0'
      return userId
    },

    recordFCM() {
      const userId = this.getUserId()
      setAppRecordFCM(userId)
      console.log('setRecordAccountFCM---', userId)
    },

    // 接收app的fcm token, 并注册
    sendFCMToken(fcmToken) {
      const userID = this.getUserId()
      console.log('[app 发送 fcmToken] -- ', fcmToken)
      if (fcmToken && typeof fcmToken == 'string') {
        // $cookies.set('fcm_token', fcmToken)
        recordFcmToken({
          userId: userID,
          fireBaseToken: fcmToken,
          deviceId: $cookies.get('device_id') || '',
          platform: Android ? 1 : 0
        })
        // .then(res => {
        //   console.log('记录fcm token --- ', res)
        // })
        // .catch(err => {
        //   console.warn('记录fcm token错误:', err)
        // })
        return 1
      } else {
        return 0
      }
    },

    getAppFCMToken() {
      try {
        getAppFcmToken()
      } catch (err) {
        console.warn(`[getAppFCMToken]`, err)
      }
    },

    // app发送红点推送相关的数据,用于webview按需渲染页面
    sendRedPointMsg(data) {
      console.log('[收到app红点数据]', data, ',type:', typeof data)
      try {
        //app会return类似 [{"tab":3,"render":[1,2,3]}]
        // 在使用的地方需要JSON.parse()
        if (typeof data === 'string') {
          localStorage.setItem('redPointData', data)
        } else if (typeof data === 'object') {
          localStorage.setItem('redPointData', JSON.stringify(data))
        } else {
          console.warn('[sendRedPointMsg]--红点数据格式错误!')
        }
      } catch (error) {}
    },

    getAppRedPoints() {
      console.log('app--getRedPointMsg()--')
      getAppRedPointMsg()
    },

    // 获取localStorage的红点数据
    getRedPointsDataByStorage() {
      try {
        return JSON.parse(localStorage.getItem('redPointData'))
      } catch {
        return null
      }
    }
  },
  created() {
    window.sendFCMToken = this.sendFCMToken
    window.sendRedPointMsg = this.sendRedPointMsg
    window.getAppRedPoints = this.getAppRedPoints
    window.getRedPointsDataByStorage = this.getRedPointsDataByStorage
    // 向app获取fcm token, app会通过sendFCMToken回传
    // this.getAppFCMToken()
    // 呼叫app记录当前账号的fcm token
    this.recordFCM()
    // 向app获取红点数据, app会通过sendRedPointMsg回传
    this.getAppRedPoints()

    window.testNet = function () {
      recordFcmToken({
        userId: '0',
        fireBaseToken: 'TEST_FCM_TOKEN',
        deviceId: $cookies.get('device_id') || '',
        platform: 0
      })
    }
  }
}
