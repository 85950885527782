<template>
  <div class="prizes-plan">
    <bread-crumb class="bread">
      <bread-crumb-item to="/">首頁</bread-crumb-item>
      <bread-crumb-item to="/prizes/plan">熱點兌換計劃</bread-crumb-item>
    </bread-crumb>
    <div class="mb-back" @click="$router.push('/')">
      <i class="left"></i>
      <span class="text">返回首頁</span>
    </div>
    <div class="plan-showcase">
      <img class="showcase-title-img" src="../../../images/prizes/title.png" alt="" />
      <div class="showcase-box">
        <div class="box-t">
          <img src="../../../images/prizes/left_points.svg" alt="" />
          <div class="txt">
            <p class="box-tip" v-if="isToReceive">LOOKin<span>熱點</span>領取成功！</p>
            <p class="box-tip" v-else>
              您尚有<span>{{ yellowTip.point }}點</span>未領取！
            </p>
          </div>
          <img src="../../../images/prizes/right_points.svg" alt="" />
        </div>
        <p class="box-text">粉紅亮白風暴來襲！快用熱點來兌換！</p>
        <div class="box-prize">
          <!-- <a href="/game/fishingluck/index.html">
            <img src="../../../images/prizes/prize-img.png" alt="" style="height: 100%; width: 100%" />
          </a> -->
          <img class="box-prize-img" src="../../../images/prizes/prize-img.jpg" alt="" />
          <div class="prize-left">
            <p class="t">肌本博士 亮采修膚精華 50ml</p>
            <div class="price">
              <div class="point">
                <img src="../../../images/prizes/icon_points.svg" alt="" />
                <span>100</span>
              </div>
              <div class="market">市價： NT$850</div>
            </div>
          </div>
        </div>
        <button class="box-btn" @click="onWatch">{{ isToReceive ? '觀看熱點兌換清單' : '登入領取點數' }}</button>
        <p class="title-right">
          <img src="../../../images/prizes/icon_praise.svg" alt="" />
          <router-link to="/point-rules" target="_blank">點數累積規則></router-link>
        </p>
      </div>
    </div>
    <div class="plan-line"></div>
    <div class="plan-bottom">
      <div class="bottom-title">
        <i class="left"></i>
        <h2>最新熱點兌換商品</h2>
        <i class="right"></i>
      </div>
      <ul class="bottom-list">
        <li class="item" v-for="item in prizesList" :key="item.id" @click="gotoPrizes(item.id)">
          <div class="img">
            <img :src="item.image" alt="" />
          </div>
          <p class="text singe-line">{{ item.title }}</p>
          <div class="price">
            <div class="point">
              <img src="../../../images/prizes/icon_points.svg" alt="" />
              <span>{{ item.point }}</span>
            </div>
            <div class="market" v-if="item.originPrice">市價： NT{{ item.originPrice }}</div>
          </div>
        </li>
      </ul>
    </div>

    <yellow-modal :title="modalTitle" :visible.sync="showModal" @close="onClose">
      <template v-slot:content>
        <login-modal
          ref="loginRef"
          v-if="modalType === 'login'"
          @loginSuccess="onLoginSuccess"
          @gotoRegister="gotoRegister"
          @appThirdPartyLogin="appThirdPartyLogin"
          @showVerify="onShowVerify"
        />
        <register-modal v-else-if="modalType === 'register'" @gotoLogin="gotoLogin" />
        <Receive v-else :point="yellowTip.point" :isEmail="isEmail" @confirm="handleConfirm" />
      </template>
    </yellow-modal>
    <!-- 人机验证 -->
    <Verify
      @success="onVerifySuccess"
      mode="pop"
      captchaType="blockPuzzle2"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { LOGIN_SUCCESS } from '../../../store/mutation-types'
import breadCrumb from '../../components/breadCrumb'
import breadCrumbItem from '../../components/breadCrumb/item'
import YellowModal from '../../components/yellowStrip/index.vue'
import LoginModal from '../../components/yellowStrip/login.vue'
import RegisterModal from '../../components/yellowStrip/register.vue'
import Receive from '../../components/yellowStrip/receive.vue'
import { newPrizes, decideContactEmail } from '../../../api/prize'
import { pointsToHousehold } from '../../../api/yellowStrip'
import { UPDATE_YELLOW_TIP } from '../../../store/mutation-types'
import { ToastProgrammatic as Toast } from 'buefy'
import Verify from '../../components/verifition/Verify.vue'

export default {
  name: 'PrizesPlan',
  components: {
    breadCrumb,
    breadCrumbItem,
    YellowModal,
    LoginModal,
    RegisterModal,
    Receive,
    Verify
  },
  data() {
    return {
      showModal: false,
      modalTitle: '',
      /**
       * modalType 弹框类型
       * login 登录弹框
       * register 注册弹框
       * receive 领取成功弹框
       *  */
      modalType: 'login',
      isToReceive: false, // 是否已領取點數

      prizesList: [],
      isEmail: false // 是否绑定联系邮箱
    }
  },
  created() {
    // 获取最新商品
    this.getNewPrizes()
  },
  mounted() {
    const { token } = this.$route.query

    if (this.user) {
      this.isToReceive = true
    } else {
      this.isToReceive = false
    }

    if (token) {
      // this.belongToFami()
      this.getContactEmail()
    }
  },
  computed: {
    ...mapState(['user', 'yellowTip'])
  },
  watch: {
    user(val) {
      // 有用户信息，已登录，反之则没有
      if (val) {
        this.isToReceive = true
      } else {
        this.isToReceive = false
      }
    },
    showModal(val) {
      if (!val) {
        switch (this.modalType) {
          case 'login':
            try {
              new TrackEvent().setEvent('ht_close_login', 'click', 'login', '', '')
            } catch (error) {
              console.warn(error)
            }
            break
          case 'register':
            try {
              new TrackEvent().setEvent('ht_close_register', 'click', 'register', '', '')
            } catch (error) {
              console.warn(error)
            }
            break
          case 'receive':
            try {
              new TrackEvent().setEvent('ht_close_email', 'click', 'bind', '', '')
            } catch (error) {
              console.warn(error)
            }
            break
          default:
            break
        }
      }
    }
  },
  methods: {
    onWatch() {
      if (this.isToReceive) {
        this.$router.push('/prizes')
      } else {
        this.showModal = true
        try {
          new TrackEvent().setEvent('ht_click_login', 'click', 'login', '', '')
        } catch (error) {
          console.warn(error)
        }
      }
    },
    onLoginSuccess() {
      this.isToReceive = true
      this.showModal = false
      // 登录成功，归户点数
      this.belongToFami()
    },
    // onLoginSuccess(data) {
    //   localStorage.setItem('auth-token', data.auth_token)
    //   // eslint-disable-next-line
    //   $cookies.set('user_id', data.id)
    //   // eslint-disable-next-line
    //   $cookies.set('toc_user_id', data.id)
    //   this.$store.commit(LOGIN_SUCCESS, data)
    //   this.isToReceive = true
    //   // this.modalType = 'receive'
    //   this.showModal = false
    //   // 登录成功，归户点数
    //   this.belongToFami()
    // },
    handleConfirm() {
      // console.log('第三方登录，绑定邮箱')
      this.showModal = false
    },
    gotoRegister() {
      this.modalType = 'register'
    },
    gotoLogin() {
      this.modalType = 'login'
    },
    appThirdPartyLogin() {
      // app第三方登录
      this.belongToFami()
      this.getContactEmail()
    },
    async getNewPrizes() {
      try {
        const { code, message, result } = await newPrizes()
        if (code === 200) {
          this.prizesList = result
        } else {
          Toast.open({
            duration: 3000,
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async belongToFami() {
      try {
        const fd = new FormData()
        // eslint-disable-next-line
        fd.append('deviceId', $cookies.get('device_id'))
        // eslint-disable-next-line
        fd.append('userId', $cookies.get('user_id'))
        // webCode直接传空就可以了
        fd.append('webCode', '')
        const data = await pointsToHousehold(fd)
        const { code, message, result } = data.data
        if (code === 200) {
          this.$store.commit(UPDATE_YELLOW_TIP, { point: result.point, code: '' })
        } else {
          Toast.open({
            duration: 3000,
            type: 'is-danger',
            message
          })
        }
        try {
          new TrackEvent().setEvent('test_monitor_readypost', '', '', null, null)
        } catch (error) {
          console.warn(error)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async getContactEmail() {
      try {
        const { code, message, result } = await decideContactEmail()
        if (code === 200) {
          this.isEmail = result
          this.modalType = 'receive'
          this.showModal = true
        } else {
          Toast.open({
            duration: 3000,
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.warn(error)
      }
    },
    onClose() {
      this.showModal = false
    },
    onShowVerify() {
      this.$refs.verify.show()
    },
    onVerifySuccess(params) {
      // 滑动验证成功 调用login组件的登录方法
      this.$refs.loginRef.onLogin(params)
    },
    gotoPrizes(id) {
      // this.$router.push(`/prizes/${id}`)
      window.location.href = `/nuxt/prizes/${id}`
    }
  }
}
</script>

<style lang="scss">
$black: #333333;
$pink: #ff826d;

.prizes-plan {
  max-width: 1014px;
  margin: 0 auto;
  // 超出省略号
  .singe-line {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }
  .mb-back {
    display: none;
  }
  .plan-showcase {
    padding: 44px 25px 31px;
    background-image: url(../../../images/prizes/banner_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    .showcase-title-img {
      width: 217px;
      height: 39px;
      margin-bottom: 24px;
    }
    .showcase-box {
      max-width: 680px;
      margin: 0 auto;
      padding: 24px 22px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(247, 140, 125, 0.3);
      border-radius: 10px;
      .box-t {
        display: flex;
        justify-content: center;
        align-items: center;
        .txt {
          margin: 0 10px;
        }
        .box-tip {
          font-size: 20px;
          font-weight: 600;
          color: #404040;
          line-height: 28px;
          span {
            color: #f78c7d;
          }
        }
      }
      .box-text {
        font-size: 15px;
        color: #404040;
        line-height: 21px;
        margin-top: 17px;
        margin-bottom: 20px;
      }
      .box-prize {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        max-width: 360px;
        // height: 140px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #f78c7d;
        margin: 0 auto;
        .box-prize-img {
          width: 148px;
          height: 120px;
          object-fit: cover;
        }
        .prize-left {
          flex: 1;
          padding-left: 15px;
          text-align: left;
          .t {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .price {
            .point {
              display: flex;
              align-items: center;
              padding: 15px 0 6px;
              span {
                font-size: 18px;
                font-weight: 600;
                color: #ff272f;
                line-height: 20px;
                margin-left: 5px;
              }
            }
            .market {
              font-size: 14px;
              color: #989595;
              line-height: 21px;
              text-decoration: line-through;
            }
          }
        }
      }
      .box-btn {
        width: 235px;
        height: 44px;
        background: #f78c7d;
        border-radius: 25px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        border: none;
        margin: 20px 0 12px;
        cursor: pointer;
      }
      .title-right {
        font-size: 14px;
        font-weight: 600;
        color: $pink;
        img {
          width: 19px;
          height: 14px;
          margin-right: 3px;
        }
      }
    }
  }
  .plan-bottom {
    .bottom-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 39px 0 30px;
      h2 {
        font-size: 21px;
        font-weight: 600;
        color: #404040;
        padding: 0 13px;
      }
      i {
        position: relative;
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #fdc3ae;
        &::after {
          position: absolute;
          bottom: 0;
          content: '';
          width: 7.5px;
          height: 7.5px;
          border-radius: 50%;
          border: 1px solid #404040;
        }
      }
      i.right {
        &::after {
          right: 0;
        }
      }
    }
    .bottom-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 240px);
      grid-gap: 18px;
      // padding-top: 30px;
      margin-bottom: 60px;
      .item {
        .img {
          height: 240px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: cover;
          }
        }
        .text {
          font-size: 14px;
          color: #333333;
          margin-top: 15px;
          margin-bottom: 6px;
        }
        .price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 0 5px;
          .point {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 18px;
              font-weight: 600;
              color: #ff272f;
            }
          }
          .market {
            font-size: 14px;
            color: #989595;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

// 移动端样式
@media screen and (max-width: 768px) {
  .prizes-plan {
    width: 100%;
    .bread {
      display: none;
    }
    .mb-back {
      padding: 16px 20px;
      display: block;
      i {
        border: solid #f08f7a;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 4px;
        margin-bottom: 1px;
      }
      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
      .text {
        font-size: 13px;
        color: #f08f7a;
        margin-left: 5px;
      }
    }
    .plan-showcase {
      .showcase-title-img {
        width: 181px;
        height: 32px;
      }
      .showcase-box {
        .box-prize {
          .box-prize-img {
            width: 128px;
            height: 100px;
          }
        }
      }
    }
    .plan-line {
      height: 10px;
      background-color: #f2f2f2;
    }
    .plan-showcase {
      background-image: url(../../../images/prizes/banner_bg_mb.png);
    }
    // .plan-top {
    //   padding: 15px;
    //   margin-top: 0;
    //   border-bottom: 10px solid #f2f2f2;
    //   .top-text {
    //     font-size: 15px;
    //   }
    //   .top-title {
    //     font-size: 20px;
    //   }
    //   .top-introduce {
    //     width: 100%;
    //     margin: 20px auto 0;
    //     .introduce-title {
    //       .title-left {
    //         font-size: 14px;
    //       }
    //       .title-right {
    //         font-size: 12px;
    //       }
    //     }
    //     .introduce-text {
    //       font-size: 12px;
    //     }
    //   }
    // }
    .plan-bottom {
      padding: 0 15px;
      .bottom-title {
        h2 {
          font-size: 17px;
        }
        padding: 13px 0 20px;
      }
      .bottom-list {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 7px;
        .item {
          padding-bottom: 7px;
          .img {
            height: 170px;
          }
          .text {
            font-size: 15px;
          }
          .price {
            .point {
              span {
                font-size: 16px;
              }
            }
            .market {
              font-size: 11px;
            }
          }
          .btn {
            margin-top: 6px;
            button {
              width: 91px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .prizes-plan {
    .plan-bottom {
      .bottom-list {
        grid-template-columns: repeat(2, minmax(170px, 1fr));
      }
    }
  }
}
</style>
