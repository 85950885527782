<template>
  <div class="my-coupon">
    <h3 class="c-title">我的票券</h3>
    <b-tabs :expanded="isMobile" :destroy-on-hide="true" @input="onInput">
      <b-tab-item label="未使用" value="unused">
        <CouponList :list="unusedList" :loading="loading" @jump="onJump" />
      </b-tab-item>
      <b-tab-item label="已使用" value="utilized">
        <CouponList :list="utilizedList" :loading="loading" @jump="onJump" />
      </b-tab-item>
      <b-tab-item label="已過期" value="expired">
        <CouponList :list="expiredList" :loading="loading" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { couponList } from '../../../api/storeManager'
import CouponList from '../../components/coupon/List.vue'

export default {
  name: 'MyCoupon',
  components: { CouponList },
  data() {
    return {
      unusedList: [], // 未使用
      utilizedList: [], // 已使用
      expiredList: [], // 已過期
      loading: false
    }
  },
  computed: {
    ...mapState(['isMobile'])
  },
  created() {
    // 默认获取未使用
    this.onInput('unused')
  },
  methods: {
    // tab栏切换
    onInput(value) {
      // console.log(value)
      switch (value) {
        // 未使用
        case 'unused':
          this.unusedList = []
          this.getCouponList(0)
          // this.getCouponList(1)
          break
        // 已使用
        case 'utilized':
          this.utilizedList = []
          this.getCouponList(2)
          break
        // 已過期
        case 'expired':
          this.expiredList = []
          this.getCouponList(3)
          break
        default:
          break
      }
    },
    /**
     * 获取票券列表
     * @param {Number} typeId 0 1 未使用；2 已使用； 3 已過期；
     */
    async getCouponList(typeId) {
      this.loading = true
      try {
        const { code, message, result } = await couponList(typeId)
        // console.log(result, '#')
        if (code === 200 && result) {
          if (typeId === 0) {
            // 0 和 1 都是未使用
            this.unusedList = result
            const res = await couponList(1)
            if (res.code === 200 && res.result) {
              this.unusedList = [...this.unusedList, ...res.result]
            }
          } else if (typeId === 2) {
            this.utilizedList = result
          } else if (typeId === 3) {
            this.expiredList = result
          }
        } else {
          // console.log(message)
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    onJump(id) {
      this.$router.push(`/my/coupon/${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.my-coupon {
  min-height: 100vh;
  background-color: #f5f5f5;
  .c-title {
    font-size: 15px;
    line-height: 18px;
    padding: 15px 0 10px 15px;
    background-color: #ffffff;
    color: #0a0a0a;
  }
  ::v-deep .tabs {
    background-color: #ffffff;
  }
}

@media screen and (min-width: 1200px) {
  .my-coupon {
    background-color: #ffffff;
    padding-top: 50px;
    .c-title {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      border-bottom: 4px solid #ffc2ae;
      margin-bottom: 30px;
    }
  }
}
</style>
