<template>
  <div class="associate">
    <slot />
    <ul class="associate-list" v-if="list && list.length > 0">
      <li class="item" v-for="(item, index) in list" :key="index" @click="$emit('click', item)" v-html="item"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Associate',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  emits: ['click'],
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.associate {
  position: relative;
  // width: 640px;
  .associate-list {
    position: absolute;
    max-height: 450px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 0px 1px 3px 0px rgba(240, 144, 122, 0.6);
    border-radius: 10px;
    border: 1px solid #ffc1ad;
    padding: 30px 20px;
    z-index: 20;
    // transform: translateY(9px);
    .item {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      &:hover {
        color: #f08f7a;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
/Users/vinson/Desktop/work/lookin/lookin-client/src/components/search/HistoryMore.vue