<template>
  <div class="coupon-verification">
    <div class="v-top">
      <Icon />
      <p class="v-text">核銷成功</p>
    </div>
    <div class="v-line"></div>
    <div class="v-middle">
      <ul>
        <li>
          <span>優惠券名稱</span>
          <span>{{ title }}</span>
        </li>
        <li>
          <span>核銷時間</span>
          <span>{{ useTime }}</span>
        </li>
        <li>
          <span>交易單號</span>
          <span>{{ convertCode }}</span>
        </li>
      </ul>
    </div>
    <div class="v-bottom">
      <b-button class="btn" type="is-primary" rounded expanded @click="handleVerification">繼續核銷</b-button>
    </div>
  </div>
</template>

<script>
import Icon from '../../components/coupon/Icon.vue'
import { isLookinApp, qrcodeScan } from '../../../tools/bridge.js'

export default {
  name: 'CouponVerification',
  components: { Icon },
  data() {
    return {
      title: '',
      convertCode: '',
      useTime: ''
    }
  },
  created() {
    const { title, convertCode, useTime } = this.$route.query
    this.title = title
    this.convertCode = convertCode
    this.useTime = useTime
  },
  methods: {
    handleVerification() {
      // 调用app扫码功能
      if (isLookinApp) {
        const token = window.localStorage.getItem('auth-token') || ''
        qrcodeScan(token)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-verification {
  .v-top {
    text-align: center;
    padding: 80px 0 52px;
    .v-text {
      font-size: 17px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
    }
  }
  .v-line {
    height: 10px;
    background-color: #f6f6f6;
  }
  .v-middle {
    margin-top: 10px;
    ul {
      padding: 7px 12px;
      li {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #666666;
        line-height: 18px;
        border-bottom: 1px solid #e5e5ea;
        padding: 13px 0 9px;
      }
    }
  }
  .v-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 83px;
    background-color: #ffffff;
    padding: 15px;
    .btn {
      font-size: 15px;
      color: #ffffff;
      background-color: #f08f7a;
    }
  }
}
</style>
