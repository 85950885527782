<template>
  <div class="bread-crumb">
    <slot />
  </div>
</template>

<script>
/**
 * 面包屑组件
 * to	路由跳转对象，同 vue-router 的 to   类型 string/object
 * 示例 /src/pages/My/PrizeDetails.vue
 */
export default {
  name: 'breadCrumb'
}
</script>

<style scoped lang="scss">
.bread-crumb {
  display: flex;
  padding: 25px 10px;
  ::v-deep &-item {
    a {
      font-size: 15px;
      font-weight: 500;
      color: #404040;
      line-height: 21px;
      &.router-link-exact-active {
        color: #f08f7a;
      }
    }

    &:last-child .iconfont {
      display: none;
    }
  }
  ::v-deep i {
    margin: 0 10px;
  }
}
</style>
