<template>
  <div class="receive">
    <img class="receive_img" src="../../../images/point/readingToPoint.svg" alt="" />
    <h3 class="receive_head">熱點領取成功！</h3>
    <div class="receive_text" v-if="isEmail">
      <p>恭喜您獲得LOOKin點數，</p>
      <p>天天報到、參與活動就有機會拿到更多點數</p>
      <p>【熱點兌換】眾多商品等妳來兌換！</p>
    </div>
    <div class="receive_text" v-else>
      <p>現在填寫常用信箱，最新資訊寄給你！還能再立刻獲得1熱點唷</p>
    </div>
    <div class="receive_input" v-if="!isEmail">
      <b-input v-model="email" type="text" placeholder="請輸入Email信箱" rounded></b-input>
    </div>
    <b-button class="receive_btn" rounded expanded @click="onConfirm">{{ textBtn }}</b-button>
    <div class="receive_tip" v-if="!isEmail">
      <router-link to="/prizes">觀看熱點兌換清單 ></router-link>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import { bindingEmail } from '../../../api/prize'
export default {
  name: 'Receive',
  emits: ['confirm'],
  props: {
    /**
     * type 弹框类型
     * 默认 ordinary 普通登录弹框 (⊙﹏⊙) 方案調整 普通登録不弹框了
     * thirdParty 第三方登录弹框
     */
    // type: {
    //   type: String,
    //   default: 'ordinary'
    // },
    point: {
      type: Number,
      default: 0
    },
    isEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: ''
    }
  },
  computed: {
    textBtn() {
      return this.isEmail ? '觀看熱點兌換清單' : '確定'
    }
  },
  methods: {
    async onConfirm() {
      if (this.isEmail) {
        this.$router.push('/prizes')
      } else {
        try {
          new TrackEvent().setEvent('ht_bind_email', 'click', 'bind', '', '')
        } catch (error) {
          console.log(error)
        }

        const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

        if (!this.email.trim()) {
          Toast.open({
            duration: 3000,
            type: 'is-warning',
            message: '請填寫郵箱'
          })
          return
        }

        if (!reg.test(this.email)) {
          Toast.open({
            duration: 3000,
            type: 'is-warning',
            message: '郵箱格式有誤'
          })
          return
        }

        try {
          const fd = new FormData()
          fd.append('email', this.email)
          const { code, message } = await bindingEmail(fd)
          if (code === 200) {
            Toast.open({
              duration: 3000,
              type: 'is-success',
              message: '綁定成功'
            })
            this.$emit('confirm')
          } else {
            Toast.open({
              duration: 3000,
              type: 'is-warning',
              message
            })
          }
        } catch (error) {
          console.warn(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.receive {
  text-align: center;
  &_img {
    width: 100px;
    height: 100px;
  }
  &_head {
    font-size: 20px;
    font-weight: 600;
    color: #0a0a0a;
    padding: 10px 0 12px;
  }
  &_text {
    font-size: 16px;
    color: #404040;
    line-height: 22px;
    padding: 0 20px;
  }
  &_input {
    padding-top: 15px;
  }
  ::v-deep .receive_btn {
    width: 229px;
    height: 45px;
    margin: 27px auto 15px;
    background-color: #f78c7d;
    span {
      font-size: 16px;
      color: #ffffff;
    }
  }
  &_tip {
    a {
      font-size: 14px;
      color: #999999;
    }
  }
}

@media screen and (max-width: 820px) {
  .receive {
    &_head {
      font-size: 18px;
    }
    &_text {
      font-size: 13px;
    }
    &_input {
      ::v-deep .input {
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
