<template>
  <section v-if="pageData.author" id="author-container">
    <div class="author">
      <div class="info">
        <div class="avatar">
          <img :src="authorAvatar(pageData.author.avatar)" />
        </div>
        <p class="name has-text-black">
          {{ pageData.author.nickname }}
          <span class="follow-btn" :class="followClass(pageData.author.followed)" @click="follow(pageData.author)">
            Follow
            <img :src="followIcon(pageData.author.followed)" />
          </span>
        </p>
        <p class="intro">{{ pageData.author.introduction }}</p>
      </div>
    </div>

    <div class="articles">
      <div class="article" v-for="(article, i) in pageData.articles" :key="i">
        <router-link :to="article.link">
          <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" />
        </router-link>
        <div class="slide-text">
          <p class="has-text-centered" v-if="article.tags">
            <router-link
              v-for="tag in article.tags.slice(0, 3)"
              :key="tag"
              class="tag"
              :to="{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }"
              >#{{ tag }}</router-link
            >
          </p>
          <p class="has-text-centered has-text-lookin-darker">
            <span class="category has-text-black" :class="cateClass(article.category.id)">{{
              article.category.title
            }}</span>
            <span class="date-author">{{ article.published_at }} ‧ {{ article.author_name }}</span>
          </p>
          <router-link :to="article.link">
            <h3 class="title has-text-black">{{ article.title }}</h3>
          </router-link>
        </div>
      </div>
    </div>
    <button
      v-if="showMore"
      class="load-more has-text-white has-background-black has-text-centered"
      @click="showMore = false"
    >
      LOAD MORE
    </button>

    <Modal :open="modal" :title="'請先登入'" :content="'這是會員專屬功能喔！'" :btnWord="'好的'" @close="closeModal" />
    <transition name="fade">
      <Loading v-show="isLoading" />
    </transition>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { categoryClass } from 'src/share'
import Modal from 'src/components/dialog/Modal'
import Loading from 'src/components/common/Loading'

export default {
  data() {
    return {
      queryParams: {
        pageNo: 1,
        pageSize: 10
      },
      pageData: {
        articles: [],
        author: {},
        total: 0
      },
      isLoading: true,
      showMore: false,
      modal: false
    }
  },
  components: { Modal, Loading },
  computed: {
    ...mapState(['user', 'author', 'isMobile']),
    authorAvatar: function () {
      return avatar => (!!avatar ? avatar : require('@/../images/editor.png'))
    },
    followIcon: function () {
      return followed => (followed != true ? require('@/../images/unfollow.svg') : require('@/../images/follow.svg'))
    },
    followClass: function () {
      return followed => (followed != true ? 'unfollow' : 'follow')
    },
    itemsToShow: function () {
      if (this.showMore) {
        return this.isMobile ? 5 : 6
      } else {
        return this.isMobile ? 10 : 12
      }
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer']),
    cateClass: id => categoryClass(id),
    follow(author) {
      if (!!this.user) {
        if (author.followed != true) {
          this.$store
            .dispatch('follow', { author_id: author.id })
            .then(() => {})
            .catch(error => {
              console.log(error.data)
            })
        } else {
          this.$store
            .dispatch('unfollow', { author_id: author.id })
            .then(() => {})
            .catch(error => {
              console.log(error.data)
            })
        }
      } else {
        this.modal = true
      }
    },
    closeModal() {
      this.modal = false
      this.setLoginDrawer(true)
    },
    throttle(fun, delay) {
      let last, deferTimer
      return function () {
        let that = this
        let _args = arguments
        let now = +new Date()
        if (last && now < last + delay) {
          clearTimeout(deferTimer)
          deferTimer = setTimeout(function () {
            last = now
            fun.apply(that, _args)
          }, delay)
        } else {
          last = now
          fun.apply(that, _args)
        }
      }
    },

    // 触底加载更多articles
    onScrollLoad() {
      if (this.pageData.total > 0 && this.pageData.articles.length >= this.pageData.total) return
      // 获取滚动位置
      const scrollY = window.scrollY
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight

      // 判断是否触底，并且不处于加载中
      if (scrollY + windowHeight >= documentHeight && !this.isLoading) {
        // 触底操作
        this.isLoading = true
        this.queryParams.pageNo++
        this.$store
          .dispatch('loadAuthor', this.queryParams)
          .then(() => {
            const { articles } = this.$store.state.author
            this.pageData.articles = this.pageData.articles.concat(articles || [])
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    }
  },
  created() {
    this.$store.dispatch('loadAuthor', this.queryParams).then(() => {
      // 这里使用组件data定义的pageData,不再使用vuex的state
      this.pageData = this.$store.state.author
      this.isLoading = false
    })
    this.throttledOnScrollLoad = this.throttle(this.onScrollLoad, 800)
    window.addEventListener('scroll', this.throttledOnScrollLoad)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.throttledOnScrollLoad)
  }
}
</script>
<style lang="scss">
#author-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  .author {
    background: #fff0ee;
    padding: 0 24px 20px;
    margin-top: 90px;
    width: 100vw;
    .info {
      position: relative;
      font-family: Ubuntu Condensed;
      font-style: normal;
      font-weight: normal;
      text-align: right;
      padding-top: 35px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      .avatar {
        position: absolute;
        width: 76px;
        border-radius: 50%;
        height: 76px;
        top: -38px;
        left: 50%;
        background: #fff0ee;
        transform: translateX(-50%);
        img {
          position: relative;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          margin: auto;
          width: 60px;
          height: 60px;
        }
      }
      p.name {
        font-family: Ubuntu Condensed;
        font-size: 17px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 1px;
        position: relative;
        .follow-btn {
          position: absolute;
          top: 5px;
          right: -120px;
          border-radius: 24px;
          padding: 3px 13px;
          font-size: 15px;
          line-height: 10px;
          letter-spacing: 1px;
          cursor: pointer;
          vertical-align: middle;
          img {
            width: 12px;
          }
        }
      }
      .follow {
        border: #f08f7a solid 1px;
        color: #f08f7a;
      }
      .unfollow {
        border: #989595 solid 1px;
        color: #989595;
      }
      p.intro {
        font-size: 14px;
        line-height: 20px;
        text-align: justify;
        margin: 18px auto 10px;
        max-width: 600px;
      }
    }
  }
  .articles {
    width: 92%;
    margin: 60px 4%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .article {
      height: 380px;
      max-width: 375px;
      margin: 20px;
      img {
        height: 216px;
        width: 100%;
        border: 1px solid #ffc2ae;
        margin: 0 auto;
        object-fit: cover;
      }
      h3.title {
        font-size: 16px;
        font-weight: normal;
        line-height: 140%;
        text-align: justify;
        margin: 0 5px 70px;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 71%;
        letter-spacing: 1px;
        padding: 5px;
        span.category {
          font-size: 13px;
          line-height: 185%;
          letter-spacing: 1px;
          padding: 1px 6px;
        }
        span.date-author {
          font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
          font-weight: normal;
          font-size: 14px;
          line-height: 71%;
          letter-spacing: 1px;
        }
        .tag {
          font-size: 13px;
          line-height: 185%;
          text-align: center;
          letter-spacing: 1px;
          color: #f08f7a;
          background-color: transparent;
          margin: 6px 6px 0;
          border: 1px solid #f08f7a;
          border-radius: 50px;
        }
      }
    }
  }
}
</style>
