<template>
  <section>
    <Modal
      :open="confirmSuccess"
      :title="'Email 驗證成功！請重新登入'"
      :btnWord="'好的'"
      @close="redirectToHome" />
    <Modal
      :open="confirmFail"
      :title="'Email 驗證失敗！請確認'"
      :btnWord="'好的'"
      @close="redirectToHome" />
  </section>
</template>

<script>
import Modal from '../components/dialog/Modal'

export default {
  data() {
    return {
      confirmSuccess: false,
      confirmFail: false
    }
  },
  components: { Modal },
  methods: {
    redirectToHome() {
      this.confirmSuccess = false
      this.confirmFail = false
      if (this.$route.name != 'home') {
        this.$router.push({ name: 'home' })
      }
    },
  },
  mounted() {
    this.$store.dispatch('confirmEmail', { confirmation_token: this.$route.query.confirmation_token }).then((response) => {
        this.confirmSuccess = true
      }).catch((error) => {
        this.confirmFail = true
      })
  },
};
</script>
