/* eslint no-param-reassign:0 */
import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import getters from './getters'
import * as actions from './actions'

Vue.use(Vuex)

const state = {
  isMobile: true,
  notFound404: false,
  serverError500: false,
  authFail: false,
  loginDrawer: false,
  signUpDrawer: false,
  forgetPasswordDrawer: false,
  keyword: null,
  logos: null,
  home: {
    slides: null,
    ig_photos: null,
    hot_videos: null,
    new_articles: null,
    hot_issues: null,
    magazines: null,
    pick_up: null,
    member_only: null,
    recommends: null,
    fixed_widget: null
  },
  menu: null,
  fixed_widget: null,
  channels: null,
  current_channel: {
    title: null,
    slides: null
  },
  current_page: 1,
  next_page: 1,
  articles: null,
  article: null,
  inter_video: null,
  authors: null,
  author: null,
  new_comment: null,
  recommends: null,
  video: null,
  topVideo: null,
  videos: null,
  videoCategories: null,
  announces: null,
  announce: null,
  prizes: null,
  specials: null,
  current_trial: null,
  trials: null,
  current_lecture: null,
  lectures: null,
  current_vote: null,
  votes: null,
  hot_tags: null,
  search_tags: null,
  user: null,
  point_records: null,
  expire_points: null,
  event_records: null,
  collections: null,
  subscriptions: null,
  notices: null,
  noticesTotal: 0,
  via: null,
  memberZoneItems: [
    { id: 2001, title: '會員專區', path: '/my/me', icon: '' },
    { id: 2002, title: '最新消息', path: '/my/notices', icon: 'member_notice' },
    { id: 2003, title: '會員資料', path: '/my/info', icon: 'member_edit' },
    { id: 2004, title: '會員公告', path: '/my/announces', icon: 'member_announce' },
    { id: 2005, title: '積點紀錄', path: '/my/points', icon: 'member_point' },
    { id: 2007, title: '收藏紀錄', path: '/my/collection', icon: 'member_follow' },
    { id: 2008, title: '訂閱紀錄', path: '/my/subscriptions', icon: 'member_keep' },
    { id: 2009, title: '活動紀錄', path: '/my/activities', icon: 'member_event' },
    { id: 2010, title: '註銷帳號', path: '/my/cancellation', icon: 'member_del' },
    { id: 2011, title: '我的票券', path: '/my/coupon', icon: 'member_coupon' },
    { id: 2012, title: '兌換記錄', path: '/my/exchange-record', icon: 'member_gift' }
  ],
  // web端 my 菜单
  desktopMenu: [
    { id: 1, title: '最新消息', path: '/my/notices' },
    { id: 2, title: '會員資料', path: '/my/info' },
    { id: 3, title: '會員公告', path: '/my/announces' },
    { id: 4, title: '積點紀錄', path: '/my/points', class: 'light' },
    { id: 5, title: '收藏紀錄', path: '/my/collection', class: 'light' },
    { id: 6, title: '訂閱紀錄', path: '/my/subscriptions', class: 'light' },
    { id: 7, title: '活動紀錄', path: '/my/activities', class: 'light' },
    { id: 8, title: '我的票券', path: '/my/coupon' },
    { id: 9, title: '兌換記錄', path: '/my/exchange-record' }
  ],
  token: localStorage.getItem('auth-token') || '',
  baseURL: process.env.API_URL,
  bindVisible: false, // 是否显示登录绑定弹框
  appVersion: '', // app版本号
  showYellowTip: false, // 是否显示黄条
  yellowTip: {
    code: '',
    point: 0 // 点数
  },
  prizeInfoModal: null, // 赠品信息
  isShowUpdateTip: false // app 版本升级弹框
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})
