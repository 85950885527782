<template>
  <section :class="width > 900 ? 'big' : 'small'" :style="`width:${width}px;height:${height}px`" ref="advertisingRef">
    <b-skeleton :active="!loaded" :height="`${height}px`"></b-skeleton>
    <!-- <iframe v-show="loaded" @load="load" :src="src" :width="width" :height="height" loading="lazy" frameborder="0" scrolling="no" allowtransparency="true"></iframe> -->
    <iframe
      :style="{ visibility: visibleFlag }"
      @load="load"
      :src="advertisingUrl"
      :width="width"
      :height="height"
      loading="lazy"
      frameborder="0"
      scrolling="no"
      allowtransparency="true"
    ></iframe>
  </section>
</template>

<script>
import useObserver from '../../../tools/useObserver'

export default {
  props: ['id', 'width', 'height'],
  data() {
    return {
      // base: 'https://banner2.look-in.com.tw/banner/bannerShow/',
      base: '',
      loaded: false,
      visibleFlag: 'hidden',
      advertisingUrl: ''
    }
  },
  created() {
    const origin = window.location.origin

    if (origin.includes('localhost')) {
      this.base = 'https://banner-staging.look-in.com.tw/banner/bannerShow/'
    } else if (origin.includes('staging')) {
      this.base = 'https://banner-staging.look-in.com.tw/banner/bannerShow/'
    } else {
      this.base = 'https://banner2.look-in.com.tw/banner/bannerShow/'
    }
  },
  mounted() {
    // 广告懒加载
    this.lazyAdvertising()
  },
  computed: {
    src() {
      return `${this.base}${this.id}`
    }
  },
  methods: {
    load: function () {
      this.loaded = true
      this.visibleFlag = 'visible'
    },
    lazyAdvertising() {
      useObserver(this.$refs.advertisingRef, () => {
        this.advertisingUrl = `${this.base}${this.id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.big {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.small {
  margin: 40px auto;
}
</style>
