var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"right-column has-background-lookin-bg"},[_c('Advertisement',{attrs:{"id":31,"width":300,"height":250}}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"recommends"},[_vm._l((_vm.recommends),function(article,i){return [(i < 5)?_c('div',{key:article.id,ref:"recommendArticleRef",refInFor:true,staticClass:"issue",attrs:{"data-id":article.id},on:{"click":function($event){return _vm.handleArticle(article.id, i)}}},[_c('div',{staticClass:"recmd-img-container"},[_c('router-link',{attrs:{"to":{
              path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
              params: {
                title: article.title
              }
            }}},[_c('img',{staticClass:"content-img",attrs:{"src":article.image_url || require('@/../images/default.jpg')}})])],1),_vm._v(" "),_c('router-link',{attrs:{"to":{
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          }}},[_c('h3',{staticClass:"has-text-black"},[_c('span',{staticClass:"category",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))]),_vm._v("\n            "+_vm._s(article.title)+"\n          ")])])],1):_vm._e()]})],2),_vm._v(" "),_c('Advertisement',{attrs:{"id":32,"width":300,"height":600}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-block-title has-text-centered has-text-black"},[_c('h2',[_c('span',[_vm._v("R")]),_vm._v("ecommend"),_c('span',{staticClass:"has-text-lookin-darker"},[_vm._v("推薦文章")])])])}]

export { render, staticRenderFns }