<template>
  <div class="register">
    <form class="register_form" @submit.prevent="onRegister">
      <b-field label="暱稱">
        <b-input v-model="registerForm.nickname" type="text" placeholder="請輸入暱稱，之後仍可以修改" rounded></b-input>
      </b-field>
      <b-field label="Email*">
        <b-input
          v-model="registerForm.email"
          type="email"
          required
          placeholder="請輸入信箱帳號"
          inputmode="email"
          rounded
        ></b-input>
      </b-field>
      <b-field label="密碼">
        <b-input
          type="password"
          v-model="registerForm.password"
          placeholder="長度 6 字元以上，需區分大小寫"
          password-reveal
          required
          rounded
        >
        </b-input>
      </b-field>
      <b-field label="密碼">
        <b-input
          type="password"
          v-model="registerForm.password_confirmation"
          placeholder="請再次輸入密碼"
          password-reveal
          required
          rounded
        >
        </b-input>
      </b-field>
      <b-field label="性別*">
        <b-radio-button v-model="registerForm.gender" native-value="female" type="is-lookin-darker">
          <span>女性</span>
        </b-radio-button>
        <b-radio-button class="men" v-model="registerForm.gender" native-value="male" type="is-lookin-darker">
          <span>男性</span>
        </b-radio-button>
        <b-radio-button v-model="registerForm.gender" native-value="hide" type="is-lookin-darker">
          <span>不透露</span>
        </b-radio-button>
      </b-field>
      <b-field>
        <b-checkbox class="edm" v-model="registerForm.edm" required>是否願意收到電子報</b-checkbox>
      </b-field>
      <b-field>
        <p class="terms">
          註冊即表示已詳細閱讀並同意
          <router-link :to="{ name: 'terms' }" target="_blank">會員條款</router-link>
        </p>
      </b-field>
      <b-field>
        <!-- <b-button class="send" rounded expanded>送出</b-button> -->
        <button class="button send">
          <span>送出</span>
        </button>
      </b-field>
    </form>
    <div class="goto_login">
      <span>已有會員帳號？</span>
      <span class="login" @click="gotoLogin">去登入</span>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
// import { register } from '../../../api/yellowStrip'
import apis from '../../../api'
export default {
  name: 'RegisterModal',
  data() {
    return {
      registerForm: {
        nickname: '',
        email: '',
        password: '',
        password_confirmation: '',
        gender: 'female',
        edm: true
      }
    }
  },
  methods: {
    async onRegister() {
      if (this.registerForm.password !== this.registerForm.password_confirmation) {
        Toast.open({
          duration: 3000,
          type: 'is-danger',
          message: '密碼與確認密碼不相符'
        })
        return
      }

      try {
        const data = await apis.signup(this.registerForm)
        // console.log(data)
        Toast.open({
          duration: 4000,
          type: 'is-success',
          message: data.data.result
        })
        this.$emit('gotoLogin')
      } catch (err) {
        console.log(err?.response)
        if (err?.response?.status === 400) {
          Toast.open({
            duration: 3000,
            type: 'is-danger',
            message: err.response.data.email[0]
          })
        } else if (err?.response?.status === 500) {
          Toast.open({
            duration: 3000,
            type: 'is-danger',
            message: '系統異常！'
          })
        }
      }
    },
    gotoLogin() {
      this.$emit('gotoLogin')
      try {
        new TrackEvent().setEvent('ht_skip_login', 'click', 'login', '', '')
      } catch (error) {
        console.warn(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  ::v-deep &_form {
    .field {
      .label {
        font-size: 12px;
        font-weight: 600;
        color: #363636;
        padding-left: 10px;
      }
      .help {
        padding-left: 10px;
      }
      .field-body {
        .control {
          .b-radio {
            width: 90px;
            height: 42px;
            border-radius: 21px !important;
            background: #f5f5f5;
            &.radio.button.is-selected {
              background: #f08f7a;
            }
            &.radio.button.is-lookin-darker {
              background: #f08f7a;
            }
          }
          &.men {
            margin: 0 10px;
          }
          .button {
            border-color: transparent;
          }
        }
      }
      .edm {
        margin-top: 5px;
        vertical-align: middle;
        .check {
          width: 14px;
          height: 14px;
        }
        .control-label {
          font-size: 14px;
          color: #404040;
        }
      }
      .terms {
        font-size: 13px;
        color: #404040;
      }
      .send {
        width: 100%;
        height: 42px;
        background: #000000;
        border-radius: 21px;
        span {
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .goto_login {
    padding-top: 12px;
    font-size: 14px;
    color: #999999;
    text-align: center;
    .login {
      color: #f08f7a;
      cursor: pointer;
    }
  }
}
</style>
