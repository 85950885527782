<template>
  <div class="sitemap-section">
    <div :class="['section-header', !!links ? 'header-with-links' : 'header-only']">
      <template v-if="!!path">
        <a :href="path" class="has-text-black" :target="open ? '_blank' : '_self'">
          {{ title }}
        </a>
      </template>
      <template v-else>
        {{ title }}
      </template>
    </div>
    <div v-if="!!links" class="section-body">
      <p v-for="(link, i) in links">
        <a :href="link.path" class="has-text-black">
          {{ link.title }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sitemap_section',
  props: ['title', 'path', 'open', 'links'],
}
</script>

<style lang="scss">
.sitemap-section {
  margin: 30px 47px;
  border: 1px solid #FFDBCF;
  border-radius: 20px;
  .section-header {
    height: 50px;
    padding: 16px 35px;
    background: #FFDBCF;
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
  }
  .section-body {
    padding: 16px 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .header-only {
    border-radius: 20px;
  }
  .header-with-links {
    border-radius: 20px 20px 0px 0px;
  }
}

</style>
