<template>
  <div class="coupon-task">
    <div class="t-top" @click="handleToggle">
      <div>
        <img class="t-task-img" src="../../../images/coupon/task.svg" alt="" />
        <span class="t-text">{{ title }}</span>
      </div>
      <img class="t-arrow" :class="{ active: !isShow }" src="../../../images/coupon/unfolding.svg" alt="" />
    </div>
    <Transition name="draw">
      <div v-show="isShow" class="t-box">
        <slot name="content" />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'CouponTask',
  props: {
    // 任务标题
    title: {
      type: String,
      default: ''
    },
    // 展开/折叠
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleToggle() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.draw-enter-active,
.draw-leave-active {
  transition: max-height 0.6s;
}

.draw-leave-active,
.draw-enter {
  max-height: 0 !important;
}

.draw-leave,
.draw-enter-active {
  max-height: 20rem;
}

.coupon-task {
  .t-top {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .t-task-img {
      vertical-align: text-top;
    }
    .t-text {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-left: 6px;
    }
    .t-arrow {
      transition: transform 0.6s;
      &.active {
        transform: rotate(90deg);
      }
    }
  }
  .t-box {
    max-height: 20rem;
    overflow: hidden;
  }
}
</style>
