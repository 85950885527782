<template>
  <section id="specials">
    <h1><span class="underline">獨家企劃</span></h1>
    <carousel-3d v-if="!isLoading" class="top"
      :key="slider3d"
      :autoplay="true"
      :display="display"
      :perspective="0"
      :width="width"
      :height="height"
      :space="space"
      :border="0">
      <slide v-for="(item, i) in specials.top" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <a :href="item.link" target='_blank'>
            <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="item.image_url">
            <h3><span class="has-text-black">{{ item.title }}</span></h3>
          </a>
        </template>
      </slide>
    </carousel-3d>
    <div v-if="!isLoading">
      <div class="category" v-for="(category, i) in specials.categories">
        <h2>{{ category.title }}</h2>
        <carousel-3d
          :key="1000+i"
          :display="display"
          :perspective="0"
          :width="width"
          :height="height"
          :space="space"
          :border="0">
          <slide v-for="(item, i) in top3items(category.specials)" :index="i" :key="i">
            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
              <a :href="item.link" target='_blank'>
                <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="item.image_url">
                <h3><span class="has-text-black">{{ item.title }}</span></h3>
              </a>
            </template>
          </slide>
        </carousel-3d>
        <router-link :to="{ name: 'specials-category', params: { category: category.path }}">
          <b-button type="is-black" rounded>更多獨家企劃</b-button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { Carousel3d, Slide } from 'vue-carousel-3d'

export default {
  data() {
    return {
      isLoading: true,
      slider3d: 0,
      display: 3,
      width: 320,
      height: 390,
      space: 180,
    }
  },
  components: { Carousel3d, Slide },
  watch: {
    isMobile: function () {
      if (this.isMobile) {
        this.height = 390
        this.width = 320
        this.space = 180
      } else {
        this.height = 550
        this.width = 440
        this.space = 480
      }
      this.slider3d += 1
    }
  },
  computed: {
    ...mapState(['specials', 'isMobile']),
    top3items: function () {
      return items => items.slice(0,3)
    }
  },
  created() {
    if (window.innerWidth >= 1200) {
      this.height = 550
      this.width = 440
      this.space = 480
      this.slider3d += 1
    }
    this.$store.dispatch('loadSpecials').then(() => {
      this.isLoading = false
    })
  },
}
</script>

<style lang="scss">
#specials {
  h1 {
    margin: 15px 0;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    & .underline {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        height: 6px;
        background: #FFC2AE;
        position: absolute;
        left: -10px;
        right: -10px;
        bottom: -5px;
        z-index: -1;
      }
    }
  }
  .top {
    margin: 40px 0;
  }
  .category {
    margin: 50px 0;
    text-align: center;
    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
      color: #404040;
    }
    button {
      height: 34px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .carousel-3d-slide {
    background-color: transparent;
    h3 {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 1px;
      text-align: center;
      span {
        background-color: #FFDBCF;
        padding: 3px;
      }
    }
    .onLeft + h3, .onRight + h3 {
      display: none;
    }
  }
}
</style>
