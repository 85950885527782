<template>
  <section id="online-magazine" :class="isMobile ? 'has-background-lookin-bg' : ''">
    <div class="home-block-title has-text-centered has-text-black">
      <h2><span>O</span>nline Magazine<span class="has-text-lookin-darker">線上雜誌</span></h2>
    </div>
    <carousel-3d
      :key="slider3d"
      :display="display"
      :perspective="0"
      :width="width"
      :height="height"
      :space="space"
      :border="0"
      v-if="!isLoading"
    >
      <slide v-for="(mag, i) in magazines" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <a :href="mag.link" target="_blank">
            <!-- <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="mag.image_url"> -->
            <LazyImage
              :data-index="index"
              :class="{ current: isCurrent, onLeft: leftIndex >= 0, onRight: rightIndex >= 0 }"
              :src="mag.image_url"
            />
          </a>
        </template>
      </slide>
    </carousel-3d>
  </section>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import { mapState } from 'vuex'
import LazyImage from '../../components/common/LazyImage.vue'

export default {
  props: ['isLoading', 'magazines'],
  data() {
    return {
      slider3d: 0,
      display: 3,
      width: 164,
      height: 220,
      space: 180
    }
  },
  watch: {
    isMobile: function () {
      this.display = this.isMobile ? 3 : 7
      this.height = this.isMobile ? 220 : 288
      this.width = this.isMobile ? 164 : 230
      this.space = this.isMobile ? 180 : 250
      this.slider3d += 1
    }
  },
  components: { Carousel3d, Slide, LazyImage },
  computed: {
    ...mapState(['isMobile'])
  }
}
</script>

<style lang="scss">
#online-magazine {
  min-height: 460px;
  @media screen and (min-width: 1200px) {
    h2 {
      z-index: 1;
      &::after {
        content: '';
        height: 8px;
        background: #ffdbcf;
        position: absolute;
        left: 6px;
        right: 7px;
        bottom: 8px;
        z-index: -1;
      }
    }
  }
  .carousel-3d-container {
    margin: 0 auto 90px;
    .carousel-3d-slide {
      padding: 5px 0;
      background-color: transparent;
      a {
        display: inline-block;
        padding: 5px;
        img {
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
}
</style>
