/**
 *
 * @param {*} target 需观察元素
 * @param {*} callback 观察元素可视触发回调
 */
const useObserver = (target, callback) => {
  //observer配置项
  var observerOptions = {
    threshold: 0.5, //目标元素与视窗重叠的阈值（0~1）
    root: null // 目标视窗即目标元素的父元素，如果没有提供，则默认body元素
  }

  //observer 回调函数
  const observerCallback = entries => {
    entries.forEach(item => {
      /*
       * item.time发生相交到相应的时间，毫秒
       * item.rootBounds：根元素矩形区域的信息，如果没有设置根元素则返回 null，图中蓝色部分区域。
       * item.boundingClientRect：目标元素的矩形区域的信息，图中黑色边框的区域。
       * item.intersectionRect：目标元素与视口（或根元素）的交叉区域的信息，图中蓝色方块和粉红色方块相交的区域。
       * item.isIntersecting：目标元素与根元素是否相交
       * item.intersectionRatio：目标元素与视口（或根元素）的相交比例。
       * item.target：目标元素，图中黑色边框的部分。
       */
      // 当前元素可见
      if (item.isIntersecting) {
        callback()
        // 解除观察当前元素 避免不可见时候再次调用callback函数
        observer.unobserve(item.target)
      }
    })
  }

  //实例化观察者对象
  var observer = new IntersectionObserver(observerCallback, observerOptions)

  //将目标元素传入观察对象
  observer.observe(target)
}

export default useObserver
