<template>
  <section id="my_exchange_record">
    <h1>兌換紀錄</h1>
    <div class="exchange_record_tabs">
      <b-tabs :expanded="isMobile" :destroy-on-hide="true" v-model="type" @input="onInput">
        <b-tab-item label="兌換記錄" value="record">
          <ExchangeList :type="type" :list="list" :loading="loading" @jump="jumpDetail" />
        </b-tab-item>
        <b-tab-item label="開獎結果" value="result">
          <ExchangeList :type="type" :list="list" :loading="loading" @jump="jumpDetail" />
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import ExchangeList from '../../components/exchangeRecord/List.vue'
import { getExchangeRecord, getLotteryResult } from '../../../api/exchange'

export default {
  name: 'ExchangeRecord',
  components: { ExchangeList },
  data() {
    return {
      type: 'record', // record:兌換記錄; result:開獎結果
      list: [],
      loading: false
    }
  },
  computed: {
    ...mapState(['isMobile'])
  },
  created() {
    // 如果是从红点事件(开奖提醒)过来的,直接切到开奖结果
    if (this.$route.query.action) this.type = 'result'
    this.exchangeListFn()
  },
  activated() {
    // 刷新开奖结果数据
    if (this.type === 'result') {
      this.exchangeListFn('result')
    }
  },
  methods: {
    onInput(value) {
      this.type = value
      // 清空数据
      this.list = []
      this.exchangeListFn(value)
    },
    async exchangeListFn(type = 'record') {
      this.loading = true
      try {
        const { code, result, message } = type === 'record' ? await getExchangeRecord() : await getLotteryResult()
        if (code === 200) {
          this.list = result || []
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    jumpDetail(item) {
      if (this.type === 'record') {
        window.location.href = `/nuxt/prizes/${item.prizesId}`
      } else {
        this.$router.push(`/my/exchange-record/${item.id}`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#my_exchange_record {
  max-width: 1000px;
  h1 {
    padding: 15px 0 10px 15px;
    font-size: 15px;
    font-weight: 500;
    color: #0a0a0a;
    line-height: 18px;
  }
  .exchange_record_tabs {
    min-height: 100vh;
    background-color: #f5f5f5;
    ::v-deep .tabs {
      background-color: #ffffff;
    }
  }
}

@media screen and (min-width: 1200px) {
  #my_exchange_record {
    max-width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #ffc2ae;
    }
    .exchange_record_tabs {
      background-color: #ffffff;
      padding-top: 30px;
    }
  }
}
</style>
