var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"specials"}},[_vm._m(0),_vm._v(" "),(!_vm.isLoading)?_c('carousel-3d',{key:_vm.slider3d,staticClass:"top",attrs:{"autoplay":true,"display":_vm.display,"perspective":0,"width":_vm.width,"height":_vm.height,"space":_vm.space,"border":0}},_vm._l((_vm.specials.top),function(item,i){return _c('slide',{key:i,attrs:{"index":i},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var isCurrent = ref.isCurrent;
var leftIndex = ref.leftIndex;
var rightIndex = ref.rightIndex;
return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{class:{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) },attrs:{"data-index":index,"src":item.image_url}}),_vm._v(" "),_c('h3',[_c('span',{staticClass:"has-text-black"},[_vm._v(_vm._s(item.title))])])])]}}],null,true)})}),1):_vm._e(),_vm._v(" "),(!_vm.isLoading)?_c('div',_vm._l((_vm.specials.categories),function(category,i){return _c('div',{staticClass:"category"},[_c('h2',[_vm._v(_vm._s(category.title))]),_vm._v(" "),_c('carousel-3d',{key:1000+i,attrs:{"display":_vm.display,"perspective":0,"width":_vm.width,"height":_vm.height,"space":_vm.space,"border":0}},_vm._l((_vm.top3items(category.specials)),function(item,i){return _c('slide',{key:i,attrs:{"index":i},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var isCurrent = ref.isCurrent;
var leftIndex = ref.leftIndex;
var rightIndex = ref.rightIndex;
return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{class:{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) },attrs:{"data-index":index,"src":item.image_url}}),_vm._v(" "),_c('h3',[_c('span',{staticClass:"has-text-black"},[_vm._v(_vm._s(item.title))])])])]}}],null,true)})}),1),_vm._v(" "),_c('router-link',{attrs:{"to":{ name: 'specials-category', params: { category: category.path }}}},[_c('b-button',{attrs:{"type":"is-black","rounded":""}},[_vm._v("更多獨家企劃")])],1)],1)}),0):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('span',{staticClass:"underline"},[_vm._v("獨家企劃")])])}]

export { render, staticRenderFns }